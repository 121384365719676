import { Component, OnInit } from '@angular/core';
import { SetupStatus } from '@enums';
import { environment } from '@environment/environment';
import { QueryService } from '@services/query.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: false,
})
export class MainComponent implements OnInit {
  public readonly setupStatusType = SetupStatus;
  private _setupStatus: SetupStatus = null;

  constructor(private queryService: QueryService) {}

  ngOnInit(): void {
    this._refreshSetupStatus();
  }

  public handleUpdateSuccess(): void {
    this._refreshSetupStatus();
  }

  public color(name: string): string {
    return window.getComputedStyle(document.querySelector('html')).getPropertyValue(name);
  }

  get setupStatus(): SetupStatus {
    return this._setupStatus;
  }

  private _refreshSetupStatus(): void {
    if (environment.platform === 'vp') {
      this.queryService.getSetupStatus().subscribe(s => (this._setupStatus = SetupStatus[s]));
    } else if (environment.platform === 'aws') {
      this._setupStatus = SetupStatus.SETUP_DONE;
    }
  }
}
