<ng-template *ngTemplateOutlet="header"></ng-template>

<mat-accordion>
  @for (contractForm of contractForms; track contractForm; let i = $index) {
    <ng-container [formGroup]="contractForms[i]">
      <mat-expansion-panel #contractPanel [expanded]="newlyAdded && i === contractForms.length - 1">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <div class="mt-3">
            <mat-panel-title>
              <h4>{{ contractVariableTitle(i) }}</h4>
            </mat-panel-title>
            <mat-panel-description>
              <p>{{ contractModel[i]?.displayName || '' }}</p>
            </mat-panel-description>
          </div>
        </mat-expansion-panel-header>
        @if (contractPanel.expanded) {
          <div>
            <div class="d-flex flex-row my-3 prices">
              <div
                class="vr-icon-trash vr-icon-force mr-3 size vr-icons"
                (click)="onDeleteVariable(i)"
                style="cursor: pointer"
                [title]="'Löschen'"
              ></div>
              <div
                class="vr-icon-reset mr-3 vr-icons"
                (click)="onResetVariable(i)"
                style="cursor: pointer; background-color: #808080"
                [title]="'Zurücksetzen'"
              ></div>
              <div
                class="vr-icon-duplicate mr-3 vr-icons"
                (click)="onCopyVariable(i)"
                style="cursor: pointer"
                [title]="'Duplizieren'"
              ></div>
            </div>
            <form [formGroup]="contractForms[i]">
              <formly-form
                [fields]="mainFields[i]"
                [form]="contractForm"
                [model]="contractModel[i]"
                (modelChange)="handleMapperTypeSelection($event, i)"
              ></formly-form>
            </form>
          </div>
        }
      </mat-expansion-panel>
      <hr />
    </ng-container>
  }
</mat-accordion>

<ng-template *ngTemplateOutlet="editIcons"></ng-template>

<ng-template #editIcons>
  @if (contractForms.length < 1000 && !isLoading) {
    <div style="cursor: pointer" class="d-flex flex-row align-items-center mt-3 add-price" (click)="onAddVariable()">
      <div class="vr-icon-add-circled mr-2"></div>
      <div>{{ title }} hinzufügen</div>
    </div>
  }
</ng-template>

<ng-template #header>
  <div class="header">
    <div class="d-flex pr-3 pb-3 bg-white">
      <div class="w-100">
        <div class="flex-grow-1">
          @if (title) {
            <h3 class="title mt-3 vr-headline-200">{{ title }}</h3>
          }
          @if (description) {
            <div class="mb-3" [class.mt-3]="!title">{{ description }}</div>
          }
          @if (contractForms.length === 0) {
            <div class="mt-3">{{ emptyMessage }}</div>
          }
        </div>
      </div>
    </div>
  </div>
</ng-template>
