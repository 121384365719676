<div class="dialog-container">
  <ng-container *ngIf="!selection; else selectionTemplate">
    <h1 mat-dialog-title>{{ title }}</h1>
    <div mat-dialog-content class="content">
      {{ text }}
      <ng-container *ngIf="custom && custom === 'csv'">
        <div>
          Bitte kontrollieren sie zunächst das Ergebnis der
          <span class="vr-color-brand-primary cursor-pointer" (click)="customAction('validation')">
            Inhaltsprüfung</span
          >
          und anschließend die
          <span class="vr-color-brand-primary cursor-pointer" (click)="customAction('changes')">
            Änderungshistorie</span
          >
          seit der letzten Synchronisierung
        </div>
        <div class="mt-3">Möchten Sie fortfahren?</div>
      </ng-container>
    </div>
    <ng-container>
      <div mat-dialog-actions>
        <ng-container *ngIf="custom; else regularButtons"></ng-container>
        <ng-container *ngIf="custom && custom === 'csv'"
          ><ng-container *ngTemplateOutlet="regularButtons"></ng-container
        ></ng-container>
        <ng-container *ngIf="custom && custom === 'deepcopy'">
          <button mat-button (click)="submitAction('deepcopy')" mat-flat-button color="basic">Deep Kopie</button>
          <button mat-button (click)="submitAction('copy')" mat-flat-button color="basic">Normale Kopie</button>
          <button mat-button (click)="closeAction()">Abbrechen</button>
        </ng-container>
        <ng-container *ngIf="custom && custom === 'customization'">
          <button mat-button (click)="submitAction('import')" mat-flat-button color="basic">Import</button>
          <button mat-button (click)="submitAction('export')" mat-flat-button color="basic">Export</button>
          <button mat-button (click)="closeAction()">Abbrechen</button>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #regularButtons>
  <button mat-button (click)="submitAction()" mat-flat-button color="warn">{{ customConfirm || 'Ja' }}</button>
  <button *ngIf="custom && custom !== 'csv'" mat-button (click)="customAction('submit')">{{ custom }}</button>
  <button mat-button (click)="closeAction()">Nein</button>
</ng-template>

<ng-template #selectionTemplate>
  <div class="vr-color-brand-primary font-weight-bold vr-text-small">
    <div
      class="cursor-pointer dialog-selection-element d-flex"
      *ngIf="selection.showTestConsultations"
      (click)="selectionAction('testConsultations')"
    >
      <div>Testberatung</div>
    </div>
    <div
      class="cursor-pointer dialog-selection-element"
      *ngIf="selection.showGraphs"
      (click)="selectionAction('showGraphs')"
    >
      <div>Grafische Ansicht</div>
    </div>
    <div
      class="cursor-pointer dialog-selection-element"
      *ngIf="selection.customization"
      (click)="selectionAction('customizations')"
    >
      <div>Individualisierung</div>
    </div>
    <div
      class="cursor-pointer dialog-selection-element"
      *ngIf="selection.resetHubShown"
      (click)="selectionAction('resetHub')"
    >
      <div>Inhalt zurücksetzen</div>
    </div>
    <div
      class="cursor-pointer dialog-selection-element"
      *ngIf="selection.licenseButtonShown"
      (click)="selectionAction('updateLicense')"
    >
      <div>Lizenz aktualisieren</div>
    </div>
  </div>
</ng-template>
