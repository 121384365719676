import { ContextEnum } from '@enums';
import { environment } from '@environment/environment';
import { DropDownData } from '@formItem/form/form.component';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/config.service';
import { EntityService } from '@services/entity.service';
import { lastValueFrom } from 'rxjs';

// Interfaces
export interface MapToFormData {
  currentEntity: string;
  data: any;
  model: ModelInterface;
  innerFormFields: string[];
  referenceRows: Map<any, any>;
  form: any;
  isProdInstance: boolean;
  currentContext: string;
  ignoreLocks: boolean;
  regularDescriptions: any[];
}

export interface FormModel {
  model: ModelInterface;
  fields: FormlyFieldConfig[];
}

export interface ModelInterface {
  application?: string;
  contentStream?: string;
  contentVersion?: string;
  status?: string;
  id?: string;
  itemReference?: number;
  metadata?: Metadata;
  openConsultations?: number;
  pairedQuestions?: string;
  jsonConditions?: string;
  type?: string;
  url?: string;
  prices?: string[];
  name?: string;
  adminName?: string;
  keyName?: string;
  value?: string;
  taskRefs?: number[];
  transitionRefs?: number[];
  productRefs?: number[];
  formRefs?: number[];
  attributeRefs?: number[];
  active?: boolean;
  jsonValidation?: string;
  groupId?: number;
  bankhubValue?: string;
  productRef?: number;
  linkedDataInstanceId?: number;
  gshAnswerContentId?: number;
  answerOptions?: number[];
  tool?: any;
  createLocks?: boolean;
  linkedDataInstanceIds?: number[];
  _lock?: any;
  compositionId?: number;
  price?: string;
  paymentInterval?: string;
  category?: string;
  template?: string;
  productId?: number;
  taskId?: number;
  transitionId?: number;
  maxValue?: number;
  minValue?: number;
  displayName?: string;
  ordinal?: number;
  description?: string;
  validation?: string;
  offset?: number;
  offsetUnit?: string;
  questionId?: string;
  mandatory?: boolean;
  _syncExcludedFields?: string[];
}

export interface Metadata {
  lockedFields?: string[];
  syncExcludedFields?: string[];
}

export interface GetRefData {
  data: any;
  currentEntity: string;
  currentContext: string;
  contentVersions: any[];
  innerFormFields: string[];
}

export interface GetRefResponse {
  referenceRows: Map<string, DropDownData[]>;
  contentVersions: any[];
}

export interface MapToFormResponse {
  fields: FormlyFieldConfig[];
  model: ModelInterface;
  innerFormFields: string[];
}

export interface EnumOption {
  value: number | string;
  label: string;
}

export class FormlyFunctions {
  private inactiveRows = [];

  constructor(
    private entityService: EntityService,
    private apiService: ApiService,
    private configService: ConfigService
  ) {}

  public mapToForm(data: MapToFormData): MapToFormResponse {
    const { model, currentEntity } = data;
    const { schemas } = data.data.components;
    const props = schemas[currentEntity]?.properties || null;
    const fields = [];

    if (!props) {
      return null;
    }

    // To-Do Backend needs to add QuestionId in Resource
    if (currentEntity === 'AnswerOptionContentResource') {
      props.questionId = { title: 'questionId', type: 'integer', $ref: 'QuestionContentResource', required: true };
    }

    const keys = Object.keys(props);
    const innerFormFields = data.innerFormFields.filter(x => keys.includes(x));

    keys.forEach(key => {
      if (props.hasOwnProperty(key)) {
        if (this.hasField(data, innerFormFields, key)) {
          const required = this.isFieldRequired(key, data.data, currentEntity);
          const field = props[key];
          const { minimum, maximum, maxLength, minLength, description, $ref } = field;
          let { type, title } = field;
          let property = model[key];
          let componentType = null;
          let inputType = null;
          let options = this.getEnumOptions(currentEntity, field, key);
          let multiple = false;
          let defaultValue = null;
          let textAreaRows = 4;

          if (this.isTypeArray($ref, field, key)) {
            type = 'array';
            try {
              options = data.referenceRows.get(key);
              if (key === 'pairedQuestionId' || key === 'syncedQuestionRefs') {
                options = options.filter(x => x.value !== model.id);
              }
            } catch {
              return;
            }
          }
          if (currentEntity === 'ThemaContentResource' && key === 'itemReference') {
            type = 'array';
            try {
              options = [...data.referenceRows?.get(key)];
              const inactiveOption = this.inactiveRows?.find(x => x.id === model.itemReference);
              if (inactiveOption) {
                let label = this.getOptionLabel(inactiveOption);
                options.push({ value: model.itemReference, label: label });
              }
            } catch {
              return;
            }
          }
          if (key === 'pairedQuestions' || key === 'jsonConditions') {
            type = 'string';
          }

          switch (type) {
            case 'integer': {
              componentType = 'input';
              inputType = 'number';
              break;
            }
            case 'number': {
              componentType = 'input';
              inputType = 'number';
              break;
            }
            case 'string': {
              if (options?.length) {
                componentType = 'ng-select';
                if (currentEntity === 'InstanceConfigRequest' && !model.id) {
                  if (key === 'contentStream') {
                    defaultValue = 'ZVB';
                  }
                  if (key === 'status') {
                    defaultValue = 'TEST';
                  }
                }
              } else {
                componentType = 'input';
                inputType = 'text';
                if (currentEntity === 'InstanceConfigRequest' && !model.id && key === 'application') {
                  defaultValue = 'BG ZV';
                }
              }
              break;
            }
            case 'boolean': {
              componentType = 'checkbox';
              defaultValue = key === 'showDisplayName' || key === 'active' || key === 'showByDefault';
              break;
            }
            case 'array': {
              componentType = 'ng-select';
              if (this.configService.m2mFields.includes(key)) {
                if (options?.length) {
                  const values = property?.map(p => options.find(o => JSON.stringify(p).includes(o.value.toString())));
                  property = values || [];
                  multiple = true;
                }
              } else {
                if (key === 'tool' && property) {
                  const { id, inputs, outputs } = property;
                  model['tool'] = id;
                  model['toolResources'] = { inputs, outputs };
                }
              }
              break;
            }
            default: {
              return;
            }
          }

          // if title is '', then use the key as label
          if (!title) {
            title = key;
          }

          // add empty value to options
          if (options?.length && !required && !multiple && key !== 'offsetUnit') {
            if (!options.find(x => x.label === '-') && !options.find(x => x.value === -1)) {
              if (key === 'pairedQuestionId') {
                options.unshift({ value: -1, label: 'Von Gruppe entfernen' });
              }
              options.unshift({ value: null, label: '-' });
            }
          }

          if (
            key === 'address' ||
            key === 'pairedQuestions' ||
            (key === 'value' && currentEntity === 'ReplacementMarkerContentResource')
          ) {
            componentType = 'textarea';
          }

          if (key === 'body' && currentEntity === 'MailTemplateContentResource') {
            componentType = 'textarea';
            textAreaRows = 8;
          }

          if (key === 'defaultMappings' && currentEntity === 'DataExportContentResource') {
            title = 'Mapping';
          }

          fields.push({
            key,
            type: componentType,
            defaultValue,
            className: this.isCustomizedField(key, model),
            hooks: {
              onInit: () => {
                data.form.enable();
              },
            },
            props: {
              label: title,
              options: options, // dynamic: can be adjusted
              allOptions: options, // static: keeping the original set of options
              description,
              required,
              multiple,
              selectAllOption: 'Select / Deselect ',
              type: inputType,
              min: minimum,
              max: maximum,
              maxLength,
              minLength,
              rows: textAreaRows,
              readonly: key === 'pairedQuestions',
              class: this.isCustomizedField(key, model),
              pattern: this.getPattern(key),
            },
            validators: {
              validation: [],
            },
            hideExpression: (model: ModelInterface, formState: any, field: FormlyFieldConfig) => {
              return this.isHidden(key, model, currentEntity);
            },
            expressionProperties: {
              'props.disabled': (model: ModelInterface, formState: any, field: FormlyFieldConfig) => {
                return this.isDisabled(key, currentEntity, model, data);
              },
            },
          });
        }
      }
    });
    this.entityService.isLoading.next(false);

    return { fields, model, innerFormFields };
  }

  private isTypeArray($ref: any, field: any, key: string): boolean {
    if (key === 'jsonConditions') return false;
    if ($ref) return true;
    if (field.items && key !== 'groups') return true;
    if (['importFrom', 'contentVersion', 'linkedDataInstanceId'].includes(key)) return true;
    return false;
  }

  private getPattern(key: string): RegExp | string {
    if (key === 'jsonAnswerOptions' || key === 'jsonConditions') {
      return /^(?:(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*')\s*:\s*(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*'|true|false|null|-?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?)\s*,?\s*)*(?:\{|\[)(?:(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*')\s*:\s*(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*'|true|false|null|-?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?)\s*,?\s*)*(?:(?:\{|\[)(?:(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*')\s*:\s*(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*'|true|false|null|-?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?)\s*,?\s*)*(?:\}|\]),?\s*)*(?:\}|\])\s*$/;
    }
    if (key === 'adminName' || key === 'displayName' || key === 'name') {
      return /.*\S.*/;
    }
    return '';
  }

  private isCustomizedField(key: string, model: ModelInterface): string {
    return model?.metadata && model?.metadata['syncExcludedFields']?.includes(key) ? 'customized' : '';
  }

  private hasRegularDescription(key: string, data: MapToFormData): boolean {
    const { currentEntity, regularDescriptions } = data;
    if (key === 'description') {
      return regularDescriptions.find(x => x === currentEntity.toLowerCase());
    } else {
      return true;
    }
  }

  private hasField(data: MapToFormData, innerFormFields: string[], key: string): boolean {
    const { currentEntity } = data;
    return (
      ((currentEntity !== 'CompositionContentResource' || !['taskRefs', 'transitionRefs'].includes(key)) &&
        this.hasRegularDescription(key, data) &&
        !['metadata', 'id'].includes(key) &&
        !innerFormFields.includes(key)) ||
      (currentEntity === 'DataFieldGroupContentResource' && ['productRefs', 'transitionRefs', 'taskRefs'].includes(key))
    );
  }
  /**
   * get the options for enum fields
   */
  private getEnumOptions(currentEntity: string, field: any, key: string) {
    const enumOptions = [];
    if (field.enum || field.items?.enum) {
      const currentEnum = field?.enum || field.items.enum;
      if (key === 'contentStream' && environment.platform !== 'aws') {
        for (const value of currentEnum) {
          if (value !== 'CUSTOM') {
            enumOptions.push({ value, label: this.configService.getEnumLabel(currentEntity, value) });
          }
        }
      } else {
        for (const value of currentEnum) {
          enumOptions.push({ value, label: this.configService.getEnumLabel(currentEntity, value) });
        }
      }
    }
    return enumOptions;
  }

  private isDisabled(key: string, currentEntity: string, model: ModelInterface, data: MapToFormData): boolean {
    const { isProdInstance, currentContext, ignoreLocks } = data;
    if (currentEntity === 'DataFieldContentResource' && (key === 'offset' || key === 'validation')) {
      return false;
    }
    return (
      (isProdInstance && currentContext !== ContextEnum.bankHub) ||
      (currentEntity === 'InstanceConfigRequest' && model.id && key === 'status' && model.status === 'PROD') ||
      currentEntity === 'InformationContentResource' ||
      currentEntity === 'InformationConfigResource' ||
      (currentEntity === 'UserConfigResource' && environment.platform === 'vp') ||
      (model.id &&
        model.metadata &&
        this.checkLock(model?.metadata?.lockedFields, key, currentContext, isProdInstance, ignoreLocks)) ||
      (currentContext === ContextEnum.bankHub &&
        currentEntity === 'InstanceConfigRequest' &&
        model.openConsultations > 0 &&
        key === 'linkedDataInstanceId') ||
      key === 'gshValue'
    );
  }

  private isHidden(key: string, model: ModelInterface, currentEntity: string): boolean {
    if (key === 'productRefs' || key === 'transitionRefs' || key === 'taskRefs') {
      return currentEntity === 'DataFieldGroupContentResource' && model.type === 'GENERAL';
    } else if (currentEntity === 'DataFieldGroupContentResource') {
      return key === 'dataFields';
    } else if (currentEntity === 'ThemaContentResource') {
      if (key === 'contentId') {
        return true;
      } else if (model.type !== 'SUBTOPIC') {
        return key === 'itemReference';
      }
    } else if (key === 'jsonValidation') {
      return model.type !== 'TEXT';
    } else if (currentEntity === 'DataExportContentResource') {
      if (!model.id) {
        return key === 'customMappings' || key === 'contentId';
      }
      return key === 'contentId' || key === 'defaultMappings';
    } else if (key === 'url') {
      return model.type !== 'LINK';
    } else if (key === 'externalId') {
      return environment.platform === 'aws';
    } else if (key === 'groups') {
      return environment.platform !== 'aws';
    } else if (currentEntity === 'InstanceConfigRequest') {
      if (
        (key === 'application' || key === 'autoUpdate' || key === 'contentStream' || key === 'contentVersion') &&
        model.status === 'PROD'
      ) {
        return true;
      } else if (key === 'live') {
        return model.status !== 'PROD';
      } else if (key === 'contentVersion') {
        return model.contentStream === 'NONE' || !model.contentStream;
      } else if (key === 'linkedDataInstanceId') {
        return model.status === 'TEST' || (!model.id && model.status !== 'PROD');
      } else {
        return key === 'createLocks';
      }
    }

    return key === 'contentId' || key === 'groupId' || key === 'id';
  }

  public checkLock(
    lockedFields: any,
    key: string,
    currentContext: string,
    isProdInstance: boolean,
    ignoreLocks: boolean
  ): boolean {
    if (isProdInstance && currentContext === ContextEnum.configApp) {
      return true;
    } else if (ignoreLocks) {
      return false;
    }
    if (lockedFields?.length) {
      return lockedFields.includes(key);
    } else {
      return false;
    }
  }

  private isFieldRequired(key: string, data: GetRefData, currentEntity: string): boolean {
    if (key === 'adminName' || key === 'questionId') return true;
    for (const field of data['components']['schemas'][currentEntity].required) {
      if (field === key) {
        if (key !== 'hasTextfield' && key !== 'quantityVisibility' && key !== 'confidentialInformation') {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * check whether the key is allowed to be displayed fullfilling one of two conditions:
   * - the key is not in the negative fields array
   * - if extended: the key is in the positive fields array
   * @returns boolean
   */
  private checkKey(key: string, data: GetRefData, extended: boolean): boolean {
    const fieldsNegative = [...data.innerFormFields, 'pairedQuestions', 'metadata', 'jsonConditions', 'groups'];
    const fieldsPositive = ['productRefs', 'transitionRefs', 'taskRefs'];
    return !fieldsNegative.includes(key) || (extended && fieldsPositive.includes(key));
  }

  public async getRef(data: GetRefData): Promise<GetRefResponse> {
    let { currentEntity, contentVersions } = data;
    const referenceRows = new Map<string, DropDownData[]>();
    const schemas = data.data['components']['schemas'];
    const props = schemas[currentEntity]?.properties;
    // To-Do Backend needs to add QuestionId in Resource
    if (currentEntity === 'AnswerOptionContentResource') {
      props.questionId = { title: 'questionId', type: 'integer', $ref: 'QuestionContentResource', required: true };
    }
    for (const key in props) {
      if (!props.hasOwnProperty(key)) break;
      if (this.checkKey(key, data, currentEntity === 'DataFieldGroupContentResource')) {
        let refString = this.getRefString(key, props, data);

        if (refString) {
          let referenceArray: DropDownData[] = [];
          let ref = this.adjustRef(refString);

          let rows = [];
          if (key === 'contentVersion') {
            rows = contentVersions = await lastValueFrom(
              this.apiService.getCAData(this.configService.getEndpoint(ref))
            );
          } else {
            if (
              key === 'importFrom' &&
              data.currentContext === ContextEnum.configApp &&
              (currentEntity === 'DataFieldContentResource' || currentEntity === 'DataFieldGroupContentResource')
            ) {
              rows = await lastValueFrom(this.apiService.getHubData(this.configService.getEndpoint(refString)));
            } else {
              const refPoint = this.configService.getEndpoint(ref);
              if (refPoint) {
                rows = await lastValueFrom(this.apiService.get(refPoint));
              }
            }
            if (currentEntity === 'QuestionContentResource' && refString === 'QuestionDataFieldGroupContentResource') {
              rows = rows.filter(x => x.type === 'GENERAL');
            }
            if (key === 'linkedDataInstanceId') {
              rows = rows.filter(x => x.status === 'TEST');
            }
            if (key === 'tool') {
              rows = await lastValueFrom(this.apiService.get(this.configService.getEndpoint('ToolContentResource')));
            }
          }
          if (key === 'benefitTypes') {
            const types = [
              { type: 'TIME', label: 'Zeit' },
              { type: 'MONEY', label: 'Geld' },
              { type: 'PROCESS', label: 'Prozess' },
              { type: 'REVENUE', label: 'Einkommen' },
              { type: 'ADVANTAGES', label: 'Vorteil' },
            ];
            referenceArray = types.map(type => ({ value: type.type, label: type.label }));
          } else {
            rows.forEach(row => {
              let label = this.getOptionLabel(row, key, ref);

              if (key === 'importFrom') {
                referenceArray.push({ value: row.contentId, label });
              } else {
                if (currentEntity === 'ThemaContentResource' && key === 'itemReference') {
                  //fill reference array for 'Contents'-tab itemReference field
                  if (row.active) {
                    referenceArray.push({ value: row.id, label });
                  } else {
                    this.inactiveRows.push(row);
                  }
                } else {
                  referenceArray.push({ value: key === 'contentVersion' ? row.version : row.id, label });
                }
              }
              referenceArray.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
            });
          }

          referenceRows.set(key, referenceArray);
        }
      }
    }
    return { referenceRows, contentVersions };
  }

  private adjustRef(refString: string): string {
    const refArray = refString.split('/');
    let ref = refArray[refArray.length - 1];

    if (ref === 'ExpertTransitionType') {
      return 'TransitionTypeContentResource';
    } else if (ref.includes('MediaContentResource')) {
      return 'MediaContentResource';
    } else if (ref.includes('TagContentResource')) {
      return 'TagContentResource';
    } else if (ref.includes('QuestionSyncContentResource')) {
      return 'QuestionContentResource';
    } else if (ref.includes('DataFieldGroupContentResource')) {
      return 'DataFieldGroupContentResource';
    }
    return ref;
  }

  private getRefString(key: string, props: any, data: GetRefData): string {
    const field = props[key];
    const { currentEntity, currentContext } = data;
    const schemas = data.data['components']['schemas'];

    if (key === 'contentVersion') {
      return 'contentVersion';
    } else if (key === 'linkedDataInstanceId') {
      return 'linkedDataInstanceId';
    } else if (
      key === 'importFrom' &&
      currentEntity === 'DataFieldContentResource' &&
      currentContext === ContextEnum.configApp
    ) {
      return 'DataFieldContentResource';
    } else if (
      key === 'importFrom' &&
      currentEntity === 'DataFieldGroupContentResource' &&
      currentContext === ContextEnum.configApp
    ) {
      return 'DataFieldGroupContentResource';
    } else if (currentEntity === 'ThemaContentResource' && key === 'itemReference') {
      return 'SubtopicContentResource';
    } else {
      if (field.$ref) {
        return field.$ref;
      } else if (field.items) {
        if (
          (currentEntity === 'QuestionGroupContentResource' && key === 'compositionRefs') ||
          (currentEntity === 'TaskContentResource' && key === 'compositionRefs')
        ) {
          return 'CompositionContentResource';
        } else if (currentEntity === 'QuestionGroupContentResource' && key === 'themaRefs') {
          return 'ThemaContentResource';
        } else if (field.items['$ref']) {
          const refRefResource = field.items['$ref'].split('/');
          const refResource = schemas[refRefResource[refRefResource.length - 1]]['properties'].id?.$ref;
          if (refResource) {
            const resource = refResource.split('/');
            return resource[resource.length - 1];
          } else {
            return refRefResource[refRefResource.length - 1];
          }
        } else {
          return 'DataFieldGroupContentResource';
        }
      }
    }
    return '';
  }

  private getOptionLabel(option: any, key?: string, ref?: string): string {
    let label = '';
    const labelId = option.id ? `[${option.id.slice(-6)}]` : '';

    if (option.adminName) {
      label = option.adminName;
    } else if (option.title) {
      label = option.title;
    } else if (!option.adminName && !option.value) {
      const version = key === 'contentVersion' ? `(${option.version})` : '';
      label = `${option.name} ${version}`;
    } else if (!option.adminName && option.value) {
      label = `${option.name}${option.value}`;
    }
    if (ref === 'ExpertContactContentResource') {
      label = `${option.firstName} ${option.lastName}`;
    }
    label = `${labelId} ${label}`;

    return label;
  }
}
