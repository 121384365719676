import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { VrIcons } from '@enums';
import { VrIconComponent } from '../vr-icon/vr-icon.component';

@Component({
  selector: 'vr-form-warning',
  templateUrl: './vr-form-warning.component.html',
  styleUrls: ['./vr-form-warning.component.scss'],
  standalone: false,
})
export class VrFormWarningComponent implements OnInit {
  @Input() message: string | null = null;

  @ViewChild('errorIcon') errorIcon: VrIconComponent;

  readonly buttonIcon = VrIcons;

  constructor() {}

  ngOnInit(): void {}
}
