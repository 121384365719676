@if (descriptionExists) {
  <div class="mt-3">
    <div
      class="description"
      [class.customized]="customized"
      [ngClass]="newData && 'smaller'"
      [class.disabled]="disabled"
    >
      Beschreibung
    </div>
    <ckeditor
      [editor]="Editor"
      [config]="EditorConfig"
      [(ngModel)]="newData"
      data="text"
      #ckeditor
      (change)="onChange($event)"
    ></ckeditor>
  </div>
}
