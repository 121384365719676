<div class="d-flex flex-column h-100 align-items-center bg-white">
  <!-- Use 'flex-center' instead of 'align-items-center' to center everything! -->
  <div class="d-flex flex-column flex-grow-1 flex-scroll w-100 flex-center">
    <!-- ------- -->
    <!-- CONTENT -->
    <!-- ------- -->
    <div class="d-flex flex-center">
      <img style="min-width: 30rem" src="{{ assetPath }}/images/logo-bg2-standard.svg" />
    </div>
    <div class="d-flex flex-center vr-color-neutral-900 vr-headline-300">BankingGuide Administration</div>
    <div class="my-3"></div>
    <ng-container *ngIf="authState !== 'requireNewPassword'">
      <ng-template *ngTemplateOutlet="loginFormTemplate; context: { form: loginForm }"></ng-template>
    </ng-container>
    <ng-container *ngIf="authState === 'requireNewPassword'">
      <ng-template *ngTemplateOutlet="passwordChangeFormTemplate; context: { form: passwordChangeForm }"></ng-template>
    </ng-container>
    <!-- ------- -->
    <!-- ------- -->
    <!-- ------- -->
  </div>
</div>

<!-- -------------------- LOGIN TEMPLATE -------------------- -->

<ng-template #loginFormTemplate let-form="form">
  <form [formGroup]="form" class="form-width" (ngSubmit)="handleLogin()">
    <!-- username -->
    <div class="form-group">
      <!-- <label class="vr-text-small" for="username">Name</label> -->
      <input
        type="text"
        formControlName="username"
        class="form-control login-input"
        placeholder="Name"
        [ngClass]="{ 'is-invalid': submitted && formUsername.errors }"
      />
      <div *ngIf="submitted && !!formUsername.errors" class="invalid-feedback">
        <div *ngIf="!!formUsername.errors.required">Benutzername ist notwendig</div>
      </div>
    </div>
    <!-- password -->
    <div class="form-group">
      <!-- <label class="vr-text-small" for="password">Passwort</label> -->
      <input
        [type]="showPassword ? 'text' : 'password'"
        formControlName="password"
        class="form-control login-input"
        placeholder="Passwort"
        [ngClass]="{ 'is-invalid': submitted && formPassword.errors }"
      />
      <span
        *ngIf="!!formPassword.value && showPassword"
        (click)="togglePassword()"
        class="vr-icon-view login-icon-span"
        style="cursor: pointer; pointer-events: all"
      ></span>
      <span
        *ngIf="!!formPassword.value && !showPassword"
        (click)="togglePassword()"
        class="vr-icon-view-off login-icon-span"
        style="cursor: pointer; pointer-events: all"
      >
      </span>
      <div *ngIf="submitted && !!formPassword.errors" class="invalid-feedback">
        <div *ngIf="!!formPassword.errors.required">Passwort ist notwending</div>
      </div>
    </div>
    <!-- login button -->
    <button [disabled]="loading" class="btn vr-text-regular login-button w-100">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1" style="vertical-align: unset"></span>
      Anmelden
    </button>
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{ error }}</div>
  </form>
</ng-template>

<!-- -------------------- PASSWORD SET TEMPLATE -------------------- -->

<ng-template #passwordChangeFormTemplate let-form="form">
  <div class="vr-text-regular">Bitte ändern Sie ihr temporäres Passwort</div>
  <div class="vr-text-extrasmall mb-4">(mindestens 8 Zeichen)</div>
  <form [formGroup]="form" class="form-width" (ngSubmit)="handlePasswordChange()">
    <div class="form-group">
      <!-- <label class="vr-text-small" for="password">Passwort</label> -->
      <input
        [type]="showPassword ? 'text' : 'password'"
        formControlName="password"
        class="form-control login-input"
        placeholder="Neues Passwort"
        [ngClass]="{ 'is-invalid': submitted && formChangePassword.errors }"
      />
      <ng-container *ngIf="!formChangePassword.errors">
        <span
          *ngIf="!!formChangePassword.value && showPassword"
          (click)="togglePassword()"
          class="vr-icon-view login-icon-span"
          style="cursor: pointer; pointer-events: all"
        ></span>
        <span
          *ngIf="!!formChangePassword.value && !showPassword"
          (click)="togglePassword()"
          class="vr-icon-view-off login-icon-span"
          style="cursor: pointer; pointer-events: all"
        ></span>
      </ng-container>
      <div *ngIf="submitted && !!formChangePassword.errors" class="invalid-feedback">
        <div *ngIf="!!formChangePassword.errors.required">Passwort ist notwending</div>
        <div *ngIf="!!formChangePassword.errors.minlength">Passwort muss mindestens 8 Zeichen enthalten</div>
      </div>
    </div>
    <!-- login button -->
    <button [disabled]="loading" class="btn vr-text-regular login-button w-100">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1" style="vertical-align: unset"></span>
      Passwort ändern
    </button>
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{ error }}</div>
  </form>
</ng-template>
