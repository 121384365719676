import { Directive, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTooltip } from '@angular/material/tooltip';

export enum FormType {
  Text = 'text',
  Textarea = 'textarea',
  Number = 'number',
  Dropdown = 'dropdown',
  Radio = 'radio',
  Date = 'date',
  Checkbox = 'checkbox',
  Upload = 'upload',
}

export interface VrSelectionData {
  label: string;
  value: string;
  checked?: boolean;
}

@Directive()
export abstract class VrFormBaseComponent implements OnChanges, OnDestroy {
  @Input() @Required vrFormControlName: string | number = null;
  @Input() @Required vrFormGroup: FormGroup = null;

  @Input() label: string;
  @Input() mandatory: boolean;
  @Input() disabled: boolean = false;
  @Input() item: any;
  @Input() selectionData: VrSelectionData[] = [];
  @Input() customErrorMessage: string;
  @Input() customRequiredMessage: string;
  @Input() testcafeData: string;
  @Input() description = '';
  @Input() tooltipText = undefined;

  // these inputs only do something if the item is not CheckoutDataFieldItem
  @Input() value: any;
  @Input() validationRegex: RegExp | string;

  @Output() valueChanged = new EventEmitter<any>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() focused = new EventEmitter<any>();

  public currentValue;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.disabled && changes.disabled.firstChange === false) {
      const control = this.vrFormGroup?.controls[this.vrFormControlName] as AbstractControl;
      changes.disabled.currentValue ? control.disable() : control.enable();
    }
  }

  ngOnDestroy(): void {
    this.vrFormGroup?.reset();
  }

  public genericFormGroup() {
    if (!this.isDataField(this.item)) {
      this.vrFormControlName =
        this.vrFormControlName && !this.vrFormControlName.toString().startsWith('genericControlName')
          ? this.vrFormControlName
          : 'genericControlName_' + this.makeRandomId(5);

      this.vrFormGroup = this.vrFormGroup ?? new FormGroup({});
      this.vrFormGroup.addControl(this.vrFormControlName.toString(), this.createFormControl(this.value ?? ''));
    }
  }

  public handleChange(event?: FocusEvent | Event | MatCheckboxChange): void {
    const control = this.vrFormGroup.controls[this.vrFormControlName] as AbstractControl;
    const value = this.vrFormGroup.value[this.vrFormControlName];

    const invalid = control.invalid;
    const requiredError = control?.errors?.required || false;

    if (this.currentValue !== value) {
      if (control.valid || (invalid && requiredError)) {
        this.currentValue = value;
        this.valueChanged.emit(value);
      }

      this.valid.emit(control.valid);
    }
  }
  public handleKeyup(event: KeyboardEvent, ref: HTMLElement = null) {
    if (event.key === 'Tab') {
      if (ref === document.activeElement || ref?.contains(document.activeElement)) {
        this.focused.emit({ ref: ref });
        ref.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }
  }

  public selectionDataFactory(): void {
    const parsed = JSON.parse(this.item.jsonAnswerOptions);
    Object.keys(parsed).forEach(x => {
      const data = {
        label: parsed[x],
        value: x,
        checked: false,
      } as VrSelectionData;
      this.selectionData.push(data);
    });
  }

  public handleTooltip(tooltip: MatTooltip, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    tooltip.toggle();
  }

  get formLabel(): string {
    // Input 'label' take precedent over 'item.name'
    return this.label || this.item?.name || '';
  }

  get isMandatory(): boolean {
    // TODO: If FormGroup and FormControl has Validator Required
    // Input 'mandatory' take precedent over 'item.mandatory'
    return this.mandatory || this.item?.mandatory || '';
  }

  get getError(): any {
    return this.vrFormGroup.controls[this.vrFormControlName]?.errors || null;
  }

  get errorMessage(): string {
    if (this.getError?.required) {
      return this.customRequiredMessage || 'Angabe erforderlich';
    } else if (this.getError?.pattern) {
      return this.customErrorMessage || 'Ungültiges Format';
    } else {
      return this.customErrorMessage;
    }
  }

  private isDataField(object: any = {}): boolean {
    return 'dataFieldValueId' in object;
  }

  private createFormControl(value: string): FormControl<string | null> {
    const validator = [];

    if (this.mandatory) {
      validator.push(Validators.required);
    }
    if (this.validationRegex) {
      validator.push(Validators.pattern(new RegExp(this.validationRegex)));
    }
    return new FormControl<string | null>(value, validator);
  }

  private makeRandomId(length: number): string {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}

export function Required(target: object, propertyKey: string) {
  Object.defineProperty(target, propertyKey, {
    get() {
      if (this.isDataField(this.item)) {
        throw new Error(`Attribute ${propertyKey} is required for DataFields`);
      }
    },
    set(value) {
      Object.defineProperty(target, propertyKey, {
        value,
        writable: true,
        configurable: true,
      });
    },
    configurable: true,
  });
}
