import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@modules/auth';
import { ContextService } from '@services/context.service';
import { EntityService } from '@services/entity.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private _authSub: Subscription;
  private _authErrorSub: Subscription;

  loginForm: FormGroup;
  passwordChangeForm: FormGroup;

  loading = false;
  submitted = false;
  showPassword: boolean;
  error: string;

  // temp
  authState: string;
  authObject: any;

  constructor(
    private router: Router,
    private contextService: ContextService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private entityService: EntityService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.passwordChangeForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    this._authSub = this.authService.currentAuthState.subscribe(authState => {
      if (authState === 'requireNewPassword') {
        console.log('NEW PASSWORD TO BE SET');
        this.authState = this.authService.authState;
        this.submitted = false;
        this.error = '';
        this.loading = false;
      } else if (authState === 'signedIn') {
        this.error = '';
        this.loading = false;

        this.router.navigate(['/configurator']);
      } else if (authState === 'signedOut') {
        this.error = '';
        this.loading = false;
      }
    });

    this._authErrorSub = this.authService.currentErrorState.subscribe(error => {
      if (!error) {
        return;
      }
      this.handleErrorState(error);
    });
  }

  ngOnDestroy(): void {
    this._authSub?.unsubscribe();
    this._authErrorSub?.unsubscribe();
  }

  public handleLogin(): void {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }

    this.authService
      .login(this.formUsername.value, this.formPassword.value)
      .then(session => {})
      .catch(e => {});
    this.error = '';
  }

  public handlePasswordChange(): void {
    this.submitted = true;
    this.loading = true;

    if (this.passwordChangeForm.invalid) {
      this.loading = false;
      return;
    }

    this.authService
      .login(this.formUsername.value, this.formPassword.value, this.formChangePassword.value)
      .then(session => {
        this.authState = '';
        this.error = '';
        this.submitted = false;
        this.loading = false;
        this.formPassword.reset();
      })
      .catch(e => {});
  }

  public handleErrorState(err): boolean {
    this.loading = false;
    console.log(err);
    if (!err || err === true || err.code === undefined) {
      this.error = '';
      return false;
    }

    if (err.code && err.code === 'NotAuthorizedException') {
      this.error = 'Falsches Passwort';
    }
    if (err.code && err.code === 'UserNotFoundException') {
      this.error = 'Nutzer exisitert nicht';
    }
    if (err.code && err.code === 'InvalidPasswordException') {
      this.error = 'Passwort entspricht nicht den Anforderungen';
    }
    if (err.code && err.code === 'NoContentAdmin') {
      this.error = 'Nutzer ist kein Content-Admin';
    }

    return true;
  }

  public togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  get formControls() {
    return this.loginForm.controls;
  }
  get formPassword() {
    return this.formControls.password;
  }
  get formUsername() {
    return this.formControls.username;
  }

  get formChangeControls() {
    return this.passwordChangeForm.controls;
  }
  get formChangePassword() {
    return this.formChangeControls.password;
  }

  get assetPath() {
    return this.contextService.assetPath;
  }
}
