<ng-container [formGroup]="vrFormGroup">
  <label class="vr-text-small vr-color-neutral-700 mb-2" [for]="getTestcafeId(label, placeholder)">
    {{ formLabel }}
    @if (isMandatory && formLabel) {
      <span class="vr-color-error">*</span>
    }
  </label>

  @if (files.length > 0) {
    @for (file of files; track file; let last = $last) {
      <div
        tabindex="0"
        class="d-flex align-items-center vr-color-neutral-700"
        style="height: 2.5rem"
        [class.divider]="!last || false"
        (keyup)="handleKeyup($event)"
      >
        <vr-icon
          [icon]="
            file.type.includes('image')
              ? buttonIcon.documentPicture
              : file.type.includes('pdf')
                ? buttonIcon.documentPdf
                : buttonIcon.document
          "
          class="mr-2"
        ></vr-icon>
        <div class="flex-grow-1 text-truncate">{{ file.name }}</div>
        <vr-button
          [icon]="buttonIcon.close"
          [options]="{ width: '1rem', height: '1rem', padding: '0' }"
          (click)="removeFile(file)"
        ></vr-button>
      </div>
    }
  }
  <label
    class="align-items-center vr-color-neutral-700 cursor-pointer mb-0 label"
    [ngClass]="uploadButtonShown ? 'd-flex' : 'd-none'"
    style="height: 2.5rem"
  >
    <vr-icon [icon]="buttonIcon.addCircled"></vr-icon>
    <div class="vr-font-regular pl-2">{{ placeholder }}</div>
    <input
      tabindex="0"
      type="file"
      class="input-file position-absolute"
      [attr.data-testcafe]="getTestcafeId(label, placeholder)"
      [formControlName]="vrFormControlName"
      [accept]="fileAccept"
      (change)="onFileSelect($event.target)"
      [id]="getTestcafeId(label, placeholder)"
    />
  </label>

  @if (getError) {
    <vr-form-warning [message]="errorMessage"></vr-form-warning>
  }
  <div class="mb-3"></div>
</ng-container>
