import { ContextEnum } from '@enums';
import { environment } from '@environment/environment';
import { DropDownData } from '@formItem/form/form.component';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/config.service';
import { EntityService } from '@services/entity.service';
import { lastValueFrom } from 'rxjs';

export interface MapToFormData {
  currentEntity: string;
  data: any;
  model: any;
  innerFormFields: any[];
  referenceRows: Map<any, any>;
  form: any;
  isProdInstance: boolean;
  currentContext: string;
  ignoreLocks: boolean;
  regularDescriptions: any[];
}

export interface GetRefData {
  data: any;
  currentEntity: string;
  currentContext: string;
  contentVersions: any[];
  innerFormFields: any[];
}

export interface GetRefResponse {
  referenceRows: Map<string, DropDownData[]>;
  contentVersions: any[];
}

export interface MapToFormResponse {
  fields: any[];
  model: any;
  innerFormFields: any[];
}

export class FormlyFunctions {
  constructor(
    private entityService: EntityService,
    private apiService: ApiService,
    private configService: ConfigService
  ) {}

  public mapToForm(data: MapToFormData): MapToFormResponse {
    const fields = [];
    const schemas = data.data['components']['schemas'];
    const props = schemas[data.currentEntity]?.properties || null;

    // To-Do Backend needs to add QuestionId in Resource
    if (data.currentEntity === 'AnswerOptionContentResource' && props != null) {
      props.questionId = { title: 'questionId', type: 'integer', $ref: 'QuestionContentResource', required: true };
    }
    if (!props) {
      return null;
    }

    const hasInnerFormField = [];

    for (const innerField of data.innerFormFields) {
      for (const key in props) {
        if (key === innerField.toString()) {
          hasInnerFormField.push(key);
        }
      }
    }

    Object.keys(props).forEach(key => {
      if (props.hasOwnProperty(key)) {
        if (
          (this.hasRegularDescription(key, data.currentEntity, data.regularDescriptions) &&
            key !== 'metadata' &&
            key !== 'id' &&
            !hasInnerFormField.includes(key)) ||
          (data.currentEntity === 'DataFieldGroupContentResource' && key === 'productRefs') ||
          (data.currentEntity === 'DataFieldGroupContentResource' && key === 'transitionRefs') ||
          (data.currentEntity === 'DataFieldGroupContentResource' && key === 'taskRefs')
        ) {
          if (data.currentEntity === 'CompositionContentResource' && (key === 'taskRefs' || key === 'transitionRefs')) {
            return;
          }
          let type = props[key].type;
          const minimum = props[key].minimum;
          const maximum = props[key].maximum;
          const maxLength = props[key].maxLength;
          const minLength = props[key].minLength;
          const description = props[key].description;
          let title = props[key].title;
          let componentType = null;
          let inputType = null;
          let isEnum = false;
          let enumOptions = [];
          let multiple = false;
          let defaultValue;
          let textAreaRows = 4;

          const required =
            key === 'adminName' || key === 'questionId'
              ? true
              : this.isFieldRequired(key, data.data, data.currentEntity);

          // checking if the field is an enum
          if (props[key].enum || props[key].items?.enum) {
            isEnum = true;
            let currentEnum;
            currentEnum = props[key].enum ? props[key].enum : props[key].items.enum;
            if (key === 'contentStream' && environment.platform !== 'aws') {
              for (const value of currentEnum) {
                if (value !== 'CUSTOM') {
                  enumOptions.push({ value, label: this.configService.getEnumLabel(data.currentEntity, value) });
                }
              }
            } else {
              for (const value of currentEnum) {
                enumOptions.push({ value, label: this.configService.getEnumLabel(data.currentEntity, value) });
              }
            }
          }

          const refString = props[key].$ref;

          if (
            (refString ||
              (props[key].items && key !== 'groups') ||
              key === 'importFrom' ||
              key === 'contentVersion' ||
              key === 'linkedDataInstanceId') &&
            key !== 'jsonConditions'
          ) {
            type = 'array';
            try {
              enumOptions = data.referenceRows.get(key);
              if (key === 'pairedQuestionId' || key === 'syncedQuestionRefs') {
                enumOptions = enumOptions.filter(x => {
                  return x.value !== data.model.id;
                });
              }
            } catch {
              return;
            }
          }
          if (data.currentEntity === 'ThemaContentResource' && key === 'itemReference') {
            type = 'array';
            try {
              enumOptions = data.referenceRows.get(key);
            } catch {
              return;
            }
          }
          if (key === 'pairedQuestions' || key === 'jsonConditions') {
            type = 'string';
          }

          switch (type) {
            case 'integer': {
              componentType = 'input';
              inputType = 'number';
              break;
            }
            case 'number': {
              componentType = 'input';
              inputType = 'number';
              break;
            }
            case 'string': {
              if (isEnum) {
                componentType = 'ng-select';
                if (data.currentEntity === 'InstanceConfigRequest' && !data.model.id) {
                  if (key === 'contentStream') {
                    defaultValue = 'ZVB';
                  }
                  if (key === 'status') {
                    defaultValue = 'TEST';
                  }
                }
              } else {
                componentType = 'input';
                inputType = 'text';
                if (data.currentEntity === 'InstanceConfigRequest' && !data.model.id && key === 'application') {
                  defaultValue = 'BG ZV';
                }
              }
              break;
            }
            case 'boolean': {
              componentType = 'checkbox';
              defaultValue = key === 'showDisplayName' || key === 'active' || key === 'showByDefault';
              break;
            }
            case 'array': {
              componentType = 'ng-select';
              if (this.configService.m2mFields.includes(key)) {
                const selectedValues = [];

                if (enumOptions && enumOptions.length > 0) {
                  for (const element of enumOptions) {
                    if (data.model[key]) {
                      data.model[key].forEach(model => {
                        if (JSON.stringify(model).includes(element.value.toString())) {
                          selectedValues.push(model);
                        }
                      });
                    }
                  }
                  multiple = true;
                  data.model[key] = selectedValues;
                }
              } else {
                if (key === 'tool') {
                  if (data.model[key]?.id) {
                    data.model['toolResources'] = data.model[key];
                    data.model[key] = data.model[key]?.id;
                  }
                }
              }
              break;
            }
            default: {
              return;
            }
          }

          // if title is '', then use the key as label
          if (!title || title === '') {
            title = key;
          }

          // add empty value to options
          if (enumOptions?.length > 0 && !required && !multiple) {
            if (!enumOptions.find(x => x.label === '-') && !enumOptions.find(x => x.value === -1)) {
              if (key === 'pairedQuestionId') {
                enumOptions.unshift({ value: -1, label: 'Von Gruppe entfernen' });
              }
              enumOptions.unshift({ value: null, label: '-' });
            }
            if (!defaultValue) {
              defaultValue = null;
            }
          }

          if (
            key === 'address' ||
            key === 'pairedQuestions' ||
            (key === 'value' && data.currentEntity === 'ReplacementMarkerContentResource')
          ) {
            componentType = 'textarea';
          }

          if (key === 'body' && data.currentEntity === 'MailTemplateContentResource') {
            componentType = 'textarea';
            textAreaRows = 8;
          }

          if (key === 'defaultMappings' && data.currentEntity === 'DataExportContentResource') {
            title = 'Mapping';
          }

          const validation = [];
          let pat;

          if (key === 'jsonAnswerOptions' || key === 'jsonConditions') {
            pat =
              /^(?:(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*')\s*:\s*(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*'|true|false|null|-?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?)\s*,?\s*)*(?:\{|\[)(?:(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*')\s*:\s*(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*'|true|false|null|-?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?)\s*,?\s*)*(?:(?:\{|\[)(?:(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*')\s*:\s*(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*'|true|false|null|-?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?)\s*,?\s*)*(?:\}|\]),?\s*)*(?:\}|\])\s*$/;
          }
          if (key === 'adminName' || key === 'displayName' || key === 'name') {
            pat = /.*\S.*/;
          }

          fields.push({
            key: key,
            type: componentType,
            defaultValue: defaultValue,
            className: this.isCustomizedField(key, data.model),
            hooks: {
              onInit: () => {
                data.form.enable();
              },
            },
            props: {
              label: title,
              options: enumOptions,
              description: description,
              required: required,
              multiple: multiple,
              selectAllOption: 'Select / Deselect ',
              type: inputType,
              min: minimum,
              max: maximum,
              maxLength: maxLength,
              minLength: minLength,
              rows: textAreaRows,
              readonly: key === 'pairedQuestions',
              class: this.isCustomizedField(key, data.model),
              pattern: pat,
            },
            validators: {
              validation,
            },
            hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
              return this.isHidden(key, model, data.currentEntity);
            },
            expressionProperties: {
              'props.disabled': (model: any, formState: any, field: FormlyFieldConfig) => {
                return this.isDisabled(
                  key,
                  data.currentEntity,
                  model,
                  data.isProdInstance,
                  data.currentContext,
                  data.ignoreLocks
                );
              },
            },
          });
        }
      }
    });
    this.entityService.isLoading.next(false);

    return { fields, model: data.model, innerFormFields: hasInnerFormField };
  }

  private isCustomizedField(key: string, model: any): string {
    return model?.metadata && model?.metadata['syncExcludedFields']?.includes(key) ? 'customized' : '';
  }

  private hasRegularDescription(key, currentEntity, regularDescriptions): boolean {
    if (key === 'description') {
      return regularDescriptions.find(x => x === currentEntity.toLowerCase());
    } else {
      return true;
    }
  }

  private isDisabled(key, currentEntity, model, isProdInstance, currentContext, ignoreLocks): boolean {
    return (
      (isProdInstance && currentContext !== ContextEnum.bankHub) ||
      (currentEntity === 'InstanceConfigRequest' && model.id && key === 'status' && model.status === 'PROD') ||
      currentEntity === 'InformationContentResource' ||
      currentEntity === 'InformationConfigResource' ||
      (currentEntity === 'UserConfigResource' && environment.platform === 'vp') ||
      (model.id &&
        model.metadata &&
        this.checkLock(model?.metadata?.lockedFields, key, currentContext, isProdInstance, ignoreLocks)) ||
      (currentContext === ContextEnum.bankHub &&
        currentEntity === 'InstanceConfigRequest' &&
        model.openConsultations > 0 &&
        key === 'linkedDataInstanceId') ||
      key === 'gshValue'
    );
  }

  private isHidden(key, model, currentEntity): boolean {
    if (key === 'productRefs' || key === 'transitionRefs' || key === 'taskRefs') {
      return currentEntity === 'DataFieldGroupContentResource' && model.type === 'GENERAL';
    } else if (currentEntity === 'DataFieldGroupContentResource') {
      return key === 'dataFields';
    } else if (currentEntity === 'ThemaContentResource') {
      if (key === 'contentId') {
        return true;
      } else if (model.type !== 'SUBTOPIC') {
        return key === 'itemReference';
      }
    } else if (key === 'jsonValidation') {
      return model.type !== 'TEXT';
    } else if (currentEntity === 'DataExportContentResource') {
      if (!model.id) {
        return key === 'customMappings' || key === 'contentId';
      }
      return key === 'contentId' || key === 'defaultMappings';
    } else if (key === 'url') {
      return model.type !== 'LINK';
    } else if (key === 'externalId') {
      return environment.platform === 'aws';
    } else if (key === 'groups') {
      return environment.platform !== 'aws';
    } else if (currentEntity === 'InstanceConfigRequest') {
      if (
        (key === 'application' || key === 'autoUpdate' || key === 'contentStream' || key === 'contentVersion') &&
        model.status === 'PROD'
      ) {
        return true;
      } else if (key === 'live') {
        return model.status !== 'PROD';
      } else if (key === 'contentVersion') {
        return model.contentStream === 'NONE' || !model.contentStream;
      } else if (key === 'linkedDataInstanceId') {
        return model.status === 'TEST' || (!model.id && model.status !== 'PROD');
      } else {
        return key === 'createLocks';
      }
    }

    return key === 'contentId' || key === 'groupId' || key === 'id';
  }

  public checkLock(lock, key, currentContext, isProdInstance, ignoreLocks): boolean {
    if (isProdInstance && currentContext === ContextEnum.configApp) {
      return true;
    } else if (ignoreLocks) {
      return false;
    }
    if (lock && lock.length > 0) {
      if (lock.includes(key)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private isFieldRequired(key: string, data, currentEntity): boolean {
    for (const field of data['components']['schemas'][currentEntity].required) {
      if (field === key) {
        if (key !== 'hasTextfield' && key !== 'quantityVisibility' && key !== 'confidentialInformation') {
          return true;
        }
      }
    }
    return false;
  }

  public async getRef(data: GetRefData): Promise<GetRefResponse> {
    const referenceRows = new Map<string, DropDownData[]>();
    const schemas = data.data['components']['schemas'];
    const props = schemas[data.currentEntity]?.properties;
    // To-Do Backend needs to add QuestionId in Resource
    if (data.currentEntity === 'AnswerOptionContentResource') {
      props.questionId = { title: 'questionId', type: 'integer', $ref: 'QuestionContentResource', required: true };
    }
    for (const key in props) {
      if (
        (props.hasOwnProperty(key) &&
          key !== 'pairedQuestions' &&
          key !== 'metadata' &&
          key !== 'jsonConditions' &&
          !data.innerFormFields.includes(key) &&
          key !== 'groups') ||
        (data.currentEntity === 'DataFieldGroupContentResource' && key === 'productRefs') ||
        (data.currentEntity === 'DataFieldGroupContentResource' && key === 'transitionRefs') ||
        (data.currentEntity === 'DataFieldGroupContentResource' && key === 'taskRefs')
      ) {
        let ref = '';
        let refString;

        if (key === 'contentVersion') {
          refString = 'contentVersion';
        } else if (key === 'linkedDataInstanceId') {
          refString = 'linkedDataInstanceId';
        } else if (
          key === 'importFrom' &&
          data.currentEntity === 'DataFieldContentResource' &&
          data.currentContext === ContextEnum.configApp
        ) {
          refString = 'DataFieldContentResource';
        } else if (
          key === 'importFrom' &&
          data.currentEntity === 'DataFieldGroupContentResource' &&
          data.currentContext === ContextEnum.configApp
        ) {
          refString = 'DataFieldGroupContentResource';
        } else if (data.currentEntity === 'ThemaContentResource' && key === 'itemReference') {
          refString = 'SubtopicContentResource';
        } else {
          if (props[key].$ref) {
            refString = props[key].$ref;
          } else if (props[key].items) {
            if (
              (data.currentEntity === 'QuestionGroupContentResource' && key === 'compositionRefs') ||
              (data.currentEntity === 'TaskContentResource' && key === 'compositionRefs')
            ) {
              refString = 'CompositionContentResource';
            } else if (data.currentEntity === 'QuestionGroupContentResource' && key === 'themaRefs') {
              refString = 'ThemaContentResource';
            } else if (props[key].items['$ref']) {
              const refRefResource = props[key].items['$ref'].split('/');
              const refResource = schemas[refRefResource[refRefResource.length - 1]]['properties'].id?.$ref;
              if (refResource) {
                const resource = refResource.split('/');
                refString = resource[resource.length - 1];
              } else {
                refString = refRefResource[refRefResource.length - 1];
              }
            } else {
              refString = 'DataFieldGroupContentResource';
            }
          }
        }

        if (refString) {
          let referenceArray: DropDownData[] = [];
          const refArray = refString.split('/');
          ref = refArray[refArray.length - 1];

          if (ref === 'ExpertTransitionType') {
            ref = 'TransitionTypeContentResource';
          } else if (ref.includes('MediaContentResource')) {
            ref = 'MediaContentResource';
          } else if (ref.includes('TagContentResource')) {
            ref = 'TagContentResource';
          } else if (ref.includes('QuestionSyncContentResource')) {
            ref = 'QuestionContentResource';
          } else if (ref.includes('DataFieldGroupContentResource')) {
            ref = 'DataFieldGroupContentResource';
          }

          let rows = [];
          if (key === 'contentVersion') {
            rows = data.contentVersions = await lastValueFrom(
              this.apiService.getCAData(this.configService.getEndpoint(ref))
            );
          } else {
            if (
              key === 'importFrom' &&
              data.currentContext === ContextEnum.configApp &&
              (data.currentEntity === 'DataFieldContentResource' ||
                data.currentEntity === 'DataFieldGroupContentResource')
            ) {
              rows = await lastValueFrom(this.apiService.getHubData(this.configService.getEndpoint(refString)));
            } else {
              const refPoint = this.configService.getEndpoint(ref);
              if (refPoint) {
                rows = await lastValueFrom(this.apiService.get(refPoint));
              }
            }
            if (
              data.currentEntity === 'QuestionContentResource' &&
              refString === 'QuestionDataFieldGroupContentResource'
            ) {
              rows = rows.filter(x => x.type === 'GENERAL');
            }
            if (key === 'linkedDataInstanceId') {
              rows = rows.filter(x => x.status === 'TEST');
            }
            if (key === 'tool') {
              rows = await lastValueFrom(this.apiService.get(this.configService.getEndpoint('ToolContentResource')));
            }
          }
          if (key === 'benefitTypes') {
            const types = [
              { type: 'TIME', label: 'Zeit' },
              { type: 'MONEY', label: 'Geld' },
              { type: 'PROCESS', label: 'Prozess' },
              { type: 'REVENUE', label: 'Einkommen' },
              { type: 'ADVANTAGES', label: 'Vorteil' },
            ];
            referenceArray = types.map(type => ({ value: type.type, label: type.label }));
          } else {
            for (const row of rows) {
              let label;
              const labelId = row.id ? `[${row.id.slice(-6)}]` : '';
              if (row.adminName) {
                label = row.adminName;
              } else if (row.title) {
                label = row.title;
              } else if (!row.adminName && !row.value) {
                const version = key === 'contentVersion' ? `(${row.version})` : '';
                label = `${row.name} ${version}`;
              } else if (!row.adminName && row.value) {
                label = `${row.name}${row.value}`;
              }
              if (ref === 'ExpertContactContentResource') {
                label = `${row.firstName} ${row.lastName}`;
              }
              label = `${labelId} ${label}`;

              if (key === 'importFrom') {
                referenceArray.push({ value: row.contentId, label });
              } else {
                referenceArray.push({ value: key === 'contentVersion' ? row.version : row.id, label });
              }
              referenceArray.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
            }
          }

          referenceRows.set(key, referenceArray);
        }
      }
    }
    return { referenceRows: referenceRows, contentVersions: data.contentVersions };
  }
}
