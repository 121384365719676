import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/config.service';
import cloneDeep from 'lodash-es/cloneDeep';
import { ToastrService } from 'ngx-toastr';
import { Subscription, lastValueFrom } from 'rxjs';

interface AttributeSelection {
  name: string;
  contentId: string;
  description: string;
}

@Component({
  selector: 'app-answer-form',
  templateUrl: './answer-form.component.html',
  styleUrls: ['./answer-form.component.scss'],
  standalone: false,
})
export class AnswerFormComponent implements OnDestroy {
  constructor(
    private apiService: ApiService,
    private toastrService: ToastrService,
    private configService: ConfigService,
    private readonly formBuilder: FormBuilder
  ) {}

  @Input() chosenAttributes;
  @Input() changesInRefs: boolean = false;
  @Output() innerFormChanged = new EventEmitter();
  @Input() inQuestion: boolean = false;

  private _attributeCreatedSub: Subscription;

  private attributeData = [];
  private initialAttributes = [];
  private reducedAttributeSelection: AttributeSelection[] = [];

  public attributeSelection: AttributeSelection[] = [];
  public formGroups: FormGroup[] = [];
  public isLoading = false;
  public newlyAdded = false;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.configService.isHubApi()) {
      if (changes.chosenAttributes?.currentValue !== changes.chosenAttributes?.previousValue) {
        this.createAttributes();
      } else if (changes.chosenAttributes.currentValue === undefined) {
        this.createAttributes();
      }
    }
  }

  ngOnDestroy(): void {
    this._attributeCreatedSub?.unsubscribe();
  }

  private async createAttributes() {
    this.isLoading = true;
    this.formGroups = [];
    this.attributeData = await lastValueFrom(this.apiService.getHubData('global-answers'));

    this.attributeSelection = this.attributeData.reduce((rv, x) => {
      const v = x.name;
      const el = rv.find(r => r && r.name === v);
      if (el) {
        el.details = { name: x.name, description: x.description, contentId: x.contentId, id: x.id };
      } else {
        rv.push({ name: x.name, description: x.description, contentId: x.contentId, id: x.id });
      }
      return rv;
    }, []);

    this.reducedAttributeSelection = cloneDeep(this.attributeSelection);
    this.setInitialValues();
    this.createFormGroups();
  }

  private setInitialValues() {
    this.initialAttributes = [];
    this.initialAttributes = this.attributeData.filter(
      attribute => attribute.contentId === this.chosenAttributes.gshAnswerContentId
    );
  }

  private createFormGroups() {
    this.formGroups = [];
    for (let i = 0; i < this.initialAttributes.length; i++) {
      this.addFormGroup(
        this.initialAttributes[i].name,
        this.initialAttributes[i].value,
        this.initialAttributes[i].contentId
      );
    }
    if (this.initialAttributes.length === 0) {
      this.addFormGroup('-', '-', '-');
    }

    this.isLoading = false;
  }

  private addFormGroup(name, value, contentId?) {
    const group = this.formBuilder.group({
      name: new FormControl<string | null>(name),
      value: new FormControl<string | null>(value),
      customName: new FormControl<string | null>(null),
      customValue: new FormControl<string | null>(null),
      contentId: new FormControl<string | null>(contentId),
      id: new FormControl(),
    });

    this.formGroups.push(group);
  }

  public getOptions(contentId) {
    const options = this.reducedAttributeSelection.find(x => x.contentId === contentId);
    return options ? options.description : [];
  }

  public addAttribute() {
    this.addFormGroup('-', '-');
    this.emitData();
    this.newlyAdded = true;
  }

  public handleAttributeNameChange(event: MatSelectChange, index: number) {
    this.emitData();
  }

  public handleAttributeValueChange(event: MatSelectChange, index: number) {
    this.emitData();
  }

  public emitData(): void {
    this.innerFormChanged.emit(this.formGroups);
  }

  public isCustomAttribute(index: number): boolean {
    return this.formGroups[index].get('contentId').value === '_newAttribute';
  }
}
