<div class="my-3" [class.title]="!inQuestion" [class.title-question]="inQuestion" [class.customized]="changesInRefs">
  AntwortIDs
</div>
@if (isLoading) {
  <!--LoadingSpinner-->
  <div class="d-flex w-100 justify-content-center">
    <mat-spinner [ngClass]="'d-inline'"></mat-spinner>
  </div>
} @else {
  @for (form of formGroups; track form; let i = $index) {
    <div>
      <!--Atribute-->
      @if (attributeSelection) {
        <form [formGroup]="form">
          <!--Attribute-Name-->
          <div class="w-100">
            <ng-select
              [placeholder]="'AntwortID Name'"
              [appendTo]="'body'"
              [virtualScroll]="false"
              [clearable]="false"
              [notFoundText]="'Kein Ergebnis'"
              [formControlName]="'contentId'"
              (change)="handleAttributeNameChange($event, i)"
            >
              <ng-option [value]="'undefined'">-</ng-option>
              <ng-option [value]="'_newAttribute'">Neue AntwortID anlegen</ng-option>
              @for (attribute of attributeSelection; track attribute) {
                <ng-option [value]="attribute.contentId"> [{{ attribute.contentId }}] {{ attribute.name }} </ng-option>
              }
            </ng-select>
          </div>
          <!--New Attribute-Name-->
          <mat-form-field [class.d-none]="!isCustomAttribute(i)" class="w-100">
            <mat-label>Neuer Name</mat-label>
            <input [formControlName]="'customName'" matInput (blur)="emitData()" />
          </mat-form-field>
          <!--Attribute-Value-->
          <div [class.d-none]="isCustomAttribute(i)" class="w-100">
            <mat-label>Beschreibung</mat-label>
            <div>{{ this.getOptions(form.value.contentId) }}</div>
          </div>
          <!--Custom-Value-->
          <mat-form-field [class.d-none]="!isCustomAttribute(i)" class="w-100">
            <mat-label>Neue Beschreibung</mat-label>
            <input type="text" [formControlName]="'customValue'" matInput (blur)="emitData()"
          /></mat-form-field>
          <hr />
        </form>
      }
    </div>
  }
  <!--Add Attribute Button-->
  @if (!chosenAttributes?.length && formGroups?.length === 0) {
    <div style="cursor: pointer" class="d-flex flex-row align-items-center mt-3 add-price" (click)="addAttribute()">
      <div class="vr-icon-add-circled mr-2"></div>
      <div>AntwortID einbinden</div>
    </div>
  }
}
