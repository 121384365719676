import { Injectable } from '@angular/core';
import { IApiEndpointService, INavigationService } from '@de.fiduciagad.kundenportal/ui-context';
import { environment } from '@environment/environment';
import { timer } from 'rxjs';
import { PortalContextProvider } from './portal-context.provider';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private _assetPath = '';

  public stickyMode: boolean = false;

  constructor(private portalContextProvider: PortalContextProvider) {
    // const navigationService =
    //   (this.portalContextProvider.portalContext?.getNavigationService() as INavigationService) || null;
    // const portalBasePath = navigationService?.getPortalBasePath() || '';
    // const webComponentsPathAtReverseProxy = 'webcomponents';
    // const webComponentPath = 'bankingguide-admin'; // 'bgzv-frontend-admin';
    // this.assetPath = `${portalBasePath}${webComponentsPathAtReverseProxy}/${webComponentPath}`;

    // console.log(`%c [bgzv-frontend-admin] CONTEXT SERVICE ASSET PATH`, 'color: #66ff00', this.assetPath);

    this.init();
  }

  private init() {
    if (environment.platform === 'vp' && (!environment.isLocal || window.location.pathname.includes('/bws'))) {
      const navigationService =
        (this.portalContextProvider.portalContext?.getNavigationService() as INavigationService) || null;
      const portalBasePath = navigationService?.getPortalBasePath() || '';

      if (portalBasePath.includes('/bws')) {
        this.assetPath = (
          this.portalContextProvider.portalContext.getApiEndpointService() as IApiEndpointService
        ).getServiceUrl('bgzv-frontend-admin', 'assets');
        this.assetPath = `${window.location.origin}${portalBasePath}serviceproxy/bgzv-frontend-admin`;
        console.log(`%c [bgzv-frontend-admin] CONTEXT SERVICE ASSET PATH IN BWS`, 'color: #66ff00', this.assetPath);
      } else {
        const webComponentsPathAtReverseProxy = 'webcomponents';
        const webComponentPath = 'bankingguide-admin';
        this.assetPath = `${portalBasePath}${webComponentsPathAtReverseProxy}/${webComponentPath}`;
        console.log(`%c [bgzv-frontend-admin] CONTEXT SERVICE ASSET PATH ON OKP`, 'color: #66ff00', this.assetPath);
      }
    } else {
      this.assetPath = '';
    }

    // var allClasses = [];

    // var allElements = document.querySelectorAll('*');

    // for (let element of allElements) {
    //   var classes = element.className.toString().split(/\s+/);
    //   for (let cls of classes) {
    //     if (cls && !allClasses.includes(cls)) {
    //       var style = getComputedStyle(element);
    //       if (style.maskImage !== 'none') {
    //         allClasses.push(cls);
    //       }
    //     }
    //   }
    // }
    // console.log(allClasses);
  }

  public toggleStickyMode(mode: 'sticky' | 'normal' | null = null): boolean {
    // const content = document.querySelector('#bgzv-content');
    const el = document.getElementsByTagName('bgzv-frontend-admin');

    if (!el || el.length === 0) {
      return false;
    }
    const content = el[0];

    if (content?.classList.contains('noscroll') || mode === 'normal') {
      this.stickyMode = false;

      if (window.location.pathname?.includes('/bws')) {
        document.getElementsByTagName('bws-header')[0]?.classList.remove('d-none');
      } else {
        document.getElementsByTagName('lib-portal-header')[0]?.classList.remove('d-none');
        document.getElementsByTagName('lib-portal-footer')[0]?.classList.remove('d-none');
        document.getElementsByTagName('navigation-component')[0]?.classList.remove('d-none');
      }
      content?.classList.remove('noscroll');
      content.scrollIntoView();
    } else {
      content.scrollIntoView({ behavior: 'smooth' });
      this.stickyMode = true;
      const timerSub = timer(250).subscribe(() => {
        if (window.location.pathname?.includes('/bws')) {
          document.getElementsByTagName('bws-header')[0]?.classList.toggle('d-none');
        } else {
          document.getElementsByTagName('lib-portal-header')[0]?.classList.toggle('d-none');
          document.getElementsByTagName('lib-portal-footer')[0]?.classList.toggle('d-none');
          document.getElementsByTagName('navigation-component')[0]?.classList.toggle('d-none');
        }
        content.classList.toggle('noscroll');
        timerSub.unsubscribe();
      });
    }

    return this.stickyMode;
  }

  public get assetPath(): string {
    const a = this._assetPath || '';
    return a ? `${a}/assets` : `assets`;
  }
  public set assetPath(value: string) {
    this._assetPath = value;
  }

  get hasPortalHeader(): boolean {
    if (window.location.pathname?.includes('/bws')) {
      return document.getElementsByTagName('bws-header').length > 0;
    } else {
      return document.getElementsByTagName('lib-portal-header').length > 0;
    }
  }
}
