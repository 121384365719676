import { FormControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export interface FormlyValidators {
  name: string;
  validation: (control: FormControl<string | null>) => ValidationErrors;
}

export interface FormlyValidatorMessages {
  name: string;
  message: ((error, field: FormlyFieldConfig) => string) | string;
}

/////////////////////////
///     Validators    ////
////////////////////////

const minLengthValidationMessage = (err, field) => {
  return `Die Länge dieses Feldes muss mindestens ${field.props.minLength} betragen.`;
};
const maxLengthValidationMessage = (err, field) => {
  return `Die Länge dieses Feldes muss mindestens ${field.props.maxLength} betragen.`;
};
const minValueValidationMessage = (err, field) => {
  return `Der Wert dieses Feldes muss mindestens ${field.props.min} betragen.`;
};
const maxValueValidationMessage = (err, field) => {
  return `Der Wert dieses Feldes darf maximal ${field.props.max} betragen.`;
};
const priceCommaValidationMessage = (err, field) => {
  return 'Fehlerhaftes Format.';
};

const priceLengthValidationMessage = (err, field) => {
  return 'Fehlerhaftes Format.';
};
const jsonFieldValidationMessage = (err, field) => {
  return 'Kein gültiges Json Format.';
};
const nameValidationMessage = (err, field) => {
  return 'Der eingegebene Name ist zu kurz.';
};

const requiredValidationMessage = (err, field) => {
  if (field.validators?.validation?.includes('priceComma') || field.validators?.validation?.includes('priceLength')) {
    return 'Fehlerhaftes Format';
  }
  return 'Erforderliches Feld';
};

/////////////////////////
///     Messages    ////
////////////////////////
const nameValidation = (control: FormControl<string | null>): ValidationErrors => {
  if (control?.value === null || control?.value?.trim() === '') {
    return { name: true };
  } else if (control?.value?.trim().length < 1) {
    return null;
  } else {
    return null;
  }
};

const jsonFieldValidator = (control: FormControl<string | null>): ValidationErrors => {
  if (control?.value === null || control?.value === '' || control?.value?.length === 0) {
    return null;
  } else if (control.value?.toString()?.match('^\\d+$')) {
    return null;
  } else if (JSON.stringify(control?.value)?.match('^\\d+$')) {
    return null;
  } else {
    try {
      const json = JSON.parse(control?.value);
      if (json) {
        return null;
      }
    } catch (e) {
      return { jsonField: true };
    }
  }
  return null;
};

const priceCommaValidator = (control: FormControl<string | null>): ValidationErrors => {
  return /^-?\d{1,8}(?:\.\d{3})?(?:\.\d{3})?(?:,\d{1,4})?$/.test(control?.value) ? null : { priceComma: true };
};

const priceLengthValidator = (control: FormControl<string | null>): ValidationErrors => {
  if (!control.value) {
    return null;
  }
  const value = control.value?.toString().split(',')[0].replace(/\./gi, '').replace(/-/gi, '');
  return value.length <= 8 ? null : { priceLength: true };
};

export const formlyValidators: FormlyValidators[] = [
  { name: 'priceComma', validation: priceCommaValidator },
  { name: 'priceLength', validation: priceLengthValidator },
  { name: 'jsonField', validation: jsonFieldValidator },
  { name: 'name', validation: nameValidation },
];

export const formlyValidatorMessages: FormlyValidatorMessages[] = [
  { name: 'required', message: requiredValidationMessage },
  { name: 'minlength', message: minLengthValidationMessage },
  { name: 'maxlength', message: maxLengthValidationMessage },
  { name: 'min', message: minValueValidationMessage },
  { name: 'max', message: maxValueValidationMessage },
  { name: 'jsonField', message: jsonFieldValidationMessage },
  { name: 'priceComma', message: priceCommaValidationMessage },
  { name: 'priceLength', message: priceLengthValidationMessage },
  { name: 'name', message: nameValidationMessage },
];
