import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-ng-select',
  template: `
    {{ to.value }}
    <ng-select
      [items]="_options"
      [notFoundText]="'Kein Ergebnis'"
      [bindLabel]="labelProp"
      [bindValue]="valueProp"
      [appendTo]="'body'"
      [compareWith]="compareWith"
      [multiple]="to.multiple"
      [groupBy]="to.multiple ? 'all' : null"
      [closeOnSelect]="!to.multiple"
      [placeholder]="to.required ? to.label + ' *' : to.label"
      [formControl]="_formControl"
      [selectableGroup]="to.multiple"
      [selectableGroupAsModel]="!to.multiple"
    >
      @if (to.multiple) {
        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" /> Alles auswählen / abwählen
        </ng-template>
      }

      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        @if (to.multiple) {
          <div class="d-flex align-items-center">
            <input class="mr-2" style="width: 1rem;" id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
            <span [title]="item.label">{{ item.label }}</span>
          </div>
        } @else {
          <option id="item-{{ index }}" [ngValue]="item$.value" [title]="item.label">
            {{ item.label }}
          </option>
        }
      </ng-template>
    </ng-select>
  `,
})
export class FormlyFieldNgSelect extends FieldType implements OnInit {
  ngOnInit(): void {
    if (this.to.multiple) {
      const allSelect = (items: any[]) => {
        items.forEach(element => {
          element['all'] = 'all';
        });
      };

      allSelect(this._options);
    }
  }

  public compareWith(item, selected) {
    return selected === item.value;
  }

  get labelProp(): string {
    return this.to.labelProp || 'label';
  }
  get valueProp(): string {
    return this.to.valueProp || 'value';
  }
  get _formControl(): FormControl {
    return this.formControl as FormControl;
  }
  get _options(): any[] {
    return this.to.options as any[];
  }
}
