import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '@components/dialog/dialog.component';
import { TabBarComponent } from '@components/tab-bar/tab-bar.component';
import { ConsultationStatusEnum, VrIcons, color } from '@enums';
import { environment } from '@environment/environment';
import { ApiService } from '@services/api.service';
import { InstanceService } from '@services/instance.service';
import { GetTestConsultationsResponse } from '@utils/domains';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'manage-consultation',
  templateUrl: './manage-consultation.component.html',
  styleUrls: ['./manage-consultation.component.scss'],
  standalone: false,
})
export class ManageConsultationComponent implements OnInit {
  private instanceSub: Subscription;
  private currentEnv = '';

  public consultations: GetTestConsultationsResponse[] = [];
  public consultationStatus = ConsultationStatusEnum;
  public currentLink = '';
  public loading = true;
  public instanceName = '';
  public mode: 'PROD' | 'TEST' = 'TEST';

  private customInstanceId = '0';

  readonly color = color;
  readonly buttonIcon = VrIcons;

  @Output() refreshInstances: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private apiService: ApiService,
    private instanceService: InstanceService,
    public dialogRef: MatDialogRef<TabBarComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.instanceName = data.instanceName || '';
    this.mode = data?.mode;
    this.customInstanceId = data?.instanceId;
  }

  ngOnInit(): void {
    this.instanceSub = this.instanceService.instanceChanged.subscribe(() => {
      this.getAllConsultations();
    });

    this.currentEnv = this.setEnv(window.location.href);

    if (environment.platform === 'vp' && environment.isLocal && window.location.pathname.includes('/bws')) {
      document.getElementById('manage-consultation-container').classList.add('bws-local');
    }
  }

  ngOnDestroy(): void {
    this.instanceSub?.unsubscribe();
  }

  public getAllConsultations(refresh = false): void {
    if (refresh) {
      this.refreshInstances.emit();
    }
    this.loading = true;
    this.mode === 'TEST' ? this.getAllTestConsultations() : this.getAllProdConsultations();
  }

  public getColor(status: ConsultationStatusEnum) {
    const name = this.isConsultationComplete(status) ? 'Neutral500' : 'Neutral700';
    return color.find(color.name[name]);
  }

  private getAllTestConsultations() {
    this.apiService
      .getTestConsultations(this.instanceService.instanceId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(testConsultations => {
        this.consultations = testConsultations;
      });
  }

  private getAllProdConsultations() {
    this.apiService
      .getProdConsultations(this.customInstanceId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(prodConsultations => {
        this.consultations = prodConsultations;

        if (prodConsultations.length === 0) {
          this.closeDialog();
        }
      });
  }

  private setEnv(url) {
    if (url.includes('dev') || url.includes('localhost')) {
      return 'dev';
    }
    if (url.includes('test')) {
      return 'test';
    } else {
      return 'demo';
    }
  }

  public createConsultation() {
    this.loading = true;
    this.apiService
      .createTestConsultation(this.instanceService.instanceId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(x => {
        this.getAllTestConsultations();
      });
  }

  public isConsultationComplete(consultationStatus: ConsultationStatusEnum) {
    return (
      consultationStatus === this.consultationStatus.checkoutDone ||
      consultationStatus === this.consultationStatus.archived
    );
  }

  public isConsultationArchivable(consultationStatus: ConsultationStatusEnum) {
    return (
      consultationStatus !== this.consultationStatus.created &&
      consultationStatus !== this.consultationStatus.inPreparation
    );
  }

  public handleDateFormat(consultationData: GetTestConsultationsResponse) {
    if (consultationData.appointmentTime) {
      const time = consultationData.appointmentTime.split(':');
      return moment(consultationData.appointmentDate)
        .hour(parseInt(time[0], 10))
        .minute(parseInt(time[1], 10))
        .format('Do MMMM YYYY, HH:mm');
    } else {
      return moment(consultationData.appointmentDate).format('Do MMMM YYYY');
    }
  }

  public openConsultation(consultationData: GetTestConsultationsResponse) {
    if (this.isConsultationComplete(consultationData.status)) {
      this.currentLink = this.setCurrentSummaryLink(consultationData.id);
      window.open(this.currentLink, '_blank');
    } else {
      const mode = this.setMode(consultationData.status);
      this.currentLink = this.setCurrentLink(consultationData.id, mode);
      window.open(this.currentLink, '_blank');
    }
  }

  /**
   *
   * @param consultationId
   * @param mode
   * @returns {string} url
   *
   * To open test consultations locally, use `//localhost:4200/#/${mode}/agenda/${consultationId}` as the first expression!
   */
  private setCurrentLink(consultationId, mode): string {
    const host = `${window.location.protocol}//${window.location.host}`;
    const slug = `#/${mode}/agenda/${consultationId}`;

    const localAWS = `http://localhost:4200/${slug}`;
    const localVP = `http://localhost:4202/${slug}`;
    const aws = `https://${this.currentEnv}.guide.bg.bankingguide.de/${slug}`;
    const bws = `${host}/bws/bgzv-frontend-main${slug}`;

    if (environment.platform === 'aws') {
      return window.location.host?.includes('localhost') ? localAWS : aws;
    }
    if (environment.platform === 'vp') {
      return window.location.pathname?.includes('localhost') ? localVP : bws;
    }
    return '';
  }

  private setCurrentSummaryLink(consultationId) {
    const host = `${window.location.protocol}//${window.location.host}`;
    const slug = `#/main/result/summary/${consultationId}`;

    const localAWS = `http://localhost:4200/${slug}`;
    const localVP = `http://localhost:4202/${slug}`;
    const aws = `https://${this.currentEnv}.guide.bg.bankingguide.de/${slug}`;
    const bws = `${host}/bws/bgzv-frontend-main${slug}`;

    if (environment.platform === 'aws') {
      return window.location.host?.includes('localhost') ? localAWS : aws;
    }
    if (environment.platform === 'vp') {
      return window.location.pathname?.includes('localhost') ? localVP : bws;
    }
    return '';
  }

  private setMode(status): string {
    return status === ConsultationStatusEnum.created ||
      status === ConsultationStatusEnum.inPreparation ||
      status === ConsultationStatusEnum.preparationSent
      ? 'prep'
      : 'main';
  }

  public deleteConsultation(consultationId: number) {
    const text = this.mode === 'TEST' ? 'Testberatung' : 'Beratung in Produktion';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      panelClass: 'custom-dialog',
      backdropClass: 'no-backdrop-background',
      data: {
        title: 'Warnung',
        text: text + ' wirklich löschen?',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event === 'submit') {
        this.loading = true;
        const request =
          this.mode === 'TEST'
            ? this.apiService.deleteConsultation(consultationId)
            : this.apiService.deleteProdConsultation(consultationId, this.customInstanceId);
        request.subscribe(() => {
          this.getAllConsultations(this.mode !== 'TEST');
        });
      }
    });
  }

  public archiveConsultation(consultationId: number) {
    const text = this.mode === 'TEST' ? 'Testberatung' : 'Beratung in Produktion';
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      panelClass: 'custom-dialog',
      backdropClass: 'no-backdrop-background',
      data: {
        title: 'Warnung',
        text: text + ' wirklich archivieren?',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event === 'submit') {
        this.loading = true;

        const request =
          this.mode === 'TEST'
            ? this.apiService.archiveConsultation(consultationId)
            : this.apiService.archiveProdConsultation(consultationId, this.customInstanceId);
        request.pipe(finalize(() => (this.loading = false))).subscribe(x => {
          this.getAllConsultations(this.mode !== 'TEST');
        });
      }
    });
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
