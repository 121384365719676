import { Injectable } from '@angular/core';
import { PortalContext } from '@de.fiduciagad.kundenportal/ui-context';

declare const Zone: any;

@Injectable({ providedIn: 'root' })
export class PortalContextProvider {
  get portalContext(): PortalContext {
    return Zone?.current?._properties['PortalContext'] || null;
  }
}
