<div class="position-relative mt-0 mb-0 flex flex-column">
  <ng-template *ngTemplateOutlet="header"></ng-template>

  <ng-template *ngTemplateOutlet="loader"></ng-template>

  <form #formDirective="ngForm" [formGroup]="form" (ngSubmit)="submit()" style="margin: 1rem">
    <div *ngIf="model.id">
      <mat-form-field class="w-100 disabled">
        <mat-label>UUID</mat-label>
        <input
          class="disabled"
          matInput
          value="{{ model.id }}"
          [cdkCopyToClipboard]="model.id"
          (cdkCopyToClipboardCopied)="onClipboardCopy($event, model.id)"
          readonly />
        <mat-icon
          class="vr-icon-duplicate mr-3 vr-color-neutral-600 icon-clipboard p-0"
          matSuffix
          [cdkCopyToClipboard]="model.id"
          (cdkCopyToClipboardCopied)="onClipboardCopy($event, model.id)"
        ></mat-icon
      ></mat-form-field>
    </div>

    <div *ngIf="currentEntity !== 'MediaContentResource'; else preview"></div>

    <formly-form
      #formly
      *ngIf="fields && !isLoading"
      [form]="form"
      [fields]="fields"
      [model]="model"
      (modelChange)="modelChanges()"
    ></formly-form>

    <app-rich-text-editor
      [hidden]="isRegularDescription"
      [ngClass]="isLoading && 'd-none'"
      (editedDescription)="newDescription = $event"
      [disabled]="checkDescriptionLock(model?.metadata?.lockedFields, 'description')"
    ></app-rich-text-editor>

    <ng-container *ngIf="checkIfHasFormField('prices') && !isLoading">
      <hr />
      <inner-form
        [model]="model.prices"
        [parentIsActive]="getModelActive()"
        [referenceContentResource]="'ProductPriceContentResource'"
        [limitEntries]="10"
        (innerFormChanged)="setInnerFormPrice($event)"
        (formsAreValid)="setInnerFormsValidStatus($event)"
      ></inner-form>
    </ng-container>

    <ng-container *ngIf="checkIfHasFormField('benefits') && !isLoading">
      <hr />
      <inner-form
        *ngIf="currentEntity === 'TransitionContentResource'"
        [model]="model.benefits"
        [parentIsActive]="getModelActive()"
        [referenceContentResource]="'TransitionBenefitContentResource'"
        (innerFormChanged)="setInnerFormBenefit($event)"
        (formsAreValid)="setInnerFormsValidStatus($event)"
      ></inner-form>
      <inner-form
        *ngIf="currentEntity === 'ProductContentResource'"
        [model]="model.benefits"
        [parentIsActive]="getModelActive()"
        [referenceContentResource]="'ProductBenefitContentResource'"
        (innerFormChanged)="setInnerFormBenefit($event)"
        (formsAreValid)="setInnerFormsValidStatus($event)"
      ></inner-form>
      <inner-form
        *ngIf="currentEntity === 'TaskContentResource'"
        [model]="model.benefits"
        [parentIsActive]="getModelActive()"
        [referenceContentResource]="'TaskBenefitContentResource'"
        (innerFormChanged)="setInnerFormBenefit($event)"
        (formsAreValid)="setInnerFormsValidStatus($event)"
      ></inner-form>
    </ng-container>

    <ng-container *ngIf="checkIfHasFormField('dataFieldGroupProfile') && !isLoading">
      <hr />
      <inner-form
        [model]="model.dataFieldGroupProfile"
        [parentIsActive]="getModelActive()"
        [referenceContentResource]="'DataFieldGroupProfileContentResource'"
        [changesInRefs]="modelHasSyncExcludedRefs('dataFieldGroupProfile')"
        (innerFormChanged)="setInnerFormProfile($event)"
        (formsAreValid)="setInnerFormsValidStatus($event)"
        [limitEntries]="1"
      ></inner-form>
    </ng-container>

    <ng-container
      *ngIf="checkIfHasFormField('dataFields') && !isLoading && currentEntity === 'DataFieldGroupContentResource'"
    >
      <hr />
      <inner-form
        [model]="model.dataFields"
        [parentIsActive]="getModelActive()"
        [referenceContentResource]="'DataFieldContentResource'"
        [changesInRefs]="modelHasSyncExcludedRefs('dataFields')"
        (innerFormChanged)="setInnerDataFields($event)"
        (formsAreValid)="setInnerFormsValidStatus($event)"
      ></inner-form>
    </ng-container>

    <inner-form
      *ngIf="currentEntity === 'QuestionContentResource' && !isLoading"
      [model]="model.answerOptions"
      [parentIsActive]="getModelActive()"
      [referenceContentResource]="'AnswerOptionContentResource'"
      (formsAreValid)="setInnerFormsValidStatus($event)"
      (innerFormChanged)="setQuestionOptions($event)"
    ></inner-form>

    <ng-container *ngIf="model.tool">
      <app-tool-form
        *ngIf="currentEntity === 'QuestionContentResource' && !isLoading"
        [chosenAttributes]="model.toolResources?.inputs"
        [type]="'Input'"
        (innerFormChanged)="setInnerFormToolInput($event)"
      ></app-tool-form>

      <app-tool-form
        *ngIf="currentEntity === 'QuestionContentResource' && !isLoading"
        [chosenAttributes]="model.toolResources?.outputs"
        [type]="'Output'"
        (innerFormChanged)="setInnerFormToolOuput($event)"
      ></app-tool-form>
    </ng-container>

    <app-answer-form
      *ngIf="currentEntity === 'AnswerOptionContentResource' && !isLoading"
      [chosenAttributes]="model"
      [changesInRefs]="modelHasSyncExcludedRefs('GlobalAnswerConfigResource')"
      (innerFormChanged)="setGlobalAnswerOptions($event)"
    ></app-answer-form>

    <ng-container
      *ngIf="checkIfHasFormField('productRefs') && !isLoading && currentEntity !== 'DataFieldGroupContentResource'"
    >
      <hr />
      <inner-form
        [model]="model.productRefs"
        [parentIsActive]="getModelActive()"
        [changesInRefs]="modelHasSyncExcludedRefs('productRefs')"
        [referenceContentResource]="'CompositionProductContentResource'"
        (innerFormChanged)="setInnerFormProducts($event)"
        (formsAreValid)="setInnerFormsValidStatus($event)"
      ></inner-form>
    </ng-container>

    <ng-container
      *ngIf="checkIfHasFormField('taskRefs') && !isLoading && currentEntity === 'CompositionContentResource'"
    >
      <hr />
      <inner-form
        [model]="model.taskRefs"
        [parentIsActive]="getModelActive()"
        [changesInRefs]="modelHasSyncExcludedRefs('taskRefs')"
        [referenceContentResource]="'CompositionTaskContentResource'"
        (innerFormChanged)="setInnerFormTasks($event)"
        (formsAreValid)="setInnerFormsValidStatus($event)"
      ></inner-form>
    </ng-container>

    <ng-container
      *ngIf="checkIfHasFormField('transitionRefs') && !isLoading && currentEntity === 'CompositionContentResource'"
    >
      <hr />
      <inner-form
        [model]="model.transitionRefs"
        [parentIsActive]="getModelActive()"
        [changesInRefs]="modelHasSyncExcludedRefs('transitionRefs')"
        [referenceContentResource]="'CompositionTransitionContentResource'"
        (innerFormChanged)="setInnerFormTransitions($event)"
        (formsAreValid)="setInnerFormsValidStatus($event)"
      ></inner-form>
    </ng-container>

    <ng-container
      *ngIf="
        checkIfHasFormField('compositionProductRefs') &&
        !isLoading &&
        currentEntity === 'AccountCalculatorContentResource'
      "
    >
      <hr />
      <inner-form
        [model]="model.compositionProductRefs"
        [parentIsActive]="getModelActive()"
        [referenceContentResource]="'AccountCalculatorCompositionProductContentResource'"
        [changesInRefs]="modelHasSyncExcludedRefs('compositionProductRefs')"
        (innerFormChanged)="setInnerFormVcItems($event)"
        (formsAreValid)="setInnerFormsValidStatus($event)"
      ></inner-form>
    </ng-container>

    <ng-container *ngIf="checkIfHasFormField('variables') && !isLoading">
      <hr />
      <app-contract-form
        [data]="model.variables"
        (variablesDataChanged)="setContractVariableItems($event)"
      ></app-contract-form>
    </ng-container>

    <ng-container *ngIf="currentEntity === 'MediaContentResource' && !isLoading">
      <upload-form
        [fileType]="fileType"
        [hasValidType]="model.type && model.type !== 'LINK' ? true : false"
        [model]="model"
        (dataChanged)="setMediaModelData($event)"
      ></upload-form>
    </ng-container>

    <ng-container *ngIf="currentEntity === 'DataExportContentResource' && model.id && !isLoading">
      <div *ngIf="model.defaultMappings" class="mt-2">
        <mat-form-field class="w-100 disabled">
          <mat-label>Default Mappings</mat-label>
          <input
            class="disabled"
            matInput
            value="{{ model.defaultMappings }}"
            [cdkCopyToClipboard]="model.defaultMappings"
            (cdkCopyToClipboardCopied)="onClipboardCopy($event, model.defaultMappings)"
            readonly />
          <mat-icon
            class="vr-icon-duplicate mr-3 vr-color-neutral-600 icon-clipboard p-0"
            matSuffix
            [cdkCopyToClipboard]="model.defaultMappings"
            (cdkCopyToClipboardCopied)="onClipboardCopy($event, model.defaultMappings)"
          ></mat-icon
        ></mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngIf="checkIfHasFormField('attributeRefs') && !isLoading">
      <hr />
      <app-attribute-form
        [chosenAttributes]="model.attributeRefs"
        [changesInRefs]="modelHasSyncExcludedRefs('attributeRefs')"
        (innerFormChanged)="setInnerFormAttributes($event)"
      ></app-attribute-form>
    </ng-container>

    <div *ngIf="showResyncButton" class="d-flex mt-3">
      <vr-button
        class="generate-button content-update cursor-pointer"
        [label]="'Sync mit aktiver Test-Instanz'"
        [buttonColor]="isSystemDisabled() ? color.find(color.name.Neutral400) : color.find(color.name.BrandPrimary)"
        [textColor]="color.find(color.name.White)"
        [disabled]="hasOpenConsultations"
        (click)="!isSystemDisabled() && importDialog(true)"
      >
      </vr-button>
    </div>

    <div
      *ngIf="currentEntity !== 'MediaContentResource' && !isLoading"
      class="d-flex flex-wrap align-items-center mt-4"
    >
      <button
        mat-flat-button
        [class.cursor-pointer]="!isProdInstance"
        [class.cursor-default]="isProdInstance"
        [disabled]="hasInvalidFields || !innerFormsAreValid || isLoading"
        (click)="!isSystemDisabled() && submit($event)"
      >
        Speichern
      </button>
      <div class="mt-1" style="flex-basis: 100%"></div>
      <div
        *ngIf="showChangelogCSV"
        class="mx-3 cursor-pointer vr-color-brand-primary font-weight-bold"
        style="height: 56px; line-height: 56px"
        (click)="downloadChangelogCSV()"
      >
        Änderungshistorie
      </div>
      <div
        *ngIf="showCSV"
        class="mx-3 cursor-pointer vr-color-brand-primary font-weight-bold"
        style="height: 56px; line-height: 56px"
        (click)="downloadValidationCSV()"
      >
        Inhalte prüfen
      </div>
      <div *ngIf="instanceRequestPending">
        <mat-spinner class="mt-4 ml-4" [diameter]="'50'"></mat-spinner>
      </div>
    </div>
  </form>
</div>

<ng-template #preview>
  <div class="mb-4" *ngIf="model.url">
    <div class="vr-text-small preview-header mb-1">Aktuelles Medium</div>
    <div class="d-flex flex-column">
      <div class="w-75" style="word-wrap: break-word">
        <a
          *ngIf="model.type !== 'LINK' && model.type !== 'VIDEO'"
          href="{{ mediaContentMap.get(model.id) }}"
          target="_blank"
          >{{ model.displayName }}</a
        >
        <a *ngIf="model.type === 'LINK'" href="{{ model.url }}" target="_blank">{{ model.displayName }}</a>
        <a *ngIf="model.type === 'VIDEO' && model.url.includes('http')" href="{{ model.url }}" target="_blank">{{
          model.displayName
        }}</a>
        <a
          *ngIf="model.type === 'VIDEO' && !model.url.includes('http')"
          href="{{ mediaContentMap.get(model.id) }}"
          target=" _blank "
          >{{ model.displayName }}</a
        >
      </div>
      <div class="d-flex align-items-center" style="height: 165px">
        <img
          alt="noImage"
          *ngIf="initialModel.url && isImage(initialModel.url); else noImage"
          style="max-width: 100%; max-height: 150px"
          [src]="mediaContentMap.get(initialModel.id)"
        />
        <ng-template #noImage>
          <div
            class="d-flex justify-content-center align-items-center"
            style="width: 100%; height: 165px; background-color: #eeeeee"
            [class.cursor-pointer]="model.url.toLowerCase().includes('pdf')"
            (click)="openPdf(model)"
          >
            <div class="vr-color-neutral-400 vr-headline-100">
              <div class="vr-color-brand-primary" *ngIf="model.url.toLowerCase().includes('pdf'); else noPdf">
                PDF Vorschau öffnen
              </div>
            </div>
          </div>
          <ng-template #noPdf>Keine Vorschau Verfügbar</ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editIcons>
  <div class="d-flex align-items-center mt-3">
    <div
      class="vr-icon-trash vr-icon-force-size mr-3 size vr-icons"
      [class.disabled]="isDeleteDisabled || isLoading"
      (click)="!isSystemDisabled() && deleteButtonClicked()"
      [title]="'Löschen'"
    ></div>
    <div
      class="vr-icon-reset mr-3 vr-icons vr-icon-force-size"
      [class.disabled]="isSystemDisabled() || isLoading"
      (click)="!isSystemDisabled() && resetForm()"
      [title]="'Zurücksetzen'"
    ></div>
    <div
      class="vr-icon-duplicate mr-3 vr-icons vr-icon-force-size"
      [class.disabled]="
        isSystemDisabled() ||
        !model.id ||
        prodInstanceSelected ||
        isLoading ||
        currentEntity === 'MailTemplateContentResource'
      "
      (click)="!isSystemDisabled() && onCopyButtonClick()"
      [title]="'Duplizieren'"
    ></div>

    @if (currentEntity !== 'MediaContentResource') {
      <div
        class="config-icon-save vr-icons mr-3 vr-icon-force-size"
        [class.disabled]="isSystemDisabled() || hasInvalidFields || isLoading || !innerFormsAreValid"
        (click)="(!isSystemDisabled() && !hasInvalidFields && submit()) || isLoading"
        [class.cursor-pointer]="!isProdInstance"
        [class.cursor-default]="isProdInstance || hasInvalidFields || isLoading"
        [title]="'Speichern'"
      ></div>
    }

    <div
      class="config-icon-add vr-icons vr-icon-force-size"
      [class.disabled]="isSystemDisabled() || isLoading || currentEntity === 'MailTemplateContentResource'"
      (click)="!isSystemDisabled() && addButtonClicked()"
      [class.cursor-pointer]="!isProdInstance"
      [class.cursor-default]="isProdInstance"
      [title]="'Neuer Eintrag'"
    ></div>
    <ng-container *ngIf="showImportExport">
      <div
        class="config-icon-upload vr-icons vr-icon-force-size ml-3"
        [class.disabled]="disableInstanceButtons"
        [title]="'Instanz importieren'"
        (click)="!disableInstanceButtons && instanceUpload.click()"
      ></div>
      <input hidden #instanceUpload type="file" (change)="handleInstanceImport($event)" accept=".zip" />
      <div
        class="config-icon-download vr-icons vr-icon-force-size ml-3"
        [class.disabled]="!enableExport || disableInstanceButtons"
        [title]="'Instanz exportieren'"
        (click)="!disableInstanceButtons && handleInstanceExport()"
      ></div>
    </ng-container>
  </div>
</ng-template>

<ng-template #loader>
  <ng-container *ngIf="isLoading">
    <div
      class="d-flex w-100 h-100 align-items-center justify-content-center"
      [class.mt-5]="isLoading"
      [class.d-inline]="isLoading"
      style="display: none"
    >
      <mat-spinner [class.d-inline]="isLoading" style="display: none"></mat-spinner>
    </div>
  </ng-container>
</ng-template>

<ng-template #header>
  <div class="fixed-header">
    <div class="d-flex px-3 pb-3 bg-white">
      <div class="w-100">
        <div class="flex-grow-1">
          <h3 *ngIf="title" class="title mt-3 vr-headline-200">{{ title }}</h3>
          <div *ngIf="description" class="mb-3 text-truncate" [class.mt-3]="!title" title="{{ description }}">
            {{ description }}
          </div>
        </div>

        <ng-container *ngIf="locksEditable">
          <div
            class="mt-3 d-flex flex-row align-items-center edit-container"
            (click)="!hasOnlyDefaultValues(model) && !isSystemDisabled() ? changeToLockMode() : null"
            [class.disabled]="hasOnlyDefaultValues(model) || isSystemDisabled()"
          >
            <div class="vr-icon-{{ lockEditMode ? 'close' : 'edit' }} vr-icons mr-2"></div>
            <div class="vr-text-small edit-label">
              {{ !lockEditMode ? 'Zum Restriktions-Editor wechseln' : 'Restriktions-Editor verlassen' }}
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!lockEditMode">
          <ng-template *ngTemplateOutlet="editIcons"></ng-template>
        </ng-container>
      </div>
      <div
        class="vr-icon-close vr-icon-force-size mt-1 mr-1 flex-shrink-0 close-button"
        (click)="closeButtonClicked()"
      ></div>
    </div>
  </div>
</ng-template>
