<div id="manage-consultation-container" class="d-flex flex-column w-100 h-100">
  <div class="h-100" *ngIf="mode === 'TEST'; else prodModeTemplate">
    <div class="h-25 list-item-separator">
      <div class="d-flex w-100">
        <div class="d-flex flex-column pl-4 pt-4 pb-3 container">
          <h3 class="title">Testberatungen für {{ instanceName }}</h3>
          <div>Hier können Testberatungen für die aktuelle Instanz erstellt und gestartet werden.</div>
        </div>
        <div (click)="closeDialog()" class="vr-icon-close cursor-pointer mt-3 mr-3"></div>
      </div>
      <div class="d-flex w-100 pl-4">
        <div class="d-flex align-items-center mr-3">
          <vr-button
            [label]="'Neue Testberatung'"
            [preset]="'small'"
            [textColor]="color.find(color.name.White)"
            [buttonColor]="color.find(color.name.BrandSecondary)"
            (click)="createConsultation()"
          ></vr-button>
        </div>
        <div class="d-flex align-items-center">
          <vr-button
            [label]="'Aktualisieren'"
            [preset]="'small'"
            [textColor]="color.find(color.name.White)"
            [buttonColor]="color.find(color.name.BrandPrimary)"
            (click)="getAllConsultations()"
          ></vr-button>
        </div>
      </div>
    </div>
    <div class="h-75" [style.overflow]="'auto'">
      <div class="w-100 align-items-start bg-white px-4 pb-3">
        <div class="d-flex align-items-center flex-column w-100 mb-3">
          <ng-container *ngIf="!loading; else loadingSpinner">
            <ng-container *ngIf="consultations.length > 0; else noTestConsultations">
              <ng-container *ngFor="let consultationDates of consultations">
                <ng-template
                  *ngTemplateOutlet="consultationDateContent; context: { $implicit: consultationDates }"
                ></ng-template>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #prodModeTemplate>
  <div class="h-100">
    <div class="h-25 list-item-separator">
      <div class="d-flex w-100">
        <div class="d-flex flex-column pl-4 pt-4 pb-3 container">
          <h3 class="title">Beratungen in Produktion für {{ instanceName }}</h3>
          <div>Hier können noch offene Beratungen in der Produktionsinstanz verwaltet werden.</div>
        </div>
        <div (click)="closeDialog()" class="vr-icon-close cursor-pointer mt-3 mr-3"></div>
      </div>
      <div class="d-flex w-100 pl-4">
        <div class="d-flex align-items-center">
          <vr-button
            [label]="'Aktualisieren'"
            [preset]="'small'"
            [textColor]="color.find(color.name.White)"
            [buttonColor]="color.find(color.name.BrandPrimary)"
            (click)="getAllConsultations()"
          ></vr-button>
        </div>
      </div>
    </div>
    <div class="h-75" [style.overflow]="'auto'">
      <div class="w-100 align-items-start bg-white px-4 pb-3">
        <div class="d-flex align-items-center flex-column w-100 mb-3">
          <ng-container *ngIf="!loading; else loadingSpinner">
            <ng-container *ngIf="consultations.length > 0">
              <ng-container *ngFor="let consultationDates of consultations">
                <ng-template
                  *ngTemplateOutlet="consultationDateContent; context: { $implicit: consultationDates }"
                ></ng-template>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- CONSULTATIONS CONTENT -->
<ng-template #consultationDateContent let-consultationData>
  <div class="d-flex align-items-center w-100 list-item-container list-item-separator">
    <div class="d-flex flex-grow-1">
      <div
        class="vr-text-large vr-color-neutral-900 text-truncate"
        [class.vr-color-neutral-500]="isConsultationComplete(consultationData.status)"
        [class.vr-color-neutral-900]="!isConsultationComplete(consultationData.status)"
      ></div>
      <div
        class="vr-text-large text-truncate"
        [class.vr-color-neutral-500]="isConsultationComplete(consultationData.status)"
        [class.vr-color-neutral-700]="!isConsultationComplete(consultationData.status)"
      >
        <ng-container *ngIf="mode === 'TEST'">
          <div class="cursor-pointer" (click)="openConsultation(consultationData)">
            {{ consultationData.name }}
          </div></ng-container
        >
        <ng-container *ngIf="mode !== 'TEST'">
          <ng-container *ngTemplateOutlet="prodConsultations; context: { $implicit: consultationData }"></ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="mode === 'TEST'">
        <ng-container *ngTemplateOutlet="consultationActions; context: { $implicit: consultationData }"></ng-container>
      </ng-container>
    </div>

    <div class="d-flex" [class.align-items-center]="mode === 'TEST'" [class.align-items-start]="mode !== 'TEST'">
      <vr-button
        *ngIf="consultationData.status === consultationStatus.mainConsultation"
        class="mr-3 cursor-default"
        label="Beratung"
        [options]="{ width: '120px' }"
        [preset]="'small'"
        [type]="'pill'"
        [outline]="true"
        [textColor]="color.find(color.name.Information)"
        [buttonColor]="color.find(color.name.Information)"
        (click)="openConsultation(consultationData)"
      >
      </vr-button>
      <vr-button
        *ngIf="consultationData.status !== consultationStatus.mainConsultation"
        (click)="openConsultation(consultationData)"
        class="mr-3 cursor-default"
        [type]="'pill'"
        [preset]="'small'"
        [options]="{ width: '120px' }"
        [outline]="true"
        [buttonColor]="
          isConsultationComplete(consultationData.status)
            ? color.find(color.name.Neutral500)
            : color.find(color.name.Neutral700)
        "
        [textColor]="
          isConsultationComplete(consultationData.status)
            ? color.find(color.name.Neutral500)
            : color.find(color.name.Neutral700)
        "
        [label]="isConsultationComplete(consultationData.status) ? 'Abgeschlossen' : 'Vorbefragung'"
      >
      </vr-button>
    </div>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div class="mt-5">
    <mat-progress-spinner color="accent" mode="indeterminate" class="m-auto" [diameter]="90"></mat-progress-spinner>
  </div>
</ng-template>
<ng-template #noTestConsultations>
  <div class="mt-5">Keine Testberatung vorhanden. Bitte zunächst Testberatung anlegen.</div></ng-template
>

<ng-template #consultationActions let-consultationData>
  <div class="d-flex">
    <div
      class="vr-icon-trash cursor-pointer ml-3"
      [style.backgroundColor]="color.find(color.name.Error)"
      [title]="'Löschen'"
      (click)="deleteConsultation(consultationData.id)"
    ></div>
    <div
      *ngIf="isConsultationArchivable(consultationData.status)"
      class="vr-icon-misc-folder cursor-pointer ml-3"
      [style.backgroundColor]="color.find(color.name.BrandPrimary)"
      [title]="'Archivieren'"
      (click)="archiveConsultation(consultationData.id)"
    ></div>
  </div>
</ng-template>

<ng-template #prodConsultations let-consultationData>
  <div class="p-3">
    <div class="d-flex mr-3 align-items-center mb-2" [style.height]="'1.5rem'">
      <div class="d-flex vr-text-large font-weight-bold cursor-pointer" (click)="openConsultation(consultationData)">
        Titel: {{ consultationData.consultationTitle }}
      </div>
      <ng-container *ngTemplateOutlet="consultationActions; context: { $implicit: consultationData }"></ng-container>
    </div>
    <div class="cursor-pointer" (click)="openConsultation(consultationData)">
      <div class="vr-text-small">Erstellt: {{ consultationData.createdAt }}</div>
      <div class="vr-text-small">
        Termin: {{ consultationData.appointmentDate }}, {{ consultationData.appointmentTime }}
      </div>
      <div class="vr-text-small">
        Kunde: {{ consultationData.customer.name }} - {{ consultationData.customer.customerNumber }}
      </div>
      <div class="vr-text-small">
        Berater: {{ consultationData.consultant?.name }} - {{ consultationData.consultant?.externalId }}
      </div>
    </div>
  </div>
</ng-template>
