import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ContextEnum, color } from '@enums';
import { environment } from '@environment/environment';
import { ApiService } from '@services/api.service';
import { CacheService } from '@services/cache.service';
import { CleanHtmlService } from '@services/clean-html.service';
import { ConfigService } from '@services/config.service';
import { EntityService } from '@services/entity.service';
import { InstanceService } from '@services/instance.service';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
const dataFieldGroupProfileFields = ['dataFieldGroupProfile', 'showGroupName', 'profileOnly', 'category', 'template'];
@Component({
  selector: 'app-record-table',
  templateUrl: './record-table.component.html',
  styleUrls: ['./record-table.component.scss'],
})
export class RecordTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() ignoreLocks = false;
  @Output() tableLoading = new EventEmitter<boolean>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren('inputFields') input;

  private alive = new Subject<void>();
  private dataFieldHubData: any[] = [];
  private untouchedItems: any[] = [];
  private m2mFields: string[] = [];
  private filterValues: { [key: string]: string } = {};
  private lastFilterValues: { [key: string]: string } = {};

  public displayedColumns: string[] = [];
  public selection = new SelectionModel(false, null);
  public isLoading = false;
  public ascColumn = true;
  public entity = '';
  public renewAttributes = false;
  public renewRules = false;
  public renewAnswerOptions = false;
  public items = [];
  public maxEntries = 0;
  public maxChosen = false;
  public dataSource: MatTableDataSource<any>;

  readonly color = color;
  readonly uuidRegEx = /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
  public currentModel: { [key: string]: any } = {};
  constructor(
    private apiService: ApiService,
    private cacheService: CacheService,
    private entityService: EntityService,
    private configService: ConfigService,
    private instanceService: InstanceService,
    private toastService: ToastrService,
    private cleanHtmlService: CleanHtmlService,
    private changeDetection: ChangeDetectorRef
  ) {
    this.dataSource = new MatTableDataSource();
  }

  async ngOnInit(): Promise<void> {
    this.m2mFields = this.configService.m2mFields;
    await this.fetchData(false, true);
    this.entityService.setModel({});
    this.instanceService.instanceChanged.pipe(takeUntil(this.alive)).subscribe(x => {
      this.fetchData(true, true, true);
    });

    this.entityService.resetSelection.pipe(takeUntil(this.alive)).subscribe(x => {
      this.fetchData(true, true);
    });

    this.entityService.forceSelection.pipe(takeUntil(this.alive)).subscribe(x => {
      this.fetchData(true, false, true);
    });

    this.entityService.currentModel.pipe(takeUntil(this.alive)).subscribe(model => {
      this.currentModel = model;
    });

    this.entityService.currentEntity.pipe(takeUntil(this.alive)).subscribe(async entity => {
      this.tableLoading.emit(true);
      this.isLoading = true;
      if (this.input) {
        this.input.forEach(element => {
          element.nativeElement.value = '';
        });
      }
      this.entity = entity;
      this.onResetTable();

      if (this.entity === 'DataFieldContentResource' || this.entity === 'DataFieldGroupContentResource') {
        const entity = this.entity.replace('Content', 'Config');
        const endpoint = this.configService.getHubEndpoint(entity);
        const data = await lastValueFrom(this.apiService.getHubData(endpoint));
        this.dataFieldHubData = data;
      } else if (this.entity === 'AnswerOptionContentResource' || this.entity === 'QuestionContentResource') {
        const entity = 'GlobalAnswerConfigResource';
        const endpoint = this.configService.getHubEndpoint(entity);
        const data = await lastValueFrom(this.apiService.getHubData(endpoint));
        this.dataFieldHubData = data;
      }
      await this.firstFetch(this.entity);

      this.entityService.entityTrigger.next(this.entityService.currentEntity.getValue());
      this.tableLoading.emit(false);
    });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.setPaginatorLabels();
  }

  public sortCaseInsensitive() {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      let sortValue = '';
      switch (typeof data[sortHeaderId]) {
        case 'string':
          if (!data[sortHeaderId]) {
            sortValue = this.sort.direction === 'asc' ? '3' : '1';
          }
          sortValue = '2' + data[sortHeaderId].toString().toLocaleLowerCase();
          break;
        default:
          if (!data[sortHeaderId]) {
            sortValue = this.sort.direction === 'asc' ? '2' : '1';
          }
          sortValue = data[sortHeaderId];
          break;
      }
      return sortValue;
    };
  }
  public onTableRowClicked(row): void {
    this.handleTableRowClick(row);
    this.selection.select(row);
  }
  public onFilterChange(filter: string, event: KeyboardEvent): void {
    this.lastFilterValues = this.filterValues;
    this.filterValues[filter] = (<HTMLInputElement>event.target).value.trim().toLowerCase();
    this.lastFilterValues[filter] = this.filterValues[filter];
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }
  public onResetFilters(): void {
    this.lastFilterValues = {};
    this.filterValues = {};
    this.dataSource.filter = '';
  }
  public onResetSort() {
    if (this.entity === 'InstanceConfigResponse') {
      const sortState: Sort = { active: 'createdAt', direction: 'desc' };
      this.sort.active = sortState.active;
    } else {
      const sortState: Sort = { active: 'id', direction: 'asc' };
      this.sort.active = sortState.active;
    }
  }
  public onResetTable() {
    this.onResetFilters();
    this.onResetSort();
  }

  private async firstFetch(entity: string): Promise<void> {
    const displayData =
      environment.platform === 'vp' && this.configService.hasVpColumns(entity)
        ? this.configService.getDisplayedColumnsVP(entity)
        : this.configService.getDisplayedColumns(entity);
    this.displayedColumns = [...displayData];
    if (!this.isInformationContentResource) {
      this.displayedColumns.unshift('contentSource');
      this.displayedColumns.unshift('lockedFields');
    }
    await this.fetchData(false, false, false);
  }

  private async fetchData(afterReset: boolean, resetSelection: boolean = false, renew: boolean = false): Promise<void> {
    if (!this.entity) {
      return;
    }
    this.isLoading = !afterReset;
    if (
      (this.instanceService.instancesAvailable &&
        this.configService.currentContext !== ContextEnum.bankHub &&
        this.instanceService.instanceId !== '-1') ||
      this.configService.currentContext === ContextEnum.bankHub
    ) {
      // special cases
      if (renew && this.entity === 'QuestionContentResource') {
        this.cacheService.renewAnswerOptions();
        this.cacheService.renewAttributes();
      }
      if (renew && this.entity === 'AnswerOptionContentResource') {
        this.cacheService.renewAttributes();
      }
      if (renew && this.entity === 'RuleContentResource') {
        this.cacheService.renewRules(this.entityService.lastModel['id']);
      }
      if (this.entity === 'ReplacementMarkerContentResource') {
        this.cacheService.renewReplacementMarkers();
      }
      const endpoint = this.configService.getEndpoint(this.entity);
      const items = await lastValueFrom(this.apiService.get(endpoint, renew));
      this.handleEndpointData(items, resetSelection, renew);
    }
  }

  private itemFactory(items: any[]) {
    return items
      .map(x => (x.metadata?.lockedFields ? { ...x, lockedFields: x.metadata.lockedFields } : x))
      .map(x => (x.metadata?.contentSource ? { ...x, contentSource: x.metadata.contentSource } : x));
  }

  private handleEndpointData(items, resetSelection: boolean, renew: boolean): void {
    this.renewAttributes = renew;
    this.renewRules = renew;
    this.renewAnswerOptions = renew;
    this.untouchedItems = cloneDeep(items);

    const modifiedItems = this.itemFactory(items);
    this.items = [...this.filterItems(cloneDeep(modifiedItems))];

    // Handling the JSON Objects in Datafields so that they don't appear as [object Object]
    if (this.entity === 'DataFieldContentResource' || this.entity === 'DataFieldGroupContentResource') {
      this.items.map(item => {
        const conditionString = item?.jsonConditions ? JSON.stringify(item.jsonConditions) : '[]';
        return (item.jsonConditions = conditionString === '[]' ? '' : conditionString);
      });
    }
    this.dataSource = new MatTableDataSource(this.items);
    this.dataSource.filterPredicate = this.createFilter();
    if (this.lastFilterValues) {
      this.dataSource.filter = JSON.stringify(this.lastFilterValues);
    }
    let sortState: Sort;
    if (this.entity === 'InstanceConfigResponse') {
      sortState = { active: 'createdAt', direction: 'asc' };
      this.sort.active = sortState.active;
    } else {
      sortState =
        this.sort.active && this.sort.active !== 'id'
          ? { active: this.sort.active, direction: this.sort.direction }
          : { active: 'id', direction: 'asc' };
    }
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sortCaseInsensitive();
    this.sort.sortChange.emit(sortState);
    this.isLoading = false;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.maxEntries = this.dataSource.filteredData?.length;
    this.paginator._changePageSize(this.pageSize);

    this.changeDetection.detectChanges();

    if (!resetSelection) {
      const lastModel = this.entityService.lastModel;
      if (lastModel) {
        const lastRow = this.dataSource.filteredData.find(model => model.id === lastModel['id']);
        if (lastRow) {
          this.selection.select(lastRow);
        } else {
          this.selection.clear();
        }
      } else {
        const currentModel = this.entityService.currentModel;
        const lastRow = this.dataSource.filteredData.find(model => model.id === currentModel['id']);
        this.selection.select(lastRow);
      }
    }
  }
  private handleTableRowClick(row: any): void {
    if (this.entity === 'UserConfigResource' || this.entity === 'QuestionContentResource') {
      const endpoint = this.configService.getEndpoint(this.entity);
      this.apiService.get(`${endpoint}/${row.id}`).subscribe(modelByEndpoint => {
        this.entityService.setModel(cloneDeep(modelByEndpoint));
      });
    } else {
      const clickedRow = this.untouchedItems.find(x => x.id === row.id);
      this.entityService.setModel(cloneDeep(clickedRow));
    }
  }
  /**
   * Filter an incoming items array, get values for the keys (using enumLabels) and join them if needed
   * Example: field groups for a consultant
   * incoming is groups: ['CONTENT_ADMIN', 'CONSULTANT']
   * returned groups: "Content-Administrator, Berater"
   *
   * @param {Object[]} items array of items
   * @returns {Object[]} items
   */
  private filterItems(items: any[]): string[] {
    const getObject = (obj, field) =>
      obj
        .reduce((a, b) => {
          return `${a},${b[field]}`;
        }, '')
        .slice(1);
    items.forEach(item => {
      Object.entries(item).forEach(([key, value]) => {
        if (
          this.m2mFields.includes(key) ||
          key === 'dataFieldGroupProfile' ||
          (this.entity === 'QuestionContentResource' && (key === 'answerOptions' || key === 'imageDefaultSize')) ||
          (this.entity === 'QuestionGroupContentResource' && (key === 'imageDefaultSize' || key === 'template')) ||
          (this.entity === 'DataFieldGroupContentResource' && key === 'type') ||
          (this.entity === 'AccountCalculatorContentResource' && key === 'productRefs') ||
          (this.entity === 'CompositionContentResource' && key === 'benefitTypes') ||
          (this.entity === 'ProductContentResource' && key === 'dataExportRefs') ||
          (this.entity === 'TopicContentResource' && key === 'position') ||
          (this.entity === 'MailTemplateContentResource' && key === 'type') ||
          (this.entity === 'RuleContentResource' &&
            (key === 'compositionProductRefs' ||
              key === 'compositionRefs' ||
              key === 'compositionTaskRefs' ||
              key === 'compositionTransitionRefs' ||
              key === 'themaRefs'))
        ) {
          if (key === 'benefitTypes') {
            item[key] = item[key].map(x => this.configService.getEnumLabel('CompositionContentResource', x)).join(', ');
          } else if (key === 'position') {
            item[key] = this.configService.getEnumLabel('TopicContentResource', item[key]);
          } else if (this.entity === 'QuestionContentResource' && key === 'imageDefaultSize') {
            item[key] = this.configService.getEnumLabel('QuestionContentResource', item[key]);
          } else if (key === 'type') {
            item[key] = this.configService.getEnumLabel('MailTemplateContentResource', item[key]);
          } else if (
            this.entity === 'QuestionGroupContentResource' &&
            (key === 'imageDefaultSize' || key === 'template')
          ) {
            item[key] = this.configService.getEnumLabel('QuestionGroupContentResource', item[key]);
          } else if (key === 'dataFieldGroupIds') {
            item[key] = item[key].join(', ');
          } else if (key === 'dataFields') {
            item[key] = item[key].map(x => x.id).join(', ');
          } else if (this.entity === 'DataFieldGroupContentResource' && key === 'type') {
            item[key] = this.configService.getEnumLabel('DataFieldGroupContentResource', item[key]);
          } else if (key === 'dataFieldGroupProfile') {
            item['profileOnly'] = item[key].profileOnly.toString() === 'true' ? 'Ja' : 'Nein';
            item['showGroupName'] = item[key].showGroupName.toString() === 'true' ? 'Ja' : 'Nein';
            item['template'] = this.configService.getEnumLabel(
              'DataFieldGroupProfileContentResource',
              item[key].template
            );
            item['category'] = this.configService.getEnumLabel(
              'DataFieldGroupProfileContentResource',
              item[key].category
            );
          } else if (key === 'answerOptions' || key === 'benefits' || key === 'prices') {
            item[key] = item[key] = getObject(value, 'id');
          } else if (key === 'groups') {
            item[key] = item[key].map(x => this.configService.getEnumLabel('UserConfigResource', x)).join(', ');
          } else if (typeof item[key] === 'object') {
            let tempKey = key;
            if (this.entity === 'RuleContentResource' && key !== 'compositionRefs') {
              tempKey = key.replace('composition', '');
              tempKey = tempKey?.charAt(0)?.toLowerCase() + tempKey?.slice(1);
            }
            let idField = `${tempKey.split('Refs')[0]}Id`;

            if (idField === 'compositionProductId') {
              idField = 'compositionId';
            }
            item[key] = getObject(value, idField);
          }
        }

        if (key === 'formRefs' && this.entity === 'ProductContentResource') {
          item[key] = item[key].map(x => x.formId).join(', ');
        }
        if (key === 'importFrom' && this.dataFieldHubData) {
          const data = this.dataFieldHubData.find(element => element.contentId === item[key]);
          item[key] = `BankHubId: ${data?.id || ''}`;
        }
        if (key === 'contentSource') {
          if (item[key] === 'MASTER') {
            item[key] = 'M';
          } else if (item[key] === 'UPDATED') {
            item[key] = 'U';
          } else {
            item[key] = 'I';
          }
        }
        if (key === 'description') {
          item[key] = this.cleanHtmlService.cleanHtml(item[key]);
        }
        if (item[key] === true) {
          item[key] = 'Ja';
        }
        if (item[key] === false) {
          item[key] = 'Nein';
        }
      });
    });
    if (
      this.entity === 'InformationContentResource' ||
      this.entity === 'InformationConfigResource' ||
      this.entity === 'SettingConsultationAppContentResource' ||
      this.entity === 'SettingContentResource'
    ) {
      items = [...items.filter(x => x.systemSetting === (this.isInformationContentResource ? 'Ja' : 'Nein'))];
    }

    return items;
  }

  private createFilter() {
    const filterFunction = function (data: any, filter: string): boolean {
      const searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }
      if (isFilterSet) {
        const found = [];
        for (const col in searchTerms) {
          let elementFound = false;
          const word = searchTerms[col].trim().toLowerCase();
          if (data[col] && data[col].toString().toLowerCase().includes(word) && isFilterSet) {
            elementFound = true;
          }
          found.push(elementFound);
        }
        return !found.includes(false);
      } else {
        return true;
      }
    };
    return filterFunction;
  }

  private setPaginatorLabels() {
    this.paginator._intl.firstPageLabel = 'Erste Seite';
    this.paginator._intl.itemsPerPageLabel = 'Einträge pro Seite';
    this.paginator._intl.lastPageLabel = 'Letzte Seite';
    this.paginator._intl.nextPageLabel = 'Nächste Seite';
    this.paginator._intl.previousPageLabel = 'Vorherige Seite';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} von ${length}`;
    };
  }

  getOpenApiColumnTitle(columnName) {
    if (columnName !== 'id') {
      if (columnName === 'contentSource') {
        return 'I/M/U';
      } else {
        const schema = this.configService.currentOpenApiConfig['components']['schemas'];
        const type = dataFieldGroupProfileFields.includes(columnName)
          ? 'DataFieldGroupProfileContentResource'
          : this.entity;
        return schema[type]['properties'][columnName]?.title || '';
      }
    } else {
      return '';
    }
  }

  public getPluralTitle(entity): string {
    if (!entity) {
      return '';
    }
    return this.configService.getPluralTitle(entity);
  }

  public clearCache(): void {
    this.apiService.clearCache().subscribe(x => {
      this.toastService.success('Cache erfolgreich geleert', 'Cache geleert');
    });
  }

  get sizeOptions() {
    const options = [50, 100, 250, 500, 750];
    const array = [...options.filter(x => x < this.maxEntries)];
    return array.length === 0 ? [options[0]] : [...array, this.maxEntries];
  }

  get currentContext() {
    return this.configService.currentContext;
  }
  get isInQuestionWorld() {
    return (
      this.entity === 'SubtopicContentResource' ||
      this.entity === 'QuestionGroupContentResource' ||
      this.entity === 'QuestionContentResource' ||
      this.entity === 'AnswerOptionContentResource'
    );
  }
  get lockColor() {
    if (this.currentContext === ContextEnum.configApp && this.isProdInstance) {
      return color.find(color.name.BrandPrimary);
    } else if (this.ignoreLocks) {
      return color.find(color.name.Neutral400);
    } else if (!this.ignoreLocks) {
      return color.find(color.name.BrandSecondary);
    } else {
      return color.find(color.name.Neutral400);
    }
  }
  get isProdInstance() {
    return this.instanceService.isProdInstance;
  }
  get isInformationContentResource() {
    return this.entity === 'InformationContentResource' || this.entity === 'InformationConfigResource';
  }

  public isCustomizedField(id: number, column: string) {
    if (
      this.entity !== 'CompositionContentResource' &&
      this.entity !== 'AnswerOptionContentResource' &&
      this.entity !== 'AccountCalculatorContentResource' &&
      this.configService.innerFormFields.includes(column)
    ) {
      const element = this.untouchedItems.find(x => x.id === id);
      return element[column]?.some(x => x?.metadata?.syncExcludedFields?.length > 0) || false;
    } else if (this.entity === 'DataFieldGroupContentResource' && dataFieldGroupProfileFields.includes(column)) {
      const dataProfile = this.untouchedItems.find(x => x.id === id)?.dataFieldGroupProfile;
      return dataProfile && dataProfile?.metadata?.syncExcludedFields.length > 0;
    } else {
      const syncExcludedField = this.untouchedItems.find(x => x.id === id)?.metadata?.syncExcludedFields;
      return syncExcludedField && syncExcludedField.length > 0 && syncExcludedField.includes(column);
    }
  }

  public setPage(event) {
    this.maxChosen = event.pageSize === this.maxEntries;
  }

  get pageSize() {
    return this.maxChosen ? (this.maxEntries < 50 ? 50 : this.maxEntries) : 50;
  }
}
