<div class="d-flex">
  @if (hasPortalHeader) {
    <vr-button
      [icon]="stickyIconClass"
      [outline]="true"
      [preset]="'regular'"
      [textColor]="color.find(color.name.Neutral700)"
      (click)="onStickApp($event)"
    ></vr-button>
  }
</div>
