import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { VrIcons } from '@enums';
import { ButtonOptions } from '../vr-button/vr-button.component';
import { VrFormBaseComponent } from '../vr-form-base/vr-form-base.component';

@Component({
  selector: 'vr-form-upload',
  templateUrl: './vr-form-upload.component.html',
  styleUrls: ['./vr-form-upload.component.scss', '../vr-form-base/vr-form-base.component.scss'],
  standalone: false,
})
export class VrFormUploadComponent extends VrFormBaseComponent implements OnInit {
  @Input() placeholder = '';
  @Input() files: File[] = [];
  @Input() fileLimit = 1;
  @Input() fileSizeLimit = 500000;
  @Input() fileAccept = '.pdf,.doc,.docx,.png,.gif,.jpeg,.jpg,.bmp,.txt,.xls,.csv';
  @Input() options: ButtonOptions = {};

  readonly buttonIcon = VrIcons;

  public onChange: Function;
  private target: HTMLInputElement;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.genericFormGroup();
    this.currentValue = this.vrFormGroup.value[this.vrFormControlName];
  }

  public onFileSelect(target: EventTarget) {
    this.target = target as HTMLInputElement;
    const fileList = this.target.files;
    const control = this.vrFormGroup.controls[this.vrFormControlName] as AbstractControl;

    if (fileList.item(0).size > this.fileSizeLimit) {
      this.customErrorMessage = 'Datei zu groß';
      control.setErrors({ pattern: true });
      this.valid.emit(false);
    } else {
      this.files.push(fileList.item(0));
      // this.onChange(fileList.item(0));
    }
    this.valueChanged.emit(this.files);
    this.valid.emit(true);
  }

  resetPath(event: any) {
    event.target.value = null;
  }

  public removeFile(fileToRemove: File) {
    const control = this.vrFormGroup.controls[this.vrFormControlName] as AbstractControl;
    const indexToRemove = this.files.findIndex(x => {
      return x.size === fileToRemove.size && x.lastModified === fileToRemove.lastModified;
    });
    this.files.splice(indexToRemove, 1);

    this.valueChanged.emit(this.files);

    control.reset();
  }

  get uploadButtonShown(): boolean {
    return this.files?.length < this.fileLimit || false;
  }

  getTestcafeId(name = '', placeholder = '') {
    return `upload-${name?.replace(/ /g, '')}-${placeholder?.replace(/ /g, '')}`;
  }
}
