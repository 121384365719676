import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private cacheStore: Map<any, any> = new Map<any, any>();
  private renewStore: Map<any, boolean> = new Map<any, boolean>();

  public fetch<Key, Value>(
    key: Key,
    generator: () => Observable<Value>,
    forceRenew: boolean = false
  ): Observable<Value> {
    const cachedEntry = this.cacheStore.get(key);
    const renewEntry = this.renewStore.get(key);

    const getCache = this.getCache(cachedEntry, renewEntry, forceRenew);
    if (getCache) {
      this.renewStore.set(key, false);
      return of(<Value>(<unknown>cachedEntry));
    } else {
      this.renewStore.set(key, false);
      return generator().pipe(tap(value => this.put(key, value)));
    }
  }

  public renewAll() {
    [...this.renewStore.keys()].forEach(key => {
      this.renewStore.set(key, true);
    });
  }

  public renewAnswerOptions(): void {
    this.renewStore.set('configurator-answer-options', true);
  }

  public renewDatafields(): void {
    this.renewStore.set('configurator-data-fields', true);
  }

  public renewDatafieldGroups(): void {
    this.renewStore.set('configurator-data-field-groups', true);
  }

  public renewAttributes(): void {
    this.renewStore.set('configurator-attributes', true);
  }

  public renewReplacementMarkers(): void {
    this.renewStore.set('configurator-replacement-markers', true);
  }

  public renewInstances(): void {
    this.renewStore.set('hub-instances', true);
  }

  public renewCompositions() {
    this.renewStore.set('configurator-compositions', true);
  }

  public renewMedias(modelId: number) {
    this.renewStore.set(`configurator-medias/${modelId}`, true);
  }

  public renewSettings(endpoint: 'settings' | 'setting-consultationapp'): void {
    this.renewStore.set(endpoint === 'settings' ? 'hub-settings' : 'configurator-setting-consultationapp', true);
  }

  public renewEntityById(entity: string, id: number): void {
    if (entity === 'UserConfigResource') {
      this.renewStore.set(`hub-users/${id}`, true);
    } else if (entity === 'QuestionContentResource') {
      this.renewStore.set(`configurator-questions/${id}`, true);
    }
  }

  public renewRules(modelId: number): void {
    this.renewStore.set(`configurator-rules/${modelId}`, true);
  }

  private put<Key, Value>(key: Key, value: Value): void {
    this.cacheStore.set(key, value);
  }

  private getCache(cachedEntry, renewEntry, forceRenew): boolean {
    return cachedEntry !== undefined && !renewEntry && !forceRenew;
  }
}
