import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { ApiService } from '@services/api.service';
import { CacheService } from '@services/cache.service';
import { TenantService } from '@services/tenant.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstanceService {
  public instanceChanged = new BehaviorSubject<string>('-1');
  public instancesAvailable = new BehaviorSubject<boolean>(false);
  public instanceRequestPending = new BehaviorSubject<boolean>(false);
  public availableInstancesSubject = new BehaviorSubject<any[]>([]);
  // public isProdInstance = new BehaviorSubject<boolean>(true);

  private _availableInstances = [];
  private _instanceId: string;
  private _isProdInstance: boolean;

  constructor(
    private apiService: ApiService,
    private cacheService: CacheService,
    private tenantService: TenantService
  ) {}

  public async getInstances(): Promise<void> {
    let data;
    try {
      data = await lastValueFrom(this.apiService.getInstances());
    } catch (e) {
      console.error(`Error getting instances [${e.message}]`);
    }

    this.availableInstances = data;
  }

  public get instanceId(): string {
    return this._instanceId;
  }

  public set instanceId(value: string) {
    if (value !== this._instanceId) {
      this._instanceId = value;

      let isProdStatus = false;

      this.cacheService.renewAll();

      // this.isProdInstance.next(isProdStatus);
      this.instanceChanged.next(this._instanceId);
    }
  }

  public get availableInstances() {
    return this._availableInstances;
  }

  public set availableInstances(value: any[]) {
    let instances = value;

    if (environment.platform === 'aws') {
      instances = value.filter(x => {
        return x.rzbk === this.tenantService.tenantId;
      });
    }

    instances.sort((a, b) => a.id - b.id);
    this._availableInstances = instances;
    this.availableInstancesSubject.next(this._availableInstances);
    this.instanceId = instances.length > 0 ? instances[0].id : -1;
  }

  public get isProdInstance(): boolean {
    // return this._isProdInstance;

    if (this.availableInstances.length > 0) {
      const myInstance = this.availableInstances.find(x => x.id === this.instanceId);
      return myInstance?.status === 'PROD' || false;
    }
    return false;
  }
  public set isProdInstance(value: boolean) {
    this._isProdInstance = value;
  }

  get hasInstances() {
    return this.availableInstances.length > 0;
  }
}
