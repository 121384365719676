import { Component, OnInit } from '@angular/core';
import { environment } from '@environment/environment';
import { ContextService } from '@services/context.service';

@Component({
  selector: 'item-benefits',
  templateUrl: './item-benefits.component.html',
  styleUrls: ['./item-benefits.component.scss'],
})
export class ItemBenefitsComponent implements OnInit {
  constructor(private contextService: ContextService) {}

  private illustrationBreak = 'ssets/illustrations';

  public illustrationFlipchart = `./a${this.illustrationBreak}/illu_zusammen-am-flipchart_duotone.svg`;
  public illustrationUhr = `./a${this.illustrationBreak}/illu_uhr_duotone.svg`;
  public illustrationPuzzle = `./a${this.illustrationBreak}/illu_puzzle-zusammen-schieben_duotone.svg`;

  ngOnInit() {}

  public color(name: string) {
    return window.getComputedStyle(document.querySelector('html')).getPropertyValue(name);
  }

  get assetPath(): string {
    return this.contextService.assetPath;
  }

  get platform(): string {
    return environment.platform;
  }
}
