<div class="position-relative mt-0 mb-0 flex flex-column">
  <ng-template *ngTemplateOutlet="header"></ng-template>

  @if (isLoading) {
    <div class="d-flex w-100 h-100 align-items-center justify-content-center mt-5 d-inline">
      <mat-spinner class="d-inline"></mat-spinner>
    </div>
  } @else {
    <form #formDirective="ngForm" [formGroup]="form" (ngSubmit)="submit()" style="margin: 1rem">
      @if (model.id) {
        <div class="cursor-pointer">
          <mat-form-field class="w-100 disabled">
            <mat-label>UUID</mat-label>
            <input
              matInput
              readonly
              class="disabled"
              [value]="model.id"
              [cdkCopyToClipboard]="model.id"
              (cdkCopyToClipboardCopied)="onClipboardCopy($event, model.id)" />
            <mat-icon
              matSuffix
              class="vr-icon-duplicate mr-3 vr-color-neutral-600 icon-clipboard p-0"
              [cdkCopyToClipboard]="model.id"
              (cdkCopyToClipboardCopied)="onClipboardCopy($event, model.id)"
            ></mat-icon
          ></mat-form-field>
        </div>
      }

      @if (currentEntity === 'MediaContentResource') {
        @if (model.url) {
          <div class="mb-4">
            <div class="vr-text-small preview-header mb-1">Aktuelles Medium</div>
            <div class="d-flex flex-column">
              <div class="w-75" style="word-wrap: break-word">
                @if (model.type !== 'LINK' && model.type !== 'VIDEO') {
                  <a href="{{ mediaContentMap.get(model.id) }}" target="_blank">{{ model.displayName }}</a>
                }
                @if (model.type === 'LINK') {
                  <a href="{{ model.url }}" target="_blank">{{ model.displayName }}</a>
                }
                @if (model.type === 'VIDEO' && model.url.includes('http')) {
                  <a href="{{ model.url }}" target="_blank">{{ model.displayName }}</a>
                }
                @if (model.type === 'VIDEO' && !model.url.includes('http')) {
                  <a href="{{ mediaContentMap.get(model.id) }}" target=" _blank ">{{ model.displayName }}</a>
                }
              </div>
              <div class="d-flex align-items-center" style="height: 165px">
                @if (initialModel.url && isImage(initialModel.url)) {
                  <img
                    alt="noImage"
                    style="max-width: 100%; max-height: 150px"
                    [src]="mediaContentMap.get(initialModel.id)"
                  />
                } @else {
                  <div
                    class="d-flex justify-content-center align-items-center"
                    style="width: 100%; height: 165px; background-color: #eeeeee"
                    [class.cursor-pointer]="model.url.toLowerCase().includes('pdf')"
                    (click)="openPdf(model)"
                  >
                    <div class="vr-color-neutral-400 vr-headline-100">
                      @if (model.url.toLowerCase().includes('pdf')) {
                        <div class="vr-color-brand-primary">PDF Vorschau öffnen</div>
                      } @else {
                        Keine Vorschau Verfügbar
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        }
      } @else {
        <div></div>
      }

      @if (fields) {
        <formly-form
          #formly
          [form]="form"
          [fields]="fields"
          [model]="model"
          (modelChange)="modelChanges()"
        ></formly-form>
      }

      <app-rich-text-editor
        [hidden]="isRegularDescription"
        [disabled]="checkDescriptionLock(model?.metadata?.lockedFields, 'description')"
        (editedDescription)="newDescription = $event"
      ></app-rich-text-editor>

      @if (checkIfHasFormField('prices')) {
        <hr />
        <inner-form
          [model]="model.prices"
          [parentIsActive]="getModelActive()"
          [referenceContentResource]="'ProductPriceContentResource'"
          [limitEntries]="10"
          (innerFormChanged)="setInnerFormPrice($event)"
          (formsAreValid)="setInnerFormsValidStatus($event)"
        ></inner-form>
      }

      @if (checkIfHasFormField('benefits')) {
        <hr />
        <inner-form
          [model]="model.benefits"
          [parentIsActive]="getModelActive()"
          [referenceContentResource]="referenceContentResource"
          (innerFormChanged)="setInnerFormBenefit($event)"
          (formsAreValid)="setInnerFormsValidStatus($event)"
        ></inner-form>
      }

      @if (checkIfHasFormField('dataFieldGroupProfile')) {
        <hr />
        <inner-form
          [model]="model.dataFieldGroupProfile"
          [parentIsActive]="getModelActive()"
          [referenceContentResource]="'DataFieldGroupProfileContentResource'"
          [changesInRefs]="modelHasSyncExcludedRefs('dataFieldGroupProfile')"
          (innerFormChanged)="setInnerFormProfile($event)"
          (formsAreValid)="setInnerFormsValidStatus($event)"
          [limitEntries]="1"
        ></inner-form>
      }

      @if (checkIfHasFormField('dataFields') && currentEntity === 'DataFieldGroupContentResource') {
        <hr />
        <inner-form
          [model]="model.dataFields"
          [parentIsActive]="getModelActive()"
          [referenceContentResource]="'DataFieldContentResource'"
          [changesInRefs]="modelHasSyncExcludedRefs('dataFields')"
          (innerFormChanged)="setInnerDataFields($event)"
          (formsAreValid)="setInnerFormsValidStatus($event)"
        ></inner-form>
      }

      @if (currentEntity === 'QuestionContentResource') {
        <inner-form
          [model]="model.answerOptions"
          [parentIsActive]="getModelActive()"
          [referenceContentResource]="'AnswerOptionContentResource'"
          (formsAreValid)="setInnerFormsValidStatus($event)"
          (innerFormChanged)="setQuestionOptions($event)"
        ></inner-form>
      }

      @if (currentEntity === 'QuestionContentResource' && model.tool) {
        <app-tool-form
          [type]="'Input'"
          [chosenAttributes]="model.toolResources?.inputs"
          (innerFormChanged)="setInnerFormToolInput($event)"
        ></app-tool-form>
        <app-tool-form
          [type]="'Output'"
          [chosenAttributes]="model.toolResources?.outputs"
          (innerFormChanged)="setInnerFormToolOuput($event)"
        ></app-tool-form>
      }

      @if (currentEntity === 'AnswerOptionContentResource') {
        <app-answer-form
          [chosenAttributes]="model"
          [changesInRefs]="modelHasSyncExcludedRefs('GlobalAnswerConfigResource')"
          (innerFormChanged)="setGlobalAnswerOptions($event)"
        ></app-answer-form>
      }

      @if (checkIfHasFormField('productRefs') && currentEntity !== 'DataFieldGroupContentResource') {
        <hr />
        <inner-form
          [model]="model.productRefs"
          [parentIsActive]="getModelActive()"
          [changesInRefs]="modelHasSyncExcludedRefs('productRefs')"
          [referenceContentResource]="'CompositionProductContentResource'"
          (innerFormChanged)="setInnerFormProducts($event)"
          (formsAreValid)="setInnerFormsValidStatus($event)"
        ></inner-form>
      }

      @if (currentEntity === 'CompositionContentResource') {
        @if (checkIfHasFormField('taskRefs')) {
          <hr />
          <inner-form
            [model]="model.taskRefs"
            [parentIsActive]="getModelActive()"
            [changesInRefs]="modelHasSyncExcludedRefs('taskRefs')"
            [referenceContentResource]="'CompositionTaskContentResource'"
            (innerFormChanged)="setInnerFormTasks($event)"
            (formsAreValid)="setInnerFormsValidStatus($event)"
          ></inner-form>
        }

        @if (checkIfHasFormField('transitionRefs')) {
          <hr />
          <inner-form
            [model]="model.transitionRefs"
            [parentIsActive]="getModelActive()"
            [changesInRefs]="modelHasSyncExcludedRefs('transitionRefs')"
            [referenceContentResource]="'CompositionTransitionContentResource'"
            (innerFormChanged)="setInnerFormTransitions($event)"
            (formsAreValid)="setInnerFormsValidStatus($event)"
          ></inner-form>
        }
      }

      @if (checkIfHasFormField('compositionProductRefs') && currentEntity === 'AccountCalculatorContentResource') {
        <hr />
        <inner-form
          [model]="model.compositionProductRefs"
          [parentIsActive]="getModelActive()"
          [referenceContentResource]="'AccountCalculatorCompositionProductContentResource'"
          [changesInRefs]="modelHasSyncExcludedRefs('compositionProductRefs')"
          (innerFormChanged)="setInnerFormVcItems($event)"
          (formsAreValid)="setInnerFormsValidStatus($event)"
        ></inner-form>
      }

      @if (checkIfHasFormField('variables')) {
        <hr />
        <app-contract-form
          [data]="model.variables"
          (variablesDataChanged)="setContractVariableItems($event)"
        ></app-contract-form>
      }

      @if (currentEntity === 'MediaContentResource') {
        <upload-form
          [fileType]="fileType"
          [hasValidType]="model.type && model.type !== 'LINK' ? true : false"
          [model]="model"
          (dataChanged)="setMediaModelData($event)"
        ></upload-form>
      }

      @if (currentEntity === 'DataExportContentResource' && model.id) {
        @if (model.defaultMappings) {
          <div class="mt-2">
            <mat-form-field class="w-100 disabled">
              <mat-label>Default Mappings</mat-label>
              <input
                matInput
                readonly
                class="disabled"
                [value]="model.defaultMappings"
                [cdkCopyToClipboard]="model.defaultMappings"
                (cdkCopyToClipboardCopied)="onClipboardCopy($event, model.defaultMappings)" />
              <mat-icon
                matSuffix
                class="vr-icon-duplicate mr-3 vr-color-neutral-600 icon-clipboard p-0"
                [cdkCopyToClipboard]="model.defaultMappings"
                (cdkCopyToClipboardCopied)="onClipboardCopy($event, model.defaultMappings)"
              ></mat-icon
            ></mat-form-field>
          </div>
        }
      }

      @if (checkIfHasFormField('attributeRefs')) {
        <hr />
        <app-attribute-form
          [chosenAttributes]="model.attributeRefs"
          [changesInRefs]="modelHasSyncExcludedRefs('attributeRefs')"
          (innerFormChanged)="setInnerFormAttributes($event)"
        ></app-attribute-form>
      }

      @if (showResyncButton) {
        <div class="d-flex mt-3">
          <vr-button
            class="generate-button content-update cursor-pointer"
            [label]="'Sync mit aktiver Test-Instanz'"
            [buttonColor]="isSystemDisabled() ? color.find(color.name.Neutral400) : color.find(color.name.BrandPrimary)"
            [textColor]="color.find(color.name.White)"
            [disabled]="hasOpenConsultations"
            (click)="!isSystemDisabled() && importDialog(true)"
          >
          </vr-button>
        </div>
      }

      @if (currentEntity !== 'MediaContentResource') {
        <div class="d-flex flex-wrap align-items-center mt-4">
          <button
            mat-flat-button
            [class.cursor-pointer]="!isProdInstance"
            [class.cursor-default]="isProdInstance"
            [disabled]="hasInvalidFields || !innerFormsAreValid || isLoading"
            (click)="!isSystemDisabled() && submit($event)"
          >
            Speichern
          </button>
          <div class="mt-1" style="flex-basis: 100%"></div>
          @if (showChangelogCSV) {
            <div
              class="mx-3 cursor-pointer vr-color-brand-primary font-weight-bold"
              style="height: 56px; line-height: 56px"
              (click)="downloadChangelogCSV()"
            >
              Änderungshistorie
            </div>
          }
          @if (showCSV) {
            <div
              class="mx-3 cursor-pointer vr-color-brand-primary font-weight-bold"
              style="height: 56px; line-height: 56px"
              (click)="downloadValidationCSV()"
            >
              Inhalte prüfen
            </div>
          }
          @if (instanceRequestPending) {
            <div>
              <mat-spinner class="mt-4 ml-4" [diameter]="'50'"></mat-spinner>
            </div>
          }
        </div>
      }
    </form>
  }
</div>

<!-- templates -->
<ng-template #editIcons>
  <div class="d-flex align-items-center mt-3">
    <div
      class="vr-icon-trash vr-icon-force-size mr-3 size vr-icons"
      [class.disabled]="isDeleteDisabled || isLoading"
      (click)="!isSystemDisabled() && deleteButtonClicked()"
      [title]="'Löschen'"
    ></div>
    <div
      class="vr-icon-reset mr-3 vr-icons vr-icon-force-size"
      [class.disabled]="isSystemDisabled() || isLoading"
      (click)="!isSystemDisabled() && resetForm()"
      [title]="'Zurücksetzen'"
    ></div>
    <div
      class="vr-icon-duplicate mr-3 vr-icons vr-icon-force-size"
      [class.disabled]="
        isSystemDisabled() ||
        !model.id ||
        prodInstanceSelected ||
        isLoading ||
        currentEntity === 'MailTemplateContentResource'
      "
      (click)="!isSystemDisabled() && onCopyButtonClick()"
      [title]="'Duplizieren'"
    ></div>

    @if (currentEntity !== 'MediaContentResource') {
      <div
        class="config-icon-save vr-icons mr-3 vr-icon-force-size"
        [class.disabled]="isSystemDisabled() || hasInvalidFields || isLoading || !innerFormsAreValid"
        (click)="(!isSystemDisabled() && !hasInvalidFields && submit()) || isLoading"
        [class.cursor-pointer]="!isProdInstance"
        [class.cursor-default]="isProdInstance || hasInvalidFields || isLoading"
        [title]="'Speichern'"
      ></div>
    }

    <div
      class="config-icon-add vr-icons vr-icon-force-size"
      [class.disabled]="isSystemDisabled() || isLoading || currentEntity === 'MailTemplateContentResource'"
      (click)="!isSystemDisabled() && addButtonClicked()"
      [class.cursor-pointer]="!isProdInstance"
      [class.cursor-default]="isProdInstance"
      [title]="'Neuer Eintrag'"
    ></div>
    @if (showImportExport) {
      <div
        class="config-icon-upload vr-icons vr-icon-force-size ml-3"
        [class.disabled]="disableInstanceButtons"
        [title]="'Instanz importieren'"
        (click)="!disableInstanceButtons && instanceUpload.click()"
      ></div>
      <input hidden #instanceUpload type="file" (change)="handleInstanceImport($event)" accept=".zip" />
      <div
        class="config-icon-download vr-icons vr-icon-force-size ml-3"
        [class.disabled]="!enableExport || disableInstanceButtons"
        [title]="'Instanz exportieren'"
        (click)="!disableInstanceButtons && handleInstanceExport()"
      ></div>
    }
  </div>
</ng-template>

<ng-template #header>
  <div class="fixed-header">
    <div class="d-flex px-3 pb-3 bg-white">
      <div class="w-100">
        <div class="flex-grow-1">
          @if (title) {
            <h3 class="title mt-3 vr-headline-200">{{ title }}</h3>
          }
          @if (description) {
            <div class="mb-3 text-truncate" [class.mt-3]="!title" title="{{ description }}">
              {{ description }}
            </div>
          }
        </div>

        @if (locksEditable) {
          <div
            class="mt-3 d-flex flex-row align-items-center edit-container"
            (click)="!hasOnlyDefaultValues(model) && !isSystemDisabled() ? changeToLockMode() : null"
            [class.disabled]="hasOnlyDefaultValues(model) || isSystemDisabled()"
          >
            <div class="vr-icon-{{ lockEditMode ? 'close' : 'edit' }} vr-icons mr-2"></div>
            <div class="vr-text-small edit-label">
              {{ !lockEditMode ? 'Zum Restriktions-Editor wechseln' : 'Restriktions-Editor verlassen' }}
            </div>
          </div>
        }

        @if (!lockEditMode) {
          <ng-template *ngTemplateOutlet="editIcons"></ng-template>
        }
      </div>
      <div
        class="vr-icon-close vr-icon-force-size mt-1 mr-1 flex-shrink-0 close-button"
        (click)="closeButtonClicked()"
      ></div>
    </div>
  </div>
</ng-template>
