<div class="contract-mapper-area">
  @for (field of field.fieldGroup; track field; let i = $index; let last = $last) {
    <h4>{{ headerText(i) }}</h4>
    <div class="d-flex pt-2 px-2 mapper-background">
      <formly-field class="w-100" [field]="field" (modelChange)="handleMapperTypeChange($event)"></formly-field>
      <div class="d-flex align-items-top mt-4">
        <div class="vr-icon-close-circled" type="button" (click)="remove(i)"></div>
      </div>
    </div>
    @if (!last) {
      <div class="my-2" style="border-bottom: solid 1px #000000"></div>
    }
  }
  <div style="cursor: pointer" class="d-flex flex-row align-items-start mt-3 brand-secondary" (click)="add()">
    <div class="vr-icon-add-circled mr-2"></div>
    <div>Variablentyp hinzufügen</div>
  </div>
</div>
