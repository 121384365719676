import { Component } from '@angular/core';
import { ContextService } from '@services/context.service';

@Component({
  selector: 'error-screen',
  templateUrl: './error-screen.component.html',
  styleUrls: ['./error-screen.component.scss'],
  standalone: false,
})
export class ErrorscreenComponent {
  constructor(private contextService: ContextService) {}

  get assetPath() {
    return this.contextService.assetPath;
  }
}
