import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { QueryService } from '@services/query.service';
import { lastValueFrom, timer } from 'rxjs';

@Component({
  selector: 'setup-none',
  templateUrl: './setup-none.component.html',
  styleUrls: ['./setup-none.component.scss'],
  standalone: false,
})
export class SetupNoneComponent implements OnInit {
  @Output() updateSuccess = new EventEmitter();
  public started = false;
  public error = false;
  public success = false;

  constructor(private queryService: QueryService) {}

  ngOnInit() {}

  public color(name: string) {
    return window.getComputedStyle(document.querySelector('html')).getPropertyValue(name);
  }

  public async onImport(event: Event) {
    if (this.started || this.error || this.success) {
      return;
    }

    this.started = true;

    try {
      const r = await lastValueFrom(this.queryService.putUpdateContent());
      const s = await lastValueFrom(this.queryService.putTenantInfo());
    } catch (error) {
      this.error = true;
      console.log(
        `%c [bgzv-frontend-admin] Error in setup or updating tenant info! Continue anyway`,
        'color: #1c52fb',
        error
      );
      this.continueUpdate();
    }

    this.continueUpdate();
  }

  private continueUpdate() {
    this.started = false;
    this.success = true;
    const t = timer(500).subscribe(() => {
      t.unsubscribe();
      this.updateSuccess.emit();
    });
  }

  get buttonText(): string {
    if (this.success) {
      return 'Einrichtung erfolgreich';
    } else if (this.error) {
      return 'Einrichtung fehlgeschlagen';
    } else if (this.started) {
      return 'Einrichtung läuft';
    }

    return 'Einrichtung starten';
  }
}
