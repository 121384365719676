import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { VrIcons } from '@enums';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { VrIconComponent } from '../vr-icon/vr-icon.component';

export const ButtonIcon = VrIcons;

export interface ButtonOptions {
  width?: string;
  height?: string;
  padding?: string;
  justify?: justifyType;
  iconSize?: string;
  fontSize?: string;
  flexGrow?: boolean;
  maxLabelLines?: number;
  iconOnEdge?: boolean;
}
export declare type justifyType = 'left' | 'right' | 'center';
@Component({
  selector: 'vr-button',
  templateUrl: './vr-button.component.html',
  styleUrls: ['./vr-button.component.scss'],
  standalone: false,
})
export class VrButtonComponent implements OnInit, OnDestroy {
  private clickSub: Subscription;
  private clickDebounce = new Subject<Event>();

  readonly buttonIcon = ButtonIcon;
  readonly textColorDisabled = 'rgb(189, 189, 189) !important';
  readonly buttonColorDisabled = 'rgb(245, 245, 245) !important';

  @Input()
  type: 'default' | 'pill' | 'round' = 'default';
  @Input() pos: 'left' | 'right' = 'left';
  @Input() preset: 'regular' | 'small' = 'regular';
  @Input() icon: Record<VrIcons, string> | string = ButtonIcon.none;
  @Input() textColor = '#000000';
  @Input() buttonColor = '#ffffff';
  @Input() label = '';
  @Input() number = '';
  @Input() disabled = false;
  @Input() outline = false;
  @Input() loading = false;
  @Input() tabIndex = 0;
  @Input() options: ButtonOptions = {};
  @Input() noScrollToView = false;
  @Input() noMinWidth = false;
  @Input() noVisibleLabel = false;

  @Output() click = new EventEmitter<Event>();
  @Output() blur = new EventEmitter<Event>();
  @Output() focused = new EventEmitter<any>();

  @ViewChild('iconContainer') iconContainer: VrIconComponent;
  @ViewChild('reference') reference: HTMLButtonElement;

  constructor() {}

  ngOnInit() {
    this.clickSub = this.clickDebounce.pipe(debounceTime(150)).subscribe(e => {
      if (!this.disabled && !this.loading) {
        this.click.emit(e);
      }
    });

    if (this.preset === 'small' && this.type !== 'round') {
      this.options.height = this.options.height; // || '1.75rem';
      this.options.padding = this.options.padding; // || '0.25rem 0.5rem';
      this.options.fontSize = this.options.fontSize; // || '0.875rem';
      this.options.iconSize = this.options.iconSize; // || '1rem';
    }

    this.options.maxLabelLines = this.options.maxLabelLines || 2;
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.clickSub?.unsubscribe();
  }

  public handleKeyup(event: any, ref: HTMLElement) {
    if (event.key === 'Tab') {
      if ((ref === document.activeElement || ref?.contains(document.activeElement)) && !this.noScrollToView) {
        ref.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }
  }

  public preventScrolling(ev: KeyboardEvent) {
    if (ev.key === ' ') {
      ev.preventDefault();
    }
  }

  public handleClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.disabled && !this.loading) {
      this.clickDebounce.next(event);
    }
  }

  // ----------------------------------------------------- //
  // -------------- PUBLIC GETTER FUNCTIONS -------------- //
  // ----------------------------------------------------- //

  get buttonClass(): string {
    const typeClass = `button-${this.type}${this.outline ? '-outline' : ''}`;
    const sizeClass = `button-size-${this.preset === 'small' ? 'small' : 'regular'}`;

    let buttonClass = ['d-flex'];

    buttonClass = [...buttonClass, typeClass];
    buttonClass = [...buttonClass, sizeClass];
    buttonClass = [...buttonClass, this.disabled ? `${typeClass}-disabled` : ''];
    buttonClass = [...buttonClass, this.noMinWidth ? 'no-min-width' : ''];

    return buttonClass.join(' ');
  }

  get buttonStyle(): CSSStyleDeclaration {
    const style = {} as CSSStyleDeclaration;

    style.width = this.handleWidth(this.options.width);
    style.height = this.handleHeight(this.options.height);
    style.padding = this.handlePadding(this.options.padding);
    style.justifyContent = this.handleJustify(this.options.justify);
    style.fontSize = this.handleFontSize(this.options.fontSize);

    style.flexGrow = this.options.flexGrow ? '1' : undefined;

    style.color = this.disabled ? this.textColorDisabled : this.textColor;

    if (!this.outline) {
      style.backgroundColor = this.disabled ? this.buttonColorDisabled : this.buttonColor;
    } else if (this.outline) {
      style.borderColor = this.disabled ? this.buttonColorDisabled : this.buttonColor;
    }

    return style;
  }

  get iconClass(): string {
    if (this.useSpinnerOnIcon) {
      return 'button-icon button-spinner';
    }
    return 'button-icon';
  }

  get iconStyle(): CSSStyleDeclaration {
    const style = {} as CSSStyleDeclaration;

    const iconSize = this.handleIconSize(this.options.iconSize);

    if (this.useSpinnerOnLabel) {
      const justify = this.handleJustify(this.options.justify);
      const leftAlign = justify === 'center' || justify === undefined ? '50%' : justify === 'left' ? '0%' : '100%';
      style.top = `calc(50% - calc(${iconSize || '1.5rem'} / 2)`;
      style.left = `calc(${leftAlign} - calc(${iconSize || '1.5rem'} / 2)`;
    }

    return style;
  }

  get hasIcon(): boolean {
    return this.icon !== this.buttonIcon.none;
  }

  get useLabel(): boolean {
    if (this.noVisibleLabel) {
      return false;
    }
    return this.label && this.type !== 'round';
  }

  get useSpinnerOnLabel(): boolean {
    return this.loading && this.useLabel && this.icon === ButtonIcon.none;
  }

  get useSpinnerOnIcon(): boolean {
    return this.loading && this.icon !== ButtonIcon.none;
  }

  get useIconOnEdge(): boolean {
    return this.options?.iconOnEdge || false;
  }

  // ------------------------------------------------------ //
  // -------------- PRIVATE HELPER FUNCTIONS -------------- //
  // ------------------------------------------------------ //

  private handleWidth(width: string): string | undefined {
    if (this.type === 'round' && this.preset !== 'small') {
      return this.options?.width || '3.5rem';
    }

    return this.normalizedUnitSize(width) || undefined;
  }

  private handleHeight(height: string): string | undefined {
    if (this.type === 'round' && this.preset !== 'small') {
      return this.options?.height || '3.5rem';
    }

    return this.normalizedUnitSize(height) || undefined;
  }

  private handlePadding(padding: string): string | undefined {
    if (this.type === 'round') {
      return '0';
    }

    if (!padding) {
      return !this.label ? '1rem' : undefined;
    }

    return padding
      .split(' ')
      .map(x => this.normalizedUnitSize(x))
      .join(' ');
  }

  private handleJustify(justify: 'left' | 'right' | 'center'): 'left' | 'right' | 'center' | undefined {
    if (!justify) {
      return undefined;
    }

    if (this.type === 'round') {
      return 'center';
    }

    return justify;
  }

  private handleFontSize(fontSize: string): string | undefined {
    return this.normalizedUnitSize(fontSize) || undefined;
  }

  private handleIconSize(iconSize: string): string | undefined {
    //TODO: maybe figure out that the icon cannot exceed the height of round buttons
    return this.normalizedUnitSize(iconSize) || undefined;
  }

  private normalizedUnitSize(value: string): string | null {
    if (!value) {
      return null;
    }
    const number = parseFloat(value);
    const unit = value.toString().match(/px|%|em|rem|pt/) || 'px';
    return isNaN(number) ? null : `${number}${unit}`;
  }
}
