<div class="w-100 h-100 d-flex flex-column">
  <section class="pl-3">
    <div class="bg-white">
      <!-- TITLE & BREADCRUMB -->
      <div style="min-height: 3rem" class="d-flex flex-grow-1 align-items-center">
        <h2 class="entity-heading mt-2">
          {{ getPluralTitle(entity) }}
        </h2>
        <div class="ml-5">
          @if (isInQuestionWorld) {
            <app-breadcrumb-navigation
              [currentEntity]="entity"
              [currentModel]="currentModel"
            ></app-breadcrumb-navigation>
          }
        </div>
      </div>

      <!-- FILTER ROW -->
      <div class="d-flex align-items-center">
        <div class="vr-icon-search vr-icon-force-size filter-neutral-900 search-icon mb-3"></div>
        <form class="d-flex justify-content-between form-width" style="column-gap: 0.75rem">
          @for (filter of displayedColumns | slice: 1; track filter) {
            <mat-form-field class="flex-fit">
              <mat-label>{{ getOpenApiColumnTitle(filter) || filter }}</mat-label>
              <input #inputFields matInput type="text" [name]="filter" (keyup)="onFilterChange(filter, $event)" />
            </mat-form-field>
          }
          <button type="reset" value="reset" class="reset-button" (click)="onResetFilters()">
            Filter zurücksetzen
          </button>
          @if (entity === 'DataFieldContentResource' && currentContext === 'configurator') {
            <button type="reset" value="reset" class="reset-button cache" (click)="clearCache()">Cache leeren</button>
          }
        </form>
      </div>
    </div>
  </section>
  <section class="flex-scroll flex-grow-1">
    <div [class.d-none]="isLoading" [hidden]="!items">
      <table #dataTable mat-table [dataSource]="dataSource" matSort matSortDisableClear>
        @for (currentColumn of displayedColumns; track currentColumn; let colIndex = $index) {
          <ng-container [matColumnDef]="currentColumn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="pr-2">
              @if (currentColumn === 'lockedFields') {
                <div class="config-icon-lock" [style.backgroundColor]="color.find(color.name.Neutral400)"></div>
              } @else {
                {{ getOpenApiColumnTitle(currentColumn) || currentColumn | uppercase }}
              }
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [title]="element[currentColumn]"
              [class.information]="isInformationContentResource"
              [class.font-weight-bold]="isCustomizedField(element.id, currentColumn)"
              [class.entry-inactive]="element['active'] && element['active'] !== 'Ja'"
            >
              <div class="pr-2 py-2">
                @if (currentColumn !== 'lockedFields' && !uuidRegEx.test(element[currentColumn])) {
                  <div>
                    {{
                      element[currentColumn] === false && entity === 'RuleContentResource' ? '' : element[currentColumn]
                    }}
                  </div>
                }
                @if (uuidRegEx.test(element[currentColumn])) {
                  <div>
                    {{ element[currentColumn].slice(-6) }}
                    {{ element[currentColumn].includes(',') ? ', ...' : '' }}
                  </div>
                }
                @if (
                  currentColumn === 'lockedFields' &&
                  (isProdInstance || (element[currentColumn] && element[currentColumn].length > 0))
                ) {
                  <div
                    class="config-icon-lock vr-color-neutral-900"
                    [style.color]="lockColor"
                    [title]="'Eingeschränktes Element'"
                  ></div>
                }
              </div>
            </td>
          </ng-container>
        }
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [class.selected]="selection.isSelected(row)"
          (click)="onTableRowClicked(row)"
        ></tr>
      </table>
    </div>

    <div class="d-flex w-100 justify-content-center" [ngClass]="isLoading && 'd-inline'" style="display: none">
      <mat-spinner [ngClass]="isLoading && 'd-inline'" style="display: none"></mat-spinner>
    </div>
  </section>
  <div [hidden]="!dataSource">
    <mat-paginator
      #matpag
      class="text-xs"
      [pageSize]="pageSize"
      [pageSizeOptions]="sizeOptions"
      (page)="setPage($event)"
      showFirstLastButtons
      fixed
    ></mat-paginator>
  </div>
</div>
