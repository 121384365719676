<div class="position-relative">
  <ng-template *ngTemplateOutlet="header"></ng-template>

  <div class="d-flex flex-column overflow-auto mb-3">
    <div class="d-flex flex-row">
      <div class="d-flex-flex-column flex-grow-1">
        <div class="m-3">
          @if (isLoading) {
            <div class="d-flex w-100 justify-content-center d-inline">
              <mat-spinner class="d-inline"></mat-spinner>
            </div>
          } @else {
            <h4>Regelbezeichnung</h4>
            <form #formDirective="ngForm" [formGroup]="form" (ngSubmit)="onSubmit()">
              @if (model.id) {
                <div class="cursor-pointer">
                  <mat-form-field class="w-100 disabled">
                    <mat-label>UUID</mat-label>
                    <input
                      readonly
                      matInput
                      class="disabled"
                      [value]="model.id"
                      [cdkCopyToClipboard]="model.id.toString()"
                      (cdkCopyToClipboardCopied)="onClipboardCopy($event, model.id)" />
                    <mat-icon
                      matSuffix
                      class="vr-icon-duplicate mr-3 vr-color-neutral-600 icon-clipboard p-0"
                      [cdkCopyToClipboard]="model.id.toString()"
                      (cdkCopyToClipboardCopied)="onClipboardCopy($event, model.id)"
                    ></mat-icon
                  ></mat-form-field>
                </div>
              }
              <formly-form #formDirective [form]="form" [fields]="fields" [model]="model"></formly-form>
            </form>
          }
          @if (readyForNextStep) {
            @if (!isLoading) {
              <h4 class="mt-4" [class.customized]="hasCustomizedRules">Regelzusammensetzung</h4>
            }
          } @else {
            <div class="d-flex align-items-center flex-row justify-content-between">
              <button
                type="button"
                class="save-button mb-3"
                [disabled]="isButtonDisabled"
                (click)="onProceedToNextStep()"
              >
                Weiter
              </button>
              <div>* Bitte Regelbezeichnung eingeben.</div>
            </div>
          }
          <div [hidden]="!readyForNextStep" class="overflow-auto mb-3">
            <app-rule-builder
              class="overflow-auto"
              [disabled]="!ruleEditable"
              [hidden]="isLoading"
              [ruleQueryInput]="model.id !== null ? model : ''"
              (newRule)="onSetNewRule($event)"
            ></app-rule-builder>
          </div>
          @if (readyForNextStep && model.id === null && !readyForFinalStep) {
            <div class="d-flex align-items-center flex-row justify-content-between">
              <button
                type="button"
                class="save-button mb-3"
                [class.cursor-pointer]="ruleEditable"
                [class.cursor-default]="!ruleEditable"
                [disabled]="newRule.ruleset.elements?.length === 0 || isButtonDisabled"
                (click)="ruleEditable && onProceedToFinalStep()"
              >
                Weiter
              </button>
              <div>* Bitte Regel anlegen.</div>
            </div>
          }
          @if (readyForFinalStep && !isLoading) {
            <h4 class="mt-4 mb-3">Regelziel</h4>
            <form #formDirectiveTarget="ngForm" [formGroup]="targetForm" (ngSubmit)="onSubmit()">
              <formly-form
                #formDirectiveTarget
                [form]="targetForm"
                [fields]="targetFields"
                [model]="model"
              ></formly-form>
            </form>
          }
        </div>
        @if (readyForNextStep && readyForFinalStep) {
          <div class="d-flex align-items-center flex-row justify-content-between mb-2">
            <button
              type="button"
              [class.cursor-pointer]="!isProdInstance"
              [class.cursor-default]="isProdInstance"
              (click)="!isProdInstance && onSubmit()"
              class="save-button ml-3"
              [disabled]="isLoading || isButtonDisabled"
            >
              Speichern
            </button>
            @if (!model.id) {
              <div>* Bitte Ziele für die Regel auswählen.</div>
            }
          </div>
        }
      </div>
    </div>
  </div>
</div>

<ng-template #header>
  <div class="fixed-header">
    <div class="d-flex px-3 pb-3 bg-white">
      <div class="w-100">
        <div class="flex-grow-1">
          @if (title) {
            <h3 class="title mt-3 vr-headline-200">
              @if (!model.id) {
                <span>Neue </span>
              }
              {{ title }}
            </h3>
          }
          @if (description) {
            <div class="mb-3 text-truncate" [class.mt-3]="!title" title="{{ description }}">
              {{ description }}
            </div>
          }
        </div>
        <!-- edit icons -->
        @if (model.id || copied) {
          <div class="d-flex align-items-center mt-3">
            <div
              class="vr-icon-trash vr-icon-force-size mr-3 size vr-icons"
              style="cursor: pointer"
              [class.disabled]="isProdInstance"
              [title]="'Löschen'"
              (click)="!isProdInstance && onDelete()"
            ></div>
            <div
              class="vr-icon-reset mr-3 vr-icons"
              style="cursor: pointer"
              [class.disabled]="isProdInstance"
              [title]="'Zurücksetzen'"
              (click)="!isProdInstance && onResetForm()"
            ></div>
            <div
              class="vr-icon-duplicate mr-3 vr-icons"
              style="cursor: pointer"
              [class.disabled]="isProdInstance"
              [title]="'Duplizieren'"
              (click)="!isProdInstance && onCopyRecord()"
            ></div>
            <div
              class="mr-3 config-icon-save vr-icons"
              [class.disabled]="isProdInstance"
              [class.cursor-pointer]="!isProdInstance"
              [class.cursor-default]="isProdInstance"
              [title]="'Speichern'"
              (click)="!isProdInstance && onSubmit()"
            ></div>
            <div
              class="config-icon-add vr-icons"
              [class.disabled]="isProdInstance"
              [class.cursor-pointer]="!isProdInstance"
              [class.cursor-default]="isProdInstance"
              [title]="'Neuer Eintrag'"
              (click)="!isProdInstance && onAddRule()"
            ></div>
          </div>
        }
      </div>
    </div>
    <div class="vr-icon-close vr-icon-force-size mt-1 mr-1 flex-shrink-0 close-button" (click)="onClose()"></div>
  </div>
</ng-template>
