@if (breadCrumbsNav.length > 0) {
  <div class="d-flex w-100 vr-color-neutral-600 font-weight-bold">
    @for (navElement of breadCrumbsNav; track navElement; let last = $last) {
      <div class="d-flex mr-1 cursor-pointer vr-text-small" (click)="jumpToEntity(navElement)">
        <div>{{ navElement.title }}</div>
        @if (!last) {
          <div class="mr-1 vr-icon-chevron-right" [style.backgroundColor]="color.find(color.name.Neutral600)"></div>
        }
      </div>
    }
    @if (currentModel.displayName) {
      <div class="d-flex vr-text-small">
        <div class="mr-1 vr-icon-chevron-right" [style.backgroundColor]="color.find(color.name.Neutral600)"></div>
        <div class="vr-color-brand-primary">{{ getModelDisplayName() }}</div>
      </div>
    }
  </div>
}
