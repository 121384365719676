<ng-container *ngIf="hasValidType">
  <div class="w-100">
    <vr-form-upload
      #uploadComponent
      id="uploadComponent"
      [class.width.%]="100"
      class="upload-component"
      ngDefaultControl
      [label]="'Datei hochladen (max. 50 MB)'"
      [placeholder]="'Datei wählen'"
      [fileAccept]="localFileType"
      [fileLimit]="1"
      [fileSizeLimit]="50000000"
      (valueChanged)="setFile($event)"
      [customRequiredMessage]="''"
      [mandatory]="true"
    ></vr-form-upload>
  </div>
  <div *ngIf="previewUrl">
    <div class="vr-text-small mb-2">Upload-Vorschau</div>
    <img [src]="previewUrl" class="w-50" />
  </div>
</ng-container>
<div class="mt-5 d-flex align-items-center">
  <button
    mat-flat-button
    class="w-25"
    [class.cursor-pointer]="!isProdInstance"
    [class.cursor-default]="isProdInstance"
    [disabled]="uploadOngoing || !submitEnabled"
    (click)="!isProdInstance && onSubmit($event)"
  >
    Speichern
  </button>
  <mat-spinner *ngIf="uploadOngoing" class="ml-4" [diameter]="'50'"></mat-spinner>
</div>
