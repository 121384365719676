enum PredefinedColors {
  BrandPrimary = '--color-brand-primary',
  BrandSecondary = '--color-brand-secondary',
  BrandTertiary = '--color-brand-tertiary',
  InteractionPrimary = '--color-interaction-primary',
  InteractionSecondary = '--color-interaction-secondary',
  InteractionTertiary = '--color-interaction-tertiary',
  Link = '--color-link',
  Success = '--color-success',
  SuccessLight = '--color-success-light',
  Warning = '--color-warning',
  WarningLight = '--color-warning-light',
  Error = '--color-error',
  ErrorLight = '--color-error-light',
  Information = '--color-information',
  InformationLight = '--color-information-light',
  Neutral50 = '--color-neutral-50 ',
  Neutral100 = '--color-neutral-100',
  Neutral200 = '--color-neutral-200',
  Neutral300 = '--color-neutral-300',
  Neutral400 = '--color-neutral-400',
  Neutral500 = '--color-neutral-500',
  Neutral600 = '--color-neutral-600',
  Neutral700 = '--color-neutral-700',
  Neutral800 = '--color-neutral-800',
  Neutral900 = '--color-neutral-900',
  White = '--color-white',
  Black = '--color-black',
}

function colorFinder(name: PredefinedColors | string) {
  if (Object.values(<any>PredefinedColors).includes(name)) {
    return window.getComputedStyle(document.querySelector('html')).getPropertyValue(name);
  } else {
    return name;
  }
}

export const color = { find: colorFinder, name: PredefinedColors };

export enum UserRoleEnum {
  ContentAdmin = 'CONTENT_ADMIN',
  Consultant = 'CONSULTANT',
}

export enum FileTypes {
  IMAGE = '.png,.gif,.jpg,.jpeg,.svg,.webp',
  PDF = '.pdf',
  VIDEO = '.mp4,.mov,.mkv',
  DOC_IMAGE = '.png,.gif,.jpg,.jpeg',
  LOGO_IMAGE = '.png,.gif,.jpg,.jpeg',
}

export enum ConsultationStatusEnum {
  created = 'CREATED',
  inPreparation = 'IN_PREPARATION',
  preparationSent = 'PRELIMINARY_SEND',
  preparationDone = 'PRELIMINARY_FINISHED',
  mainConsultation = 'MAIN_CONSULTATION',
  checkoutDone = 'CHECKOUT_FINISHED',
  archived = 'ARCHIVED',
}

export enum EntityWorldEnum {
  'QuestionWorld',
  'ProductWorld',
  'Other',
}

export enum LicenseStatusEnum {
  active = 'LICENSED',
  notActive = 'NOT_ACTIVE_LICENSE',
  notValid = 'NOT_VALID_LICENSE',
  notLicensed = 'NOT_LICENSED',
}

export enum ContextEnum {
  configApp = 'configurator',
  bankHub = 'hub',
}

export enum MapperTypes {
  const = 'CONST',
  selectedProduct = 'SELECTED_PRODUCT_PROPERTY',
  generalDataField = 'GENERAL_DATA_FIELD_VALUE',
  productDataField = 'PRODUCT_DATA_FIELD_VALUE',
}

export enum SetupStatus {
  SETUP_NOT_DONE = 'SETUP_NOT_DONE',
  SETUP_DONE = 'SETUP_DONE',
}
