import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import '@angular/common/locales/de';
import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KfThemeModule } from '@app/_modules/kf-theme_module';
import { MaterialModule } from '@app/_modules/material_module';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { ConfiguratorComponent } from '@app/mainConfig/configurator/configurator.component';
import { LoginComponent } from '@app/mainConfig/login/login.component';
import { RuleBuilderComponent } from '@app/rule-items/rule-builder/rule-builder.component';
import { RuleMainComponent } from '@app/rule-items/rule-main/rule-main.component';
import { VrButtonComponent } from '@app/ui-library/vr-button/vr-button.component';
import { VrIconComponent } from '@app/ui-library/vr-icon/vr-icon.component';
import { BreadcrumbNavigationComponent } from '@components/breadcrumb-navigation/breadcrumb-navigation.component';
import { DialogComponent } from '@components/dialog/dialog.component';
import { ErrorscreenComponent } from '@components/error-screen/error-screen.component';
import { GraphComponent } from '@components/graph/graph.component';
import { ItemBenefitsComponent } from '@components/item-benefits/item-benefits.component';
import { LicenseInvalidComponent } from '@components/license-invalid/license-invalid.component';
import { LockingEditorComponent } from '@components/locking-editor/locking-editor.component';
import { MainComponent } from '@components/main/main.component';
import { ManageConsultationComponent } from '@components/manage-consultations/manage-consultation.component';
import { ModalComponent } from '@components/modal/modal.component';
import { RecordTableComponent } from '@components/record-table/record-table.component';
import { RichTextEditorComponent } from '@components/rich-text-editor/rich-text-editor.component';
import { SetupDoneComponent } from '@components/setup-done/setup-done.component';
import { SetupNoneComponent } from '@components/setup-none/setup-none.component';
import { StickyButtonComponent } from '@components/sticky-button/sticky-button.component';
import { TabBarComponent } from '@components/tab-bar/tab-bar.component';
import {
  ENVIRONMENT_SPECIFIC_CUSTOM_ELEMENT,
  ENVIRONMENT_SPECIFIC_IMPORTS,
  ENVIRONMENT_SPECIFIC_PROVIDERS,
} from '@environment/environment';
import { AnswerFormComponent } from '@formItem/answer-form/answer-form.component';
import { AttributeFormComponent } from '@formItem/attribute-form/attribute-form.component';
import { ContractFormComponent } from '@formItem/contract-form/contract-form.component';
import { ConditionsComponent } from '@formItem/contract-form/form-types/conditions/conditions.component';
import { MapperComponent } from '@formItem/contract-form/form-types/mapper/mapper.component';
import { FormComponent } from '@formItem/form/form.component';
import { InnerFormComponent } from '@formItem/inner-form/inner-form.component';
import { ToolFormComponent } from '@formItem/tool-form/tool-form.component';
import { UploadFormComponent } from '@formItem/upload-form/upload-form.component';
import { AuthInterceptor } from '@modules/auth';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { USERBACK } from '@services/userback';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { formlyValidatorMessages, formlyValidators } from '@utils/formly-validations';
import { FormlyFieldNgSelect } from '@utils/ng-select.type.component';
import { SafePipe } from '@utils/pipes/self-html.pipe';
import { NgxAngularQueryBuilderModule } from 'ngx-angular-query-builder';
import { ToastrModule } from 'ngx-toastr';
import { VrFormWarningComponent } from './ui-library/vr-form-base/vr-form-warning.component';
import { VrFormUploadComponent } from './ui-library/vr-form-upload/vr-form-upload.component';

@NgModule({
  declarations: [
    AppComponent,
    SetupNoneComponent,
    SetupDoneComponent,
    LicenseInvalidComponent,
    ItemBenefitsComponent,
    MainComponent,
    LoginComponent,
    TabBarComponent,
    RecordTableComponent,
    DialogComponent,
    SafePipe,
    ConfiguratorComponent,
    RichTextEditorComponent,
    LockingEditorComponent,
    InnerFormComponent,
    RuleBuilderComponent,
    RuleMainComponent,
    AttributeFormComponent,
    AnswerFormComponent,
    ToolFormComponent,
    UploadFormComponent,
    ManageConsultationComponent,
    BreadcrumbNavigationComponent,
    ModalComponent,
    GraphComponent,
    FormComponent,
    FormlyFieldNgSelect,
    ErrorscreenComponent,
    ContractFormComponent,
    MapperComponent,
    ConditionsComponent,
    StickyButtonComponent,
    VrButtonComponent,
    VrIconComponent,
    VrFormWarningComponent,
    VrFormUploadComponent,
  ],
  imports: [
    ...ENVIRONMENT_SPECIFIC_IMPORTS,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    EditorModule,
    MaterialModule,
    KfThemeModule,
    ReactiveFormsModule,
    NgxAngularQueryBuilderModule,
    NgSelectModule,
    NgxGraphModule,
    FormsModule,
    FormlyMaterialModule,
    FormlyModule.forRoot({
      validators: formlyValidators,
      validationMessages: formlyValidatorMessages,
      types: [
        { name: 'ng-select', component: FormlyFieldNgSelect },
        { name: 'mapper-repeat', component: MapperComponent },
        { name: 'conditions-repeat', component: ConditionsComponent },
      ],
    }),
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
  ],
  providers: [
    ...ENVIRONMENT_SPECIFIC_PROVIDERS,
    { provide: USERBACK, useFactory: () => (window as any).Userback },
    // { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule implements DoBootstrap {
  constructor(private readonly injector: Injector) {}

  ngDoBootstrap(): void {
    const app = ENVIRONMENT_SPECIFIC_CUSTOM_ELEMENT(AppComponent, this.injector);
    if (!customElements.get('bgzv-frontend-admin')) {
      customElements.define('bgzv-frontend-admin', app);
    }
  }
}
