import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QueryService } from '@services/query.service';
import { SaveFileService } from '@services/save-file.service';
import { TenantService } from '@services/tenant.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, lastValueFrom, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'setup-done',
  templateUrl: './setup-done.component.html',
  styleUrls: ['./setup-done.component.scss'],
  standalone: false,
})
export class SetupDoneComponent implements OnInit {
  constructor(
    private router: Router,
    private queryService: QueryService,
    private saveFileService: SaveFileService,
    private tenantService: TenantService,
    private toastService: ToastrService
  ) {}

  private stopPolling = new Subject<void>();
  errorMessage;
  httpStatus;

  public isPolling = false;

  public currentTenantId;
  public showReport = false;
  public showConfigurator = false;

  ngOnInit() {
    this.queryService.getCompetences().subscribe(x => {
      (this.showReport = x.currentUserAuthorities.includes('REPORT_ADMIN')),
        (this.showConfigurator = x.currentUserAuthorities.includes('CONTENT_ADMIN'));
    });
    this.currentTenantId = this.tenantService.tenantId;
  }

  public onBannerButtonClicked(status: any) {
    this.router.navigate(['configurator']);
  }

  public onReportButtonClicked() {
    // poll status every 5 seconds
    this.isPolling = true;
    timer(0, 10000)
      .pipe(
        switchMap(() => this.queryService.getReportStatus()),
        takeUntil(this.stopPolling)
      )
      .subscribe(
        async requestStatus => {
          if (requestStatus) {
            this.stopPolling.next();
            const data = await lastValueFrom(this.queryService.getReportDownload());
            this.saveFileService.saveFile(
              data,
              `AlleBeratungen${this.currentTenantId ? '_' + this.currentTenantId : ''}.csv`,
              '',
              false
            );
            this.isPolling = false;
          }
        },
        err => {
          if (err.error) {
            this.errorMessage = err.error.message;
            this.toastService.error(err.error.message);
          } else {
            this.toastService.error('Ein Fehler ohne Fehlermeldung ist aufgetreten! Statuscode: ' + err.status);
          }

          this.httpStatus = err.status;
          this.stopPolling.next();
          this.isPolling = false;
        }
      );
  }
}
