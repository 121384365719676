import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '@components/dialog/dialog.component';
import { ModelInterface } from '@formItem/form/utils/formly-functions';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/config.service';
import { ContextService } from '@services/context.service';
import { EntityService } from '@services/entity.service';
import { InstanceService } from '@services/instance.service';
import { LockingService } from '@services/locking.service';
import cloneDeep from 'lodash-es/cloneDeep';
import { ToastrService } from 'ngx-toastr';
import { Subject, forkJoin, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface NewRuleInterface {
  ruleset: any;
}

@Component({
  selector: 'app-rule-main',
  templateUrl: './rule-main.component.html',
  styleUrls: ['./rule-main.component.scss'],
  standalone: false,
})
export class RuleMainComponent implements OnInit, OnDestroy {
  @Input() containerRef: HTMLDivElement;

  private alive = new Subject<void>();

  private initialModel;
  private referenceRows = [];
  private compositionData = [];

  public isLoading = false;

  public m2mFields: string[] = [];
  public isProdInstance = this.instanceService.isProdInstance;

  public title = '';
  public description = '';
  public readyForNextStep = false;
  public readyForFinalStep = false;

  public targetForm = new FormGroup({});
  public form = new FormGroup({});
  public model: ModelInterface = {
    id: null,
    displayName: null,
    adminName: null,
    description: null,
    metadata: {},
  };

  public currentEntity = 'RuleContentResource';
  public fields = [];
  public targetFields = [];

  public newRule: NewRuleInterface = { ruleset: '' };
  public copied = false;

  @ViewChild('formDirective', { static: false }) private formDirective: NgForm;

  constructor(
    private entityService: EntityService,
    private configService: ConfigService,
    private apiService: ApiService,
    private toastService: ToastrService,
    private dialog: MatDialog,
    private lockingService: LockingService,
    private changeDetection: ChangeDetectorRef,
    private instanceService: InstanceService,
    private contextService: ContextService
  ) {}

  public ngOnInit(): void {
    this.m2mFields = this.configService.m2mFields;

    this.title = this.configService.getEntityFormTitle('RuleContentResource');
    this.description = this.configService.getEntityDescription('RuleContentResource');

    this.setModelSub();
  }

  public ngOnDestroy(): void {
    this.alive.next();
    this.alive.unsubscribe();
  }

  private setModelSub(): void {
    this.isLoading = true;
    if (this.entityService.currentEntity.getValue() === 'RuleContentResource') {
      this.entityService.isLoading.next(true);
    }

    this.entityService.currentModel.pipe(takeUntil(this.alive)).subscribe(async model => {
      let currentInstanceId = this.instanceService.instanceId;
      this.copied = false;

      if (model['id']) {
        this.readyForNextStep = true;
        this.readyForFinalStep = true;
        try {
          const newModel = await lastValueFrom(this.apiService.get(`rules/${model['id']}`));
          this.initialModel = cloneDeep(newModel);

          this.model = this.initialModel;

          Object.keys(this.model).forEach(key => {
            if (
              key.includes('Refs') &&
              !key.includes('Task') &&
              !key.includes('Product') &&
              !key.includes('Transition')
            ) {
              const idName = `${key.split('Refs')[0]}Id`;

              this.model[key] = this.model[key].flatMap(x => x[idName]);
            }
          });
        } catch (e) {
          console.error(e);
        }
      } else {
        this.resetRuleData();
      }

      this.instanceService.instanceChanged.pipe(takeUntil(this.alive)).subscribe(instanceId => {
        if (instanceId == '-1') {
          return;
        }

        if (currentInstanceId !== instanceId) {
          this.resetRuleData();
          currentInstanceId = '-666';
        }

        this.getCompositions();
      });
    });
  }

  // ------------ INTERACTIONS ------------ //

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.newRule) {
      this.model['ruleset'] = this.newRule.ruleset;
    }

    Object.keys(this.model).forEach(key => {
      if (key.includes('Refs') && !key.includes('Task') && !key.includes('Product') && !key.includes('Transition')) {
        const idName = `${key.split('Refs')[0]}Id`;
        const array = [];
        for (let value of this.model[key]) {
          const obj = {};
          obj[idName] = value;
          array.push(obj);
        }

        this.model[key] = [...array];
      }
    });

    if (!this.model.id) {
      this.apiService.post(this.configService.getEndpoint(this.currentEntity), this.model).subscribe(
        res => {
          this.toastService.success('Regel erfolgreich erstellt!');
          this.entityService.setLastModel(res);
          this.entityService.forceSelect();
          this.entityService.setModel(res);
        },
        err => this.errorMessage(err)
      );
    } else {
      this.apiService.patch(this.configService.getEndpoint(this.currentEntity), this.model).subscribe(
        res => {
          this.toastService.success('Regel erfolgreich geändert!');
          this.entityService.setLastModel(res);
          this.entityService.forceSelect();
          this.entityService.setModel(res);
        },
        err => this.errorMessage(err)
      );
    }
  }

  public onClose() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      panelClass: 'custom-dialog',

      data: {
        id: this.model.id,
        title: 'Änderungen speichern',
        text: 'Möchten Sie die Änderungen an diesem Datensatz speichern?',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'submit') {
        this.onSubmit();
      } else {
        this.entityService.forceSelect();
        this.entityService.resetSelect();
        this.entityService.setModel({});
      }
    });
  }

  public onDelete(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      panelClass: 'custom-dialog',
      data: {
        id: this.model.id,
        title: 'Eintrag löschen',
        text: 'Möchten Sie diesen Eintrag wirklich löschen? Dies kann nicht mehr rückgängig gemacht werden!',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'submit') {
        this.apiService.delete(this.configService.getEndpoint(this.currentEntity), result.id).subscribe(
          res => {
            this.entityService.setModel({});
            this.entityService.forceSelect();
            this.formDirective.resetForm();
            this.toastService.success('Regel gelöscht!');
          },
          err => {
            if (err.error) {
              this.toastService.error(err.error.message);
            } else {
              this.toastService.error('Ein Fehler ohne Fehlerbezeichnung ist aufgetreten!');
            }
          }
        );
      }
    });
  }

  public onClipboardCopy(ev, item) {
    if (ev) {
      this.toastService.success(item + ' erfolgreich in die Zwischenablage kopiert.');
    } else {
      this.toastService.error('Fehler beim Kopieren in die Zwischenablage.');
    }
  }

  public onCopyRecord() {
    this.copied = true;
    const newModel = cloneDeep(this.model);

    if (newModel.hasOwnProperty('name')) {
      newModel['name'] = `${newModel['name']} - Kopie`;
    } else if (newModel.hasOwnProperty('adminName')) {
      newModel['adminName'] = `${newModel['adminName']} - Kopie`;
    }
    // deleting the id key so that we have a new record and dont override the old one
    if (newModel.hasOwnProperty('id')) {
      delete newModel.id;
    }

    this.model = newModel;
    this.readyForFinalStep = true;
    this.readyForNextStep = true;
    this.onSubmit();
  }

  public onAddRule(): void {
    this.formDirective.resetForm();
    this.entityService.forceSelect();
    this.entityService.resetSelect();
    this.entityService.setModel({});
  }

  public onSetNewRule(newRule) {
    this.newRule = newRule;
  }

  public onProceedToNextStep() {
    this.readyForNextStep = true;
  }

  public onProceedToFinalStep() {
    this.readyForFinalStep = true;
  }

  public onResetForm(): void {
    this.model = cloneDeep(this.initialModel);
  }

  // ----------- PRIVATE FUNCTIONS ------------- //

  private errorMessage(err: ErrorEvent): void {
    if (err.error) {
      this.toastService.error(err.error.message);
    } else {
      this.toastService.error('Ein Fehler ohne Fehlerbezeichnung ist aufgetreten!');
    }
  }

  private resetRuleData(): void {
    this.newRule = { ruleset: '' };
    this.readyForNextStep = false;
    this.readyForFinalStep = false;
    this.model = {
      id: null,
      displayName: null,
      adminName: null,
      description: null,
      metadata: {},
    };
  }

  private getCompositions(): void {
    this.isLoading = true;
    this.entityService.isLoading.next(true);
    this.compositionData = [];
    const refs = {
      productRefs: this.apiService.get(this.configService.getEndpoint('productContentResource')),
      taskRefs: this.apiService.get(this.configService.getEndpoint('taskContentResource')),
      transitionRefs: this.apiService.get(this.configService.getEndpoint('transitionContentResource')),
      compositionRefs: this.apiService.get(this.configService.getEndpoint('compositionContentResource')),
    };

    forkJoin(refs)
      .pipe(takeUntil(this.alive))
      .subscribe(data => {
        this.getCompositionForElement(
          {
            productRefs: data['productRefs'],
            taskRefs: data['taskRefs'],
            transitionRefs: data['transitionRefs'],
          },
          data['compositionRefs']
        );
      });
  }

  private getCompositionForElement(refs, compositionData): void {
    for (const [key, value] of Object.entries(refs)) {
      const elementRefs = [];
      let idName = key !== 'compositionRefs' ? `${key.split('Refs')[0]}Id` : 'id';
      let refKey = key !== 'compositionRefs' ? `composition${key.charAt(0).toUpperCase()}${key.slice(1)}` : key;

      compositionData.forEach(element => {
        const skipMain = element['productRefs'].find(x => x.type === 'VARIANT');
        element[key].forEach(object => {
          if ((object.type === 'MAIN' && !skipMain) || object.type !== 'MAIN' || key !== 'productRefs') {
            const productName = refs[key].find(x => x.id === object[idName])?.adminName;
            const label = `[${object[idName].slice(-8)}] ${productName} - [${element.id.slice(-8)}] ${
              element.displayName
            }`;
            let value = {};
            value[idName] = object[idName];
            value['compositionId'] = element.id;

            if (!elementRefs.find(y => y.label === label)) {
              elementRefs.push({ label, value });
            }
          }
        });
      });
      elementRefs.sort((a, b) => (a.value.id > b.value.id ? 1 : b.value.id > a.value.id ? -1 : 0));

      this.compositionData.push({ key: refKey, data: elementRefs });
    }
    this.getReferenceFields();
  }

  private async getReferenceFields(): Promise<void> {
    this.referenceRows = [];

    const schemas = this.configService.appOpenApiConfig['components']['schemas'];
    const props = schemas[this.currentEntity].properties;
    for (const key in props) {
      if (
        (props.hasOwnProperty(key) &&
          key !== 'productRefs' &&
          key !== 'metadata' &&
          key !== 'id' &&
          key !== 'ruleset' &&
          key !== 'taskRefs' &&
          key !== 'transitionRefs') ||
        (this.currentEntity === 'DataFieldGroupContentResource' &&
          key !== 'compositionProductRefs' &&
          key !== 'compositionTaskRefs' &&
          key !== 'compositionTransitionRefs')
      ) {
        let ref = '';
        let refString;

        if (props[key].$ref) {
          refString = props[key].$ref;
        } else if (props[key].items) {
          if (props[key].items['$ref']) {
            switch (key) {
              case 'compositionRefs':
                refString = 'CompositionContentResource';
                break;
              case 'questionRefs':
                refString = 'QuestionContentResource';
                break;
              case 'questionGroupRefs':
                refString = 'QuestionGroupContentResource';
                break;
              case 'themaRefs':
                refString = 'ThemaContentResource';
                break;
            }
          }
        }

        if (refString) {
          const referenceArray = [];
          const refArray = refString.split('/');
          ref = refArray[refArray.length - 1];

          const rows = await lastValueFrom(this.apiService.get(this.configService.getEndpoint(ref)));
          for (const row of rows) {
            let label;
            if (!row.adminName) {
              label = row.name;
              if (row.value) {
                label += row.value;
              }
            } else {
              label = row.adminName;
            }

            label = `[${row.id.slice(-6)}] ${label}`;
            referenceArray.push({ value: row.id, label });
          }
          referenceArray.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
          this.referenceRows = this.referenceRows.filter(x => x.key !== key);
          this.referenceRows.push({ key, data: referenceArray });
        }
      }
    }
    this.referenceRows = [...this.referenceRows.concat(this.compositionData)];
    this.mapJsonToDynamicForm();
  }

  private mapJsonToDynamicForm(): void {
    this.fields = [];
    this.targetFields = [];
    const schemas = this.configService.appOpenApiConfig['components']['schemas'];

    const props = schemas[this.currentEntity].properties;
    Object.entries(props).forEach(([key]) => {
      if (key !== 'used' && key !== 'id') {
        let type = props[key].type;
        const minimum = props[key].minimum;
        const maximum = props[key].maximum;
        const maxLength = props[key].maxLength;
        const minLength = props[key].minLength;
        const description = props[key].description;
        let title = props[key].title;
        let componentType = null;
        let inputType = null;
        let disabled = false;
        let isEnum = false;
        let enumOptions = [];
        let multiple = false;
        let defaultValue;

        const required = this.isFieldRequired(key);

        // checking if the field is an enum
        if (props[key].enum) {
          isEnum = true;

          for (const value of props[key].enum) {
            enumOptions.push({ value, label: value });
          }
        }

        if (this.model?.metadata['lockedFields']) {
          disabled = this.model?.metadata['lockedFields'].includes(key);
        }

        if (
          key === 'compositionRefs' ||
          key === 'questionRefs' ||
          key === 'questionGroupRefs' ||
          key === 'compositionProductRefs' ||
          key === 'compositionTransitionRefs' ||
          key === 'compositionTaskRefs' ||
          key === 'themaRefs'
        ) {
          type = 'array';

          try {
            enumOptions = this.referenceRows.find(o => o.key === key).data;
          } catch {
            return;
          }
        }

        switch (type) {
          case 'integer': {
            componentType = 'input';
            inputType = 'number';
            break;
          }
          case 'string': {
            if (isEnum) {
              componentType = 'ng-select';
            } else {
              componentType = 'input';
              inputType = 'text';
            }
            break;
          }
          case 'number': {
            componentType = 'input';
            inputType = 'number';
            break;
          }
          case 'boolean': {
            componentType = 'checkbox';
            defaultValue = true;
            break;
          }
          case 'array': {
            componentType = 'ng-select';

            if (this.m2mFields.includes(key)) {
              const selectedValues = [];

              for (const element of enumOptions) {
                if (
                  this.model[key] &&
                  key !== 'compositionProductRefs' &&
                  key !== 'compositionTaskRefs' &&
                  key !== 'compositionTransitionRefs'
                ) {
                  this.model[key].forEach(model => {
                    if (element.value === model) {
                      selectedValues.push(element.value);
                    }
                  });
                } else if (this.model[key]) {
                  let searchKey = key;
                  if (key.toLowerCase().includes('product')) {
                    searchKey = 'product';
                  } else if (key.toLowerCase().includes('task')) {
                    searchKey = 'task';
                  } else if (key.toLowerCase().includes('transition')) {
                    searchKey = 'transition';
                  }
                  this.model[key].forEach(model => {
                    if (
                      element.value[searchKey !== key ? `${searchKey}Id` : key] ===
                        model[searchKey !== key ? `${searchKey}Id` : key] &&
                      element.value.compositionId === model.compositionId
                    ) {
                      selectedValues.push(element.value);
                    }
                  });
                }
              }

              multiple = true;
              this.model[key] = selectedValues;
            }

            break;
          }
          default: {
            return;
          }
        }

        if (!title || title === '') {
          title = key;
        }

        const validation = key === 'displayName' || key === 'adminName' ? ['name'] : [];

        const data: FormlyFieldConfig = {
          key: key,
          type: componentType,
          defaultValue: defaultValue,
          className: this.isCustomizedField(key, this.model),
          hooks: {
            onInit: () => {
              this.form.enable();
            },
          },
          props: {
            label: title,
            // placeholder: 'Enter ' + key,
            options: enumOptions,
            description: description,
            required: required,
            multiple: multiple,
            selectAllOption: 'Select / Deselect ',
            type: inputType,
            min: minimum,
            max: maximum,
            maxLength: maxLength,
            minLength: minLength,
            rows: 4,
          },
          validators: {
            validation: validation,
          },
          expressionProperties: {
            'props.disabled': (model: ModelInterface, formState: any, field: FormlyFieldConfig) => {
              return this.getDisabledState(key);
            },
          },
        };
        if (key === 'adminName' || key === 'displayName' || key === 'description') {
          this.fields.push(data);
        } else {
          this.targetFields.push(data);
        }
      }
    });
    this.isLoading = false;
    this.entityService.isLoading.next(false);
    this.changeDetection.detectChanges();
    // VSS-5655 - Scroll to top of the form
    this.containerRef.scrollTo(0, 0);
  }

  private getDisabledState(key: string): boolean {
    const lock = this.model.metadata['lockedFields'];

    if (this.instanceService.isProdInstance) {
      return true;
    } else if (this.ignoreLocks) {
      return false;
    } else if (lock && lock.length > 0) {
      if (lock.includes(key)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  private isCustomizedField(key: string, model: ModelInterface): string {
    return model?.metadata['syncExcludedFields']?.includes(key) ? 'customized' : '';
  }

  private isFieldRequired(key: string): boolean {
    const required = this.configService.appOpenApiConfig['components']['schemas'][this.currentEntity].required;
    for (const field of required) {
      if (field === key && key !== 'hasTextfield') {
        return true;
      }
    }
    return false;
  }

  get ignoreLocks() {
    return this.lockingService.ignoreLocking;
  }

  get ruleEditable() {
    if (this.instanceService.isProdInstance) {
      return false;
    } else if (this.model?.metadata['lockedFields']?.includes('ruleset') && !this.ignoreLocks) {
      return false;
    } else if (this.model?.metadata['lockedFields']?.includes('ruleset') && this.ignoreLocks) {
      return true;
    } else {
      return true;
    }
  }

  get isButtonDisabled() {
    if (this.model.displayName && this.model.adminName) {
      return this.isProdInstance || this.model.displayName.trim() === '' || this.model.adminName.trim() === '';
    } else {
      return true;
    }
  }

  get hasCustomizedRules(): boolean {
    return this.model && Object.keys(this.model).length > 0 && this.model?.metadata['syncExcludedFields']?.length > 0;
  }
}
