import { ToastrService } from 'ngx-toastr';
export interface PrepareAttributesResponse {
  existingAttributes: any[];
  newAttributes: any[];
}

export interface InnerFormsData {
  compositionProductRefs: InnerFormsElement;
  benefits: InnerFormsElement;
  products: InnerFormsElement;
  prices: InnerFormsElement;
  profile: InnerFormsElement;
  answerOptions: InnerFormsElement;
  dataFields: InnerFormsElement;
  taskRefs: InnerFormsElement;
  transitionRefs: InnerFormsElement;
  productRefs: InnerFormsElement;
}

export interface InnerFormsElement {
  hasFormField: boolean;
  data: any[];
}

export interface HandleInnerFormsResponse {
  valid: boolean;
  model: any;
}
export const checkNumeric = (field, event) => {
  if (event.shiftKey) {
    event.preventDefault();
  }
  const currentValue = event.currentTarget.value;
  const ctrlDown = event.ctrlKey || event.metaKey;
  const key = event.keyCode;
  const doSpecialCharExist = (string: string): boolean => string.includes(',') || string.includes('.');
  if (
    !(
      key === 9 || //Tab!!
      key === 8 || // backspace
      key === 46 || // delete
      (ctrlDown && key === 67) ||
      (ctrlDown && key === 65) ||
      (ctrlDown && key === 86) ||
      (ctrlDown && key === 88) ||
      (key === 190 && !doSpecialCharExist(currentValue)) ||
      (key === 188 && !doSpecialCharExist(currentValue)) ||
      (key === 189 && !currentValue) ||
      (key >= 35 && key <= 40) || // arrow keys/home/end
      (key >= 48 && key <= 57) || // numbers on keyboard
      (key >= 96 && key <= 105)
    )
  ) {
    event.preventDefault();
  }
};
export class InnerFormFunctions {
  constructor(private toastService: ToastrService) {}

  public prepareAttributes(
    attributes: any[],
    question: any,
    attributeData: any[],
    model: any
  ): PrepareAttributesResponse {
    const existingAttributes = [];
    const newAttributes = [];

    const multipleNewValues = attributes.filter(x => x.name === '_newValue').length > 1;
    attributes.forEach((attribute, index) => {
      let exists = false;
      let name;
      let value;
      if (attribute.name === '_newValue') {
        const str = `${question.displayName.toString().replace(/\s/g, '').substr(0, 14).toUpperCase()}${
          multipleNewValues && attribute?.value === '_newValue' ? index : ''
        }`;
        name = `${question.id}-${str}`;
      } else if (attribute.name === '_newAttribute') {
        name = attribute.customName.trim();
      } else {
        name = attribute.name.trim();
      }

      if (attribute?.value === '_newValue' || attribute?.value === 'newAttribute') {
        const ordinal = model.ordinal.toString();
        const name = model.displayName.toString().replace(/\s/g, '').substr(0, 4);
        const min = model?.minValue?.toString() || '0';
        const max = model?.maxValue?.toString() || '0';
        const date = Date.now().toString();
        value = `${ordinal}-${name}-${min}-${max}${date}`.toUpperCase();
      } else {
        if (attribute.customValue) {
          value = attribute.customValue.trim();
        } else {
          value = attribute?.value.trim();
          const id = attributeData.find(x => x.displayName === attribute.name && x.value === attribute?.value)?.id;
          existingAttributes.push(id);
          exists = true;
        }
      }
      if (!exists) {
        const newAttribute = { value, displayName: name, adminName: `${name}=${value}` };
        newAttributes.push(newAttribute);
      }
    });
    return { newAttributes, existingAttributes };
  }

  public handleInnerForms(
    valid: boolean,
    innerFormsData: InnerFormsData,
    ignoreLocks: boolean,
    model: any,
    currentEntity: string
  ): HandleInnerFormsResponse {
    if (innerFormsData.benefits.hasFormField && innerFormsData.benefits.data) {
      const benefits = innerFormsData.benefits.data.map(x => {
        if (x.status === 'INVALID') {
          valid = false;
        }
        return x.value;
      });

      if (innerFormsData.benefits.data.length > 0 && !ignoreLocks) {
        if (!model.benefits) model.benefits = innerFormsData.benefits.data;
        benefits.forEach(x => {
          if (model.benefits.find(y => y.id === x['id'])) {
            model.benefits.forEach(element => {
              element.metadata.lockedFields.forEach(lock => {
                x[lock] = model.benefits.find(z => z.id === x['id'])[lock];
              });
            });
          }
        });
      }

      model.benefits = benefits;
    }

    if (innerFormsData.prices.hasFormField && innerFormsData.prices.data) {
      let prices = innerFormsData.prices.data.map(x => {
        if (x.status === 'INVALID') {
          valid = false;
        }
        return x.value;
      });

      if (innerFormsData.prices.data.length > 0 && !ignoreLocks) {
        prices.forEach(x => {
          if (model?.prices?.find(y => y?.id === x['id'])) {
            model.prices.forEach(element => {
              element.metadata.lockedFields.forEach(lock => {
                x[lock] = model.prices.find(z => z.id === x['id'])[lock];
              });
            });
          }
        });
      }

      prices = innerFormsData.prices.data.map(x => {
        let cleanPrice = x.value.price.replace(/\./gi, '');
        cleanPrice = cleanPrice.replace(/\,/gi, '.');
        return { ...x.value, price: Number(cleanPrice) };
      });

      model.prices = prices;
    }

    if (
      innerFormsData.products.hasFormField &&
      currentEntity !== 'DataFieldGroupContentResource' &&
      innerFormsData.products.data.length > 0
    ) {
      const products = [];
      const additionalParents = [];
      const availableParents = [];
      const mainId = innerFormsData.products.data.find(product => product.value.type === 'MAIN')?.value?.productId;

      innerFormsData.products.data.forEach(x => {
        const productRef = x.value;
        if (productRef.type === 'MAIN') {
          delete productRef.parentId;
          availableParents.push(productRef.productId);
        } else if (productRef.type === 'VARIANT') {
          productRef.parentId = mainId;
          availableParents.push(productRef.productId);
        } else {
          additionalParents.push(productRef.parentId);
        }
        products.push(productRef);
        if (x.status === 'INVALID') {
          valid = false;
        }
      });

      let idFound = true;
      if (additionalParents.length !== 0) {
        for (const additionalParent of additionalParents) {
          if (!availableParents.find(availableParent => availableParent === additionalParent)) {
            idFound = false;
          }
        }
        if (!idFound) {
          valid = false;
          this.toastService.error(
            'ADDITIONALs parentId müssen Id eines Main Produktes oder einer Variante entsprechen'
          );
        }
      }
      model.productRefs = products;
    } else if (
      innerFormsData.products.hasFormField &&
      innerFormsData.products.data?.length === 0 &&
      currentEntity !== 'DataFieldGroupContentResource'
    ) {
      model.productRefs = [];
    }

    if (innerFormsData.profile.hasFormField && innerFormsData.profile.data) {
      if (innerFormsData.profile.data[0]?.status === 'INVALID') {
        valid = false;
      }
      if (innerFormsData.profile.data[0]) {
        if (innerFormsData.profile.data[0].value['id'] === model.dataFieldGroupProfile?.id && !ignoreLocks) {
          model.dataFieldGroupProfile?.metadata.lockedFields.forEach(element => {
            innerFormsData.profile.data[0].value[element] = model.dataFieldGroupProfile[element];
          });
          model.dataFieldGroupProfile = { ...innerFormsData.profile.data[0].value };
        } else {
          model.dataFieldGroupProfile = innerFormsData.profile.data[0].value;
        }
      } else {
        model.dataFieldGroupProfile = null;
      }
    }

    const regularInnerForms = [
      'compositionProductRefs',
      'taskRefs',
      'productRefs',
      'transitionRefs',
      'answerOptions',
      'dataFields',
    ];
    for (const regularInnerForm of regularInnerForms) {
      if (
        (currentEntity === 'DataFieldGroupContentResource' && regularInnerForm === 'productRefs') ||
        (currentEntity === 'DataFieldGroupContentResource' && regularInnerForm === 'taskRefs') ||
        (currentEntity === 'DataFieldGroupContentResource' && regularInnerForm === 'transitionRefs')
      ) {
        model[regularInnerForm] = innerFormsData[regularInnerForm].data;
      } else if (!(currentEntity === 'CompositionContentResource' && regularInnerForm === 'productRefs')) {
        const result = this.addInnerFormItemsToModel(
          innerFormsData[regularInnerForm],
          regularInnerForm,
          valid,
          model.id
        );
        valid = result.valid;
        model[regularInnerForm] = result.data;
      }
    }

    Object.keys(model)?.forEach(key => {
      if (key.includes('Refs')) {
        const ref = [];

        if (currentEntity === 'ProductContentResource' && key === 'dataExportRefs' && typeof model[key] === 'string') {
          model[key] = [model[key]];
        }
        if (typeof model[key] === 'number') {
          model[key] = [model[key]];
        } else {
          model[key]?.forEach(valueElement => {
            const id = valueElement;
            ref.push(id);
          });
          model[key] = ref;
        }
      }
    });
    return { valid, model };
  }

  private addInnerFormItemsToModel(
    innerFormsElement: InnerFormsElement,
    field: string,
    valid: boolean,
    modelId = -1
  ): { data: any[]; valid: boolean } {
    if (innerFormsElement.hasFormField && innerFormsElement.data) {
      if (innerFormsElement.data.length > 0) {
        return {
          data: innerFormsElement.data.map(item => {
            item.value = item.getRawValue();

            if (item.status === 'INVALID') {
              valid = false;
            }
            return item.value;
          }),
          valid,
        };
      } else {
        return { data: [], valid };
      }
    } else {
      return { data: [], valid };
    }
  }
}
