import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private _tenantId: string = '';
  private _userRole: string[] = [];
  private _userName: string = '';

  public tenantIdChanged = new BehaviorSubject<string>('');
  public userRoleChanged = new BehaviorSubject<string[]>([]);
  public userNameChanged = new BehaviorSubject<string>('');

  constructor() {}

  public get tenantId(): string {
    return this._tenantId;
  }
  public set tenantId(value: string) {
    this._tenantId = value;
    this.tenantIdChanged.next(value);
  }

  public get userRole(): string[] {
    return this._userRole;
  }
  public set userRole(value: string[]) {
    this._userRole = value;
    this.userRoleChanged.next(value);
  }

  public get userName(): string {
    return this._userName;
  }
  public set userName(value: string) {
    this._userName = value;
    this.userNameChanged.next(value);
  }
}
