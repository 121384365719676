<div class="d-flex w-100 vr-color-neutral-600 font-weight-bold" *ngIf="breadCrumbsNav.length > 0">
  <div
    class="d-flex mr-1 cursor-pointer vr-text-small"
    (click)="jumpToEntity(navElement)"
    *ngFor="let navElement of breadCrumbsNav; let last = last"
  >
    <div>{{ navElement.title }}</div>
    <div
      *ngIf="!last"
      class="mr-1 vr-icon-chevron-right"
      [style.backgroundColor]="color.find(color.name.Neutral600)"
    ></div>
  </div>
  <div *ngIf="currentModel.displayName" class="d-flex vr-text-small">
    <div class="mr-1 vr-icon-chevron-right" [style.backgroundColor]="color.find(color.name.Neutral600)"></div>
    <div class="vr-color-brand-primary">{{ getModelDisplayName() }}</div>
  </div>
</div>
