<div class="w-100 h-100 d-flex flex-column">
  <section class="pl-3">
    <div class="bg-white">
      <!-- TITLE & BREADCRUMB -->
      <div style="min-height: 3rem" class="d-flex flex-grow-1 align-items-center">
        <h2 class="entity-heading mt-2">
          {{ getPluralTitle(entity) }}
        </h2>
        <div class="ml-5">
          <app-breadcrumb-navigation
            *ngIf="isInQuestionWorld"
            [currentEntity]="entity"
            [currentModel]="currentModel"
          ></app-breadcrumb-navigation>
        </div>
      </div>

      <!-- FILTER ROW -->
      <div class="d-flex align-items-center">
        <div class="vr-icon-search vr-icon-force-size filter-neutral-900 search-icon mb-3"></div>
        <form class="d-flex justify-content-between form-width" style="column-gap: 0.75rem">
          <mat-form-field *ngFor="let filter of displayedColumns | slice: 1" class="flex-fit">
            <mat-label>{{ getOpenApiColumnTitle(filter) || filter }}</mat-label>
            <input #inputFields matInput type="text" [name]="filter" (keyup)="onFilterChange(filter, $event)" />
          </mat-form-field>
          <button type="reset" value="reset" class="reset-button" (click)="onResetFilters()">
            Filter zurücksetzen
          </button>
          <ng-container *ngIf="entity === 'DataFieldContentResource' && currentContext === 'configurator'">
            <button type="reset" value="reset" class="reset-button cache" (click)="clearCache()">Cache leeren</button>
          </ng-container>
        </form>
      </div>
    </div>
  </section>
  <section class="flex-scroll flex-grow-1">
    <div [class.d-none]="isLoading" [hidden]="!items">
      <table #dataTable mat-table [dataSource]="dataSource" matSort matSortDisableClear>
        <ng-container
          [matColumnDef]="currentColumn"
          *ngFor="let currentColumn of displayedColumns; let colIndex = index"
        >
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="pr-2">
            <ng-container *ngIf="currentColumn !== 'lockedFields'; else lockIcon">
              {{ getOpenApiColumnTitle(currentColumn) || currentColumn | uppercase }}
            </ng-container>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [title]="element[currentColumn]"
            [class.information]="isInformationContentResource"
            [class.font-weight-bold]="isCustomizedField(element.id, currentColumn)"
            [class.entry-inactive]="element['active'] && element['active'] !== 'Ja'"
          >
            <div class="pr-2 py-2">
              <div *ngIf="currentColumn !== 'lockedFields' && !uuidRegEx.test(element[currentColumn])">
                {{ element[currentColumn] === false && entity === 'RuleContentResource' ? '' : element[currentColumn] }}
              </div>
              <div *ngIf="uuidRegEx.test(element[currentColumn])">
                {{
                  element[currentColumn].includes(',')
                    ? element[currentColumn].slice(-6) + ', ...'
                    : element[currentColumn].slice(-6)
                }}
              </div>
              <div
                class="config-icon-lock vr-color-neutral-900"
                *ngIf="
                  (currentColumn === 'lockedFields' && element[currentColumn] && element[currentColumn].length > 0) ||
                  (currentColumn === 'lockedFields' && isProdInstance)
                "
                [style.color]="lockColor"
                [title]="'Eingeschränktes Element'"
              ></div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [class.selected]="selection.isSelected(row)"
          (click)="onTableRowClicked(row)"
        ></tr>
      </table>
    </div>

    <div class="d-flex w-100 justify-content-center" [ngClass]="isLoading && 'd-inline'" style="display: none">
      <mat-spinner [ngClass]="isLoading && 'd-inline'" style="display: none"></mat-spinner>
    </div>
  </section>
  <div [hidden]="!dataSource">
    <mat-paginator
      #matpag
      class="text-xs"
      [pageSize]="pageSize"
      [pageSizeOptions]="sizeOptions"
      (page)="setPage($event)"
      showFirstLastButtons
      fixed
    ></mat-paginator>
  </div>
</div>
<ng-template #lockIcon>
  <div class="config-icon-lock" [style.backgroundColor]="color.find(color.name.Neutral400)"></div>
</ng-template>
