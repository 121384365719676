import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@modules/auth';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.jwtToken) {
      return true;
    } else {
      return this.authService
        .getSession()
        .then(result => {
          if (result?.isValid()) {
            return true;
          }

          return this.notAuth();
        })
        .catch(e => {
          return this.notAuth();
        });
    }
  }

  private notAuth(): boolean {
    this.router.navigate(['login']);
    return false;
  }
}
