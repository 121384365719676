<div
  class="w-100 d-flex d-inline-block"
  [class.justify-content-between]="entityWorld === entityWorldEnum.QuestionWorld"
  [class.justify-content-end]="entityWorld !== entityWorldEnum.QuestionWorld"
  style="border-bottom: 1px solid"
>
  @if (entityWorld === entityWorldEnum.QuestionWorld) {
    <mat-form-field class="m-3">
      <mat-label>
        <div>Unterthemen</div>
      </mat-label>
      <mat-select
        [placeholder]="'Subtopic ID'"
        [disabled]="subtopics.length === 0"
        [value]="subtopics.length !== 0 ? currentSubtopicId : 'noId'"
        (selectionChange)="changeSubtopic($event)"
      >
        @for (subtopic of subtopics; track subtopic) {
          <mat-option [value]="subtopic.id">[{{ subtopic.id }}] {{ subtopic.displayName }}</mat-option>
        } @empty {
          <mat-option [value]="'noId'">Keine Instanzen</mat-option>
        }
      </mat-select></mat-form-field
    >
  }
  <div (click)="closeDialog()" class="vr-icon-close cursor-pointer mt-2 mr-2"></div>
</div>

<div class="graph-container">
  @if (nodes?.length > 0 && edges?.length > 0) {
    <ngx-graph
      class="chart-container"
      [view]="view"
      layout="dagreCluster"
      isClustered="true"
      [links]="edges"
      [nodes]="nodes"
      [curve]="curve"
      [zoomSpeed]="zoomSpeed"
      [autoZoom]="autoZoom"
      [zoomLevel]="zoomLevel"
      [update$]="update$"
      [center$]="center$"
      [zoomToFit$]="zoomToFit$"
    >
      <ng-template #defsTemplate>
        <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4" orient="auto">
          <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
        </svg:marker>
      </ng-template>
      <ng-template #nodeTemplate let-node>
        <svg:g class="node cursor-pointer">
          @if (node.type === 'CompositionContentResource' || node.type === 'SubtopicContentResource') {
            <svg:rect
              [attr.width]="node.dimension.width"
              [attr.height]="node.dimension.height"
              [attr.fill]="node.data.color || 'rgba(230, 132, 126, .5)'"
            />
          }
          @if (node.type === 'ProductContentResource' || node.type === 'QuestionGroupContentResource') {
            <svg:rect
              [attr.width]="node.dimension.width"
              [attr.height]="node.dimension.height"
              [attr.fill]="node.data.color || 'rgba(247, 214, 104, .5)'"
            />
          }
          @if (node.type === 'TaskContentResource' || node.type === 'QuestionContentResource') {
            <svg:rect
              [attr.width]="node.dimension.width"
              [attr.height]="node.dimension.height"
              [attr.fill]="node.data.color || 'rgba(1300, 138, 255, .5)'"
            />
          }
          @if (node.type === 'TransitionContentResource' || node.type === 'AnswerOptionContentResource') {
            <svg:rect
              [attr.width]="node.dimension.width"
              [attr.height]="node.dimension.height"
              [attr.fill]="node.data.color || 'rgba(193, 2430, 103, .5)'"
            />
          }
          <svg:text alignment-baseline="central" [attr.x]="10" [attr.y]="node.dimension.height / 2">
            {{ getId(node.id) }} [{{ getContentSource(node.contentSource) }}] - {{ node.label }}
          </svg:text>
        </svg:g>
      </ng-template>
      <ng-template #linkTemplate let-link>
        <svg:g class="edge">
          <svg:path class="line" stroke-width="2" [attr.d]="link.line" marker-end="url(#arrow)"></svg:path>
          <svg:text class="edge-label" text-anchor="middle">
            <textPath style="fill: rgb(22, 22, 22)" [attr.href]="'#' + link.id" startOffset="60%">
              {{ link.label }}
            </textPath>
          </svg:text>
        </svg:g>
      </ng-template>
      <ng-template #clusterTemplate let-cluster>
        <svg:g class="node cluster">
          <svg:rect
            rx="5"
            ry="5"
            [attr.width]="cluster.dimension.width"
            [attr.height]="cluster.dimension.height"
            [attr.fill]="cluster.data.color"
          />
        </svg:g>
      </ng-template>
    </ngx-graph>
  }
</div>
