<div class="dialog-container">
  @if (!selection) {
    <h1 mat-dialog-title>{{ title }}</h1>
    <div mat-dialog-content class="content">
      {{ text }}
      @if (custom === 'csv') {
        <div>
          Bitte kontrollieren sie zunächst das Ergebnis der
          <span class="vr-color-brand-primary cursor-pointer" (click)="customAction('validation')">
            Inhaltsprüfung
          </span>
          und anschließend die
          <span class="vr-color-brand-primary cursor-pointer" (click)="customAction('changes')">
            Änderungshistorie
          </span>
          seit der letzten Synchronisierung
        </div>
        <div class="mt-3">Möchten Sie fortfahren?</div>
      }
    </div>
    <div mat-dialog-actions>
      @if (!custom || custom === 'csv') {
        <!-- regular buttons -->
        <button mat-button (click)="submitAction()" mat-flat-button color="warn">{{ customConfirm || 'Ja' }}</button>
        @if (custom && custom !== 'csv') {
          <button mat-button (click)="customAction('submit')">{{ custom }}</button>
        }
        <button mat-button (click)="closeAction()">Nein</button>
      }
      @if (custom === 'deepcopy') {
        <button mat-button (click)="submitAction('deepcopy')" mat-flat-button color="basic">Deep Kopie</button>
        <button mat-button (click)="submitAction('copy')" mat-flat-button color="basic">Normale Kopie</button>
        <button mat-button (click)="closeAction()">Abbrechen</button>
      }
      @if (custom === 'customization') {
        <button mat-button (click)="submitAction('import')" mat-flat-button color="basic">Import</button>
        <button mat-button (click)="submitAction('export')" mat-flat-button color="basic">Export</button>
        <button mat-button (click)="closeAction()">Abbrechen</button>
      }
    </div>
  } @else {
    <div class="vr-color-brand-primary font-weight-bold vr-text-small">
      @if (selection.showTestConsultations) {
        <div class="cursor-pointer dialog-selection-element d-flex" (click)="selectionAction('testConsultations')">
          <div>Testberatung</div>
        </div>
      }
      @if (selection.showGraphs) {
        <div class="cursor-pointer dialog-selection-element" (click)="selectionAction('showGraphs')">
          <div>Grafische Ansicht</div>
        </div>
      }
      @if (selection.customization) {
        <div class="cursor-pointer dialog-selection-element" (click)="selectionAction('customizations')">
          <div>Individualisierung</div>
        </div>
      }
      @if (selection.resetHubShown) {
        <div class="cursor-pointer dialog-selection-element" (click)="selectionAction('resetHub')">
          <div>Inhalt zurücksetzen</div>
        </div>
      }
      @if (selection.licenseButtonShown) {
        <div class="cursor-pointer dialog-selection-element" (click)="selectionAction('updateLicense')">
          <div>Lizenz aktualisieren</div>
        </div>
      }
    </div>
  }
</div>
