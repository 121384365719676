import { Component, Input, OnInit } from '@angular/core';
import { color } from '@enums';
import { ContextService } from '@services/context.service';

@Component({
  selector: 'sticky-button',
  templateUrl: './sticky-button.component.html',
  styleUrls: ['./sticky-button.component.scss'],
  standalone: false,
})
export class StickyButtonComponent implements OnInit {
  @Input() marginClass: string = '';

  public isSticky: boolean;

  public readonly color = color;

  constructor(private contextService: ContextService) {}

  ngOnInit(): void {
    this.isSticky = this.contextService.stickyMode;
  }

  public onStickApp(event: Event): void {
    this.isSticky = this.contextService.toggleStickyMode();
  }

  get stickyIconClass(): string {
    return this.isSticky ? 'vr-icon-pin-remove' : 'vr-icon-pin';
  }

  get hasPortalHeader(): boolean {
    return this.contextService.hasPortalHeader;
  }
}
