import { Component, Inject, OnInit, Optional } from '@angular/core';
/* eslint-disable */
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { environment } from '@environment/environment';
import { BehaviorSubject } from 'rxjs';

export interface DialogData {
  id?: string;
  title?: string;
  text?: string;
  custom?: string;
  customConfirm?: string;
  selection?: string;
  target?: HTMLElement;
}

/**
 * Dialog that is displayed before deleting a record.
 */
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  public title;
  public text;
  public custom;
  public customConfirm;
  public selection;

  private a: MatDialog | MatDialogActions | MatDialogTitle | MatDialogContent | MatDialogClose;

  public customEvent = new BehaviorSubject<string>('');

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: DialogData
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.text = this.data.text;
    this.custom = this.data.custom;
    this.customConfirm = this.data.customConfirm;
    this.selection = this.data.selection;

    if (this.data.target) {
      this.updatePosition(this.data.target);
    }

    if (environment.platform === 'vp' && environment.isLocal && window.location.pathname.includes('/bws')) {
      document.querySelector('.dialog-container').classList.add('bws-local');
    }
  }

  private updatePosition(target): void {
    const rect: DOMRect = target.getBoundingClientRect();
    const offest = rect.width === 24 ? 0 : 24;
    this.dialogRef.updatePosition({
      left: `${rect.left + offest}px`,
      top: `${rect.bottom - rect.height / 2}px`,
    });
  }

  public submitAction(customString?): void {
    this.dialogRef.close({ event: customString || 'submit', id: this.data.id });
  }

  public closeAction(): void {
    this.dialogRef.close({ event: 'close' });
  }

  public selectionAction(selectionAction: string) {
    this.dialogRef.close({ event: selectionAction });
  }

  public customAction(target: string): void {
    this.customEvent.next(target);
  }
}
