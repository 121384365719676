import { LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { ConfiguratorComponent } from '@app/mainConfig/configurator/configurator.component';
import { LoginComponent } from '@app/mainConfig/login/login.component';
import { ErrorscreenComponent } from '@components/error-screen/error-screen.component';
import { MainComponent } from '@components/main/main.component';
import { SetupDoneComponent } from '@components/setup-done/setup-done.component';
import { AuthGuard } from '@modules/auth';
import { CustomLocationStrategy } from './custom-location-strategy';

const routes: Routes = [
  { path: '', component: MainComponent, pathMatch: 'full' },
  { path: 'setup-done', component: SetupDoneComponent },
  { path: 'login', component: LoginComponent },
  { path: 'configurator', component: ConfiguratorComponent, canActivate: [AuthGuard] },
  { path: 'start', component: MainComponent },
  { path: 'error', component: ErrorscreenComponent },
  { path: '**', redirectTo: 'configurator' },
];

const options: ExtraOptions = { useHash: true };

@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  providers: [{ provide: LocationStrategy, useClass: CustomLocationStrategy }],
  exports: [RouterModule],
})
export class AppRoutingModule {}
