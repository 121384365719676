import { Injectable } from '@angular/core';

const htmltags = /(<([^>]+)>)/gi;

const umlaute = {
  ä: /&auml;/gi,
  Ä: /&Auml;/gi,
  ü: /&uuml;/gi,
  Ü: /&Uuml;/gi,
  ö: /&ouml;/gi,
  Ö: /&Ouml;/gi,
  ß: /&szlig;/gi,
  ' ': /&nbsp;/gi,
  '-': /&ndash;/gi,
};

@Injectable({
  providedIn: 'root',
})
export class CleanHtmlService {
  constructor() {}

  public cleanHtml(input, skipHtml = false): string {
    Object.entries(umlaute).forEach(umlaut => {
      const [key, value] = umlaut;
      input = input.replace(value, key);
    });
    if (!skipHtml) {
      input = input.replace(htmltags, '');
    }
    return input;
  }
}
