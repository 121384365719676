<div class="d-flex flex-column h-100 align-items-center bg-white">
  <!-- Use 'flex-center' instead of 'align-items-center' to center everything! -->
  <div class="d-flex flex-column flex-grow-1 flex-scroll w-100 flex-center">
    <!-- ------- -->
    <!-- CONTENT -->
    <!-- ------- -->
    <div class="d-flex flex-center">
      <img style="min-width: 30rem" src="{{ assetPath }}/images/logo-bg2-standard.svg" />
    </div>
    <div class="d-flex flex-center vr-color-neutral-900 vr-headline-300">BankingGuide Administration</div>
    <div class="my-3"></div>
    @if (authState === 'requireNewPassword') {
      <ng-template *ngTemplateOutlet="passwordChangeFormTemplate; context: { form: passwordChangeForm }"></ng-template>
    } @else {
      <ng-template *ngTemplateOutlet="loginFormTemplate; context: { form: loginForm }"></ng-template>
    }
    <!-- ------- -->
    <!-- ------- -->
    <!-- ------- -->
  </div>
</div>

<!-- -------------------- LOGIN TEMPLATE -------------------- -->

<ng-template #loginFormTemplate let-form="form">
  <form [formGroup]="form" class="form-width" (ngSubmit)="handleLogin()">
    <!-- username -->
    <div class="form-group">
      <!-- <label class="vr-text-small" for="username">Name</label> -->
      <input
        type="text"
        formControlName="username"
        class="form-control login-input"
        placeholder="Name"
        [ngClass]="{ 'is-invalid': submitted && formUsername.errors }"
      />
      @if (submitted && formUsername.errors) {
        <div class="invalid-feedback">
          @if (formUsername.errors.required) {
            <div>Benutzername ist notwendig</div>
          }
        </div>
      }
    </div>
    <!-- password -->
    <div class="form-group">
      <!-- <label class="vr-text-small" for="password">Passwort</label> -->
      <input
        [type]="showPassword ? 'text' : 'password'"
        formControlName="password"
        class="form-control login-input"
        placeholder="Passwort"
        [ngClass]="{ 'is-invalid': submitted && formPassword.errors }"
      />
      @if (formPassword.value) {
        <span
          class="login-icon-span"
          [ngClass]="showPassword ? 'vr-icon-view' : 'vr-icon-view-off'"
          (click)="togglePassword()"
        ></span>
      }
      @if (submitted && formPassword.errors) {
        <div class="invalid-feedback">
          @if (formPassword.errors.required) {
            <div>Passwort ist notwending</div>
          }
        </div>
      }
    </div>
    <!-- login button -->
    <button [disabled]="loading" class="btn vr-text-regular login-button w-100">
      @if (loading) {
        <span class="spinner-border spinner-border-sm mr-1" style="vertical-align: unset"></span>
      }
      Anmelden
    </button>
    @if (error) {
      <div class="alert alert-danger mt-3 mb-0">{{ error }}</div>
    }
  </form>
</ng-template>

<!-- -------------------- PASSWORD SET TEMPLATE -------------------- -->

<ng-template #passwordChangeFormTemplate let-form="form">
  <div class="vr-text-regular">Bitte ändern Sie ihr temporäres Passwort</div>
  <div class="vr-text-extrasmall mb-4">(mindestens 8 Zeichen)</div>
  <form [formGroup]="form" class="form-width" (ngSubmit)="handlePasswordChange()">
    <div class="form-group">
      <!-- <label class="vr-text-small" for="password">Passwort</label> -->
      <input
        formControlName="password"
        class="form-control login-input"
        placeholder="Neues Passwort"
        [type]="showPassword ? 'text' : 'password'"
        [ngClass]="{ 'is-invalid': submitted && formChangePassword.errors }"
      />
      @if (!formChangePassword.errors && formChangePassword.value) {
        <span
          class="login-icon-span"
          [ngClass]="showPassword ? 'vr-icon-view' : 'vr-icon-view-off'"
          (click)="togglePassword()"
        ></span>
      }
      @if (submitted && formChangePassword.errors) {
        <div class="invalid-feedback">
          @if (formChangePassword.errors.required) {
            <div>Passwort ist notwending</div>
          }
          @if (formChangePassword.errors.minlength) {
            <div>Passwort muss mindestens 8 Zeichen enthalten</div>
          }
        </div>
      }
    </div>
    <!-- login button -->
    <button [disabled]="loading" class="btn vr-text-regular login-button w-100">
      @if (loading) {
        <span class="spinner-border spinner-border-sm mr-1" style="vertical-align: unset"></span>
      }
      Passwort ändern
    </button>
    @if (error) {
      <div class="alert alert-danger mt-3 mb-0">{{ error }}</div>
    }
  </form>
</ng-template>
