import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { QueryBuilderComponent, QueryBuilderConfig, Rule } from 'ngx-angular-query-builder';
import { ToastrService } from 'ngx-toastr';

interface QueryInterface {
  condition: string;
  rules: any[];
}

@Component({
  selector: 'app-rule-builder',
  templateUrl: './rule-builder.component.html',
  styleUrls: ['./rule-builder.component.scss'],
})
export class RuleBuilderComponent implements OnInit, OnChanges {
  @Input() ruleQueryInput;
  @Input() disabled = false;
  @Output() newRule = new EventEmitter();

  @ViewChild('queryBuilder') queryBuilder: QueryBuilderComponent;

  private defaultQuery: QueryInterface = { condition: 'and', rules: [] };

  public config: QueryBuilderConfig = null;
  public query: QueryInterface = this.defaultQuery;
  private usedAttributes = [];
  private ruleData = [];
  private rawQueryValues = [];

  private missingAttribute: any;

  constructor(
    private apiService: ApiService,
    private toastService: ToastrService
  ) {
    this.ruleQueryInput = '';
  }

  public ngOnInit(): void {
    this.apiService.get('attributes').subscribe(rules => {
      this.ruleData = rules;
      this.config = this.createQueryConfig();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.ruleQueryInput) {
      const object = changes.ruleQueryInput.currentValue;

      if (object.id) {
        if (this.checkForAttributes(this.translateJson(object.ruleset))) {
          this.query = this.translateJson(object.ruleset);
        } else {
          this.toastService.error(this.missingAttribute);
          this.query = this.defaultQuery;
        }
      }
    } else {
      this.query = this.defaultQuery;
    }
  }

  public updateView(rule: Rule = undefined): void {
    for (let r of this.query.rules) {
      Object.keys(r).map(k => (typeof r[k] === 'string' ? r[k].trim() : r[k]));
    }

    const transformedRule = this.transformJson(this.query);
    const newRule = { ruleset: transformedRule };
    this.newRule.emit(newRule);
    if (rule) {
      this.refreshField(rule);
    }
  }

  public getOptionsForField(field: string): any[] {
    return this.queryBuilder?.fields?.find(x => x.name === field)?.options || [];
  }

  private refreshField(rule: Rule): void {
    // this.queryBuilder.changeField(rule.field, rule); -> this resulted in https://jira.eudemonia-solutions.de/browse/VSS-3586
    rule.value = '';
  }

  private createQueryConfig(): QueryBuilderConfig {
    const attributes = [];
    const queryOptions = [];
    this.rawQueryValues = [];
    this.ruleData.forEach(record => {
      if (!attributes.includes(record.displayName)) {
        attributes.push(record.displayName);
        const elementOptions = [];
        this.ruleData.forEach(element => {
          if (record.displayName === element.displayName && !elementOptions.includes(element.value)) {
            elementOptions.push(element.value);
          }
        });
        queryOptions.push(elementOptions);
      }
    });

    const queryFields = {};
    attributes.forEach((attribute, index) => {
      const localOptions = [{ name: '', value: '' }];
      queryOptions[index].forEach(option => {
        localOptions.push({ name: option, value: option });
      });

      this.rawQueryValues.push({ name: attribute, options: localOptions });

      const attributeElement = {
        name: attribute,
        type: 'category',
        defaultOperator: '=',
        operators: ['=', '!='],
        options: localOptions,
      };
      queryFields[attribute] = attributeElement;
    });
    const queryConfig = {};
    queryConfig['fields'] = queryFields;
    return queryConfig as QueryBuilderConfig;
  }

  private checkForAttributes(attributelist: any): boolean {
    this.usedAttributes = [];
    this.checkForUsedAttributes(attributelist.rules);
    let attributeFound = true;

    this.usedAttributes.forEach(attribute => {
      const nameFound = this.rawQueryValues.find(rawValue => rawValue.name === attribute.name);
      if (nameFound) {
        const valueFound = nameFound.options.find(option => option.name === attribute?.value);

        if (!valueFound) {
          this.missingAttribute = `Wert "${attribute?.value}" für Attribut "${attribute.name}" nicht gefunden. Bitte Attribute überprüfen.`;
          attributeFound = false;
        }
      } else {
        this.missingAttribute = `Attributname: "${attribute.name}" nicht gefunden. Bitte Attribute überprüfen.`;
        attributeFound = false;
      }
    });
    return attributeFound;
  }

  private checkForUsedAttributes(object: any): void {
    Object.values(object).forEach(value => {
      if (!value['rules']) {
        if (!this.usedAttributes.find(x => x === value['field'])) {
          this.usedAttributes.push({ name: value['field'], value: value['value'] });
        }
      } else if (value['rules']) {
        this.checkForUsedAttributes(value['rules']);
      }
    });
  }

  // from data to plugin
  private transformJson(queryInput): any {
    let json = JSON.stringify(queryInput);
    const rules = /"rules"/gi;
    const field = /"field"/gi;

    json = json.replace(/"="/gi, '"EXISTENT"');
    json = json.replace(/"!="/gi, '"NOTEXISTENT"');
    json = json.replace(/"or"/gi, '"OR"');
    json = json.replace(/"and"/gi, '"AND"');
    json = json.replace(/"condition"/gi, '"operator"');
    json = json.replace(rules, '"elements"');
    json = json.replace(field, '"attribute"');

    return JSON.parse(json);
  }

  // from plugin to data
  private translateJson(queryInput): any {
    let json = JSON.stringify(queryInput);

    const operatorOr = /"operator":"OR"/gi;
    const operatorAnd = /"operator":"AND"/gi;
    const elements = /"elements"/gi;
    const attribute = /"attribute"/gi;

    json = json.replace(/"EXISTENT"/gi, '"="');
    json = json.replace(/"NOTEXISTENT"/gi, '"!="');
    json = json.replace(operatorOr, '"condition":"or"');
    json = json.replace(operatorAnd, '"condition":"and"');
    json = json.replace(elements, '"rules"');
    json = json.replace(attribute, '"field"');

    return JSON.parse(json);
  }
}
