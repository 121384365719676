import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatColorsEnum as Colors, VrIcons } from '@enums';
import { icons as iconDefinitions } from '@utils/icons';

export const libIcons = VrIcons;

@Component({
  selector: 'vr-icon',
  templateUrl: './vr-icon.component.html',
  styleUrls: ['./vr-icon.component.scss'],
  standalone: false,
})
export class VrIconComponent implements OnInit {
  @Input() icon: Record<VrIcons, string> | string = VrIcons.none;
  @Input() iconColor: string | null = null;
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() materialColor: Record<Colors, string> | null = null;
  @Input() iconOpacity: number;

  public iconDefinitions = iconDefinitions;
  public svgIcon = null;
  public svgWidth: string = '';
  public svgHeight: string = '';

  constructor(@Inject(DomSanitizer) private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.setIcon();
  }

  // probably only useful for storybook purposes (updates the display correctly)
  // but would also enables us to change an icon by listening to an event
  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      switch (propName) {
        case 'icon':
        case 'iconOpacity':
          this.setIcon();
          break;
      }
    }
  }

  private setIcon() {
    let svgCode = iconDefinitions.icon.find(icon => icon.css[0] === this.icon).code;
    let widthAttr = svgCode.match(/width=".\d./gm) !== null ? svgCode.match(/width=".\d./gm)[0] : '24';
    let heightAttr = svgCode.match(/height=".\d./gm) !== null ? svgCode.match(/height=".\d./gm)[0] : '24';

    if (widthAttr !== null) {
      this.svgWidth = widthAttr.replace(/\D/g, '');
    }

    if (heightAttr !== null) {
      this.svgHeight = heightAttr.replace(/\D/g, '');
    }

    if (this.iconOpacity) {
      svgCode = svgCode.replace('<svg', `<svg fill-opacity="${this.iconOpacity}"`);
    }

    this.svgIcon = this.icon && this.sanitizer.bypassSecurityTrustHtml(svgCode);
  }

  public setWidth(): string {
    if (this.width === '' && this.svgWidth) {
      return this.svgWidth + 'px';
    }
    if (this.width !== '') {
      return this.width;
    }

    return '0px';
  }

  public setHeight(): string {
    if (this.height !== '') {
      return this.height;
    }
    // useful in most cases because icon boundaries are usually square
    if (this.width !== '' && this.height === '') {
      return this.width;
    }
    // last resort if width and height are not available
    if (this.svgHeight && this.height === '' && this.width === '') {
      return this.svgHeight + 'px';
    }

    return '0px';
  }
}
