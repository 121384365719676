<div class="d-flex flex-column flex-scroll h-100" style="max-width: 100vw; max-height: 100vh">
  <ng-container *ngIf="!loading; else loadingActive">
    <app-tab-bar style="mb-3"></app-tab-bar>

    <div
      class="d-flex flex-scroll h-100"
      style="overflow: hidden"
      *ngIf="(instancesAvailable && currentContext === 'configurator') || currentContext === 'hub'; else noInstances"
    >
      <app-record-table
        [class.table-width-default]="!useWideEditor"
        [class.table-width-rule]="useWideEditor"
        [ignoreLocks]="ignoreLocks"
      ></app-record-table>
      <div
        #formContainer
        [class.form-width-default]="!useWideEditor"
        [class.form-width-rule]="useWideEditor"
        class="divider"
      >
        <app-form
          *ngIf="currentEntity !== 'RuleContentResource'"
          [containerRef]="formContainer"
          [useWideEditor]="useWideEditor"
        ></app-form>
        <app-rule-main *ngIf="ruleBuilderActive" [containerRef]="formContainer"></app-rule-main>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loadingActive>
  <div class="d-flex h-100 align-items-center justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #noInstances
  ><div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
    <div class="vr-headline-300 mb-3">Keine Instanzen im Account verfügbar</div>
    <div class="vr-regular-text"><div>Bitte Instanz im Hub anlegen</div></div>
  </div>
</ng-template>
