import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/config.service';
import { Edge, NgxGraphZoomOptions, Node } from '@swimlane/ngx-graph';
import { EntityWorldEnum } from '@utils/enums';
import * as shape from 'd3-shape';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
})
export class GraphComponent implements OnInit, OnDestroy {
  nodeOptions = [];

  nodes: Node[] = [];
  edges: Edge[] = [];

  hierarchicalGraph = { nodes: [], links: [] };

  view: [number, number];
  width: any;
  height: any;
  fitContainer = true;
  enableZoom = true;
  autoCenter = true;
  autoZoom = true;
  zoomSpeed = 0.3;
  zoomLevel = 0.4;
  update$ = new Subject<boolean>();
  zoomToFit$ = new Subject<NgxGraphZoomOptions>();
  center$ = new Subject<boolean>();
  curve = shape.curveBundle.beta(1);
  subtopics = [];

  entityWorld: EntityWorldEnum;
  entityWorldEnum = EntityWorldEnum;
  currentSubtopicId: number;
  private graphSub: Subscription;

  constructor(
    private apiService: ApiService,
    public dialogRef: MatDialogRef<GraphComponent>,
    private configService: ConfigService,
    @Inject(MAT_DIALOG_DATA) data: EntityWorldEnum
  ) {
    this.entityWorld = data;
  }
  ngOnDestroy(): void {
    this.graphSub?.unsubscribe();
  }

  ngOnInit() {
    if (this.entityWorld === EntityWorldEnum.ProductWorld) {
      this.apiService.getGraphCompositionData().subscribe(x => {
        this.nodes = x.nodes;
        this.edges = x.edges;
      });
    } else {
      this.apiService.get(this.configService.getEndpoint('SubtopicContentResource')).subscribe(subtopics => {
        this.subtopics = subtopics;
        this.currentSubtopicId = this.subtopics[0].id;
        this.getSubtopicGraphData();
      });
    }

    if (!this.fitContainer) {
      this.applyDimensions();
    }
  }

  applyDimensions() {}

  toggleEnableZoom(enableZoom: boolean) {
    this.enableZoom = enableZoom;
  }

  toggleFitContainer(fitContainer: boolean, autoZoom: boolean, autoCenter: boolean): void {
    this.fitContainer = fitContainer;
    this.autoZoom = autoZoom;
    this.autoCenter = autoCenter;

    if (this.fitContainer) {
      this.view = undefined;
    } else {
      this.applyDimensions();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getId(name: string) {
    return name.split('-')[1];
  }

  private getSubtopicGraphData() {
    this.apiService.getGraphSubtopicData(this.currentSubtopicId).subscribe(x => {
      this.nodes = [...x.nodes];
      this.edges = [...x.edges];
    });
  }

  changeSubtopic(event) {
    this.currentSubtopicId = event.value;
    this.getSubtopicGraphData();
    this.zoomToFit$.next({ autoCenter: true });
    this.center$.next(true);
  }

  getContentSource(contentSource: string): string {
    return contentSource === 'USER' || contentSource === 'MASTER' ? contentSource[0] : 'I';
  }
}
