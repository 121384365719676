import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContextEnum, color } from '@enums';
import { ModelInterface } from '@formItem/form/utils/formly-functions';
import { checkNumeric } from '@formItem/form/utils/inner-form-functions';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/config.service';
import { EntityService } from '@services/entity.service';
import { InstanceService } from '@services/instance.service';
import { LockingService } from '@services/locking.service';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject, lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'inner-form',
  templateUrl: './inner-form.component.html',
  styleUrls: ['./inner-form.component.scss'],
  standalone: false,
})
export class InnerFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() model: any = [];
  @Input() initialModel: any = [];
  @Input() referenceField = 'price';
  @Input() referenceContentResource = 'ProductPriceContentResource';
  @Input() limitEntries = 50;
  @Input() changesInRefs = false;
  @Input() parentIsActive: boolean;

  @Output() innerFormChanged = new EventEmitter();
  @Output() formsAreValid = new EventEmitter<boolean>();
  @Output() attributesChanged = new EventEmitter();

  public newlyAdded = false;
  public title = '';
  public description = '';
  public loading = 0;
  public form: FormGroup[] = [];
  public fields: any[] = [];
  public defaultField: FormlyFieldConfig[] = [];
  public models: any[] = [];
  public formsValid: boolean;
  public attributeMap = new Map();
  public answerMap = new Map();

  private alive = new Subject<void>();
  private newInput = new ReplaySubject();
  private referenceRows = [];
  private m2mFields: string[] = [];
  private attributeChangedMap = new Map();
  private compositionData = [];

  readonly color = color;

  constructor(
    private toastService: ToastrService,
    private entityService: EntityService,
    private apiService: ApiService,
    private configService: ConfigService,
    private lockingService: LockingService,
    private instanceService: InstanceService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.m2mFields = this.configService.m2mFields;
    this.entityService.submit.pipe(takeUntil(this.alive)).subscribe(submit => {
      if (this.form) {
        this.form.forEach(x => {
          x.markAllAsTouched();
        });
      }
    });

    this.newInput
      .pipe(debounceTime(500), distinctUntilChanged())
      .pipe(takeUntil(this.alive))
      .subscribe(ev => {
        if (ev) {
          this.formsValid = this.form.every(x => x.valid);
          this.formsAreValid.emit(this.formsValid);
          this.emitData();
        }
      });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { model, referenceContentResource: resource } = changes;
    if (model && model.currentValue !== model.previousValue) {
      this.models = [];
      this.form = [];
      this.newlyAdded = false;
      this.attributeMap.clear();
      this.getReferenceFields();
    }
    if (resource && resource.currentValue !== resource.previousValue) {
      this.title = this.configService.getEntityFormTitle(this.referenceContentResource);
      this.description = this.configService.getEntityDescription(this.referenceContentResource);
      this.getReferenceFields();
    }
  }

  private getIdType(model): string {
    if (model.taskId) {
      return 'taskId';
    }
    if (model.productId) {
      return 'productId';
    }
    if (model.transitionId) {
      return 'transitionId';
    }
    return 'id';
  }

  public handleModelChange(event: any, index: number) {
    const fields = this.models[index].fields;
    const type = fields.find(x => x.key === 'type');
    const mandatory = fields.find(x => x.key === 'mandatory');
    const validation = fields.find(x => x.key === 'validation');
    const offsetUnit = fields.find(x => x.key === 'offsetUnit');
    const offset = fields.find(x => x.key === 'offset');
    const validationMessage = fields.find(x => x.key === 'validationMessage');
    const readonly = fields.find(x => x.key === 'readonly');

    if (mandatory && type) {
      const allowMandatory = type.formControl.value !== 'HIDDEN';
      const allowHidden = !mandatory.formControl.value;
      mandatory.props.disabled = !allowMandatory;
      type.props.options = type.props.allOptions.filter(x => x.value !== 'HIDDEN' || allowHidden);
    }

    if (validation && offsetUnit && offset && validationMessage && readonly) {
      const showValidation = type.formControl.value === 'DATE';
      const showUnit = validation.formControl.value === 'PAST_OFFSET_MAX';
      const showOffset = offsetUnit.formControl.value;
      const showMessage = offset.formControl.value || validation.formControl.value === 'FUTURE';
      const showReadonly = ['DATE', 'TEXT', 'LONG_TEXT', 'NUMBER', 'PRICE'].includes(type.formControl.value);

      validation.hide = !showValidation;
      offsetUnit.hide = !showUnit;
      offset.hide = !showOffset;
      readonly.hide = !showReadonly;
      validationMessage.hide = !showMessage;
      offsetUnit.props.required = showUnit;
      offset.props.required = showOffset;
    }

    if (
      this.referenceContentResource === 'AccountCalculatorCompositionProductContentResource' &&
      event.compositionId &&
      !this.compositionData
        .find(x => x.id === event.compositionId)
        ?.productRefs.find(z => z.productId === event.productId)
    ) {
      this.models[index].model.productId = undefined;
      this.form[index].controls.productId.setValue(undefined);
    }
  }

  public triggerDebounce(event: Event): void {
    this.newInput.next(event);
  }

  public copyRecordPrice(index: number): void {
    if (this.form) {
      const newModel = this.form[index].value;
      const fields = this.fields[index];

      this.form.push(new FormGroup({}));
      this.models.push({ fields: cloneDeep(fields), model: newModel });
    }
    this.newlyAdded = true;
  }

  private userDeleted = false;

  public deleteButtonClickedPrice(index: number): void {
    this.userDeleted = true;
    this.newlyAdded = false;
    this.models = this.models.filter((element, i) => i !== index);
    this.form = this.form.filter((element, i) => i !== index);
    this.toastService.warning('Element gelöscht. Eintrag speichern, um Änderung zu übernehmen');

    if (this.referenceContentResource === 'AnswerOptionContentResource') {
      this.attributeMap.forEach((value, key) => {
        if (key === index + 1 && this.attributeMap.has(index + 1)) {
          this.attributeMap.set(key - 1, value);
        } else if (key === this.attributeMap.size) {
          this.attributeMap.delete(key);
        }
      });
    }

    this.emitData();
  }

  public resetFormPrice(index: number): void {
    this.models[index].model = this.initialModel[index];
  }

  public addPrice(): void {
    this.newlyAdded = true;
    let fields = this.defaultField;

    this.form.push(new FormGroup({}));
    this.models.push({ fields: cloneDeep(fields), model: {} });

    this.changeDetector.detectChanges();
  }

  public emitData(): void {
    this.loading = Math.max(0, this.loading - 1);
    if (this.referenceContentResource === 'AnswerOptionContentResource') {
      this.innerFormChanged.emit({
        answerOptions: this.form,
        attributes: this.attributeMap,
        answerIds: this.answerMap,
      });
      this.attributesChanged.emit(this.attributeChangedMap);
    } else {
      this.innerFormChanged.emit(this.form);
    }
  }

  public getExpansionDescription(model: ModelInterface): string {
    if (this.referenceContentResource === 'ProductPriceContentResource') {
      const price = model.price || '';
      const inter = model.paymentInterval ? `(${model.paymentInterval})` : 'Neues Element';
      return `${price} ${inter}`.trim();
    } else if (this.referenceContentResource === 'DataFieldGroupProfileContentResource') {
      const category = model.category || '';
      const template = model.template ? `${model.template}` : 'Profil';
      return `${this.configService.getEnumLabel(
        'DataFieldGroupProfileContentResource',
        category
      )} (${this.configService.getEnumLabel('DataFieldGroupProfileContentResource', template)})`.trim();
    } else if (this.referenceContentResource === 'CompositionProductContentResource') {
      const title = this.getTitleById('parentId', model.productId) || '';
      const type = model.type ? `(${model.type})` : 'Neues Element';
      return `${title} ${this.configService.getEnumLabel('CompositionProductContentResource', type)}`.trim();
    } else if (this.referenceContentResource === 'CompositionTaskContentResource') {
      return `${this.getTitleById('taskId', model.taskId) || 'Neues Element'}`;
    } else if (this.referenceContentResource === 'CompositionTransitionContentResource') {
      return `${this.getTitleById('transitionId', model.transitionId) || 'Neues Element'}`;
    } else if (this.referenceContentResource === 'AccountCalculatorCompositionProductContentResource') {
      return `${this.getTitleById('productId', model.productId) || 'Neues Element'}`;
    }
    return 'Neues Element';
  }

  public getModelId(i: number): string {
    if (this.model && this.models[i]?.model.id) {
      return this.models[i]?.id ? `[${this.models[i]?.model.id?.substring(0, 8)}]` : '';
    } else if (this.models[i] && this.models[i]?.model.taskId?.substring(0, 8)) {
      return `[${this.models[i]?.model.taskId?.substring(0, 8)}]`;
    } else if (this.models[i] && this.models[i]?.model.transitionId?.substring(0, 8)) {
      return `[${this.models[i]?.model.transitionId?.substring(0, 8)}]`;
    } else if (
      (this.models[i] && this.models[i]?.model.productId) ||
      this.models[i]?.model.compositionId?.substring(0, 8)
    ) {
      return `${
        this.models[i]?.model.compositionId ? '[' + this.models[i]?.model.compositionId?.substring(0, 8) + ']' : ''
      }${this.models[i]?.model.productId ? '[' + this.models[i]?.model.productId?.substring(0, 8) + ']' : ''}`;
    } else {
      return '';
    }
  }

  public setAttribute(index: number, event: any) {
    this.attributeMap.set(index, event);
    this.emitData();
  }

  public setAnswerId(index: number, event, change = false) {
    if ((!event.length && this.userDeleted) || event[0].value.contentId !== null) {
      this.answerMap.set(index, event[0].value);
      this.emitData();
      this.userDeleted = false;
    }
  }

  public hasCustomizedField(innerModel: any): boolean {
    const model = innerModel['model'];
    return model && Object.keys(model).length > 0 && model.metadata && model.metadata['syncExcludedFields']?.length > 0;
  }

  public getModelActive(): boolean {
    if (this.model.active === undefined) return true;
    return this.model.active;
  }

  public deactivateDuplicateButton(): boolean {
    if (this.referenceContentResource === 'DataFieldGroupProfileContentResource') {
      return false;
    } else if (this.referenceContentResource === 'CompositionProductContentResource') {
      return false;
    } else if (this.referenceContentResource === 'CompositionTaskContentResource') {
      return false;
    } else if (this.referenceContentResource === 'CompositionTransitionContentResource') {
      return false;
    }
    return true;
  }

  public hasClassInactive(outerModel: any) {
    const { model } = outerModel;
    return (!this.getExpansionDescription(model) && !model?.displayName) || model.active === undefined
      ? false
      : !model?.active;
  }

  // ------ PRIVATE FUNCTIONS ------ //

  private handleModelData(): void {
    if (this.model === undefined) {
      this.emitData();
      return;
    }

    const assignData = (model, index) => {
      if (
        !this.models.some(x => x.model[this.getIdType(model)] === model[this.getIdType(model)]) ||
        (this.referenceContentResource === 'VcInputCompositionProductContentResource' &&
          !this.models.some(x => x.model.productId === model.id && x.model.compositionId === model.compositionId))
      ) {
        this.form = [...this.form, new FormGroup({})];
        this.models = [...this.models, { fields: this.fields[index], model }];
      }
    };

    if (this.model && Array.isArray(this.model) && this.model.length > 0) {
      this.model.forEach((x, index) => {
        assignData(x, index);
      });
    } else if (this.model && Object.keys(this.model).length > 0) {
      assignData(this.model, 0);
    }
    this.models.sort((a, b) => a.model.ordinal - b.model.ordinal || a.model.id - b.model.id);
    this.changeDetector.detectChanges();

    this.emitData();
  }

  private async getReferenceFields(): Promise<void> {
    this.loading++;
    this.referenceRows = [];

    let schemas = this.configService.currentOpenApiConfig['components']['schemas'];

    let props = schemas[this.referenceContentResource]?.properties;

    for (const key in props) {
      if (
        props.hasOwnProperty(key) &&
        key !== 'metadata' &&
        key !== 'vcInput' &&
        this.referenceContentResource !== 'VcInputCompositionProduct'
      ) {
        let ref = '';
        let refString = '';
        if (key === 'attributeRefs') {
          refString = 'AttributeContentResource';
        } else if (key === 'importFrom' && this.referenceContentResource === 'DataFieldContentResource') {
          refString = 'DataFieldContentResource';
        } else if (key === 'id' && this.referenceContentResource === 'CompositionProductContentResource') {
          refString = 'ProductContentResource';
        } else if (key === 'id' && this.referenceContentResource === 'CompositionTaskContentResource') {
          refString = 'TaskContentResource';
        } else if (key === 'id' && this.referenceContentResource === 'CompositionTransitionContentResource') {
          refString = 'TransitionContentResource';
        } else if (key === 'answerOptionContentId') {
          refString = 'AnswerOptionContentResource';
        } else if (key === 'dataFieldContentId') {
          refString = 'DataFieldContentResource';
        } else {
          refString = props[key].$ref;
        }

        if (refString) {
          const referenceArray = [];
          const refArray = refString.split('/');
          ref = refArray[refArray.length - 1];

          let rows = [];

          if (key === 'importFrom' && this.referenceContentResource === 'DataFieldContentResource') {
            rows = await lastValueFrom(this.apiService.getHubData(this.configService.getEndpoint(refString)));
          } else {
            rows = await lastValueFrom(this.apiService.get(this.configService.getEndpoint(ref)));
            if (key === 'answerOptionContentId' || key === 'dataFieldContentId') {
              rows = rows.map(x => (x = { id: x.contentId, name: x.adminName }));
            }
            if (
              this.referenceContentResource === 'AccountCalculatorCompositionProductContentResource' &&
              key === 'compositionId'
            ) {
              rows = rows.filter(x => x.productRefs.length > 0 && x.productRefs.some(y => y.type === 'VARIANT'));
              this.compositionData = rows;
            }
          }
          for (const row of rows) {
            let label;
            if (!row.adminName) {
              label = `[${row.id.slice(-6)}] ${row.name}`;

              if (row.value) {
                label += row.value;
              }
            } else {
              label = `[${row.id.slice(-6)}] ${row.adminName}`;
            }
            if (key === 'importFrom') {
              referenceArray.push({ value: row.contentId, label });
            } else {
              referenceArray.push({ value: row.id, label });
            }
          }
          if (key === 'importFrom' && this.referenceContentResource === 'DataFieldContentResource') {
            referenceArray.unshift({ value: '', label: '-' });
          }
          this.referenceRows.push({ key, data: referenceArray });
        }
      }
    }
    this.mapJsonToDynamicForm();
  }

  private async mapJsonToDynamicForm(): Promise<void> {
    this.fields = [];
    let schemas;

    if (this.referenceContentResource === 'GlobalAnswerConfigResource') {
      schemas = this.configService.hubOpenApiConfig['components']['schemas'];
    } else {
      schemas = this.configService.currentOpenApiConfig['components']['schemas'];
    }

    let props = schemas[this.referenceContentResource]?.properties;

    if (this.model && !Array.isArray(this.model)) {
      this.model = [this.model];
    }

    this.createField(-1, props, true);
    if (this.model) {
      for (let i = 0; i < this.model.length; i++) {
        this.createField(i, props);
      }
    }
    this.handleModelData();
  }

  private createField(i: number, props: any, defaultField: boolean = false): void {
    const fields: FormlyFieldConfig[] = [];

    Object.keys(props).forEach(key => {
      if (props.hasOwnProperty(key) && key !== undefined) {
        if (key !== 'metadata' && key !== 'contentId') {
          let type = props[key].type;
          const minimum = props[key].minimum;
          const maximum = props[key].maximum;
          const maxLength = props[key].maxLength;
          const minLength = props[key].minLength;
          const description = props[key].description;
          let title = props[key].title;
          let componentType = null;
          let inputType = null;
          let isEnum = false;
          let enumOptions = [];
          let multiple = false;
          let defaultValue;
          let required = this.isFieldRequired(key) || key === 'adminName';

          // checking if the field is an enum
          if (props[key].enum) {
            isEnum = true;

            for (const value of props[key].enum) {
              enumOptions.push({ value, label: this.configService.getEnumLabel(this.referenceContentResource, value) });
            }
          }

          // checking if the field is a ref
          const refString = props[key].$ref;
          if (
            refString ||
            props[key].items ||
            props[key].title === 'productId' ||
            key === 'importFrom' ||
            props[key].title === 'taskId' ||
            props[key].title === 'themaId' ||
            props[key].title === 'transitionId' ||
            props[key].title === 'Antwortoptionen content-ID' ||
            props[key].title === 'Datenfeld content-ID'
          ) {
            type = 'array';
            try {
              enumOptions = this.referenceRows.find(o => o.key === key)?.data;
            } catch {
              return;
            }
          }

          switch (type) {
            case 'integer': {
              componentType = 'input';
              inputType = 'number';
              break;
            }
            case 'string': {
              if (isEnum) {
                componentType = 'ng-select';
              } else {
                componentType = 'input';
                inputType = key === 'price' && 'text';
              }
              break;
            }
            case 'number': {
              componentType = 'input';
              inputType = key !== 'price' && 'number';
              break;
            }
            case 'boolean': {
              componentType = 'checkbox';
              defaultValue = key === 'active';
              required = false;
              break;
            }
            case 'array': {
              componentType = 'ng-select';

              if (this.m2mFields.includes(key)) {
                const selectedValues = [];

                for (const element of enumOptions) {
                  if (element[key]) {
                    // eslint-disable-next-line guard-for-in
                    for (const selectedId in element[key]) {
                      if (+selectedId === this.model.id) {
                        selectedValues.push(element.value);
                      }
                    }
                  }
                  multiple = true;
                  defaultValue = selectedValues;
                }
              } else {
                if (this.model && i !== -1 && this.model[i][key]) defaultValue = this.model[i][key];
              }

              break;
            }
            default: {
              return;
            }
          }

          if (key === 'jsonConditions') {
            componentType = 'input';
          }
          // if title is '', then use the key as label
          if (!title || title === '') {
            title = key;
          }

          const validation = [];
          let pat;
          if (key === 'price') {
            pat = /^(?:(\d{1,2}\.)?(?:\d{3}\.)?(?:\d{3})|\d{1,8})(?:,\d{1,4})?$/;
          }
          if (key.includes('json') && key !== 'jsonValidation') {
            pat =
              /^(?:(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*')\s*:\s*(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*'|true|false|null|-?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?)\s*,?\s*)*(?:\{|\[)(?:(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*')\s*:\s*(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*'|true|false|null|-?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?)\s*,?\s*)*(?:(?:\{|\[)(?:(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*')\s*:\s*(?:"(?:[^"\\]|\\.)*"|'(?:[^'\\]|\\.)*'|true|false|null|-?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?)\s*,?\s*)*(?:\}|\]),?\s*)*(?:\}|\])\s*$/;
          }
          if (key === 'adminName' || key === 'displayName') {
            pat = /.*\S.*/;
          }

          const templateProps = {
            label: title,
            options: [],
            description: description,
            required: required,
            multiple: multiple,
            selectAllOption: 'Select / Deselect ',
            type: inputType,
            min: minimum,
            max: maximum,
            readonly: key === 'id' && this.referenceContentResource !== 'CompositionProductContentResource',
            maxLength: maxLength,
            minLength: minLength,
            keydown: inputType === 'number' && checkNumeric,
            rows: 4,
          };

          fields.push({
            key: key,
            type: componentType,
            defaultValue: defaultValue,
            className: this.hasCustomClass(key, defaultField ? undefined : this.model[i]),
            props: templateProps,
            hideExpression: (model: ModelInterface) => {
              return (
                this.hideValidation(key, model) ||
                this.hideReadonly(key, model) ||
                ((model.type === 'MAIN' || model.type === 'VARIANT') && key === 'parentId') ||
                (key === 'questionId' && this.referenceContentResource === 'AnswerOptionContentResource') ||
                key === '_syncExcludedFields' ||
                (this.referenceContentResource === 'AccountCalculatorCompositionProductContentResource' &&
                  key === 'productId' &&
                  !model.compositionId) ||
                (this.referenceContentResource === 'DataFieldContentResource' &&
                  (key === 'dataFieldGroupId' || key === 'contentId'))
              );
            },
            expressionProperties: {
              'templateOptions.disabled': (model: ModelInterface) => {
                return this.isDisabled(key, model);
              },
              'templateOptions.options': (model: ModelInterface) => {
                // get filtered options
                return this.getOptions(model, key, enumOptions, props, true);
              },
              'templateOptions.allOptions': (model: ModelInterface) => {
                // get all available options
                return this.getOptions(model, key, enumOptions, props);
              },
            },
            validators: {
              validation,
            },
          });
        }
      }
    });

    if (defaultField) {
      this.defaultField = [...fields];
    } else {
      this.fields = [...this.fields, fields];
    }
  }

  /** for readonly field check if type is supported */
  private hideReadonly(key: string, model: any): boolean {
    const isSupported = ['DATE', 'TEXT', 'LONG_TEXT', 'NUMBER', 'PRICE'].includes(model.type);
    return key === 'readonly' && !isSupported;
  }

  /** for validation fields check if field has to be displayed */
  private hideValidation(key: string, model: any): boolean {
    return (
      (key === 'validation' && model.type !== 'DATE') ||
      (key === 'offsetUnit' && model.validation !== 'PAST_OFFSET_MAX') ||
      (key === 'offset' && !model.offsetUnit) ||
      (key === 'validationMessage' && !model.offset && model.validation !== 'FUTURE')
    );
  }

  private getOptions(model: ModelInterface, key: string, options: any[], props: any, filter?: boolean): any[] {
    if (
      model.compositionId &&
      this.referenceContentResource === 'AccountCalculatorCompositionProductContentResource' &&
      key === 'productId'
    ) {
      options = options.filter(x =>
        this.compositionData
          .find(k => k.id === model.compositionId)
          ?.productRefs.find(z => z.productId === x.value && z.type === 'VARIANT')
      );
    }
    if (options?.length && key !== 'offsetUnit') {
      // add empty value to options if not exists
      if (!options.find(x => x.label === '-') && !options.find(x => x.value === -1)) {
        const label = key === 'pairedQuestionId' ? 'Von Gruppe entfernen' : '-';
        options.unshift({ value: null, label });
      }
    }
    // remove 'HIDDEN' option from type if mandatory is true
    if (filter && model['mandatory'] && key === 'type') {
      options = options.filter(x => x.value !== 'HIDDEN');
    }
    return options;
  }

  private hasCustomClass(key: string, model: ModelInterface): string {
    if (key === 'attributeRefs' || key === 'id') {
      return 'id';
    } else if (model && Object.keys(model).length !== 0 && model['_syncExcludedFields']?.length > 0) {
      return model['_syncExcludedFields'].includes(key) ? 'customized' : '';
    } else return '';
  }

  private isDisabled(key, model): boolean {
    return (
      (key === 'mandatory' && model.type === 'HIDDEN') ||
      this.instanceService.isProdInstance ||
      (model.id && model?.metadata?.lockedFields && this.checkLock(model?.metadata?.lockedFields, key))
    );
  }

  private checkLock(lock, key): boolean {
    if (this.instanceService.isProdInstance && this.configService.currentContext === ContextEnum.configApp) {
      return true;
    } else if (this.ignoreLocks && this.configService.currentContext === ContextEnum.configApp) {
      return false;
    }
    if (this.configService.currentContext === ContextEnum.configApp) {
      if (lock && lock.length > 0) {
        if (lock.includes(key)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    return false;
  }

  private isFieldRequired(key: string): boolean {
    const schema = this.configService.currentOpenApiConfig.components.schemas;
    if (this.referenceContentResource === 'GlobalAnswerConfigResource') return true;
    for (const field of schema[this.referenceContentResource].required) {
      if (field === key && field !== 'quantityVisibility') {
        return true;
      }
    }
    return false;
  }

  private getTitleById(fieldKey: string, id: number): string {
    if (!id) {
      return '';
    }

    const trimId = (trimString: string, id: number): string => {
      return trimString?.includes(id.toString()) ? trimString.split(`[${id}] `)[1] : trimString;
    };

    const field = this.referenceRows.find(element => element.key === fieldKey)?.data;
    return field ? trimId(field.find(x => x.value === id)?.label, id) : '';
  }

  get emptyMessage(): string {
    const e =
      this.referenceContentResource.startsWith('Composition') ||
      this.referenceContentResource === 'AnswerOptionContentResource' ||
      this.referenceContentResource === 'FormVariableContentResource'
        ? 'e'
        : '';
    return `Noch kein${e} ${this.title} vorhanden`;
  }

  get ignoreLocks() {
    return this.lockingService.ignoreLocking;
  }
}
