<div class="container">
  <div class="row">
    <div class="col-12">
      <kf-headline [headline]="'Administration für Beratungsmodule'" [showBackButton]="false"></kf-headline>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <kf-tile [interactive]="false">
        <p>
          Individualisieren Sie Ihre Beratungsmodule / ZukunftsDialoge und passen Sie sie so an, wie sie zu Ihrer Bank
          passen.
        </p>

        <div class="w-100 d-sm-flex justify-content-end">
          @if (showReport) {
            <button mat-stroked-button color="primary" (click)="onReportButtonClicked()" class="mb-3 mb-lg-0">
              @if (isPolling) {
                <mat-progress-spinner
                  color="primary"
                  mode="indeterminate"
                  class="m-auto"
                  [diameter]="24"
                ></mat-progress-spinner>
              } @else {
                <kf-icon name="ic_herunterladen_24"></kf-icon>
                <span>Report</span>
              }
            </button>
          }
          @if (showConfigurator) {
            <button mat-flat-button color="primary" (click)="onBannerButtonClicked($event)" class="ml-sm-3">
              <span>Zur Administration</span>
            </button>
          }
        </div>
      </kf-tile>
    </div>
  </div>

  <item-benefits></item-benefits>
</div>
