import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogComponent } from '@components/dialog/dialog.component';
import { GraphComponent } from '@components/graph/graph.component';
import { ManageConsultationComponent } from '@components/manage-consultations/manage-consultation.component';
import { color, ContextEnum, EntityWorldEnum, VrIcons } from '@enums';
import { environment } from '@environment/environment';
import { AuthService } from '@modules/auth';
import { ApiService } from '@services/api.service';
import { CacheService } from '@services/cache.service';
import { ConfigService } from '@services/config.service';
import { ContextService } from '@services/context.service';
import { EntityService } from '@services/entity.service';
import { InstanceService } from '@services/instance.service';
import { LockingService } from '@services/locking.service';
import { QueryService } from '@services/query.service';
import { SaveFileService } from '@services/save-file.service';
import { TenantService } from '@services/tenant.service';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subject, timer } from 'rxjs';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';

const miscTabs = [{ name: 'Rules' }, { name: 'RulesReferences' }];
@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
  standalone: false,
})
export class TabBarComponent implements OnInit, OnDestroy {
  @ViewChild('customisationUpload') customisationUpload;

  private alive = new Subject<void>();
  private stopPolling = new Subject<void>();

  private entities = [];
  private defaultValue;

  public currentContext = '';
  public platform = environment.platform;

  public menuConfig;
  public activeTopic;
  public activeEndpoint;
  public activeEntities = [];
  public instances = [];
  public currentTenantId;
  public currentInstanceId = '-1';

  public isLicenseButtonShown = true;
  public isLoading = false;

  public maxEntityNumber = 6;
  public maxNavBarWidth = 250;

  public contextEnum = ContextEnum;

  readonly color = color;
  readonly buttonIcon = VrIcons;

  constructor(
    private router: Router,
    private authService: AuthService,
    private entityService: EntityService,
    private configService: ConfigService,
    private contextService: ContextService,
    private apiService: ApiService,
    private toastService: ToastrService,
    private instanceService: InstanceService,
    private tenantService: TenantService,
    private lockingService: LockingService,
    public dialog: MatDialog,
    private saveFileService: SaveFileService,
    private cacheService: CacheService,
    private queryService: QueryService
  ) {}

  ngOnInit() {
    this.tenantService.tenantIdChanged.pipe(takeUntil(this.alive)).subscribe(tenantId => {
      this.currentTenantId = tenantId;
    });

    this.configService.context.pipe(takeUntil(this.alive)).subscribe(context => {
      if (context !== undefined) {
        this.currentContext = context;
      }
      this.menuConfig = this.configService.menuConfig;

      this.activeEndpoint = this.configService.currentContext;
      this.defaultValue = this.menuConfig.flatMap(x => x.subtopics).find(y => y.default === true).name;
    });

    this.entityService.isLoading.pipe(takeUntil(this.alive)).subscribe(isLoading => (this.isLoading = isLoading));

    this.instanceService.availableInstancesSubject.subscribe(instances => (this.instances = instances));

    this.currentInstanceId = this.instanceService.instanceId;

    this.fetchEntities();
    this.setInitalTopic();
    this.lockingService.setLockEditing();
    this.handleNavbarSize();
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleNavbarSize(event.target.innerWidth);
  }

  private handleNavbarSize(width: number = null) {
    const innerWidth = width || window.innerWidth;
    const optWidth = this.currentContext === 'hub' ? 540 : 750;
    this.maxNavBarWidth = Math.max(250, innerWidth - optWidth);
  }

  public onLogoClicked(event: Event): void {
    this.setInitalTopic();
    this.resetToDefaultSelection();
    this.router.navigate['configurator'];
  }

  public onEntityClicked(entity): void {
    this.entities.forEach(ent => {
      if (ent.active) {
        ent.active = false;
      }
    });
    this.switchEntity(entity);
  }

  public onTopicClicked(newTopic: string): void {
    this.activeTopic = newTopic;
    this.filterEntities();
  }

  public onInstanceChange(event): void {
    this.instanceService.instanceId = event.value;
  }

  public onLogoutClicked(event: Event): void {
    this.onSwitchContext(ContextEnum.bankHub);
    this.instanceService.instanceId = '-1';
    this.router.navigate(['login']);
    this.authService.logout();
  }

  public moreOptionsDialogHub(event: Event): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '200px',
      height: this.isLicenseButtonShown ? '10rem' : '8rem',
      panelClass: 'custom-dialog',
      backdropClass: 'no-backdrop-background',
      data: {
        title: 'Information',
        text: 'Option treffen',
        selection: { licenseButtonShown: this.isLicenseButtonShown, resetHubShown: true, customizations: true },
        target: event.target,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event === 'testConsultations') {
        this.navigateToTest();
      } else if (result?.event === 'showGraphs') {
        this.navigateToGraph();
      } else if (result?.event === 'resetHub') {
        this.onResetHub(null);
      } else if (result?.event === 'updateLicense') {
        this.onUpdateLicense(null);
      } else if (result?.event === 'downloadRZBKData') {
        this.onDownloadRZBK();
      }
    });
  }

  public handleCustomization() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      panelClass: 'custom-dialog',
      data: {
        title: 'Individualisierung',
        text: 'Sollen individuelle Anpassungen importiert oder exportiert werden?',
        custom: 'customization',
        customConfirm: 'Export',
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && result?.event === 'import') {
        this.customisationUpload.nativeElement.value = null;
        this.customisationUpload.nativeElement.click();
      } else if (result && result?.event === 'export') {
        const data = await lastValueFrom(this.apiService.getCustomizationData());
        this.saveFileService.saveFile(data, 'customization.xlsx', '', true);
      }
    });
  }

  public uploadCustomization(event: any): void {
    const file = event.target.files[0];
    const formData = new FormData();

    const fileNameEnding = file.name.toString().split('.');

    if (fileNameEnding[fileNameEnding.length - 1] !== 'xlsx') {
      this.toastService.error('Der Import unterstützt nur Dateien im xlsx Format!');
      return;
    }

    formData.append('file', file, file.name);
    this.entityService.isLoading.next(true);

    if (environment.platform === 'aws') {
      this.apiService
        .postImportCustomization(formData)
        .pipe(finalize(() => this.entityService.isLoading.next(false)))
        .subscribe(
          success => {
            onSuccess();
          },
          error => {
            onFail(error);
          }
        );
    } else {
      this.apiService.getUNDTicket().subscribe(ticket => {
        // formData.append('ticket', JSON.stringify(ticket));
        formData.append('ticket', new Blob([JSON.stringify(ticket)], { type: 'application/json' }));
        this.apiService
          .postUNDUploadFile(formData)
          .pipe(
            map(uploadResponse =>
              this.apiService.postUNDImportCustomization(uploadResponse.undID).subscribe(
                success => onSuccess(),
                error => onFail(error)
              )
            ),
            finalize(() => this.entityService.isLoading.next(false))
          )
          .subscribe();
      });
    }

    const onSuccess = (): void => {
      this.toastService.success('Individueller Import erfolgreich!');
      this.cacheService.renewAll();
      this.entityService.resetSelection.next();
    };
    const onFail = (error: any): void => {
      this.toastService.error(JSON.parse(error.error)?.message);
    };
  }

  public onSwitchContext(newEndpoint: ContextEnum): void {
    this.configService.currentContext = newEndpoint;
    this.activeEndpoint = newEndpoint;
    this.fetchEntities();
    this.setInitalTopic();
    this.resetToDefaultSelection();
    this.lockingService.setLockEditing();
    this.handleNavbarSize();
  }

  public onResetHub(event: Event): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      panelClass: 'custom-dialog',
      data: { title: 'Warnung', text: 'Der Mandant wird zurückgesetzt. Trotzdem fortfahren?' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event === 'submit') {
        this.apiService.resetContentForTenant().subscribe(() => {
          this.toastService.success('Mandanten erfolgreich zurückgesetzt!');
        });
      }
    });
  }

  public onUpdateLicense(event: Event): void {
    this.apiService.updateLicense().subscribe(x => {
      this.toastService.success('Lizenz erfolgreich aktualisiert', 'Lizenz aktualisiert');
    });
  }
  public async onDownloadRZBK(): Promise<void> {
    // poll status every 5 seconds
    timer(0, 10000)
      .pipe(
        switchMap(() => this.queryService.getReportStatus()),
        takeUntil(this.stopPolling)
      )
      .subscribe(async requestStatus => {
        if (requestStatus) {
          this.stopPolling.next();
          // .subscribe(x => console.log(JSON.parse(x)));
          const data = await lastValueFrom(this.queryService.getReportDownload());
          this.saveFileService.saveFile(
            data,
            `AlleBeratungen${this.currentTenantId ? '_' + this.currentTenantId : ''}.csv`,
            '',
            false
          );
        }
      });
  }

  public getPluralTitle(entity: string): string {
    return this.configService.getPluralTitle(entity);
  }

  /**
   * gets all entities of the OpenAPI json file and pushes them into the array of entities which get displayed in the tab bar
   */
  private fetchEntities(): void {
    this.entities = [];
    // eslint-disable-next-line guard-for-in
    for (const entity in this.configService.currentOpenApiConfig['components']['schemas']) {
      this.entities.push({ name: entity });
    }

    if (this.configService.isHubApi()) {
      for (const miscTab of miscTabs) {
        this.entities.push(miscTab);
      }
    }
    // set the current entity to active
    for (const entity of this.entities) {
      if (this.entityService.currentEntity.value === entity.name) {
        entity.active = true;
      }
    }
  }

  /**
   * switches currently selected entity, and navigates to the selected entity template
   * @param entity the entity to be switched to
   */
  private switchEntity(entity): void {
    this.entityService.setEntity(entity.name);
    this.entityService.setModel({});

    entity.active = true;
  }

  /**filters enitities to match their topics */
  private filterEntities(): void {
    const activeEntities = [];
    let currentTopic;

    for (let i = 0; i < this.menuConfig.length; i++) {
      if (this.menuConfig[i].name === this.activeTopic) {
        currentTopic = this.menuConfig[i].subtopics;
      }
    }

    for (let i = 0; i < currentTopic.length; i++) {
      for (const entity of this.entities) {
        if (entity.name === currentTopic[i].name) {
          activeEntities.push(entity);
        }
      }
    }

    // custom information endpoint

    this.activeEntities = activeEntities;
  }

  private resetToDefaultSelection(): void {
    const defaultValue = { name: this.defaultValue, active: true };
    this.switchEntity(defaultValue);
    this.onEntityClicked(defaultValue);

    const entity = this.entities.find(x => x.name === defaultValue.name);
    if (entity) {
      entity.active = true;
    }
  }

  private moreDialogHeight(): string {
    if (this.instanceService.isProdInstance) {
      return '8rem';
    } else {
      return this.isTestButtonShown && this.isGraphButtonShown ? '12rem' : '10rem';
    }
  }

  navigateToTest() {
    this.dialog.open(ManageConsultationComponent, {
      width: '800px',
      height: '600px',
      data: {
        instanceName: this.instanceService.availableInstances.find(x => x.id === this.instanceService.instanceId).name,
        mode: 'TEST',
      },
    });
  }

  navigateToGraph() {
    this.dialog.open(GraphComponent, { width: '90%', height: '90%', data: this.entityService.entityWorld });
  }

  public moreOptionsDialogCA(event) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '200px',
      height: this.moreDialogHeight(),
      panelClass: 'custom-dialog',
      backdropClass: 'no-backdrop-background',
      data: {
        title: 'Information',
        text: 'Option treffen',
        selection: {
          showTestConsultations: this.isTestButtonShown,
          showGraphs: this.isGraphButtonShown,
          customization: !this.instanceService.isProdInstance,
        },
        target: event.target,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event === 'testConsultations') {
        this.navigateToTest();
      } else if (result?.event === 'showGraphs') {
        this.navigateToGraph();
      } else if (result?.event === 'customizations') {
        this.handleCustomization();
      }
    });
  }

  get wideEnoughForButtons(): boolean {
    return false; // document.body.offsetWidth > 1800;
  }

  get isTestButtonShown() {
    return (
      !this.instanceService.isProdInstance &&
      this.currentInstanceId != '-1' &&
      this.instanceService.availableInstances.length > 0
    );
  }

  get isGraphButtonShown() {
    return (
      this.entityService.entityWorld !== EntityWorldEnum.Other &&
      this.currentInstanceId &&
      this.instanceService.availableInstances.length > 0
    );
  }

  private setInitalTopic(): void {
    let defaultActiveTopic = '';
    for (const object of this.menuConfig) {
      for (const subtopic of object['subtopics']) {
        if (subtopic.name === this.defaultValue) {
          defaultActiveTopic = object.name;
        }
      }
    }
    this.activeTopic = defaultActiveTopic;
    this.filterEntities();
  }

  get assetPath() {
    return this.contextService.assetPath;
  }
}
