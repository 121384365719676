<div class="d-flex flex-column flex-scroll h-100" style="max-width: 100vw; max-height: 100vh">
  @if (loading) {
    <div class="d-flex h-100 align-items-center justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
  } @else {
    <app-tab-bar class="mb-3"></app-tab-bar>

    @if ((instancesAvailable && currentContext === 'configurator') || currentContext === 'hub') {
      <div class="d-flex flex-scroll h-100" style="overflow: hidden">
        <app-record-table
          [class.table-width-default]="!useWideEditor"
          [class.table-width-rule]="useWideEditor"
          [ignoreLocks]="ignoreLocks"
        ></app-record-table>
        <div
          #formContainer
          [class.form-width-default]="!useWideEditor"
          [class.form-width-rule]="useWideEditor"
          class="divider"
        >
          @if (currentEntity !== 'RuleContentResource') {
            <app-form [containerRef]="formContainer" [useWideEditor]="useWideEditor"></app-form>
          }
          @if (ruleBuilderActive) {
            <app-rule-main [containerRef]="formContainer"></app-rule-main>
          }
        </div>
      </div>
    } @else {
      <div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
        <div class="vr-headline-300 mb-3">Keine Instanzen im Account verfügbar</div>
        <div class="vr-regular-text"><div>Bitte Instanz im Hub anlegen</div></div>
      </div>
    }
  }
</div>
