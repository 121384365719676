<div class="pl-3 mb-2 contract-conditions-area">
  @for (field of field.fieldGroup; track field; let i = $index; let last = $last) {
    <h5>{{ headerText(i) }}</h5>
    <div class="d-flex pt-2 px-2 conditions-background">
      <formly-field class="w-100" [field]="field"></formly-field>
      <div class="d-flex align-items-top mt-4 brand-primary">
        <div class="vr-icon-close-circled" type="button" (click)="remove(i)"></div>
      </div>
    </div>
    @if (!last) {
      <div class="my-2" style="border-bottom: solid 1px #000000"></div>
    }
  }

  <div style="cursor: pointer" class="d-flex flex-row align-items-start mt-3 brand-primary" (click)="add()">
    <div class="vr-icon-add-circled mr-2"></div>
    <div>..., wenn...</div>
  </div>
</div>
