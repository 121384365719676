export const informationContentResource = {
  title: 'Information',
  required: ['defaultValue', 'keyName', 'value'],
  type: 'object',
  properties: {
    keyName: {
      title: 'Schlüsselwert',
      maxLength: 255,
      minLength: 0,
      type: 'string',
    },
    value: {
      title: 'Wert',
      maxLength: 255,
      minLength: 0,
      type: 'string',
    },
    defaultValue: {
      title: 'Standardwert',
      maxLength: 255,
      minLength: 0,
      type: 'string',
    },
    mandatory: {
      title: 'Pflichtfeld',
      type: 'boolean',
      description: 'Dieses Feld als Pflichtfeld festlegen.',
    },
    systemSetting: {
      title: 'System',
      type: 'boolean',
      readOnly: true,
    },
    description: {
      title: 'Beschreibung',
      maxLength: 255,
      minLength: 0,
      type: 'string',
    },
  },
  description: 'Informationen über die Instanz',
};
