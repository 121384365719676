import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContextEnum } from '@enums';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/config.service';
import { EntityService } from '@services/entity.service';
import { InstanceService } from '@services/instance.service';
import { LockingService } from '@services/locking.service';
import { Subject, lastValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-main-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.scss'],
})
export class ConfiguratorComponent implements OnInit, OnDestroy {
  private alive = new Subject<void>();

  public currentContext: ContextEnum;
  public currentEntity: string;
  public instancesAvailable: boolean;
  public lockEditMode: boolean;

  public loading = true;

  constructor(
    private entityService: EntityService,
    private instanceService: InstanceService,
    private configService: ConfigService,
    private lockingService: LockingService,
    private api: ApiService
  ) {}

  async ngOnInit(): Promise<void> {
    // We're only here if we're logged in!
    await this.getConfiguratorData();

    this.loading = false;

    this.configService.context.pipe(takeUntil(this.alive)).subscribe(context => {
      this.currentContext = context;
    });

    this.lockingService.lockingState.pipe(takeUntil(this.alive)).subscribe(lockstate => {
      this.lockEditMode = lockstate;
    });

    this.entityService.currentEntity.pipe(takeUntil(this.alive)).subscribe(entity => {
      if (
        (this.instanceService.instanceId &&
          this.instanceService.instanceId !== '-1' &&
          this.currentContext !== ContextEnum.bankHub) ||
        this.currentContext === ContextEnum.bankHub
      ) {
        this.currentEntity = entity === 'InstanceConfigResponse' ? 'InstanceConfigRequest' : entity;
      }
    });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.unsubscribe();
  }

  private async getConfiguratorData(): Promise<void> {
    // Get consultationApp Config
    try {
      this.configService.appOpenApiConfig = await lastValueFrom(this.api.getAppOpenApiConfiguration());
    } catch (e) {
      this.configService.appOpenApiConfig = null;
      console.error(`Error getting app openApi [${e.message}]`);
    }

    // Get bankHub Config
    try {
      this.configService.hubOpenApiConfig = await lastValueFrom(this.api.getHubOpenApiConfiguration());
    } catch (e) {
      this.configService.hubOpenApiConfig = null;
      console.error(`Error getting hub openApi [${e.message}]`);
    }

    // Get all instances
    try {
      await this.instanceService.getInstances();
      this.instancesAvailable = this.instanceService.hasInstances;
    } catch (e) {
      console.error(`Error getting instances [${e.message}]`);
    }

    const currentEntity = this.entityService.currentEntity.value;

    if (currentEntity) {
      this.entityService.setEntity(currentEntity);
    } else {
      const template = this.configService.currentOpenApiConfig
        .flatMap(x => x.subtopics)
        .find(y => y.default === true)
        .name.toString();

      this.entityService.setEntity(template);
    }
  }

  get ignoreLocks(): boolean {
    return this.lockingService.ignoreLocking;
  }

  get ruleBuilderActive(): boolean {
    return this.currentEntity === 'RuleContentResource' && this.currentContext === ContextEnum.configApp;
  }

  get useWideEditor(): boolean {
    return (
      (this.currentEntity === 'RuleContentResource' || this.currentEntity === 'FormContentResource') &&
      this.currentContext === ContextEnum.configApp
    );
  }
}
