export const config = {
  toolbarGroups: [
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
    { name: 'forms', groups: ['forms'] },
    { name: 'tools', groups: ['tools'] },
    '/',
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    '/',
    { name: 'styles', groups: ['styles'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] },
  ],

  removeButtons: `ShowBlocks,About,Font,FontSize,TextColor,
BGColor,Image,Flash,Table,HorizontalRule,
PageBreak,Iframe,Anchor,Blockquote,CreateDiv,
CopyFormatting,RemoveFormat,Form,Checkbox,
Radio,TextField,Textarea,Select,Button,
ImageButton,HiddenField,Templates,ExportPdf,
Styles,Maximize,Source,Subscript,Superscript,Strikethrough`,
};
