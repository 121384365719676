import { MapperTypes } from '@enums';
import { FormlyFieldConfig } from '@ngx-formly/core';

export const generalDatafieldField = {
  config: {
    key: 'value',
    type: 'ng-select',
    props: {
      label: 'Datenfeld',
    },
    hideExpression(model, formState, field?) {
      return !(model?.type === MapperTypes.generalDataField);
    },
  } as FormlyFieldConfig,
  setData: data => {
    generalDatafieldField.config.props.options = data;
  },
};

export const productDatafieldField = {
  config: {
    key: 'value',
    type: 'ng-select',
    props: {
      label: 'Datenfeld',
    },
    hideExpression(model, formState, field?) {
      return !(model?.type === MapperTypes.productDataField);
    },
  } as FormlyFieldConfig,
  setData: data => {
    productDatafieldField.config.props.options = data;
  },
};

const selectedProductPropertyField = {
  config: [
    {
      key: 'property',
      type: 'ng-select',
      defaultValue: 'NAME',
      props: {
        label: 'Produkteigenschaft',
        options: [
          { value: 'NAME', label: 'Name' },
          { value: 'DESCRIPTION', label: 'Beschreibung' },
          { value: 'PRICE', label: 'Preis' },
          { value: 'VAT', label: 'Umsatzsteuer' },
          { value: 'PAYMENT_INTERVAL', label: 'Bezahlintervall' },
          { value: 'QUANTITY', label: 'Anzahl' },
        ],
      },
      hideExpression(model, formState, field?) {
        return !(model?.type === MapperTypes.selectedProduct);
      },
    },
    {
      key: 'paymentInterval',
      type: 'ng-select',
      defaultValue: 'MONTHLY',
      props: {
        label: 'Zahlungsintervall',
        options: [
          { value: 'FIXED', label: 'fest' },
          { value: 'ANNUALLY', label: 'jährlich' },
          { value: 'MONTHLY', label: 'monatlich' },
          { value: 'ONCE', label: 'einmalig' },
          { value: 'ONCE_TO_EXPENDITURE', label: 'einmalig nach Aufwand' },
          { value: 'CUSTOM_OFFER', label: 'Individuelles Angebot' },
        ],
      },
      hideExpression(model, formState, field?) {
        return (
          !(model?.type === MapperTypes.selectedProduct) ||
          !(model?.property === 'PRICE' || model?.property === 'VAT' || model?.property === 'PAYMENT_INTERVAL')
        );
      },
    },
  ] as FormlyFieldConfig[],
  setData: data => {
    selectedProductPropertyField.config[0].props.options = data;
  },
};

////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////

const mapperOptions = [
  { value: MapperTypes.const, label: 'Fester Wert' },
  { value: MapperTypes.productDataField, label: 'Produkt Datenfeld' },
  { value: MapperTypes.generalDataField, label: 'Allgemeines Datenfeld' },
  { value: MapperTypes.selectedProduct, label: 'Ausgewähltes Produkt' },
];

const constValueField = {
  config: {
    key: 'value',
    type: 'input',
    modelOptions: {
      debounce: {
        default: 350,
      },
    },
    props: {
      label: 'Wert',
      maxLength: 255,
    },
    hideExpression(model, formState, field?) {
      return !(model?.type === MapperTypes.const);
    },
  } as FormlyFieldConfig,
};

const conditionField = {
  config: (keyValue: string, label: string): FormlyFieldConfig => {
    return {
      key: keyValue,
      className: '',
      fieldGroup: [
        {
          key: 'type',
          type: 'ng-select',
          defaultValue: MapperTypes.generalDataField,
          props: {
            label: label,
            options: mapperOptions,
            required: true,
          },
        },
        constValueField.config,
        generalDatafieldField.config,
        productDatafieldField.config,
        ...selectedProductPropertyField.config,
      ],
      expressionProperties: {},
      validators: { validation: [] },
    };
  },
};

const operatorField = {
  config: {
    key: 'operator',
    type: 'radio',
    defaultValue: 'EQ',
    props: {
      type: 'radio',
      label: '',
      required: true,
      options: [
        { label: 'entpricht', value: 'EQ' },
        { label: 'entspricht nicht', value: 'NEQ' },
      ],
    },
  },
};

const conditionsFields: FormlyFieldConfig[] = [
  conditionField.config('keyValue', 'Key Value'),
  operatorField.config,
  conditionField.config('matchingValue', 'Matching Value'),
];

const mapperFields: FormlyFieldConfig[] = [
  {
    key: 'value',
    className: 'w-100',
    fieldGroup: [
      {
        key: 'type',
        type: 'ng-select',
        defaultValue: MapperTypes.const,
        props: {
          label: 'Variablentyp',
          options: mapperOptions,
          required: true,
        },
      },
      constValueField.config,
      generalDatafieldField.config,
      productDatafieldField.config,
      ...selectedProductPropertyField.config,
    ],
    expressionProperties: {},
    validators: { validation: [] },
  },
  {
    key: 'conditions',
    type: 'conditions-repeat',
    props: {
      label: 'Bedingungen',
    },
    fieldArray: {
      fieldGroup: conditionsFields,
    },
    expressionProperties: {},
    validators: { validation: [] },
  },
];

export const mainFields: FormlyFieldConfig[] = [
  {
    key: 'id',
    type: 'input',
    className: 'id',
    modelOptions: {
      debounce: {
        default: 350,
      },
    },
    props: {
      label: 'id',
      options: [],
      type: 'number',
      readonly: true,
    },
    expressionProperties: {},
    validators: { validation: [] },
  },
  {
    key: 'displayName',
    type: 'input',
    className: '',
    modelOptions: {
      debounce: {
        default: 350,
      },
    },
    props: {
      label: 'Name',
      options: [],
      required: true,
      readonly: false,
      maxLength: 255,
      minLength: 0,
    },
    expressionProperties: {},
    validators: { validation: ['name'] },
  },
  {
    key: 'adminName',
    type: 'input',
    className: '',
    modelOptions: {
      debounce: {
        default: 350,
      },
    },
    props: {
      label: 'Admin-Name',
      options: [],
      description: 'Eindeutiger Name welcher im Konfigurator verwendet wird.',
      required: true,
      readonly: false,
      maxLength: 255,
      minLength: 0,
    },
    expressionProperties: {},
    validators: { validation: ['name'] },
  },
  {
    key: 'variableId',
    type: 'input',
    className: '',
    modelOptions: {
      debounce: {
        default: 350,
      },
    },
    props: {
      label: 'Variablen Id',
      options: [],
      required: true,
    },
    expressionProperties: {},
    validators: { validation: [] },
  },
  {
    key: 'mappers',
    type: 'mapper-repeat',
    props: {
      label: 'Variablentyp',
    },
    fieldArray: {
      fieldGroup: mapperFields,
    },
    expressionProperties: {},
    validators: { validation: [] },
  },
];
