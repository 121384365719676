import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QueryService {
  private _headers = new HttpHeaders().set('Content-Type', 'application/json');
  private options = { headers: this._headers };

  public api = environment?.api || 'api/v1';
  public hub = environment?.hub || 'hub/v1';

  constructor(protected http: HttpClient) {
    if (environment.platform === 'vp' && (!environment.isLocal || window.location.pathname.includes('/bws'))) {
      this.hub = `serviceproxy/bgzv-backend-guided-service-hub/api/v1`;
      this.api = `serviceproxy/bgzv-backend-consultation-app/api/v1`;
    }
  }

  public getLicenseStatus(): Observable<string> {
    return this.http.get<string>(`${this.hub}/application/license/status`);
  }

  public getSetupStatus(): Observable<string> {
    return this.http.get<string>(`${this.hub}/application/setup-status`);
  }

  public getIsLicensed(): Observable<any> {
    return this.http.get<any>(`${this.hub}/application/license/is-licensed`);
  }

  public postUpdateLicense(): Observable<any> {
    return this.http.post<any>(`${this.hub}/application/license/update-license`, {}, this.options);
  }

  public putUpdateContent(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    // return this.http.put<any>(`${this.hub}/application/content/content-update`, {});
    return this.http.put(`${this.hub}/application/content/content-update`, {}, { headers, responseType: 'text' });
  }

  public putTenantInfo(): Observable<any> {
    return this.http.put(`${this.hub}/application/import-tenant-info`, {});
  }

  public getReportStatus(): Observable<any> {
    return this.http.get<any>(`${this.hub}/consultations/report/status`);
  }

  public getReportDownload(): Observable<any> {
    return this.http.get<any>(`${this.hub}/consultations/report/download`, { responseType: 'text' as any });
  }

  public getCompetences(): Observable<any> {
    return this.http.get<any>(`${this.hub}/configuration`);
  }
}
