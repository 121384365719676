import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BreadCrumbElement } from '@components/breadcrumb-navigation/breadcrumb-navigation.component';
import { DialogComponent } from '@components/dialog/dialog.component';
import { ManageConsultationComponent } from '@components/manage-consultations/manage-consultation.component';
import { ContextEnum, FileTypes, color } from '@enums';
import { environment } from '@environment/environment';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiService } from '@services/api.service';
import { CacheService } from '@services/cache.service';
import { CleanHtmlService } from '@services/clean-html.service';
import { ConfigService } from '@services/config.service';
import { ContextService } from '@services/context.service';
import { EntityService } from '@services/entity.service';
import { InstanceService } from '@services/instance.service';
import { LockingService } from '@services/locking.service';
import { MediaService } from '@services/media.service';
import { SaveFileService } from '@services/save-file.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, forkJoin, lastValueFrom } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { FormlyFunctions } from './utils/formly-functions';
import { InnerFormFunctions } from './utils/inner-form-functions';

export interface DropDownData {
  value: any;
  label: string;
}
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() useWideEditor: boolean = false;
  @Input() containerRef: HTMLDivElement;
  @ViewChild('formDirective', { static: false }) private formDirective: NgForm;
  @ViewChild('formly') formlyForm;

  private alive = new Subject<void>();

  public model: { [key: string]: any } = {};
  public initialModel;
  public title = '';

  private innerFormFunctions = new InnerFormFunctions(this.toastService);
  private formlyFunctions = new FormlyFunctions(this.entityService, this.apiService, this.configService);

  public isRegularDescription = true;

  public description = '';
  public newDescription = '';
  public currentEntity = '';

  public numberOfDisplayedFields = 0;

  public fileType;

  public instanceRequestPending = false;
  public isLoading;

  public jumpData: BreadCrumbElement;
  public contentVersions = [];

  private lastLinkedInstanceId = '';

  // Special Condition Entities and Fields
  private m2mFields = [];
  private regularDescriptions = [];
  private innerFormFields = [];

  // InnerForm Variables
  private benefits: FormGroup[];
  private profile: FormGroup[];
  private prices: FormGroup[];
  private products: FormGroup[];
  private compositionProductRefs: FormGroup[];
  private answerOptions: FormGroup[];
  private dataFields: FormGroup[];
  private tasks: FormGroup[];
  private transitions: FormGroup[];
  private contractVariables: any[];
  private globalAnswerOptions: FormGroup[];
  private toolResource: {
    input: FormGroup[];
    output: FormGroup[];
  };
  private toolResourceInput: FormGroup[];
  private toolResourceOutput: FormGroup[];

  // Fetch field data
  private dataFetched = new Subject<boolean>();
  private referenceRows = new Map<string, DropDownData[]>();

  public mediaContentMap: Map<string, string> = new Map<string, string>();

  // Formly Form
  public form = new FormGroup({});
  public fields: FormlyFieldConfig[] = [];

  // Attribute Variables
  private attributes = [];
  private attributesOutputs;
  private originalAttributeRefs = [];
  private attributesChanged = true;

  private answerIdOutputs;

  // Lock Variables
  private lockChanges: boolean;
  private noLockFields: string[];

  lockEditMode: boolean;

  readonly color = color;

  @Input() entity;
  innerFormsAreValid: boolean = true;

  constructor(
    private entityService: EntityService,
    private configService: ConfigService,
    private apiService: ApiService,
    private mediaService: MediaService,
    private toastService: ToastrService,
    private dialog: MatDialog,
    private lockingService: LockingService,
    private changeDetection: ChangeDetectorRef,
    private instanceService: InstanceService,
    private cleanHtmlService: CleanHtmlService,
    private cacheService: CacheService,
    private saveFileService: SaveFileService,
    private contextService: ContextService
  ) {}

  ngOnInit(): void {
    this.createInstanceSubs();
    this.createLockChangesSubs();
    this.createSubsAfterApi();
  }

  ngAfterViewChecked(): void {
    this.changeDetection.detectChanges();
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.unsubscribe();
  }

  private createSubsAfterApi(): void {
    this.createConfigSubs();
    this.createFetchDataSub();
    this.createLockSubs();
    this.createEntitySub();
    this.createModelSub();
  }

  // Subs
  private async createInstanceSubs(): Promise<void> {
    this.instanceService.instanceRequestPending.pipe(takeUntil(this.alive)).subscribe(status => {
      this.instanceRequestPending = status;
    });

    this.instanceService.instanceChanged.pipe(takeUntil(this.alive)).subscribe(async instanceId => {
      if (instanceId == '-1') {
        return;
      }

      if (this.configService.currentOpenApiConfig && this.configService.currentContext === ContextEnum.configApp) {
        this.model = {};
        await this.getReferenceFields();
      }
    });

    this.entityService.isLoading.pipe(takeUntil(this.alive)).subscribe(isLoading => (this.isLoading = isLoading));
  }

  private createLockChangesSubs(): void {
    this.lockingService.changesMade.pipe(takeUntil(this.alive)).subscribe(changes => {
      this.lockChanges = changes;
    });
  }

  private createModelSub(): void {
    this.entityService.currentModel.pipe(takeUntil(this.alive)).subscribe(model => {
      if (
        this.model !== model &&
        this.currentEntity !== 'RuleContentResource' &&
        ((this.instanceService.instanceId !== '-1' && this.configService.currentContext !== ContextEnum.bankHub) ||
          this.configService.currentContext === ContextEnum.bankHub)
      ) {
        this.model = model;
        this.initialModel = JSON.parse(JSON.stringify(model));
        this.lastLinkedInstanceId = this.initialModel?.linkedDataInstanceId || '';
        this.modelFactory();
        if (this.referenceRows.size > 0 || this.configService.getNoRefEntities(this.currentEntity)) {
          this.mapJsonToDynamicForm();
        }
        this.form.reset();
      }
    });
  }

  private createFetchDataSub(): void {
    this.dataFetched.pipe(takeUntil(this.alive)).subscribe(val => {
      this.mapJsonToDynamicForm();
    });
  }

  private createEntitySub(): void {
    this.entityService.entityTrigger.pipe(takeUntil(this.alive)).subscribe(async entity => {
      if (
        (this.instanceService.instanceId != '-1' && this.configService.currentContext !== ContextEnum.bankHub) ||
        this.configService.currentContext === ContextEnum.bankHub
      ) {
        this.isLoading = true;
        this.entityService.isLoading.next(true);
        this.model = {};
        this.currentEntity = entity === 'InstanceConfigResponse' ? 'InstanceConfigRequest' : entity;

        this.isRegularDescription = this.hasRegularDescription('description');
        this.numberOfDisplayedFields = this.configService.getNumberOfDisplayedFields(this.currentEntity);
        this.title = this.configService.getEntityFormTitle(this.currentEntity);
        this.description = this.configService.getEntityDescription(this.currentEntity);
        this.form.reset();

        if (this.formDirective) {
          this.formDirective.resetForm();
        }

        if (entity !== 'RuleContentResource') {
          await this.getReferenceFields();
        }
      }
    });
  }

  private createConfigSubs(): void {
    this.m2mFields = this.configService.m2mFields;
    this.noLockFields = this.configService.noLocks;
    this.regularDescriptions = this.configService.regularDescriptions;
  }

  private createLockSubs(): void {
    this.lockingService.lockingState.pipe(takeUntil(this.alive)).subscribe(lockstate => {
      this.lockEditMode = lockstate;
    });
  }

  public changeToLockMode(): void {
    if (!this.lockingService.lockingEditorIsActive) {
      this.lockingService.changeEditorState(!this.lockingService.lockingEditorIsActive);
      this.lockingService.triggerSubmit('');
    } else {
      if (this.lockChanges) {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '400px',
          panelClass: 'custom-dialog',

          data: {
            id: this.model.id,
            title: 'Änderungen verwerfen',
            text: 'Möchten Sie die Änderungen an diesem Datensatz verwerfen?',
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result?.event === 'submit') {
            this.lockingService.triggerSubmit('submit');
          } else {
            this.lockingService.changeEditorState(!this.lockingService.lockingEditorIsActive);
            this.entityService.forceSelect();
          }
        });
      } else {
        this.lockingService.changeEditorState(!this.lockingService.lockingEditorIsActive);
      }
    }
  }

  public hasOnlyDefaultValues(obj): boolean {
    if (obj) {
      const counter = Object.keys(obj).filter(x => !!x).length;
      return counter + 4 > Object.keys(obj).length;
    }
    return false;
  }

  public checkIfHasFormField(key): boolean {
    return this.innerFormFields.includes(key);
  }

  public checkDescriptionLock(lock, key) {
    return this.formlyFunctions.checkLock(
      lock,
      key,
      this.configService.currentContext,
      this.isProdInstance,
      this.ignoreLocks
    );
  }

  // ----- PRIVATE HELPER FUNCTIONS ----- //

  // InitModel
  private async modelFactory(): Promise<void> {
    if (this.currentEntity === 'DataFieldContentResource' || this.currentEntity === 'DataFieldGroupContentResource') {
      this.model.jsonConditions =
        JSON.stringify(this.model.jsonConditions) === '[]' ? '' : JSON.stringify(this.model.jsonConditions);
    }

    if (this.currentEntity === 'QuestionContentResource') {
      this.model.pairedQuestions = this.model.pairedQuestions
        ?.map(x => `[${x.id.substring(0, 8)}] ${x.adminName}`)
        .join('\n');
    }

    if (this.currentEntity === 'AnswerOptionContentResource') {
      this.resetAttributes(this.model['attributeRefs'] || []);
    }

    if (this.currentEntity === 'QuestionContentResource' && this.attributesOutputs) {
      this.attributesOutputs.clear();
    }

    if (this.currentEntity === 'MediaContentResource' && this.model.type) {
      const isLink = this.model.type.includes('LINK');
      const isVideo = this.model.type.includes('VIDEO') && this.model.url.includes('http');

      // needed to not throw an 500 error in console on Links or Videos
      const content = !(isLink || isVideo) ? await this.mediaService.getMediaContent(this.model.url, true) : '';

      this.mediaContentMap.set(this.model.id, content);
      this.fileType = FileTypes[this.model.type];

      // remove the upload button if the model changes
      // this is so we don't have to change library code
      const uploadButton =
        (document.querySelector('#uploadComponent')?.querySelector('vr-button') as HTMLButtonElement) || null;

      !!uploadButton && uploadButton.click();
    }

    if (this.currentEntity === 'ProductContentResource') {
      if (this.model.prices && this.model.prices.length > 0) {
        this.model.prices = this.formatPrices(this.model.prices);
      }
    }

    Object.keys(this.model).forEach(key => {
      if (
        (key.includes('Refs') &&
          !key.includes('attribute') &&
          this.currentEntity !== 'CompositionContentResource' &&
          this.currentEntity !== 'AccountCalculatorContentResource') ||
        key === 'tagRefs'
      ) {
        let idName = `${key.split('Refs')[0]}Id`;
        this.model[key] = this.model[key].flatMap(x => x[idName]);
      }

      if (key === 'dataFields' && this.currentEntity === 'DataFieldGroupContentResource') {
        this.model[key] = this.model[key].map(x => ({ ...x, jsonConditions: JSON.stringify(x?.jsonConditions) }));
      }
    });
  }

  private formatPrices(prices: any[]) {
    return prices.map(x => {
      const newPrice = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 4,
      })
        .format(x.price)
        .toString();
      return {
        ...x,
        price: newPrice.substring(0, newPrice.length - 2),
      };
    });
  }

  private hasRegularDescription(key): boolean {
    if (key === 'description') {
      return this.regularDescriptions.find(x => x === this.currentEntity.toLowerCase());
    } else {
      return true;
    }
  }

  private mapJsonToDynamicForm(): void {
    const data = this.formlyFunctions.mapToForm({
      data: this.configService.currentOpenApiConfig,
      currentEntity: this.currentEntity,
      innerFormFields: this.configService.innerFormFields,
      model: this.model,
      referenceRows: this.referenceRows,
      form: this.form,
      isProdInstance: this.isProdInstance,
      currentContext: this.configService.currentContext,
      ignoreLocks: this.ignoreLocks,
      regularDescriptions: this.regularDescriptions,
    });
    if (data !== null) {
      this.model = data.model;
      this.fields = data.fields;
      this.innerFormFields = data.innerFormFields;
      this.isLoading = false;
    }
    // VSS-5655 scroll to top each time something in the record table has been clicked
    this.containerRef.scrollTo(0, 0);
  }

  private async getReferenceFields(): Promise<void> {
    const refData = await this.formlyFunctions.getRef({
      data: this.configService.currentOpenApiConfig,
      currentEntity: this.currentEntity,
      currentContext: this.configService.currentContext,
      contentVersions: this.contentVersions,
      innerFormFields: this.innerFormFields,
    });
    this.referenceRows.clear();
    this.referenceRows = refData.referenceRows;
    this.contentVersions = refData.contentVersions;
    this.dataFetched.next(true);
  }

  get locksEditable() {
    return this.lockingService.lockEditing;
  }

  get ignoreLocks() {
    return this.lockingService.ignoreLocking;
  }

  onClipboardCopy(ev, item) {
    if (ev) {
      this.toastService.success(item + ' erfolgreich in die Zwischenablage kopiert.');
    } else {
      this.toastService.error('Fehler beim Kopieren in die Zwischenablage.');
    }
  }

  onCopyButtonClick() {
    if (!this.model.id) {
      return;
    }
    if (this.currentEntity === 'QuestionContentResource' || this.currentEntity === 'SubtopicContentResource') {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '400px',
        panelClass: 'custom-dialog',
        data: {
          id: this.model.id,
          title: 'Info',
          text: 'Welche Art von Kopie soll erstellt werden?',
          custom: 'deepcopy',
          customConfirm: 'Kopie erstellen',
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result?.event === 'deepcopy') {
          this.deepCopy();
        } else if (result && result?.event === 'copy') {
          this.copyRecord();
        }
      });
    } else if (this.currentEntity === 'InstanceConfigRequest') {
      if (this.prodInstanceSelected) {
        return;
      }
      this.instanceRequestPending = true;
      this.apiService
        .copyInstance(this.model.id)
        .pipe(finalize(() => (this.instanceRequestPending = false)))
        .subscribe(res => {
          this.toastService.success(`Instanz ${this.model.name} wurde erfolgreich erstellt.`);
          this.finishInstanceRequest();
          this.initNewData(res);
        });
    } else {
      this.copyRecord();
    }
  }

  private deepCopy() {
    if (this.model.adminName?.length > 220) {
      this.toastService.error('Admin-Name darf nicht aus mehr als 220 Zeichen bestehen.');
      return;
    }

    const title = this.currentEntity === 'SubtopicContentResource' ? 'Unterthema' : 'Frage';
    const observable =
      this.currentEntity === 'SubtopicContentResource'
        ? this.apiService.copySubTopic(this.model['id'])
        : this.apiService.copyQuestion(this.model['id']);

    observable.subscribe(res => {
      this.toastService.success(`${title} mit ID: ${res.id} erfolgreich erstellt.`);
      this.initNewData(res);
      this.cacheService.renewAll();
    });
  }

  // Editing Record
  copyRecord() {
    const newModel = JSON.parse(JSON.stringify(this.model));

    if (newModel.hasOwnProperty('adminName')) {
      newModel['adminName'] = `${newModel['adminName']} - Kopie`;
    }

    if (newModel.hasOwnProperty('keyName')) {
      newModel['keyName'] = `${newModel['keyName']} - Kopie`;
    }

    if (newModel.hasOwnProperty('name')) {
      newModel['name'] = `${newModel['name']} - Kopie`;
    }

    // Special cases
    if (this.currentEntity === 'AttributeContentResource') {
      newModel['value'] = `${newModel['value']} - Kopie`;
    }

    if (this.currentEntity === 'ExpertContactContentResource') {
      newModel['email'] = `${newModel['email']} - Kopie`;
      newModel['firstName'] = `${newModel['firstName']} - Kopie`;
      newModel['lastName'] = `${newModel['lastName']} - Kopie`;
    }

    // deleting the id key so that we have a new record and dont override the old one
    delete newModel?.id;

    // paired questions
    if (this.currentEntity === 'QuestionContentResource') {
      const arrayString = newModel.pairedQuestions.split(' ')[0];
      if (arrayString) {
        const groupElementId = JSON.parse(arrayString)[0];
        newModel.pairedQuestionId = groupElementId;
      } else {
        newModel.pairedQuestionId = null;
      }
      newModel.pairedQuestions = [];
    }

    if (newModel?.jsonConditions === '') {
      delete newModel.jsonConditions;
    }

    if (newModel?.jsonAnswerOptions === '') {
      delete newModel.jsonAnswerOptions;
    }
    this.innerFormFields.forEach(key => {
      if (newModel.hasOwnProperty(key) && key !== 'attributeRefs' && key !== 'productRefs') {
        if (newModel[key].length > 0) {
          newModel[key].forEach(element => {
            delete element.id;
            if (element.adminName) {
              element.adminName = `${element.adminName}-Kopie`;
            }
          });
        }
      }
    });
    if (newModel.hasOwnProperty('prices')) {
      newModel.prices = [
        ...newModel.prices.map(priceItem => ({
          ...priceItem,
          price: Number(priceItem.price.replace(/\./gi, '').replace(/\,/gi, '.')),
        })),
      ];
    }

    this.model = newModel;
    this.finalSubmit(true);
  }

  private navigateToManage(): void {
    const dialogRef = this.dialog.open(ManageConsultationComponent, {
      width: '800px',
      height: '600px',
      data: {
        instanceName: this.model?.name,
        mode: 'PROD',
        instanceId: this.model?.id,
      },
    });

    const refreshSub = dialogRef.componentInstance?.refreshInstances.subscribe(async () => {
      this.cacheService.renewInstances();
      await this.instanceService.getInstances();
      await this.getReferenceFields();

      const updatedInstance = this.instanceService.availableInstances.find(x => x.id === this.model.id);
      if (updatedInstance) {
        this.initNewData(updatedInstance);
      }
    });

    const dialogSub = dialogRef.afterClosed().subscribe(() => {
      refreshSub?.unsubscribe();
      dialogSub?.unsubscribe();
    });
  }

  public deleteButtonClicked(): void {
    if (
      this.currentEntity === 'InstanceConfigRequest' &&
      this.hasOpenConsultations &&
      this.model?.status === 'PROD' &&
      this.model?.id
    ) {
      this.navigateToManage();
      return;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      panelClass: 'custom-dialog',
      data: {
        id: this.model.id,
        title: 'Eintrag löschen',
        text: 'Möchten Sie diesen Eintrag wirklich löschen? Dies kann nicht mehr rückgängig gemacht werden!',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event === 'submit') {
        this.apiService.delete(this.configService.getEndpoint(this.currentEntity), result.id).subscribe(
          async res => {
            if (
              (this.currentEntity === 'SettingConsultationAppContentResource' &&
                this.model.keyName === 'showRestrictionEditor') ||
              this.model.keyName === 'expertConfMode'
            ) {
              this.lockingService.setLockEditing();
            }

            this.entityService.setModel({});
            this.entityService.forceSelect();
            this.formDirective.resetForm();
            this.toastService.success(`${this.title} gelöscht.`);
            if (this.currentEntity === 'InstanceConfigRequest') {
              this.finishInstanceRequest();
            }
          },
          err => {
            if (err.error) {
              this.toastService.error(err.error.message);
            } else {
              this.toastService.error('Ein Fehler ohne Fehlerbezeichnung ist aufgetreten.');
            }
          }
        );
      }
    });
  }

  public closeButtonClicked(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      panelClass: 'custom-dialog',

      data: {
        id: this.model.id,
        title: 'Änderungen speichern',
        text: 'Möchten Sie die Änderungen an diesem Datensatz speichern?',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'submit') {
        this.submit();
      } else {
        this.entityService.forceSelect();
        this.entityService.resetSelect();
        this.entityService.setModel({});
      }
    });
  }

  public addButtonClicked(): void {
    this.formDirective.resetForm();
    this.entityService.forceSelect();
    this.entityService.resetSelect();
    this.entityService.setModel({});
  }

  public resetForm() {
    this.model = JSON.parse(JSON.stringify(this.initialModel));
  }

  public async submit(ev?: Event): Promise<void> {
    ev?.preventDefault();
    if (this.checkIfHasFormField('variables')) {
      this.handleContractSubmit();
      return;
    }

    if (this.model.hasOwnProperty('name')) {
      this.model.name = this.model.name.trim();
    }
    if (this.model.hasOwnProperty('displayName')) {
      this.model.displayName = this.model.displayName.trim();
    }
    if (this.model.hasOwnProperty('adminName')) {
      this.model.adminName = this.model.adminName.trim();
    }
    if (this.model.hasOwnProperty('value')) {
      this.model.value = this.model.value.trim();
    }

    this.entityService.triggerSubmit();

    this.form.markAllAsTouched();
    let valid = true;

    // remove validation if no Textfield
    this.handleJson();

    // check innerforms

    const innerFormsData = {
      benefits: { data: this.benefits, hasFormField: this.checkIfHasFormField('benefits') },
      prices: { data: this.prices, hasFormField: this.checkIfHasFormField('prices') },
      products: { data: this.products, hasFormField: this.checkIfHasFormField('productRefs') },
      profile: { data: this.profile, hasFormField: this.checkIfHasFormField('dataFieldGroupProfile') },
      compositionProductRefs: {
        data: this.compositionProductRefs,
        hasFormField: this.checkIfHasFormField('compositionProductRefs'),
      },
      answerOptions: { data: this.answerOptions, hasFormField: this.checkIfHasFormField('answerOptions') },
      dataFields: { data: this.dataFields, hasFormField: this.checkIfHasFormField('dataFields') },
      taskRefs: {
        data: this.currentEntity !== 'DataFieldGroupContentResource' ? this.tasks : this.model.taskRefs,
        hasFormField: this.checkIfHasFormField('taskRefs'),
      },
      transitionRefs: {
        data: this.currentEntity !== 'DataFieldGroupContentResource' ? this.transitions : this.model.transitionRefs,
        hasFormField: this.checkIfHasFormField('transitionRefs'),
      },
      productRefs: {
        data: this.model.productRefs,
        hasFormField: false,
      },
      formRefs: {
        data: [this.model?.formRefs || null],
      },
    };

    const data = this.innerFormFunctions.handleInnerForms(
      valid,
      innerFormsData,
      this.ignoreLocks,
      this.model,
      this.currentEntity
    );

    this.model = data.model;
    valid = data.valid;

    this.cleanUpUnusedFields();

    if ((this.form.valid && valid) || (!this.form.errors && valid)) {
      if (!this.isRegularDescription) {
        this.model['description'] = this.newDescription ?? null;
      }

      if (this.model.description === '') {
        this.model.description = null;
      }

      if (
        this.currentEntity === 'AnswerOptionContentResource' &&
        this.checkIfHasFormField('attributeRefs') &&
        this.attributes
      ) {
        if (this.attributesChanged) {
          const attributes = [];
          this.attributes.forEach(x => {
            attributes.push(x.value);
            if (x.status === 'INVALID') {
              valid = false;
            }
          });
          this.generateAttribute(attributes);
          return;
        } else {
          this.model.attributeRefs = this.originalAttributeRefs;
        }
      } else if (this.currentEntity === 'QuestionContentResource') {
        Object.keys(this.attributesOutputs).forEach((key, value) => {
          if (value[value].status === 'INVALID') {
            valid = false;
          }
        });

        const attributeData = await lastValueFrom(
          this.apiService.get(this.configService.getEndpoint('AttributeContentResource'))
        );

        this.generateQuestionAttributes(this.attributesOutputs, attributeData);
        return;
      }

      // check content Version
      if (
        this.model.id &&
        this.model.contentVersion &&
        this.model.contentVersion !== this.initialModel.contentVersion
      ) {
        this.importDialog();
      } else {
        this.finalSubmit();
      }
    }
  }

  /**
   * @returns {boolean} - model active state
   */
  public getModelActive(): boolean {
    if (this.model.active === undefined) return true;
    return this.model.active;
  }

  private handleJson(): void {
    if (this.model.hasOwnProperty('jsonValidation') && this.model.hasOwnProperty('type')) {
      if (this.model.type !== 'TEXT') {
        this.model.jsonValidation = null;
      }
    }

    if (this.model.jsonConditions && this.model.jsonConditions?.length > 0) {
      this.model.jsonConditions = JSON.parse(this.model.jsonConditions);
    } else if (this.model.jsonConditions === '') {
      this.model.jsonConditions = [];
    }
  }

  private cleanUpUnusedFields() {
    delete this.model.pairedQuestions;
    delete this.model.groupId;
    delete this.model.bankhubValue;

    if (this.currentEntity === 'DataFieldGroupContentResource' && this.model.type === 'GENERAL') {
      this.model.productRef = [];
      this.model.transitionRefs = [];
      this.model.taskRefs = [];
    }
  }

  public importDialog(isSync = false): void {
    const text = (): string => {
      return this.model.status === 'PROD'
        ? 'Es dürfen daher keine offenen Beratungen existieren. '
        : 'Zudem werden bestehende Beratungen gelöscht.  Fortfahren?';
    };

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      panelClass: 'custom-dialog',
      data: {
        id: this.model.id,
        title: 'Warnung',
        text: `Die Instanz wird mit neuem Inhalt überschrieben. ${text()}`,
        custom: !this.showCSV ? 'csv' : '',
        customConfirm: 'Instanz synchronisieren',
      },
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result || result?.event !== 'submit') {
        return;
      }

      if (!isSync) {
        this.finalSubmit();
        return;
      }

      this.instanceRequestPending = true;

      try {
        const syncData = {
          sourceInstanceId: this.model.linkedDataInstanceId,
          targetInstanceId: this.model.id,
        };
        await lastValueFrom(this.apiService.post('instances/sync', syncData));

        const instanceGet = await lastValueFrom(this.apiService.get(`instances/${this.model.linkedDataInstanceId}`));
        this.model.contentVersion = instanceGet.contentVersion;

        const instancePatch = await lastValueFrom(this.apiService.patch('instances', this.model));
        this.toastService.success(`${this.title} erfolgreich synchronisiert.`);
        this.finishInstanceRequest();
        this.initNewData(instancePatch);
      } catch (e) {
        this.toastService.error(e.message);
      }

      this.instanceRequestPending = false;
    });

    dialogRef.componentInstance.customEvent.subscribe(x => {
      if (x && x === 'validation') {
        this.downloadValidationCSV();
      } else if (x && x === 'changes') {
        this.downloadProdCompareChangelog();
      }
    });
  }

  get hasInvalidFields(): boolean {
    if (!this.formlyForm) {
      return false;
    }
    const validFields = this.formlyForm.fields
      .filter(x => x)
      .map(field => field.formControl.valid || field.formControl.disabled);

    return validFields.some(field => !field);
  }

  private async finalSubmit(copy = false): Promise<void> {
    if (this.globalAnswerOptions?.length !== 0 && this.globalAnswerOptions !== undefined) {
      if (this.globalAnswerOptions[0].value['contentId'] === '_newAttribute') {
        const requestBody = {
          name: this.globalAnswerOptions[0].value['customName'],
          description: this.globalAnswerOptions[0].value['customValue'],
        };
        const postAnswer = await lastValueFrom(
          this.apiService.postHub(this.configService.getEndpoint('GlobalAnswerConfigResource'), requestBody)
        );
        this.model.gshAnswerContentId = postAnswer['contentId'];
      } else {
        this.model.gshAnswerContentId = this.globalAnswerOptions[0].value['contentId'];
      }
    }

    if (this.currentEntity === 'QuestionContentResource') {
      for (const [key, value] of this.answerIdOutputs) {
        if (value.contentId === '_newAttribute') {
          const requestBody = {
            name: value.customName,
            description: value.customValue,
          };
          const postAnswer = await lastValueFrom(
            this.apiService.postHub(this.configService.getEndpoint('GlobalAnswerConfigResource'), requestBody)
          );
          this.model.answerOptions[key].gshAnswerContentId = postAnswer['contentId'];
        } else {
          this.model.answerOptions[key].gshAnswerContentId = value.contentId;
        }
      }
    }

    Object.keys(this.model).forEach(key => {
      if (
        (key.includes('Refs') &&
          !key.includes('attribute') &&
          this.currentEntity !== 'CompositionContentResource' &&
          this.currentEntity !== 'AccountCalculatorContentResource') ||
        key === 'tagRefs'
      ) {
        const idName = `${key.split('Refs')[0]}Id`;
        const array = [];
        for (let value of this.model[key]) {
          const obj = {};
          obj[idName] = value;
          array.push(obj);
        }

        this.model[key] = [...array];
      }
      if (key === 'tool') {
        if (this.model['type'] === 'TOOL') {
          this.model[key] = {
            id: this.model.tool,
            inputs: this.toolResourceInput || this.model['toolResources']?.inputs,
            outputs: this.toolResourceOutput || this.model['toolResources']?.outputs,
          };
        } else {
          this.model[key] = null;
        }

        this.model['toolResources'] = undefined;
      }

      if (key === 'dataFields' && this.currentEntity === 'DataFieldGroupContentResource') {
        this.model[key] = this.model[key].map(x => {
          let json = '[]';
          if (x?.jsonConditions) {
            json = x?.jsonConditions?.replace(/\'/g, '"');
          }

          return {
            ...x,
            jsonConditions: JSON.parse(json),
          };
        });
      }
    });

    if (this.hasInvalidFields && this.currentEntity !== 'MediaContentResource') {
      return;
    }

    if (this.model.adminName?.length > 220) {
      this.toastService.error('Admin-Name darf nicht aus mehr als 220 Zeichen bestehen.');
      return;
    }

    if (this.currentEntity === 'InstanceConfigRequest') {
      this.instanceRequestPending = true;
    }

    if (this.model.id) {
      // update record
      if (this.currentEntity === 'InstanceConfigRequest') {
        this.model.createLocks = true;
      }

      this.apiService.patch(this.configService.getEndpoint(this.currentEntity), this.model).subscribe(
        res => {
          if (this.currentEntity === 'InstanceConfigRequest') {
            this.handleInstancePatchRequest();
          }

          if (
            (this.currentEntity === 'SettingConsultationAppContentResource' &&
              this.model.keyName === 'showRestrictionEditor') ||
            this.model.keyName === 'expertConfMode'
          ) {
            this.lockingService.setLockEditing();
          }

          this.toastService.success(`${this.title} erfolgreich geändert.`);
          this.initNewData(res);
        },
        err => {
          this.errorMessage(err, true);
          this.entityService.setModel(this.initialModel);
        }
      );
    } else {
      // create record
      if (this.currentEntity === 'InstanceConfigRequest') {
        this.model.createLocks = true;
      }

      this.apiService.post(this.configService.getEndpoint(this.currentEntity), this.model).subscribe(
        res => {
          if (this.currentEntity === 'InstanceConfigRequest') {
            this.handleInstancePostRequest(res);
          }
          if (
            (this.currentEntity === 'SettingConsultationAppContentResource' &&
              this.model.keyName === 'showRestrictionEditor') ||
            this.model.keyName === 'expertConfMode'
          ) {
            this.lockingService.setLockEditing();
          }
          this.toastService.success(`${this.title} erfolgreich erstellt.`);

          this.initNewData(res);
        },
        err => {
          this.errorMessage(err, copy);
          if (copy) {
            this.entityService.setModel(this.initialModel);
          }
        }
      );
    }
    this.getReferenceFields();
  }

  private errorMessage(err: ErrorEvent, modelReset = false) {
    let message = err.error?.message || 'Ein Fehler ohne Fehlerbezeichnung ist aufgetreten!';
    if (modelReset) {
      message += '- Änderungen wurden zurückgesetzt.';
    }
    this.toastService.error(message);
    this.instanceRequestPending = false;
  }

  private handleInstancePatchRequest(): void {
    if (this.model.status === 'PROD' && this.model.linkedDataInstanceId !== this.initialModel.linkedDataInstanceId) {
      this.apiService
        .post('instances/sync', {
          sourceInstanceId: this.model.linkedDataInstanceId,
          targetInstanceId: this.model.id,
        })
        .pipe(
          finalize(() => {
            this.finishInstanceRequest();
          })
        )
        .subscribe(x => {
          this.toastService.success(`${this.title} erfolgreich synchronisiert.`);
        });
    } else {
      this.finishInstanceRequest();
    }
  }

  private handleInstancePostRequest(res): void {
    if (this.model.status === 'PROD') {
      this.instanceRequestPending = true;
      this.apiService
        .post('instances/sync', {
          sourceInstanceId: this.model.linkedDataInstanceId,
          targetInstanceId: res['id'],
        })
        .pipe(
          finalize(() => {
            this.finishInstanceRequest();
          })
        )
        .subscribe(x => {
          this.toastService.success(`${this.title} erfolgreich synchronisiert.`);
        });
    } else {
      this.finishInstanceRequest();
    }
  }

  private async finishInstanceRequest(): Promise<void> {
    this.cacheService.renewAll();
    await this.instanceService.getInstances();
    await this.getReferenceFields();
    this.instanceRequestPending = false;
  }

  private initNewData(res): void {
    this.entityService.setLastModel({ ...res, _lock: this.model['_lock'] });
    this.entityService.setModel({ ...res, _lock: this.model['_lock'] });
    if (this.currentEntity === 'UserConfigResource' || this.currentEntity === 'QuestionContentResource') {
      this.cacheService.renewEntityById(this.currentEntity, res.id);
      if (this.currentEntity === 'QuestionContentResource') {
        this.cacheService.renewAnswerOptions();
        this.cacheService.renewAttributes();
      }
    }
    if (this.currentEntity === 'DataFieldGroupContentResource') {
      this.cacheService.renewDatafields();
    }

    if (this.currentEntity === 'DataFieldContentResource') {
      this.cacheService.renewDatafieldGroups();
    }

    if (this.currentEntity === 'CompositionContentResource') {
      this.cacheService.renewCompositions();
    }
    if (this.currentEntity === 'AnswerOptionContentResource') {
      this.cacheService.renewAll();
    }
    if (this.currentEntity === 'MediaContentResource') {
      this.cacheService.renewMedias(this.model.id);
    }
    this.entityService.forceSelect();
  }

  // Media
  public isImage(url): boolean {
    const imageTypes = FileTypes.IMAGE.split(',');
    let isImage = false;

    imageTypes.forEach(imageType => {
      if (url.includes(imageType) || url.includes(imageType.toUpperCase())) {
        isImage = true;
      }
    });

    return isImage;
  }

  public getMediaUrl(mediaModel: any): string {
    return this.mediaContentMap.get(this.model.id);
  }

  public setMediaModelData(event) {
    if (event.url) {
      this.model.url = event.url;
    }
    if (event.adminName) {
      this.model.adminName = event.adminName;
    }
    this.submit();
  }

  public openPreview(url: string) {
    window.open(url, '_blank');
  }

  public openPdf(model: any) {
    if (model.url.toLowerCase().includes('pdf')) {
      this.openPreview(this.mediaContentMap.get(model.id));
    }
  }

  // System Disabled
  public isSystemDisabled(): boolean {
    return (
      this.instanceRequestPending ||
      (this.model && this.model._lock?.type === 'RECORD') ||
      (this.isProdInstance && this.configService.currentContext !== ContextEnum.bankHub) ||
      this.currentEntity === 'InformationContentResource' ||
      this.currentEntity === 'InformationConfigResource' ||
      (this.currentEntity === 'UserConfigResource' && environment.platform === 'vp')
    );
  }

  // ModelChanges
  public async modelChanges(): Promise<void> {
    const type = this.fields.find(x => x.key === 'type');
    const mandatory = this.fields.find(x => x.key === 'mandatory');
    if (mandatory && type) {
      const allowMandatory = type.formControl.value !== 'HIDDEN';
      const allowHidden = !mandatory.formControl.value;
      mandatory.props.disabled = !allowMandatory;
      type.props.options = type.props.allOptions.filter(x => x.value !== 'HIDDEN' || allowHidden);
    }

    const validation = this.fields.find(x => x.key === 'validation');
    const offsetUnit = this.fields.find(x => x.key === 'offsetUnit');
    const offset = this.fields.find(x => x.key === 'offset');
    const validationMessage = this.fields.find(x => x.key === 'validationMessage');
    const readonly = this.fields.find(x => x.key === 'readonly');

    if (validation && offsetUnit && offset && validationMessage && readonly) {
      const showValidation = this.model.type === 'DATE';
      const showUnit = validation.formControl.value === 'PAST_OFFSET_MAX';
      const showOffset = offsetUnit.formControl.value;
      const showMessage = offset.formControl.value || validation.formControl.value === 'FUTURE';
      const showReadonly = ['DATE', 'TEXT', 'LONG_TEXT', 'NUMBER', 'PRICE'].includes(this.model.type);

      validation.hide = !showValidation;
      offsetUnit.hide = !showUnit;
      offset.hide = !showOffset;
      readonly.hide = !showReadonly;
      validationMessage.hide = !showMessage;
      offsetUnit.props.required = showUnit;
      offset.props.required = showOffset;
    }

    if (this.currentEntity === 'InstanceConfigRequest') {
      if (this.model.contentStream === 'NONE') {
        this.model = { ...this.model, contentVersion: null };
        const contentVersion = this.fields.find(x => x.key === 'contentVersion');
        contentVersion['props'].required = false;
      }
      if (this.model.contentStream) {
        let contentVersions = [];
        if (this.contentVersions.find(x => x.contentStream.toLowerCase() === this.model.contentStream.toLowerCase())) {
          this.contentVersions.map(x => {
            if (x.contentStream.toLowerCase() === this.model.contentStream.toLowerCase()) {
              for (let i = 0; i < x.versions.length; i++) {
                contentVersions.push({ value: x.versions[i], label: `${x.contentStream} (${x.versions[i]})` });
              }
              return contentVersions;
            }

            return null;
          });
        }
        if (this.model.contentStream && this.model.contentStream !== 'NONE') {
          const contentVersion = this.fields.find(x => x.key === 'contentVersion');
          contentVersion['props'].options = contentVersions;
          contentVersion['props'].required = true;
        }

        this.changeDetection.detectChanges();
      }

      if (
        this.model.linkedDataInstanceId &&
        this.model.status === 'PROD' &&
        this.model.linkedDataInstanceId !== this.lastLinkedInstanceId
      ) {
        this.apiService.get('instances').subscribe(instances => {
          const chosenInstance = instances.find(instance => instance.id === this.model.linkedDataInstanceId);
          this.model = {
            ...this.model,
            application: chosenInstance.application,
            autoUpdate: chosenInstance.autoUpdate,
            contentStream: chosenInstance.contentStream,
            contentVersion: chosenInstance.contentVersion,
          };
          this.lastLinkedInstanceId = chosenInstance.id;
        });
      }
    }
  }

  // Instances

  get showCSV(): boolean {
    return (
      this.currentEntity === 'InstanceConfigRequest' && this.model.status === 'TEST' && this.model.id !== undefined
    );
  }

  get showChangelogCSV(): boolean {
    return this.currentEntity === 'InstanceConfigRequest' && this.model.id !== undefined;
  }

  get showResyncButton(): boolean {
    return (
      this.model.linkedDataInstanceId !== undefined &&
      this.currentEntity === 'InstanceConfigRequest' &&
      this.initialModel.linkedDataInstanceId === this.model.linkedDataInstanceId &&
      this.model.status === 'PROD' &&
      this.model.id !== undefined
    );
  }

  get hasOpenConsultations(): boolean {
    return this.currentEntity === 'InstanceConfigRequest' && this.model.openConsultations > 0;
  }

  get referenceContentResource() {
    switch (this.currentEntity) {
      case 'TransitionContentResource':
        return 'TransitionBenefitContentResource';
      case 'TaskContentResource':
        return 'TaskBenefitContentResource';
      case 'ProductContentResource':
        return 'ProductBenefitContentResource';
    }
    return this.currentEntity;
  }

  // BreadCrumbs
  public setJumpData(event: BreadCrumbElement): void {
    this.jumpData = event;
  }

  // InnerForms
  public setInnerFormToolInput(output: FormGroup[]): void {
    this.toolResourceInput = output.map(x => x.value);
  }

  public setInnerFormToolOuput(output: FormGroup[]): void {
    this.toolResourceOutput = output.map(x => x.value);
  }

  public setInnerFormBenefit(output: FormGroup[]): void {
    this.benefits = output;
  }

  public setInnerFormProfile(output: FormGroup[]): void {
    this.profile = output;
  }

  public setInnerFormPrice(output: FormGroup[]): void {
    this.prices = output;
  }

  public setInnerFormProducts(output: FormGroup[]): void {
    this.products = output;
  }

  public setInnerFormTasks(output: FormGroup[]): void {
    this.tasks = output;
  }

  public setInnerFormTransitions(output: FormGroup[]): void {
    this.transitions = output;
  }

  public setInnerFormVcItems(output: FormGroup[]): void {
    this.compositionProductRefs = output;
  }

  public setContractVariableItems(variables: any[]): void {
    this.contractVariables = variables;
  }

  public setInnerFormAttributes(output: any[]): void {
    this.attributesChanged = true;
    this.attributes = output;
  }

  public setInnerFormAnswerOptions(output: FormGroup[]): void {
    this.answerOptions = output;
  }

  public setInnerDataFields(output: FormGroup[]): void {
    this.dataFields = output;
  }

  public setQuestionOptions(output: {
    answerOptions: FormGroup[];
    attributes: Map<any, any>;
    answerIds: Map<any, any>;
  }) {
    this.answerOptions = output.answerOptions;
    this.attributesOutputs = output.attributes;
    this.answerIdOutputs = output.answerIds;
  }

  public setGlobalAnswerOptions(output: FormGroup[]) {
    this.globalAnswerOptions = output;
  }

  // InnerForms Attributes
  private resetAttributes(attributes): void {
    this.attributesChanged = false;
    this.attributes = [];
    this.originalAttributeRefs = attributes;
  }

  private async generateAttribute(attributes): Promise<any> {
    if (attributes.length === 0) {
      this.model.attributeRefs = [];
      this.attributesChanged = false;
      this.finalSubmit();
      return;
    }
    this.form.markAllAsTouched();
    const question = await lastValueFrom(
      this.apiService.get(`${this.configService.getEndpoint('questionContentResource')}/${this.model.questionId}`)
    );

    const attributeData = await lastValueFrom(
      this.apiService.get(this.configService.getEndpoint('AttributeContentResource'))
    );

    const data = this.innerFormFunctions.prepareAttributes(attributes, question, attributeData, this.model);
    const existingAttributes = data.existingAttributes;
    const newAttributes = data.newAttributes;

    const requests = newAttributes.map(newAttribute =>
      this.apiService.post(this.configService.getEndpoint('AttributeContentResource'), newAttribute)
    );

    if (requests.length !== 0) {
      const requestCollection = forkJoin(requests).subscribe(
        data => {
          let ids = data.map(dataElement => {
            return { attributeId: dataElement['id'] };
          });
          ids = ids.concat(
            existingAttributes.map(exAttribute => {
              return { attributeId: exAttribute };
            })
          );
          this.model.attributeRefs = ids;
          this.attributesChanged = false;
          this.finalSubmit();
          requestCollection.unsubscribe();
        },
        error => {
          requestCollection.unsubscribe();
          console.log(error);
          if (error.error) {
            this.toastService.error(error.error.message);
          } else {
            this.toastService.error('Ein Fehler ohne Fehlerbezeichnung ist aufgetreten!');
          }
        }
      );
    } else {
      this.model.attributeRefs = existingAttributes.map(x => ({
        attributeId: x,
      }));
      this.attributesChanged = false;
      this.finalSubmit();
    }
  }

  private async generateQuestionAttributes(attributesOutput, attributeData: any): Promise<any> {
    this.form.markAllAsTouched();
    const question = this.model;

    const changeLength = this.attributesOutputs.size;
    let index = 0;
    if (changeLength === 0) {
      this.finalSubmit();
    }
    attributesOutput.forEach((value, key) => {
      index++;
      if (value.length === 0) {
        this.model.answerOptions[key].attributeRefs = [];
        if (index === changeLength) {
          this.finalSubmit();
        }
        return;
      }
      const attributes = value.map(x => x.value);

      const data = this.innerFormFunctions.prepareAttributes(
        attributes,
        question,
        attributeData,
        this.model.answerOptions[key]
      );
      const existingAttributes = data.existingAttributes;
      const newAttributes = data.newAttributes;

      const requests = newAttributes.map(newAttribute =>
        this.apiService.post(this.configService.getEndpoint('AttributeContentResource'), newAttribute)
      );

      if (requests.length !== 0) {
        forkJoin(requests).subscribe(
          data => {
            let ids = data.map(dataElement => {
              return { attributeId: dataElement['id'] };
            });
            ids = ids.concat(
              existingAttributes.map(exAttribute => {
                return { attributeId: exAttribute };
              })
            );
            this.model.answerOptions[key].attributeRefs = ids;
            if (index === changeLength) {
              this.finalSubmit();
            }
          },
          error => {
            console.log(error);
            if (error.error) {
              this.toastService.error(error.error.message);
            } else {
              this.toastService.error('Ein Fehler ohne Fehlerbezeichnung ist aufgetreten!');
            }
          }
        );
      } else {
        this.model.answerOptions[key].attributeRefs = existingAttributes.map(x => ({ attributeId: x }));
        if (index === changeLength) {
          this.finalSubmit();
        }
      }
    });
  }

  // ValidateContent
  public async downloadValidationCSV(): Promise<void> {
    const data = await lastValueFrom(this.apiService.getContentEvalCSVData(this.model.id));
    this.saveFile(data, 'Report');
  }

  // Download Changelog
  public downloadChangelogCSV(): void {
    this.model.status === 'PROD' ? this.downloadProdCompareChangelog() : this.downloadRegularChangeLogCSV();
  }

  private async downloadRegularChangeLogCSV(): Promise<void> {
    const data = await lastValueFrom(this.apiService.getChangelogData(this.model.id));
    this.saveFile(this.cleanHtmlService.cleanHtml(data, true), 'TEST-Changelog');
  }

  private async downloadProdCompareChangelog(): Promise<void> {
    const settings = await lastValueFrom(
      this.apiService.getCustomInstanceCAData('setting-consultationapp', this.model.id)
    );

    const lastUpdateProd = JSON.parse(settings)
      .find(element => element.keyName === 'lastUpdateMasterContentCA')
      .value.split(' ')[0]
      .split('.');

    const lastUpdateProdDate = `${lastUpdateProd[2]}-${lastUpdateProd[1]}-${lastUpdateProd[0]}`;

    const data = await lastValueFrom(
      this.apiService.getChangelogData(this.model.linkedDataInstanceId, lastUpdateProdDate)
    );

    this.saveFile(this.cleanHtmlService.cleanHtml(data, true), 'PROD-Changelog');
  }

  private saveFile(data: any, name: string): void {
    const fileName = `${this.model.name}-${this.model.contentStream}-${this.model.contentVersion}-${name}.csv`;
    this.saveFileService.saveFile(data, fileName, 'text/csv');
  }

  private handleContractSubmit() {
    this.model.variables = this.contractVariables;

    if (this.model.adminName?.length > 220) {
      this.toastService.error('Admin-Name darf nicht aus mehr als 220 Zeichen bestehen.');
      return;
    }

    if (this.model.id) {
      this.apiService.patch('forms', this.model).subscribe(data => {
        this.toastService.success(`${this.title} ${this.model.displayName} erfolgreich geändert.`);
        this.initNewData(data);
      });
    } else {
      this.apiService.post('forms', this.model).subscribe(data => {
        this.toastService.success(`${this.title} erfolgreich erstellt.`);
        this.initNewData(data);
      });
    }
  }

  get isProdInstance() {
    return this.instanceService.isProdInstance;
  }

  public modelHasSyncExcludedRefs(refField: string): boolean {
    return (
      this.model &&
      this.model.hasOwnProperty['metadata'] &&
      this.model['metadata']?.syncExcludedFields?.includes(refField)
    );
  }

  public setAttributeChanged(event: boolean) {
    this.attributesChanged = event;
  }

  public setInnerFormsValidStatus(valid: boolean) {
    this.innerFormsAreValid = valid;
  }

  public async handleInstanceExport(): Promise<void> {
    if (!this.enableExport) {
      return;
    }

    try {
      const data = await lastValueFrom(this.apiService.getInstanceExport(this.model.id));
      this.saveFileService.saveFile(data, `Instance-Export-${this.model.name}.zip`, '', true);
    } catch (error) {
      console.error(`Error creating the export: [${error.message ? error.message : 'null'}]`);
    }
  }

  public handleInstanceImport(event): void {
    const suffix = 'zip';
    const mime = `application/${suffix}`;

    const onSuccess = (): void => {
      this.toastService.success('Instanz Import erfolgreich!');
      this.cacheService.renewAll();
      this.instanceService.getInstances();

      this.entityService.resetSelection.next();
    };
    const onFail = (error: any = null): void => {
      this.toastService.error(error?.error?.message || 'Fehler beim Upload');
      this.entityService.isLoading.next(false);
    };

    const file = event.target.files[0];
    const formData = new FormData();
    const fileNameEnding = file.name.toString().split('.');

    if (fileNameEnding[fileNameEnding.length - 1] !== suffix) {
      this.toastService.error(`Der Import unterstützt nur Dateien im ${suffix.toUpperCase()} Format!`);
      return;
    }

    formData.append('file', file, file.name);
    this.entityService.isLoading.next(true);

    if (environment.platform === 'aws') {
      this.apiService
        .postInstanceImport(formData)
        .pipe(finalize(() => this.entityService.isLoading.next(false)))
        .subscribe(
          success => {
            this.initNewData(success);
            onSuccess();
          },
          error => onFail(error)
        );
    } else {
      this.apiService.getUNDTicket().subscribe(ticket => {
        formData.append('ticket', new Blob([JSON.stringify(ticket)], { type: `application/json` }));
        this.apiService
          .postUNDUploadFile(formData)
          .pipe(
            tap(uploadResponse => {
              this.apiService.postUNDImportInstance(uploadResponse.undID).subscribe(
                success => {
                  this.entityService.isLoading.next(false);
                  this.initNewData(success);
                  onSuccess();
                },
                error => onFail(error)
              );
            })
          )
          .subscribe(
            res => {},
            error => {
              onFail();
            }
          );
      });
    }
  }

  get isDeleteDisabled() {
    if (this.configService.currentContext === 'hub') {
      return (
        this.isSystemDisabled() ||
        !this.model.id ||
        (!this.model.selected && this.isLiveInstance) ||
        (this.hasOpenConsultations && this.isLiveInstance)
      );
    } else if (this.configService.currentContext === 'configurator') {
      return this.isSystemDisabled() || !this.model.id;
    } else {
      return true;
    }
  }

  get prodInstanceSelected() {
    if (this.model.status) {
      return this.model.status === 'PROD';
    } else {
      return false;
    }
  }

  get modelSelected() {
    return this.model.status ? true : false;
  }

  get isLiveInstance() {
    return this.initialModel.live === true;
  }

  get enableExport(): boolean {
    return this.currentEntity === 'InstanceConfigRequest' && this.model.id && this.model.status === 'TEST';
  }

  get showImportExport(): boolean {
    return this.currentEntity === 'InstanceConfigRequest';
  }

  get disableInstanceButtons(): boolean {
    return this.isLoading || this.instanceRequestPending;
  }
}
