<span
  class="vr-icon"
  aria-hidden="true"
  [style.color]="!materialColor && iconColor"
  [attr.data-color]="materialColor && materialColor"
  [innerHTML]="svgIcon"
  [style.min-width]="setWidth()"
  [style.min-height]="setHeight()"
  [style.width]="setWidth()"
  [style.height]="setHeight()"
></span>
