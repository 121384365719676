import { Injectable } from '@angular/core';
import { ContextEnum } from '@enums';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/config.service';
import { InstanceService } from '@services/instance.service';
import { BehaviorSubject } from 'rxjs';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class LockingService {
  public lockingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public lockingState = this.lockingSubject.asObservable();
  public createSubmit = new BehaviorSubject<any>('');
  public changesMade = new BehaviorSubject<boolean>(false);
  public lockingEditorIsActive = false;
  public lockData = new BehaviorSubject<Array<String>>([]);
  private initialLockData;
  public lockEditing = false;
  public ignoreLocking = false;

  constructor(
    private apiService: ApiService,
    private instanceService: InstanceService,
    private configService: ConfigService,
    private cacheService: CacheService
  ) {
    this.instanceService.instanceChanged.subscribe(x => {
      if (x !== '-1') {
        this.setLockEditing();
      }
    });
  }

  changeEditorState(value: boolean) {
    this.lockingEditorIsActive = value;
    this.lockingSubject.next(value);
  }

  triggerSubmit(value) {
    this.createSubmit.next(value);
  }

  setInitialLockData(lockData) {
    this.initialLockData = lockData;
  }

  checkNewLockData(lockData) {
    if (this.initialLockData) {
      if (this.initialLockData === lockData) {
        this.changesMade.next(true);
      } else {
        this.changesMade.next(false);
      }
    }
  }

  public setLockEditing() {
    const endpoint =
      this.configService.currentContext === ContextEnum.configApp ? 'setting-consultationapp' : 'settings';
    this.cacheService.renewSettings(endpoint);

    this.apiService.get(endpoint).subscribe(x => {
      this.lockEditing = x.find(setting => setting.keyName === 'showRestrictionEditor')?.value === '1';
      this.ignoreLocking = x.find(setting => setting.keyName === 'expertConfMode')?.value === '1';
    });
  }
}
