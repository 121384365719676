import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class SaveFileService {
  constructor() {}

  public saveFile(
    data: any,
    fileNameString: string,
    type: string = 'text/csv',
    skipBlobCreation: boolean = false
  ): void {
    // const FileSaver = require('file-saver');
    const blob = !skipBlobCreation ? new Blob([`\ufeff${data}`], { type: `${type};charset=utf-8` }) : data;
    saveAs(blob, fileNameString);
  }
}
