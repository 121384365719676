<div class="container">
  <div class="row">
    <div class="col-12">
      <kf-headline [headline]="'BankingGuide Administration'" [showBackButton]="false"></kf-headline>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <kf-tile [interactive]="false">
        <p>
          Mit dem BankingGuide steht Ihnen ein digitales Beratungstool mit Anbindung an die Vertriebsplattform und
          Schnittstellen zu MindBanker und dem ZV-Report zur Verfügung.<br />
          Weitere Informationen finden Sie auf
          <a href="https://www.bankingguide.de" target="_blank" rel="noopener"
            >www.bankingguide.de <kf-icon name="ic_neues-fenster_16"></kf-icon></a
          >.
        </p>
        <!-- <p>
          Über
          <a href="mailto:support@bankingguide.de?subject=BG Infomations-Anfrage"
            >support&#64;bankingguide.de <kf-icon name="ic_neues-fenster_16"></kf-icon
          ></a>
          können Sie gerne direkt per Mail Kontakt mit uns aufnehmen.
        </p> -->
        <div class="d-flex w-100 justify-content-end">
          <div>
            <button mat-flat-button color="primary" (click)="onImport($event)">
              <mat-spinner class="mr-2" *ngIf="started && !error" diameter="24"></mat-spinner>
              <span>{{ buttonText }}</span>
            </button>
          </div>
        </div>
      </kf-tile>
    </div>
  </div>

  <item-benefits></item-benefits>
</div>
