enum PredefinedColors {
  BrandPrimary = '--color-brand-primary',
  BrandSecondary = '--color-brand-secondary',
  BrandTertiary = '--color-brand-tertiary',
  InteractionPrimary = '--color-interaction-primary',
  InteractionSecondary = '--color-interaction-secondary',
  InteractionTertiary = '--color-interaction-tertiary',
  Link = '--color-link',
  Success = '--color-success',
  SuccessLight = '--color-success-light',
  Warning = '--color-warning',
  WarningLight = '--color-warning-light',
  Error = '--color-error',
  ErrorLight = '--color-error-light',
  Information = '--color-information',
  InformationLight = '--color-information-light',
  Neutral50 = '--color-neutral-50 ',
  Neutral100 = '--color-neutral-100',
  Neutral200 = '--color-neutral-200',
  Neutral300 = '--color-neutral-300',
  Neutral400 = '--color-neutral-400',
  Neutral500 = '--color-neutral-500',
  Neutral600 = '--color-neutral-600',
  Neutral700 = '--color-neutral-700',
  Neutral800 = '--color-neutral-800',
  Neutral900 = '--color-neutral-900',
  White = '--color-white',
  Black = '--color-black',
}

function colorFinder(name: PredefinedColors | string) {
  if (Object.values(<any>PredefinedColors).includes(name)) {
    return window.getComputedStyle(document.querySelector('html')).getPropertyValue(name);
  } else {
    return name;
  }
}

export const color = { find: colorFinder, name: PredefinedColors };

export enum UserRoleEnum {
  ContentAdmin = 'CONTENT_ADMIN',
  Consultant = 'CONSULTANT',
}

export enum FileTypes {
  IMAGE = '.png,.gif,.jpg,.jpeg,.svg,.webp',
  PDF = '.pdf',
  VIDEO = '.mp4,.mov,.mkv',
  DOC_IMAGE = '.png,.gif,.jpg,.jpeg',
  LOGO_IMAGE = '.png,.gif,.jpg,.jpeg',
}

export enum ConsultationStatusEnum {
  created = 'CREATED',
  inPreparation = 'IN_PREPARATION',
  preparationSent = 'PRELIMINARY_SEND',
  preparationDone = 'PRELIMINARY_FINISHED',
  mainConsultation = 'MAIN_CONSULTATION',
  checkoutDone = 'CHECKOUT_FINISHED',
  archived = 'ARCHIVED',
}

export enum EntityWorldEnum {
  'QuestionWorld',
  'ProductWorld',
  'Other',
}

export enum LicenseStatusEnum {
  active = 'LICENSED',
  notActive = 'NOT_ACTIVE_LICENSE',
  notValid = 'NOT_VALID_LICENSE',
  notLicensed = 'NOT_LICENSED',
}

export enum ContextEnum {
  configApp = 'configurator',
  bankHub = 'hub',
}

export enum MapperTypes {
  const = 'CONST',
  selectedProduct = 'SELECTED_PRODUCT_PROPERTY',
  generalDataField = 'GENERAL_DATA_FIELD_VALUE',
  productDataField = 'PRODUCT_DATA_FIELD_VALUE',
}

export enum SetupStatus {
  SETUP_NOT_DONE = 'SETUP_NOT_DONE',
  SETUP_DONE = 'SETUP_DONE',
}

export enum MatColorsEnum {
  primary = 'primary',
  accent = 'accent',
  warn = 'warn',
}

export enum VrIcons {
  add = 'vr-icon-add',
  addCircled = 'vr-icon-add-circled',
  addSquare = 'vr-icon-add-square',
  arrow45 = 'vr-icon-arrow-45',
  arrowDown = 'vr-icon-arrow-down',
  arrowDownSmall = 'vr-icon-arrow-down-small',
  arrowLeft = 'vr-icon-arrow-left',
  arrowLeftBig = 'vr-icon-misc-arrow-left-big',
  arrowLeftLarge = 'vr-icon-arrow-left-large',
  arrowRight = 'vr-icon-arrow-right',
  arrowRightBig = 'vr-icon-misc-arrow-right-big',
  arrowRightBold = 'vr-icon-arrow-right-bold',
  arrowRightLarge = 'vr-icon-arrow-right-large',
  arrowUp = 'vr-icon-arrow-up',
  calendar = 'vr-icon-calendar',
  calendar24 = 'vr-icon-calendar-24',
  camera = 'vr-icon-camera',
  chat = 'vr-icon-chat',
  check = 'vr-icon-check',
  checkCircled = 'vr-icon-check-circled',
  chevronDoubleLeft = 'vr-icon-double-chevron-left',
  chevronDoubleRight = 'vr-icon-double-chevron-right',
  chevronLeft = 'vr-icon-chevron-left',
  chevronRight = 'vr-icon-chevron-right',
  client = 'vr-icon-client',
  clock24 = 'vr-icon-clock-24',
  close = 'vr-icon-close',
  closeCircled = 'vr-icon-close-circled',
  compare = 'vr-icon-compare',
  consultation = 'vr-icon-misc-consultation',
  creditCard = 'vr-icon-sub-credit-card',
  delivery = 'vr-icon-sub-delivery',
  document = 'vr-icon-document',
  documentLink = 'vr-icon-document-link',
  documentPdf = 'vr-icon-document-pdf',
  documentPdfChecked = 'vr-icon-document-pdf-checked',
  documentPicture = 'vr-icon-document-picture',
  documentVideo = 'vr-icon-document-video',
  download24 = 'vr-icon-download-24',
  drag = 'vr-icon-drag',
  draw = 'vr-icon-draw',
  drawColors = 'vr-icon-draw-colors',
  drawErase = 'vr-icon-draw-erase',
  drawFont = 'vr-icon-draw-font',
  drawIndicator = 'vr-icon-draw-indicator',
  drawStroke = 'vr-icon-draw-stroke',
  duplicate = 'vr-icon-duplicate',
  edit = 'vr-icon-edit',
  erase = 'vr-icon-erase',
  expandLess = 'vr-icon-expand-less',
  expandMore = 'vr-icon-expand-more',
  help = 'vr-icon-help',
  home = 'vr-icon-home',
  house = 'vr-icon-house',
  info = 'vr-icon-info',
  link = 'vr-icon-external-link',
  list = 'vr-icon-list',
  mail = 'vr-icon-mail',
  mail24 = 'vr-icon-mail-24',
  menu = 'vr-icon-menu',
  more = 'vr-icon-more-h',
  none = '',
  note24 = 'vr-icon-note-24',
  notes = 'vr-icon-notes',
  notify = 'vr-icon-notify',
  number = 'number-icon',
  paperclip = 'vr-icon-paperclip',
  person24 = 'vr-icon-person-24',
  phone24 = 'vr-icon-phone-24',
  pin = 'vr-icon-pin',
  pinRemove = 'vr-icon-pin-remove',
  print = 'vr-icon-print',
  refresh = 'vr-icon-refresh-24',
  remove = 'vr-icon-remove',
  removeCircled = 'vr-icon-remove-circled',
  reset = 'vr-icon-reset',
  restore = 'vr-icon-restore',
  search = 'vr-icon-search',
  sendmail24 = 'vr-icon-sendmail-24',
  settings = 'vr-icon-settings',
  share = 'vr-icon-share',
  sort = 'vr-icon-sort',
  submit = 'vr-icon-submit',
  time = 'vr-icon-time',
  todoList = 'vr-icon-todo-list',
  transition = 'vr-icon-transition',
  transparency = 'vr-icon-transparency',
  trash = 'vr-icon-trash',
  trash24 = 'vr-icon-trash-24',
  untrash = 'vr-icon-untrash',
  upload24 = 'vr-icon-upload-24',
  user = 'vr-icon-user',
  userAdd = 'vr-icon-user-add',
  userData = 'vr-icon-user-data',
  users = 'vr-icon-users',
  userSearch = 'vr-icon-user-search',
  userSwitch = 'vr-icon-user-switch',
  view = 'vr-icon-view',
  viewOff = 'vr-icon-view-off',
  warning = 'vr-icon-warning',
  zoom = 'vr-icon-zoom',
  zoomOut = 'vr-icon-zoom-out',
}
