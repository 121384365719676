import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'mapper',
  templateUrl: './mapper.component.html',
  styleUrls: ['./mapper.component.scss'],
  standalone: false,
})
export class MapperComponent extends FieldArrayType implements OnInit {
  ngOnInit(): void {}

  public handleMapperTypeChange(ev: any): void {}

  public headerText(index: number): string {
    if (this.field.fieldGroup.length === 1) {
      return `Variable wird mit folgendem Wert gef${String.fromCharCode(252)}llt...`;
    }
    return `Variable ${index + 1} von ${this.field.fieldGroup.length} wird mit folgendem Wert gef${String.fromCharCode(
      252
    )}llt...`;
  }
}
