<div class="position-relative">
  <ng-template *ngTemplateOutlet="header"></ng-template>

  <div class="d-flex flex-column overflow-auto mb-3">
    <div class="d-flex flex-row">
      <div class="d-flex-flex-column flex-grow-1">
        <div class="m-3">
          <h4 *ngIf="!isLoading">Regelbezeichnung</h4>
          <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>

          <form *ngIf="!isLoading" #formDirective="ngForm" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div *ngIf="model.id" class="cursor-pointer">
              <mat-form-field class="w-100 disabled">
                <mat-label>UUID</mat-label>
                <input
                  class="disabled"
                  matInput
                  value="{{ model.id }}"
                  [cdkCopyToClipboard]="model.id.toString()"
                  (cdkCopyToClipboardCopied)="onClipboardCopy($event, model.id)"
                  readonly />
                <mat-icon
                  class="vr-icon-duplicate mr-3 vr-color-neutral-600 icon-clipboard p-0"
                  matSuffix
                  [cdkCopyToClipboard]="model.id.toString()"
                  (cdkCopyToClipboardCopied)="onClipboardCopy($event, model.id)"
                ></mat-icon
              ></mat-form-field>
            </div>
            <formly-form #formDirective [form]="form" [fields]="fields" [model]="model"></formly-form>
          </form>
          <div *ngIf="!readyForNextStep" class="d-flex align-items-center flex-row justify-content-between">
            <button
              type="button"
              (click)="onProceedToNextStep()"
              class="save-button mb-3"
              [disabled]="isButtonDisabled"
            >
              Weiter
            </button>
            <div>* Bitte Regelbezeichnung eingeben.</div>
          </div>

          <ng-container *ngIf="readyForNextStep">
            <h4 class="mt-4" *ngIf="!isLoading" [class.customized]="hasCustomizedRules">Regelzusammensetzung</h4>
          </ng-container>
          <div [hidden]="!readyForNextStep" class="overflow-auto mb-3">
            <app-rule-builder
              class="overflow-auto"
              [disabled]="!ruleEditable"
              [hidden]="isLoading"
              [ruleQueryInput]="model.id !== null ? model : ''"
              (newRule)="onSetNewRule($event)"
            ></app-rule-builder>
          </div>
          <div
            *ngIf="readyForNextStep && model.id === null && !readyForFinalStep"
            class="d-flex align-items-center flex-row justify-content-between"
          >
            <button
              type="button"
              (click)="ruleEditable && onProceedToFinalStep()"
              [class.cursor-pointer]="ruleEditable"
              [class.cursor-default]="!ruleEditable"
              class="save-button mb-3"
              [disabled]="newRule.ruleset.elements?.length === 0 || isButtonDisabled"
            >
              Weiter
            </button>
            <div>* Bitte Regel anlegen.</div>
          </div>
          <ng-container *ngIf="readyForFinalStep && !isLoading">
            <h4 class="mt-4 mb-3">Regelziel</h4>
            <form #formDirectiveTarget="ngForm" [formGroup]="targetForm" (ngSubmit)="onSubmit()">
              <formly-form
                #formDirectiveTarget
                [form]="targetForm"
                [fields]="targetFields"
                [model]="model"
              ></formly-form>
            </form>
          </ng-container>
        </div>
        <div
          *ngIf="readyForNextStep && readyForFinalStep"
          class="d-flex align-items-center flex-row justify-content-between mb-2"
        >
          <button
            *ngIf="readyForNextStep && readyForFinalStep"
            type="button"
            [class.cursor-pointer]="!isProdInstance"
            [class.cursor-default]="isProdInstance"
            (click)="!isProdInstance && onSubmit()"
            class="save-button ml-3"
            [disabled]="isLoading || isButtonDisabled"
          >
            Speichern
          </button>
          <div *ngIf="!model.id">* Bitte Ziele für die Regel auswählen.</div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editIcons>
  <ng-container *ngIf="model.id || copied">
    <div class="d-flex align-items-center mt-3">
      <div
        class="vr-icon-trash vr-icon-force-size mr-3 size vr-icons"
        [class.disabled]="isProdInstance"
        (click)="!isProdInstance && onDelete()"
        style="cursor: pointer"
        [title]="'Löschen'"
      ></div>
      <div
        class="vr-icon-reset mr-3 vr-icons"
        [class.disabled]="isProdInstance"
        (click)="!isProdInstance && onResetForm()"
        style="cursor: pointer"
        [title]="'Zurücksetzen'"
      ></div>
      <div
        class="vr-icon-duplicate mr-3 vr-icons"
        [class.disabled]="isProdInstance"
        (click)="!isProdInstance && onCopyRecord()"
        style="cursor: pointer"
        [title]="'Duplizieren'"
      ></div>

      <div
        class="mr-3 config-icon-save vr-icons"
        [class.disabled]="isProdInstance"
        (click)="!isProdInstance && onSubmit()"
        [class.cursor-pointer]="!isProdInstance"
        [class.cursor-default]="isProdInstance"
        [title]="'Speichern'"
      ></div>
      <div
        class="config-icon-add vr-icons"
        [class.disabled]="isProdInstance"
        (click)="!isProdInstance && onAddRule()"
        [class.cursor-pointer]="!isProdInstance"
        [class.cursor-default]="isProdInstance"
        [title]="'Neuer Eintrag'"
      ></div>
    </div>
  </ng-container>
</ng-template>

<ng-template #loadingSpinner>
  <div class="d-flex w-100 justify-content-center" [ngClass]="isLoading && 'd-inline'" style="display: none">
    <mat-spinner [ngClass]="isLoading && 'd-inline'" style="display: none"></mat-spinner>
  </div>
</ng-template>

<ng-template #header>
  <div class="fixed-header">
    <div class="d-flex px-3 pb-3 bg-white">
      <div class="w-100">
        <div class="flex-grow-1">
          <h3 *ngIf="title" class="title mt-3 vr-headline-200"><span *ngIf="!model.id">Neue </span>{{ title }}</h3>
          <div *ngIf="description" class="mb-3 text-truncate" [class.mt-3]="!title" title="{{ description }}">
            {{ description }}
          </div>
        </div>

        <ng-template *ngTemplateOutlet="editIcons"></ng-template>
      </div>
    </div>
    <div class="vr-icon-close vr-icon-force-size mt-1 mr-1 flex-shrink-0 close-button" (click)="onClose()"></div>
  </div>
</ng-template>
