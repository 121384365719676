<div class="d-flex">
  <vr-button
    *ngIf="hasPortalHeader"
    [icon]="stickyIconClass"
    [outline]="true"
    [preset]="'regular'"
    [textColor]="color.find(color.name.Neutral700)"
    (click)="onStickApp($event)"
  ></vr-button>
</div>
