@if (setupStatus) {
  <div class="d-flex flex-column h-100" style="min-height: 40rem">
    @switch (setupStatus) {
      @case (setupStatusType.SETUP_NOT_DONE) {
        <setup-none (updateSuccess)="handleUpdateSuccess()"></setup-none>
      }
      @case (setupStatusType.SETUP_DONE) {
        <setup-done></setup-done>
      }
    }
  </div>
}
