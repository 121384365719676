import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private apiService: ApiService) {}

  public async getMediaContent(url: string, renew: boolean = false, isChromium?: boolean): Promise<string> {
    try {
      const mediaData = await lastValueFrom(this.apiService.getMediaContent(url, renew));

      if (mediaData.type.includes('mp4') || mediaData.type.includes('pdf') || isChromium) {
        if (window.URL) {
          return window.URL.createObjectURL(mediaData);
        } else if (window.webkitURL) {
          return window.webkitURL.createObjectURL(mediaData);
        }
      }
      return (await this.blobToBase64(mediaData)) as string;
    } catch (error) {
      return '';
    }
  }

  private blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
