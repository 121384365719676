<ng-template *ngTemplateOutlet="header"></ng-template>

<mat-accordion>
  <ng-container *ngFor="let contractForm of contractForms; index as i" [formGroup]="contractForms[i]">
    <mat-expansion-panel #contractPanel [expanded]="newlyAdded && i === contractForms.length - 1">
      <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
        <div class="mt-3">
          <mat-panel-title>
            <h4>{{ contractVariableTitle(i) }}</h4>
          </mat-panel-title>
          <mat-panel-description>
            <p>{{ contractModel[i]?.displayName || '' }}</p>
          </mat-panel-description>
        </div>
      </mat-expansion-panel-header>
      <div *ngIf="contractPanel.expanded">
        <div class="d-flex flex-row my-3 prices">
          <div
            class="vr-icon-trash vr-icon-force mr-3 size vr-icons"
            (click)="onDeleteVariable(i)"
            style="cursor: pointer"
            [title]="'Löschen'"
          ></div>
          <div
            class="vr-icon-reset mr-3 vr-icons"
            (click)="onResetVariable(i)"
            style="cursor: pointer; background-color: #808080"
            [title]="'Zurücksetzen'"
          ></div>
          <div
            class="vr-icon-duplicate mr-3 vr-icons"
            (click)="onCopyVariable(i)"
            style="cursor: pointer"
            [title]="'Duplizieren'"
          ></div>
        </div>
        <form [formGroup]="contractForms[i]">
          <formly-form
            [fields]="mainFields[i]"
            [form]="contractForm"
            [model]="contractModel[i]"
            (modelChange)="handleMapperTypeSelection($event, i)"
          ></formly-form>
        </form>
      </div>
    </mat-expansion-panel>
    <hr />
  </ng-container>
</mat-accordion>

<ng-template *ngTemplateOutlet="editIcons"></ng-template>

<ng-template #editIcons>
  <ng-container *ngIf="contractForms.length < 1000 && !isLoading">
    <div style="cursor: pointer" class="d-flex flex-row align-items-center mt-3 add-price" (click)="onAddVariable()">
      <div class="vr-icon-add-circled mr-2"></div>
      <div>{{ title }} hinzufügen</div>
    </div>
  </ng-container>
</ng-template>

<ng-template #header>
  <div class="header">
    <div class="d-flex pr-3 pb-3 bg-white">
      <div class="w-100">
        <div class="flex-grow-1">
          <h3 *ngIf="title" class="title mt-3 vr-headline-200">{{ title }}</h3>
          <div *ngIf="description" class="mb-3" [class.mt-3]="!title">{{ description }}</div>
          <div class="mt-3" *ngIf="contractForms.length === 0">{{ emptyMessage }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
