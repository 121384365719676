import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { color } from '@enums';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/config.service';
import { EntityService } from '@services/entity.service';
import { InstanceService } from '@services/instance.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface BreadCrumbElement {
  resource: string;
  targetRef?: string;
}

export interface BreadCrumbsNavElement {
  title: string;
  targetEntity: string;
  targetId: string;
}

@Component({
  selector: 'app-breadcrumb-navigation',
  templateUrl: './breadcrumb-navigation.component.html',
  styleUrls: ['./breadcrumb-navigation.component.scss'],
  standalone: false,
})
export class BreadcrumbNavigationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() currentModel;
  @Input() currentEntity: string = '';
  @Output() jumpEvent: EventEmitter<any> = new EventEmitter();

  private alive = new Subject<void>();
  private entityIndex = 0;
  private breadCrumbData: any[] = [];
  private connectedEntities = [
    { resource: 'AnswerOptionContentResource', targetRef: 'questionId' },
    { resource: 'QuestionContentResource', targetRef: 'questionGroupId' },
    { resource: 'QuestionGroupContentResource', targetRef: 'subtopicId' },
    { resource: 'SubtopicContentResource', targetRef: 'topicId' },
    { resource: 'TopicContentResource' },
  ];

  public breadCrumbsNav: BreadCrumbsNavElement[] = [];

  readonly color = color;

  constructor(
    private apiService: ApiService,
    private configService: ConfigService,
    private entityService: EntityService,
    private instanceService: InstanceService
  ) {}

  ngOnInit(): void {
    this.instanceService.instanceChanged.pipe(takeUntil(this.alive)).subscribe(x => {
      this.prepareNavigationData();
    });

    this.entityService.entityTrigger.pipe(takeUntil(this.alive)).subscribe(newEntity => {
      this.prepareNavigationData();
    });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentModel'] && this.breadCrumbData.length > 0) {
      this.createNavigation();
    }
  }

  private prepareNavigationData(): void {
    this.entityIndex = this.connectedEntities.indexOf(
      this.connectedEntities.find(x => x.resource === this.currentEntity)
    );

    const requests = [];

    for (let i = this.entityIndex + 1; i < this.connectedEntities.length; i++) {
      requests.push(this.apiService.get(this.configService.getEndpoint(this.connectedEntities[i].resource)));
    }

    forkJoin(requests).subscribe(data => {
      this.breadCrumbData = data;
      if (this.currentModel) {
        this.createNavigation();
      } else {
        this.breadCrumbsNav = [];
      }
    });
  }

  private createNavigation(): void {
    this.breadCrumbsNav = [];

    let index = this.entityIndex;
    let currentId = this.currentModel[this.connectedEntities[index].targetRef];

    for (let entity = 0; entity < this.breadCrumbData.length; entity++) {
      const element = this.breadCrumbData[entity].find(x => x.id === currentId);
      if (element) {
        const obj: BreadCrumbsNavElement = {
          title: `${element?.displayName}`,
          targetId: element?.id,
          targetEntity: this.connectedEntities[index + 1].resource,
        };
        this.breadCrumbsNav.push(obj);
        index++;

        currentId = element[this.connectedEntities[index]?.targetRef];
      }
    }
    this.breadCrumbsNav = [...this.breadCrumbsNav.reverse()];
  }

  public getModelDisplayName(): string {
    if (this.currentModel.displayName && this.currentModel.id) {
      return `${this.currentModel.displayName}`;
    } else {
      return '';
    }
  }

  public jumpToEntity(element: BreadCrumbsNavElement): void {
    this.jumpEvent.emit(element);
  }
}
