import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormType } from '@app/ui-library/vr-form-base/vr-form-base.component';
import { color, FileTypes } from '@enums';
import { environment } from '@environment/environment';
import { ApiService } from '@services/api.service';
import { InstanceService } from '@services/instance.service';
import { UNDTicketResponse } from '@utils/domains';
import { finalize, tap } from 'rxjs/operators';

const umlautMap = {
  Ü: 'Ue',
  Ä: 'Ae',
  Ö: 'Oe',
  ü: 'ue',
  ä: 'ae',
  ö: 'oe',
  ß: 'ss',
};
@Component({
  selector: 'upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss'],
  standalone: false,
})
export class UploadFormComponent implements OnChanges {
  constructor(
    private apiService: ApiService,
    private domSanitizer: DomSanitizer,
    private instanceService: InstanceService
  ) {}

  @ViewChild('uploadComponent', { static: false }) uploadComponent;
  @Input() fileType;
  @Input() hasValidType;
  @Input() model;
  @Input() startUpload = false;
  @Output() newFile = new EventEmitter();
  @Output() dataChanged = new EventEmitter();

  private file: File;
  private ticket: UNDTicketResponse = null;

  public localFileType = '';
  public uploadOngoing = false;
  public hasType = false;
  public previewUrl;

  public submitDisabled = true;

  readonly formType = FormType;
  readonly color = color;

  ngOnChanges(changes: SimpleChanges) {
    this.localFileType = this.fileType;
    if ((changes['model'] || changes['fileType']) && this.uploadComponent) {
      this.uploadComponent.documents = [];
      this.uploadComponent.file = [];
      this.resetData();
    }
  }

  public setFile(file: File[]) {
    if (file?.length > 0) {
      this.file = file[0];
      this.getUrl();

      if (environment.platform === 'vp') {
        this.apiService.getUNDTicket().subscribe(ticket => {
          this.ticket = ticket;
          this.submitDisabled = false;
        });
      } else {
        this.submitDisabled = false;
      }
    } else {
      this.resetData();
    }
  }

  public onSubmit(ev?: Event) {
    ev?.preventDefault();
    if (!this.model.adminName || !this.model.displayName) {
      return;
    }
    const cleanedName = this.model.adminName;

    if (this.file) {
      this.uploadOngoing = true;

      const file: File = new File([this.file], this.cleanString(this.file.name, true));
      const formData = new FormData();
      formData.append('file', file);

      if (environment.platform === 'aws') {
        this.handleFileUploadAWS(formData, cleanedName);
      } else if (environment.platform === 'vp') {
        this.handleFileUploadVP(formData, cleanedName);
      }
    } else {
      this.dataChanged.emit({ adminName: cleanedName });
    }
  }

  private handleFileUploadAWS(formData: FormData, cleanedName: string) {
    this.apiService
      .postUploadFile('content/medias/upload', formData)
      .pipe(
        tap(response => {
          this.dataChanged.emit({ url: response.url, name: cleanedName });
        }),
        finalize(() => {
          this.uploadOngoing = false;
        })
      )
      .subscribe();
  }

  private handleFileUploadVP(formData: FormData, cleanedName: string) {
    // formData.append('ticket', JSON.stringify(this.ticket));
    formData.append('ticket', new Blob([JSON.stringify(this.ticket)], { type: 'application/json' }));
    this.apiService
      .postUNDUploadFile(formData)
      .pipe(
        tap(data => {
          this.apiService
            .postUNDDataToBackend(data)
            .pipe(
              tap(data => {
                this.dataChanged.emit({ url: data.url, name: cleanedName });
              })
            )
            .subscribe();
        }),
        finalize(() => {
          this.uploadOngoing = false;
        })
      )
      .subscribe();
  }

  private cleanString(string, isFile = false) {
    let fileType = '';

    if (isFile) {
      fileType = this.file.type.split('/')[1];
      const stringArray = string.split('.');
      const stringIndexes = stringArray.length - 1;
      if (fileType === stringArray[stringIndexes]) {
        stringArray.pop();
        string = stringArray.join();
      }
    }

    let cleanString = string
      .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, a => {
        const big = umlautMap[a.slice(0, 1)];
        return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
      })
      .replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'), a => umlautMap[a])
      .replace(/\s/g, '')
      .replace(/[&`´'?=;§!\|\@\/\\#,+()$~%.'":*?<>{}]/g, '');

    if (fileType) {
      cleanString = `${cleanString}.${this.getFileType(fileType)}`;
    }

    return cleanString;
  }

  private getFileType(type: string): string {
    if (type === 'x-matroska') {
      return 'mkv';
    }
    if (type === 'svg+xml') {
      return 'svg';
    }
    return type;
  }

  private getUrl() {
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = event => {
      const allowedImageTypes = FileTypes.IMAGE.split(',');

      this.previewUrl = this.file.type.includes('image')
        ? this.domSanitizer.bypassSecurityTrustUrl(event.target.result.toString())
        : '';
    };
  }

  private resetData() {
    this.file = undefined;
    this.previewUrl = undefined;
    this.submitDisabled = true;
  }

  get isProdInstance() {
    return this.instanceService.isProdInstance;
  }

  get submitEnabled(): boolean {
    return this.model.adminName && this.model.displayName && this.model.type;
  }
}
