import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConfigService } from '@services/config.service';
import { EntityService } from '@services/entity.service';
import { InstanceService } from '@services/instance.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  standalone: false,
})
export class RichTextEditorComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Output() editedDescription = new EventEmitter();

  private newInput = new BehaviorSubject<any>('');
  private alive = new Subject<void>();

  public descriptionExists = false;
  public initialModel: string;
  public customized: boolean = false;
  public editorControl = new FormControl();

  public EditorConfig = {
    base_url: '/tinymce',
    suffix: '.min',
    license_key: 'T7LK7464E4DC01DF0F8075464E29EAAFF96DE546A1BF2036375F0840DD163A13',
    language_url: '/assets/i18n/tinyMCE/de.js',
    language: 'de',
    plugins: ['link', 'lists', 'autoresize', 'help'],
    toolbar: 'styles bold italic link | outdent indent bullist numlist blockquote undo redo',
    style_formats: [
      { title: 'Absatz', block: 'p' },
      { title: 'Überschrift 1', block: 'h2' },
      { title: 'Überschrift 2', block: 'h3' },
      { title: 'Überschrift 3', block: 'h4' },
    ],
    menubar: false,
    statusbar: false,
  };

  constructor(
    private entityService: EntityService,
    private configService: ConfigService,
    private instanceService: InstanceService
  ) {}

  async ngOnInit(): Promise<void> {
    this.entityService.currentModel.pipe(takeUntil(this.alive)).subscribe(model => {
      if (Object.keys(model).length > 0) {
        if (model['description']) {
          this.initialModel = model['description'];
          this.editorControl.setValue(model['description']);
          this.customized = model['metadata'] && model['metadata']['syncExcludedFields']?.includes('description');
        } else {
          this.editorControl.setValue('');
        }
      } else {
        this.editorControl.setValue('');
      }
    });

    this.instanceService.instanceChanged.pipe(takeUntil(this.alive)).subscribe(() => {
      this.editorControl.setValue('');
    });

    this.entityService.currentEntity.pipe(takeUntil(this.alive)).subscribe(entity => {
      this.descriptionExists = false;
      if (
        entity !== 'RuleContentResource' &&
        entity !== 'InformationConfigResource' &&
        entity !== 'InformationContentResource'
      ) {
        const properties =
          this.configService.openApiArray[this.configService.hubIndex]['components']['schemas'][entity].properties;
        for (const key in properties) {
          if (key === 'description') {
            this.descriptionExists = true;
          }
        }
      }
    });

    this.newInput
      .pipe(debounceTime(100), distinctUntilChanged())
      .pipe(takeUntil(this.alive))
      .subscribe(data => {
        this.editedDescription.emit(data.trim());
      });

    this.entityService.modelToDefault.pipe(takeUntil(this.alive)).subscribe(reset => {
      this.editorControl.setValue(this.initialModel);
    });

    this.editorControl.valueChanges.pipe(takeUntil(this.alive)).subscribe(text => {
      this.newInput.next(text);
    });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.unsubscribe();
  }
}
