import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/config.service';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tool-form',
  templateUrl: './tool-form.component.html',
  styleUrls: ['./tool-form.component.scss'],
})
export class ToolFormComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private toastrService: ToastrService,
    private configService: ConfigService,
    private readonly formBuilder: FormBuilder
  ) {}

  @Input() chosenAttributes;
  @Input() type: string;
  @Output() innerFormChanged = new EventEmitter();

  public formGroups: FormGroup[] = [];
  public isLoading = false;
  public newlyAdded = false;
  public rowsAnswerOptions = [];
  public rowsDataField = [];

  async ngOnInit() {
    this.createFormGroups();

    const data = await lastValueFrom(
      this.apiService.get(this.configService.getEndpoint('answerOptionContentResource'))
    );
    this.rowsAnswerOptions = data.map(x => (x = { contentId: x.contentId, name: x.adminName }));

    const rows = await lastValueFrom(this.apiService.get(this.configService.getEndpoint('dataFieldContentResource')));
    this.rowsDataField = rows.map(x => (x = { contentId: x.contentId, name: x.adminName }));
  }

  getToolTitle(tool) {
    return tool.controls.name.value === '' ? 'Neues Werkzeug' : tool.controls.name.value;
  }

  public onDeleteAttribute(i: number): void {
    this.formGroups = this.formGroups.filter((element, index) => index !== i);
    this.toastrService.warning('Element gelöscht. Eintrag speichern, um Änderung zu übernehmen');
    this.emitData();
  }

  public emitData(event?: MatSelectChange, index?: number): void {
    if (event) {
      this.formGroups[index].patchValue({
        answerOptionContentId: null,
        dataFieldContentId: null,
        value: null,
      });
    }
    this.innerFormChanged.emit(this.formGroups);
  }

  private createFormGroups() {
    this.formGroups = [];
    if (this.chosenAttributes) {
      for (let i = 0; i < this.chosenAttributes.length; i++) {
        this.addFormGroup(
          this.chosenAttributes[i].name,
          this.chosenAttributes[i].type,
          this.chosenAttributes[i].answerOptionContentId,
          this.chosenAttributes[i].dataFieldContentId,
          this.chosenAttributes[i].value
        );
      }
    }
    this.isLoading = false;
  }

  private addFormGroup(name, type, answerOptionContentId?, dataFieldContentId?, value?) {
    const group = this.formBuilder.group({
      name: new FormControl<string | null>(name),
      type: new FormControl<string | null>(type),
      answerOptionContentId: new FormControl<string | null>(answerOptionContentId),
      dataFieldContentId: new FormControl<string | null>(dataFieldContentId),
      value: new FormControl<string | null>(value),
    });

    this.formGroups.push(group);
  }

  public addTool() {
    this.addFormGroup('', null);
    this.emitData();
    this.newlyAdded = true;
  }
}
