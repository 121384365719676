import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss'],
})
export class ConditionsComponent extends FieldArrayType implements OnInit {
  ngOnInit(): void {}

  public handleConditionTypeChange(ev: any): void {
    console.log(ev);
  }

  public headerText(index: number): string {
    if (this.field.fieldGroup.length === 1) {
      return 'Wenn...';
    }
    return `Wenn Bedingung ${index + 1} von ${this.field.fieldGroup.length}...`;
  }
}
