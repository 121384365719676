// this can not be a normal interceptor, since the HttpClient used for getting
// theme related items are from the KF-Theme, and tehefore not accessible from
// the main app. A bit hacky, but it works.
// This also relies on a the icons taken from the kf-theme repository
export class ThemeInterceptor {
  constructor() {
    const intercept = (XHR: any): any => {
      const self = this;
      const open = XHR.prototype.open;
      XHR.prototype.open = function (method: string, url: string, async: boolean, user: string, pass: string): void {
        if (self.svgMap.has(url)) {
          self.svgMap.get(url);
        }

        if (url.includes('ets?f=')) {
          let path = url.split('f=')[1];
          path = path.replace(/&a/g, '?a');

          if (path.endsWith('.svg')) {
            self.svgMap.set(url, open.call(this, 'GET', path, true, user, pass));
          }

          open.call(this, 'GET', path, true, user, pass);
        } else {
          open.apply(this, arguments);
        }
      };
    };
    intercept.apply(null, [XMLHttpRequest]);
  }

  private svgMap = new Map<string, any>();
}
