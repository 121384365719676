import { NgModule } from '@angular/core';
import { KFThemeCommonModule } from '@de.fiduciagad.kundenportal/kf-theme/common';
import { KfThemeHeadlineModule } from '@de.fiduciagad.kundenportal/kf-theme/headline';
import { KFIconModule } from '@de.fiduciagad.kundenportal/kf-theme/icon';
import { KFImgModule } from '@de.fiduciagad.kundenportal/kf-theme/image';
import { KfTileModule } from '@de.fiduciagad.kundenportal/kf-theme/tile';

@NgModule({
  exports: [KFIconModule, KFThemeCommonModule, KfThemeHeadlineModule, KfTileModule, KFImgModule],
})
export class KfThemeModule {}
