@if (loading === 0) {
  @if (title) {
    <div class="title mt-3" [class.customized]="changesInRefs">{{ title }}</div>
  }
  @if (description) {
    <div class="mb-3">{{ description }}</div>
  }
  @if (!models?.length) {
    <div class="mt-3">{{ emptyMessage }}</div>
  }
}

@for (model of models; track model; let i = $index) {
  <div [hidden]="loading > 0" [formGroup]="form[i]">
    <mat-accordion>
      <mat-expansion-panel [expanded]="newlyAdded && i === models.length - 1">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <div class="mt-3">
            <mat-panel-title>
              <h4 [class.customized]="hasCustomizedField(model)" [class.entry-inactive]="hasClassInactive(model)">
                {{ model.model?.displayName || getExpansionDescription(model.model) || 'Neues Element' }}
              </h4>
            </mat-panel-title>
            <mat-panel-description>
              <p>{{ getModelId(i) }}</p>
            </mat-panel-description>
          </div>
        </mat-expansion-panel-header>
        <div>
          <div class="d-flex flex-row my-3 prices">
            <div
              class="vr-icon-trash vr-icon-force mr-3 size vr-icons"
              (click)="deleteButtonClickedPrice(i)"
              style="cursor: pointer"
              [title]="'Löschen'"
            ></div>
            <div
              class="vr-icon-reset mr-3 vr-icons"
              style="cursor: pointer"
              [title]="'Zurücksetzen'"
              (click)="resetFormPrice(i)"
            ></div>
            @if (deactivateDuplicateButton()) {
              <div
                class="vr-icon-duplicate mr-3 vr-icons"
                style="cursor: pointer"
                [title]="'Duplizieren'"
                (click)="copyRecordPrice(i)"
              ></div>
            }
          </div>
          <form [formGroup]="form[i]">
            <formly-form
              [form]="form[i]"
              [fields]="models[i].fields"
              [model]="models[i].model"
              (modelChange)="[handleModelChange($event, i), triggerDebounce($event)]"
            ></formly-form>
          </form>
        </div>
        @if (referenceContentResource === 'AnswerOptionContentResource') {
          <div class="ml-4 mt-4">
            <app-answer-form
              [chosenAttributes]="models[i].model"
              [inQuestion]="true"
              (innerFormChanged)="setAnswerId(i, $event, true)"
            ></app-answer-form>
            <app-attribute-form
              [chosenAttributes]="models[i].model.attributeRefs"
              [inQuestion]="true"
              (innerFormChanged)="setAttribute(i, $event)"
            ></app-attribute-form>
          </div>
        }
      </mat-expansion-panel>
    </mat-accordion>
    <hr />
  </div>
}

@if (loading) {
  <div class="d-flex w-100 justify-content-center mt-4">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
} @else if (!models || models.length < limitEntries) {
  <div style="cursor: pointer" class="d-flex flex-row align-items-center mt-3 add-price" (click)="addPrice()">
    <div class="vr-icon-add-circled mr-2"></div>
    <div>
      {{ referenceContentResource === 'DataFieldGroupProfileContentResource' ? 'Zum Profil' : title }} hinzufügen
    </div>
  </div>
}
