import { InjectionToken } from '@angular/core';

export const USERBACK = new InjectionToken<Userback>('Userback');

/**
 * @see https://help.userback.io/hc/en-us/articles/115005681028-Javascript-API
 */
export interface Userback {
  /**
   * The show method displays the Userback Widget.
   */
  show: () => void;

  /**
   * The hide method closes the Userback Widget interface and hides it.
   */
  hide: () => void;

  /**
   * The open method opens the Userback Widget interface in a modal dialog. If you specify a "mode", the widget will be
   * opened into the mode directly.
   *
   * The open method is to be used when widget auto hide is turned on.
   */
  open: (openMethod?: 'form' | 'capture' | 'video' | 'feedback') => void;

  /**
   * The close method closes the Userback Widget interface in exactly the same way as when the widget close button is
   * clicked.
   */
  close: () => void;

  /**
   * Set the default email. The email field will be auto-filled in the feedback form.
   */
  email: string;

  /**
   * Set the default name. The name field will be auto-filled in the feedback form.
   */
  name: string;

  /**
   * Set categories in a comma-separated string
   */
  categories: string;

  /**
   * Set custom data to be sent along with the feedback form. The custom data will be display on the feedback page.
   */
  custom_data: object;
}
