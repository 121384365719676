import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/de';
import { ConfigService } from '@services/config.service';
import { EntityService } from '@services/entity.service';
import { InstanceService } from '@services/instance.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { config } from './toolbarConfig';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent implements OnInit, OnDestroy {
  @Input() disabled = false;
  @Output() editedDescription = new EventEmitter();
  @Output() editedBody = new EventEmitter();

  private newInput = new BehaviorSubject<any>('');
  private alive = new Subject<void>();

  public descriptionExists = false;
  public focused = false;
  public newData = '';
  public data = '';
  public initialModel;
  public config = config;
  public customized: boolean = false;

  public Editor = ClassicEditor;
  public EditorConfig = {
    language: 'de',
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      '|',
      'link',
      '|',
      'outdent',
      'indent',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'blockQuote',
      '|',
      'undo',
      'redo',
      '|',
    ],
    link: {
      // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
      addTargetToExternalLinks: true,
    },
    removePlugins: [
      'CKBox',
      'CKFinder',
      'CKFinderUploadAdapter',
      'EasyImage',
      'Image',
      'ImageCaption',
      'ImageStyle',
      'ImageToolbar',
      'ImageUpload',
      'MediaEmbed',
      'PictureEditing',
      'Table',
      'TableToolbar',
      'TextTransformation',
    ],
  };

  constructor(
    private entityService: EntityService,
    private configService: ConfigService,
    private instanceService: InstanceService
  ) {}

  async ngOnInit(): Promise<void> {
    this.entityService.currentModel.pipe(takeUntil(this.alive)).subscribe(model => {
      if (Object.keys(model).length > 0) {
        if (model['description']) {
          this.initialModel = model['description'];
          this.newData = model['description'];
          this.customized = model['metadata'] && model['metadata']['syncExcludedFields']?.includes('description');
        } else {
          this.newData = '';
        }
      } else {
        this.newData = '';
      }
    });

    this.instanceService.instanceChanged.pipe(takeUntil(this.alive)).subscribe(() => {
      this.newData = '';
    });

    this.entityService.currentEntity.pipe(takeUntil(this.alive)).subscribe(entity => {
      this.descriptionExists = false;
      if (
        entity !== 'RuleContentResource' &&
        entity !== 'InformationConfigResource' &&
        entity !== 'InformationContentResource'
      ) {
        const properties =
          this.configService.openApiArray[this.configService.hubIndex]['components']['schemas'][entity].properties;
        for (const key in properties) {
          if (key === 'description') {
            this.descriptionExists = true;
          }
        }
      }
    });

    this.newInput
      .pipe(debounceTime(100), distinctUntilChanged())
      .pipe(takeUntil(this.alive))
      .subscribe(data => {
        this.editedDescription.emit(data.trim());
      });

    this.entityService.modelToDefault.pipe(takeUntil(this.alive)).subscribe(reset => {
      if (this.newData) {
        this.newData = this.initialModel;
      }
    });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.unsubscribe();
  }

  onChange(event) {
    const data = event.editor.getData();
    if (data?.replace(/<[^>]*>/g, '') !== this.data || data === '') {
      this.newInput.next(data);
    }
  }
}
