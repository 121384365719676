<div class="my-3" [class.title]="!inQuestion" [class.title-question]="inQuestion" [class.customized]="changesInRefs">
  Attribute
</div>
@if (!chosenAttributes?.length && formGroups?.length === 0) {
  <div class="mt-3">Noch keine Attribute vorhanden</div>
}
@if (isLoading) {
  <!--LoadingSpinner-->
  <div class="d-flex w-100 justify-content-center">
    <mat-spinner [ngClass]="'d-inline'"></mat-spinner>
  </div>
} @else {
  @for (form of formGroups; track form; let i = $index) {
    <!--Atribute-->
    @if (attributeSelection) {
      <form [formGroup]="form">
        <mat-accordion>
          <!--Atribute-Header -->
          <mat-expansion-panel [expanded]="newlyAdded && i === formGroups.length - 1">
            <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
              <div class="mt-3">
                <mat-panel-title>
                  <h4 [class.in-question]="inQuestion">
                    {{ getAttributeTitle(form) }}
                  </h4>
                </mat-panel-title>
                <mat-panel-description>
                  <p>{{ getAttributeId(form) }}</p>
                </mat-panel-description>
              </div>
            </mat-expansion-panel-header>
            <div class="d-flex flex-row my-3 prices">
              <div
                class="vr-icon-trash vr-icon-force-size mr-3 size vr-icons"
                (click)="onDeleteAttribute(i)"
                style="cursor: pointer"
                [title]="'Löschen'"
              ></div>
            </div>
            <!--Attribute-Name-->
            <div class="w-100">
              <ng-select
                [placeholder]="'Attributname'"
                [appendTo]="'body'"
                [virtualScroll]="false"
                [clearable]="false"
                [notFoundText]="'Kein Ergebnis'"
                [formControlName]="'name'"
                (change)="handleAttributeNameChange($event, i)"
              >
                <ng-option [value]="'_newValue'">Automat. Attribut generieren</ng-option>
                <ng-option [value]="'_newAttribute'">Neues Attribut anlegen</ng-option>
                @for (attribute of attributeSelection; track attribute) {
                  <ng-option [value]="attribute.name">
                    {{ attribute.name }}
                  </ng-option>
                }
              </ng-select>
            </div>
            <!--New Attribute-Name-->
            <mat-form-field [class.d-none]="!isCustomAttribute(i)" class="w-100">
              <mat-label>Neuer Name</mat-label>
              <input [formControlName]="'customName'" matInput (blur)="emitData()" />
            </mat-form-field>
            <!--Attribute-Value-->
            <div class="w-100">
              <ng-select
                [placeholder]="form.controls.name.value === '_newValue' ? 'Wird automatisch angelegt' : 'Attributwert'"
                [appendTo]="'body'"
                [virtualScroll]="false"
                [notFoundText]="'Kein Ergebnis'"
                [formControlName]="'value'"
                [clearable]="false"
                [disabled]="form.controls.name.value === '_newAttribute'"
                (change)="handleAttributeValueChange($event, i)"
              >
                @if (form.controls.name.value !== '_newValue' || form.controls.name.value !== '_newAttribute') {
                  <ng-option [value]="'_newValue'">Automat. Wert generieren</ng-option>
                }
                <ng-option [value]="'customValue'"> Manuell neuen Wert erstellen </ng-option>
                @for (option of this.getOptions(form.controls.name.value); track option) {
                  <ng-option [value]="option.value">
                    {{ option.name }}
                  </ng-option>
                }
              </ng-select>
            </div>
            <!--Custom-Value-->
            @if (form.controls.value.value === 'customValue') {
              <mat-form-field class="w-100">
                <mat-label>Neuer Wert</mat-label>
                <input type="text" [formControlName]="'customValue'" matInput
              /></mat-form-field>
            }
          </mat-expansion-panel>
        </mat-accordion>
        <hr />
      </form>
    }
  }
  <!--Add Attribute Button-->
  <div style="cursor: pointer" class="d-flex flex-row align-items-center mt-3 add-price" (click)="addAttribute()">
    <div class="vr-icon-add-circled mr-2"></div>
    <div>Attribut hinzufügen</div>
  </div>
}
