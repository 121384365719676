<div class="pb-3 h-100">
  <div class="d-flex flex-row" [style.width.vw]="34" [style.overflow-x]="'auto'">
    <div class="d-flex mb-3 position-relative flex-grow-1" [class.grayscale]="disabled" style="min-height: 30%">
      @if (disabled) {
        <div class="disabled-querybuilder"></div>
      }
      @if (config) {
        <query-builder #queryBuilder [(ngModel)]="query" [config]="config" (ngModelChange)="updateView()">
          <ng-container
            *queryButtonGroup="
              let ruleset;
              let addRule = addRule;
              let addRuleSet = addRuleSet;
              let removeRuleSet = removeRuleSet
            "
          >
            <button mat-button (click)="addRule()">+ Attribut</button>
            <button mat-button (click)="addRuleSet()">+ Verschachtelung</button>
            @if (removeRuleSet) {
              <button mat-button (click)="removeRuleSet()">- Verschachtelung</button>
            }
          </ng-container>
          <ng-container *queryRemoveButton="let rule; let removeRule = removeRule">
            <button type="button" mat-icon-button color="accent" (click)="removeRule(rule)">
              <div class="config-icon-remove"></div>
            </button>
          </ng-container>
          <ng-container *querySwitchGroup="let ruleset">
            @if (ruleset) {
              <mat-radio-group [(ngModel)]="ruleset.condition" (ngModelChange)="updateView()">
                <mat-radio-button class="mr-5" value="and">Und</mat-radio-button>
                <mat-radio-button value="or">Oder</mat-radio-button>
              </mat-radio-group>
            }
          </ng-container>
          <ng-container *queryEmptyWarning>
            <div class="p-3">
              Eine Verschachtelung darf nicht leer sein. Bitte füge ein Attribut hinzu oder entferne die
              Verschachtelung.
            </div>
          </ng-container>
          <ng-container *queryOperator="let rule; let operators = operators">
            <div class="operator ml-3">
              <mat-form-field style="width: 100px">
                <mat-select [(ngModel)]="rule.operator" (ngModelChange)="updateView()">
                  @for (value of operators; track value) {
                    <mat-option [value]="value">{{ value === '=' ? 'Wenn' : 'Wenn nicht' }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
          <ng-container *queryField="let rule; let fields = fields; let onChange = onChange">
            <div class="field d-flex align-items-center">
              <div class="ml-3" style="width: 9vw">
                <ng-select
                  [(ngModel)]="rule.field"
                  [clearable]="false"
                  [placeholder]="'Attributname'"
                  [appendTo]="'body'"
                  [virtualScroll]="false"
                  (ngModelChange)="updateView(rule)"
                  [notFoundText]="'Kein Ergebnis'"
                >
                  @for (field of fields; track field) {
                    <ng-option [value]="field.name">
                      <span [title]="field.name">{{ field.name }}</span>
                    </ng-option>
                  }
                </ng-select>
              </div>
              <div class="ml-3">=</div>
            </div>
          </ng-container>
          <ng-container *queryInput="let rule; let field = field; let options = options; type: 'category'">
            <div class="field align-items-center">
              <div class="ml-3" style="width: 9vw">
                <ng-select
                  [(ngModel)]="rule.value"
                  [clearable]="false"
                  [placeholder]="'Attributwert'"
                  [appendTo]="'body'"
                  [virtualScroll]="false"
                  (ngModelChange)="updateView()"
                  [notFoundText]="'Kein Ergebnis'"
                >
                  @for (option of getOptionsForField(rule.field); track option) {
                    <ng-option [value]="option.name">
                      <span [title]="option.name">{{ option.name }}</span>
                    </ng-option>
                  }
                </ng-select>
              </div>
            </div>
          </ng-container>
        </query-builder>
      }
    </div>
  </div>
</div>
