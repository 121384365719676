import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { INavigationService } from '@de.fiduciagad.kundenportal/ui-context';
import { environment } from '@environment/environment';
import { ApiService } from '@services/api.service';
import { PortalContextProvider } from '@services/portal-context.provider';
import { TenantService } from '@services/tenant.service';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  private navigationService: INavigationService;
  public currentUrl: string;

  private activeLink;

  constructor(
    private contextProvider: PortalContextProvider,
    private locationService: Location,
    private router: Router,
    private api: ApiService,
    private tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this.router.initialNavigation();
    this.router.events
      .pipe(
        filter(routerEvent => (routerEvent as RouterEvent) instanceof NavigationEnd),
        first(Boolean)
      )
      .subscribe((value: NavigationEnd) => {
        this.activeLink = value.urlAfterRedirects.replace(/^\//, '');
      });

    if (environment.platform === 'aws') {
      this.router.navigate(['configurator']);
    }

    if (environment.platform === 'vp') {
      this.tenantService.userRole = ['CONTENT_ADMIN', 'CONSULTANT'];
    }

    if (environment.useUserback) {
      const s = document.createElement('script');
      s.async = true;
      s.src = 'https://static.userback.io/widget/v1.js';
      (document.head || document.body).appendChild(s);
    }

    if (environment.platform === 'vp' && environment.isLocal && window.location.pathname.includes('/bws')) {
      const elements = document.getElementsByTagName('bgzv-frontend-admin');
      const element = elements.length > 0 ? elements[0] : null;
      if (element) {
        element.classList.add('bws-local');
      }
    }

    // if (environment.platform === 'vp') {
    //   setTimeout(() => {
    //     if (document.querySelector('.theme-initialization')) {
    //       console.log('theme initialization still not done - remove it');
    //       document.querySelector('.theme-initialization').classList.remove('theme-initialization');
    //     }
    //   }, 8000);
    // }
  }

  // ngAfterViewChecked(): void {
  //   if (document.querySelector('.theme-initialized')) {
  //     return;
  //   }
  //   if (document.querySelector('#theme-styles')) {
  //     document.querySelector('#theme-styles').remove();
  //   }

  //   if (document.querySelector('#theme-styles-full')) {
  //     document.querySelector('#theme-styles-full').remove();
  //   }

  //   if (document.querySelector('#theme-colors')) {
  //     this.handleConfigColors();
  //   }
  // }

  // private handleConfigColors() {
  //   let primary;
  //   let secondary;

  //   const hexToRgb = hex =>
  //     hex
  //       .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
  //       .substring(1)
  //       .match(/.{2}/g)
  //       .map(x => parseInt(x, 16));

  //   if (environment.platform === 'aws') {
  //     this.router.navigate(['configurator']);
  //   } else if (environment.platform === 'vp') {
  //     const themeColors = document.querySelector('#theme-colors') as HTMLStyleElement;
  //     const rules = themeColors.sheet.cssRules[0] as CSSStyleRule;
  //     if (rules?.style) {
  //       primary = rules.style.getPropertyValue('--color-primary-500').trim();
  //       secondary = rules.style.getPropertyValue('--color-accent-500').trim();
  //       themeColors.remove();
  //     } else {
  //       return;
  //     }
  //   }

  //   if (primary) {
  //     document.documentElement.style.setProperty('--color-brand-primary', primary);
  //     document.documentElement.style.setProperty('--color-brand-primary-rgb', hexToRgb(primary).join());
  //   }
  //   if (secondary) {
  //     document.documentElement.style.setProperty('--color-brand-secondary', secondary);
  //     document.documentElement.style.setProperty('--color-brand-secondary-rgb', hexToRgb(secondary).join());
  //   }
  // }
}
