import { Injectable } from '@angular/core';
import menuConfig from '@config/menu-config.json';
import { EntityWorldEnum } from '@enums';
import { ConfigService } from '@services/config.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

/**
 * service that saves the currently selected entity and the current model for the dynamic form
 */
@Injectable({
  providedIn: 'root',
})
export class EntityService {
  private _selectedEntity = 'none';
  public isLoading = new BehaviorSubject<boolean>(false);

  public lastModel = {};
  public defaultSubTopic: string;

  public currentEntity = new BehaviorSubject<any>('');
  public currentModel = new BehaviorSubject<any>({});
  public modelReset = new BehaviorSubject<any>({});
  public modelToDefault = new BehaviorSubject<any>({});
  public submit = new BehaviorSubject<any>('');

  public entityTrigger = new BehaviorSubject<any>('');

  public resetSelection = new ReplaySubject<void>();
  public forceSelection = new ReplaySubject<void>();

  public entityWorld = EntityWorldEnum.Other;

  constructor(private configService: ConfigService) {
    this.defaultSubTopic = this.configService.initialMenuConfig;
    this.currentEntity.next(this.defaultSubTopic);
  }

  public setEntity(entity: string): void {
    if (entity === this.selectedEntity) {
      return;
    }
    this.selectedEntity = entity;
    this.setEntityWorld(entity);
    this.currentEntity.next(entity);
    this.lastModel = {};
  }

  public setModel(model): void {
    this.currentModel.next(model);
  }

  public resetModel(): void {
    this.modelReset.next(null);
  }

  public resetModelToDefault(): void {
    this.modelToDefault.next(null);
  }

  public triggerSubmit(): void {
    this.submit.next('');
  }

  public resetSelect(): void {
    this.lastModel = {};
    this.resetSelection.next();
  }

  public forceSelect(): void {
    this.forceSelection.next();
  }

  public setLastModel(model): void {
    this.lastModel = model;
  }

  private setEntityWorld(entity: string): void {
    const currentWorld = menuConfig.find(x => x.subtopics.find(y => y.name === entity))?.name;
    if (currentWorld === 'questionWorld') {
      this.entityWorld = EntityWorldEnum.QuestionWorld;
    } else if (currentWorld === 'productWorld') {
      this.entityWorld = EntityWorldEnum.ProductWorld;
    } else {
      this.entityWorld = EntityWorldEnum.Other;
    }
  }

  public get selectedEntity() {
    return this._selectedEntity;
  }
  public set selectedEntity(value) {
    this._selectedEntity = value;
  }
}
