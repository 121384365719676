import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@modules/auth';
import { InstanceService } from '@services/instance.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private instanceService: InstanceService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const findInstanceId = request.params.get('instance-id');
    const instanceId = findInstanceId ? findInstanceId : this.instanceService.instanceId;

    if (this.authService.authState === 'signedIn' && this.authService.jwtToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.jwtToken}`,
          'instance-id': String(instanceId),
        },
        params: request.params.delete('instance-id'),
      });
    }

    return next.handle(request);
  }
}
