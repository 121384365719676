import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/config.service';
import cloneDeep from 'lodash-es/cloneDeep';
import { ToastrService } from 'ngx-toastr';
import { Subscription, lastValueFrom } from 'rxjs';

interface AttributeSelection {
  name: string;
  options: [
    {
      id: number;
      name: string;
      value: string;
    },
  ];
}

@Component({
  selector: 'app-attribute-form',
  templateUrl: './attribute-form.component.html',
  styleUrls: ['./attribute-form.component.scss'],
})
export class AttributeFormComponent implements OnDestroy {
  constructor(
    private apiService: ApiService,
    private toastrService: ToastrService,
    private configService: ConfigService,
    private readonly formBuilder: FormBuilder
  ) {}

  @Input() chosenAttributes;
  @Input() changesInRefs: boolean = false;
  @Output() innerFormChanged = new EventEmitter();
  @Input() inQuestion: boolean = false;

  private _attributeCreatedSub: Subscription;

  private attributeData = [];
  private initialAttributes = [];
  private reducedAttributeSelection: AttributeSelection[] = [];

  public attributeSelection: AttributeSelection[] = [];
  public formGroups: FormGroup[] = [];
  public isLoading = false;
  public newlyAdded = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chosenAttributes && changes.chosenAttributes.currentValue !== changes.chosenAttributes.previousValue) {
      if (!this.configService.isHubApi()) {
        this.createAttributes();
      }
    } else if (changes.chosenAttributes.currentValue === undefined) {
      if (!this.configService.isHubApi()) {
        this.createAttributes();
      }
    }
  }

  ngOnDestroy(): void {
    this._attributeCreatedSub?.unsubscribe();
  }

  private async createAttributes() {
    this.isLoading = true;
    this.formGroups = [];
    this.attributeData = await lastValueFrom(this.apiService.get('attributes'));

    this.attributeSelection = this.attributeData.reduce((rv, x) => {
      const v = x.displayName;
      const el = rv.find(r => r && r.name === v);
      if (el) {
        el.options.push({ name: x.value, value: x.value, id: x.id });
      } else {
        rv.push({ name: v, options: [{ name: x.value, value: x.value, id: x.id }] });
      }
      return rv;
    }, []);
    this.reducedAttributeSelection = cloneDeep(this.attributeSelection);

    this.setInitialValues();
    this.createFormGroups();
  }

  private setInitialValues() {
    this.initialAttributes = [];
    if (this.chosenAttributes && this.chosenAttributes.length > 0) {
      this.initialAttributes = this.attributeData.filter(attribute =>
        this.chosenAttributes.find(chosenAttribute => chosenAttribute.attributeId === attribute.id)
      );
    }
  }

  private createFormGroups() {
    this.formGroups = [];
    if (this.chosenAttributes) {
      for (let i = 0; i < this.initialAttributes.length; i++) {
        this.addFormGroup(this.initialAttributes[i].displayName, this.initialAttributes[i].value);
      }
    }
    this.isLoading = false;
  }

  private addFormGroup(name, value) {
    const group = this.formBuilder.group({
      name: new FormControl<string | null>(name),
      value: new FormControl<string | null>(value),
      customName: new FormControl<string | null>(null),
      customValue: new FormControl<string | null>(null),
    });

    this.formGroups.push(group);
  }

  public getOptions(name) {
    const options = this.reducedAttributeSelection.find(x => x.name === name);

    return options ? options.options : [];
  }

  public addAttribute() {
    this.addFormGroup('_newValue', '_newValue');
    this.emitData();
    this.newlyAdded = true;
  }

  public copyAttribute(i) {
    if (this.formGroups) {
      const name = this.formGroups[i].controls.name.value;
      const value = this.formGroups[i].controls.value.value;
      this.addFormGroup(name, value);
      this.newlyAdded = true;
    }
    this.emitData();
  }

  public handleAttributeNameChange(event: MatSelectChange, index: number) {
    this.emitData();
  }

  public handleAttributeValueChange(event: MatSelectChange, index: number) {
    this.emitData();
  }

  public emitData(): void {
    this.innerFormChanged.emit(this.formGroups);
  }

  public onDeleteAttribute(i: number): void {
    this.formGroups = this.formGroups.filter((element, index) => index !== i);
    this.toastrService.warning('Element gelöscht. Eintrag speichern, um Änderung zu übernehmen');
    this.emitData();
  }

  public isCustomAttribute(index: number): boolean {
    return this.formGroups[index].get('name').value === '_newAttribute';
  }

  public getAttributeTitle(form: FormGroup): string {
    const name = form.get('name')?.value;
    const value = form.get('value')?.value;
    return `${name === '_newAttribute' ? '' : name} ${name || value ? '' : '-'} ${
      value === '_newValue' || value === '_customValue' ? '' : value
    }`;
  }

  public getAttributeId(form: FormGroup): string {
    const name = form.get('name')?.value;
    const value = form.get('value')?.value;
    const attribute = this.attributeSelection.find(attribute => attribute.name === name);
    if (attribute) {
      return `[${attribute.options.find(values => (values.value = value)).id}]` || '[]';
    } else {
      return '[]';
    }
  }
}
