<div class="my-3 title">Werkzeug {{ type }}</div>
<div *ngIf="!isLoading; else loading">
  <div *ngFor="let tool of formGroups; index as i">
    <!--Atribute-->
    <form [formGroup]="tool">
      <mat-accordion>
        <!--Atribute-Header -->
        <mat-expansion-panel [expanded]="newlyAdded && i === chosenAttributes?.length - 1">
          <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
            <div class="mt-3">
              <mat-panel-title>
                <h4>
                  {{ getToolTitle(tool) }}
                </h4>
              </mat-panel-title>
            </div>
          </mat-expansion-panel-header>
          <div class="d-flex flex-row my-3 prices">
            <div
              class="vr-icon-trash vr-icon-force-size mr-3 size vr-icons"
              (click)="onDeleteAttribute(i)"
              style="cursor: pointer"
              [title]="'Löschen'"
            ></div>
          </div>

          <!--New Attribute-Name-->
          <mat-form-field class="w-100">
            <mat-label>Name</mat-label>
            <input [formControlName]="'name'" matInput (blur)="emitData()" />
          </mat-form-field>

          <!--Attribute-Value-->
          <div class="w-100">
            <ng-select
              [placeholder]="'Werkzeugtyp'"
              [appendTo]="'body'"
              [virtualScroll]="false"
              [notFoundText]="'Kein Ergebnis'"
              [formControlName]="'type'"
              [clearable]="false"
              (change)="emitData($event, i)"
            >
              <ng-option [value]="'ANSWER_OPTION'">Antwortoptionen</ng-option>
              <ng-option [value]="'DATA_FIELD'">Datenfelder</ng-option>
              <ng-option *ngIf="type === 'Input'" [value]="'FREE_INPUT'">Freitext</ng-option>
            </ng-select>
          </div>
          <div class="w-100" *ngIf="tool.controls.type.value === 'ANSWER_OPTION'">
            <ng-select
              [placeholder]="'Antwortoptionen'"
              [appendTo]="'body'"
              [virtualScroll]="false"
              [notFoundText]="'Kein Ergebnis'"
              [formControlName]="'answerOptionContentId'"
              [clearable]="false"
              (change)="emitData()"
            >
              <ng-option *ngFor="let option of rowsAnswerOptions" [value]="option.contentId"
                >{{ option.name }} [{{ option.contentId }}]</ng-option
              >
            </ng-select>
          </div>

          <div class="w-100" *ngIf="tool.controls.type.value === 'DATA_FIELD'">
            <ng-select
              [placeholder]="'Datenfelder'"
              [appendTo]="'body'"
              [virtualScroll]="false"
              [notFoundText]="'Kein Ergebnis'"
              [formControlName]="'dataFieldContentId'"
              [clearable]="false"
              (change)="emitData()"
            >
              <ng-option *ngFor="let option of rowsDataField" [value]="option.contentId">
                {{ option.name }} [{{ option.contentId }}]
              </ng-option>
            </ng-select>
          </div>

          <mat-form-field class="w-100" *ngIf="tool.controls.type.value === 'FREE_INPUT'">
            <mat-label>Freitext</mat-label>
            <input [formControlName]="'value'" matInput (blur)="emitData()" />
          </mat-form-field>
        </mat-expansion-panel>
      </mat-accordion>
      <hr />
    </form>
  </div>
  <!--Add Attribute Button-->
  <ng-container>
    <div style="cursor: pointer" class="d-flex flex-row align-items-center mt-3 add-price" (click)="addTool()">
      <div class="vr-icon-add-circled mr-2"></div>
      <div>Werkzeug hinzufügen</div>
    </div>
  </ng-container>
</div>
<!--LoadingSpinner-->
<ng-template #loading>
  <div class="d-flex w-100 justify-content-center">
    <mat-spinner [ngClass]="'d-inline'"></mat-spinner>
  </div>
</ng-template>
