<div class="container">
  <div class="row">
    <div class="col-12">
      <kf-headline [headline]="'BankingGuide Administration'" [showBackButton]="false"></kf-headline>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <kf-tile [interactive]="false">
        <p>Individualisieren Sie den BankingGuide und passen Sie Ihn so an wie er zu ihrer Bank passt.</p>

        <div class="w-100 d-sm-flex justify-content-end">
          <button
            *ngIf="showReport"
            mat-stroked-button
            color="primary"
            (click)="onReportButtonClicked()"
            class="mb-3 mb-lg-0"
          >
            <mat-progress-spinner
              *ngIf="isPolling"
              color="primary"
              mode="indeterminate"
              class="m-auto"
              [diameter]="24"
            ></mat-progress-spinner>
            <ng-container *ngIf="!isPolling">
              <kf-icon name="ic_herunterladen_24"></kf-icon>
              <span>Report</span>
            </ng-container>
          </button>
          <button
            *ngIf="showConfigurator"
            mat-flat-button
            color="primary"
            (click)="onBannerButtonClicked($event)"
            class="ml-sm-3"
          >
            <span>Zum Konfigurator</span>
          </button>
        </div>
      </kf-tile>
    </div>
  </div>

  <item-benefits></item-benefits>
</div>
