<div class="row">
  <div class="col-lg-4 equal-height-col mb-4 mb-lg-0">
    <kf-tile [interactive]="false">
      <figure class="d-flex flex-center mb-3 illu">
        @if (platform === 'vp') {
          <kf-img [src]="illustrationFlipchart" [selfHosted]="false" [alt]=""></kf-img>
        } @else {
          <svg
            width="236px"
            height="178px"
            viewBox="0 0 236 178"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title></title>
            <defs>
              <polygon
                id="illu_zusammen-am-flipchart_duotone_svg__path-1"
                points="0 0 27.0008 0 27.0008 46.7999286 0 46.7999286"
              ></polygon>
              <polygon
                id="illu_zusammen-am-flipchart_duotone_svg__path-3"
                points="0.0829 0.1215 16.0409 0.1215 16.0409 3 0.0829 3"
              ></polygon>
            </defs>
            <g
              id="illu_zusammen-am-flipchart_duotone_svg__Symbols"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="illu_zusammen-am-flipchart_duotone_svg__Zusammen/Am-Flipchart-(Duotone)">
                <polyline
                  id="illu_zusammen-am-flipchart_duotone_svg__Stroke-1"
                  stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                  stroke-width="2"
                  points="145.1142 102.2435 145.1142 150.6745 111.3832 156.2035"
                ></polyline>
                <line
                  x1="145.1142"
                  y1="150.6742"
                  x2="167.2812"
                  y2="150.6742"
                  id="illu_zusammen-am-flipchart_duotone_svg__Stroke-2"
                  stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                  stroke-width="2"
                ></line>
                <line
                  x1="164.4472"
                  y1="166.0072"
                  x2="145.1142"
                  y2="150.6742"
                  id="illu_zusammen-am-flipchart_duotone_svg__Stroke-3"
                  stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                  stroke-width="2"
                ></line>
                <g id="illu_zusammen-am-flipchart_duotone_svg__Group-6" transform="translate(106.115200, 0.999640)">
                  <polyline
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-4"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    points="74.087 89.84176 78.499 89.84176 77.998 85.51566 81.8514515 4.03735998 79.8016134 1.9955272 4.3691 0 1.76145439 1.9955272 3.8848 4.74797901 0 102.54876 73.31 106.53776"
                  ></polyline>
                  <polyline
                    id="illu_zusammen-am-flipchart_duotone_svg__Stroke-5"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                    points="77.998 4.74775998 73.31 106.53776 0 102.54876 4.369 4.50975998"
                  ></polyline>
                </g>
                <g id="illu_zusammen-am-flipchart_duotone_svg__Group-9" transform="translate(110.000000, 5.037000)">
                  <line
                    x1="0.4843"
                    y1="0.4722"
                    x2="71.7043"
                    y2="2.6292"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-7"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                  ></line>
                  <line
                    x1="0.4843"
                    y1="0.4722"
                    x2="71.7043"
                    y2="2.6292"
                    id="illu_zusammen-am-flipchart_duotone_svg__Stroke-8"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></line>
                </g>
                <g id="illu_zusammen-am-flipchart_duotone_svg__Group-120" transform="translate(0.000000, 0.999640)">
                  <path
                    d="M184.1132,6.66675998 C184.1132,6.66675998 180.2682,8.64775998 181.1072,4.24475998 C181.9472,-0.15824002 188.3642,2.80875998 187.9472,5.47075998 C187.5312,8.13275998 183.6112,79.50776 184.1132,84.67476 L184.6142,89.84176 L180.2022,89.84176"
                    id="illu_zusammen-am-flipchart_duotone_svg__Stroke-10"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></path>
                  <path
                    d="M110.8817,3.79465998 C110.8817,3.79465998 107.9077,6.74565998 107.876654,2.26365998 C107.8647,0.56865998 110.2197,-0.0793400202 115.8637,0.00765997976 C126.6967,0.17465998 184.1127,1.99465998 184.1127,1.99465998"
                    id="illu_zusammen-am-flipchart_duotone_svg__Stroke-12"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></path>
                  <polyline
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-14"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    points="224.4364 94.88356 225.4364 118.38356 225.5 123.50036 224.2704 126.16956 203.1544 119.39456 203.1544 93.55056 192.2704 89.38356 181.4364 73.46756 183.4364 70.88356 196.1034 83.38356 206.1484 81.37256 220.1004 81.37356 230.5 89.00036 234 100.50036 231.5 123.50036 224.5 122.50036"
                  ></polyline>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-16"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                    points="199.1034 83.38356 209.1474 81.37256 206.1484 81.37256 198.5974 82.88456"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-18"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                    points="206.1542 119.39426 206.1542 93.54926 195.2702 89.38326 184.4362 73.46726 185.1372 72.56226 183.4362 70.88326 181.4362 73.46726 192.2702 89.38326 203.1542 93.54926 203.1542 119.39426 224.2702 126.16926 224.4082 125.25026"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-20"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    points="216.5155 169.22146 200.5585 169.22146 201.4105 167.47046 216.1315 166.34346"
                  ></polygon>
                  <path
                    d="M212.3007,124.16966 C212.4707,124.41266 211.9157,145.88566 211.9157,145.88566 L213.3007,171.11866 L221.9907,170.96366 C221.9907,170.96366 221.4307,150.39466 220.9907,150.39466 C220.5507,150.39466 219.9927,148.89466 219.9927,148.89466 L221.1867,147.14466 L222.4637,125.89866 L222.4637,124.89466 L205.3007,119.39466 C205.4277,112.64866 204.9047,142.64166 204.9047,142.64166 L206.3007,166.34366 L212.5207,166.18866"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-22"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                  ></path>
                  <path
                    d="M214.5985,123.89326 L212.3005,123.39426 C212.4275,123.64826 211.9055,142.64126 211.9055,142.64126 L213.3005,166.34326 L215.2945,166.24426 L213.9435,142.86926 L214.5985,123.89326 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-24"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                  ></path>
                  <path
                    d="M212.3564,172.13456 L210.9184,145.94056 C211.1274,137.70056 211.4094,125.23556 211.3184,124.33056 L212.1954,124.24256 L213.1224,123.59756 C213.3684,123.94956 213.4794,124.11156 212.9164,145.91056 L214.2474,170.10156 L220.9634,169.98256 C220.7694,163.15256 220.4094,153.14456 220.1344,151.06456 C219.8064,150.77856 219.4284,150.24256 219.0554,149.24156 L218.8754,148.75756 L220.2054,146.80856 L221.4654,125.83856 L223.4614,125.95756 L222.1684,147.47856 L221.1274,149.00556 C221.2034,149.16556 221.2804,149.31256 221.3454,149.41556 C222.3024,149.61356 222.4634,151.56956 222.9914,170.93656 L223.0184,171.94556 L212.3564,172.13456 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-26"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <path
                    d="M208.5985,121.89326 L206.3005,121.39426 C206.4275,121.64826 205.9055,142.64126 205.9055,142.64126 L207.3005,166.34326 L209.2945,166.24426 L207.9435,142.86926 L208.5985,121.89326 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-28"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                  ></path>
                  <path
                    d="M205.3603,167.36596 L203.9073,142.69996 C204.1053,134.19696 204.3803,121.36496 204.3123,120.52396 L205.1783,120.45496 L206.1963,119.94596 C206.3443,120.24296 206.4343,120.42296 205.9053,142.66496 L207.2433,165.31896 L212.4963,165.18796 L212.5453,167.18796 L205.3603,167.36596 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-30"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-32"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    points="222.5155 174.22146 206.5585 174.22146 207.4105 172.47046 222.1315 171.34346"
                  ></polygon>
                  <path
                    d="M225.0849,127.48026 L202.1549,120.12326 L202.1549,94.23726 L191.6199,90.20526 L180.2019,73.42926 L183.3369,69.37826 L196.4279,82.29726 L206.1479,80.37326 L220.4039,80.37426 L225.1399,83.54226 L229.5119,86.98726 C231.0969,88.23726 232.2219,89.91426 232.7649,91.83426 L235.1179,100.17126 L235.0929,100.37226 L232.1219,124.39326 L225.606702,124.010545 L225.868882,122.143988 L230.3549,122.39326 L233.0829,100.32626 L230.8409,92.37826 C230.4129,90.86726 229.5249,89.54426 228.2729,88.55726 L223.9649,85.15826 L219.7969,82.37426 L206.2469,82.37326 L195.7789,84.46726 L183.5379,72.38626 L182.6729,73.50526 L192.9209,88.56026 L204.1549,92.86226 L204.1549,118.66426 L223.4559,124.85726 L224.4349,118.32926 L223.4389,94.92526 L225.4369,94.83926 L226.4399,118.43626 L225.0849,127.48026 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-34"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <path
                    d="M212.8398,83.53296 C207.6738,83.04396 204.0088,77.14496 204.6518,70.35896 C205.2968,63.57396 209.2698,63.55496 214.4348,64.04496 C219.5998,64.53496 224.0008,65.34796 223.3578,72.13496 C222.7138,78.92096 218.0048,84.02396 212.8398,83.53296"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-36"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                  ></path>
                  <path
                    d="M220.5165,62.71946 C217.9825,61.61846 215.1105,61.36246 212.3925,61.86046 C211.8915,61.95146 211.3935,62.06946 210.8865,62.11546 C210.1895,62.17746 209.4855,62.10546 208.7995,61.96346 C207.4035,61.67346 206.0465,61.07446 205.0175,60.08846 C204.8995,61.28046 205.6795,62.50246 206.8105,62.89646 C205.9335,62.82946 205.0655,62.63746 204.2425,62.32446 C204.1275,63.19046 204.5435,64.04746 205.1045,64.71546 C206.2565,66.08946 208.0815,66.86946 209.8715,66.75346 C209.1965,66.92246 208.5855,67.34146 208.1875,67.91246 C211.3305,68.00146 214.3655,69.04446 217.4805,69.47546 C218.4405,69.60846 219.4695,69.67046 220.3255,69.21746 C221.4905,68.60146 222.1515,67.05746 222.5265,65.85546 C222.9935,64.35946 221.7625,63.26146 220.5165,62.71946"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-38"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <path
                    d="M224.4804,66.82006 C224.9434,67.97806 225.2514,69.21306 225.2434,70.46006 C225.2334,71.75006 224.8874,73.01006 224.5314,74.25106 C224.3204,74.98506 224.1044,75.71706 223.8844,76.44906 C223.3264,78.30606 222.6074,80.32306 220.9234,81.28506 C219.1504,79.47606 217.4294,77.61506 215.7654,75.70606 C215.1524,75.00306 214.5434,74.28806 214.0604,73.49106 C213.7864,73.04006 213.5514,72.55806 213.4314,72.04506 C213.2524,71.28706 213.3294,70.49106 213.4904,69.72906 C213.9964,67.34506 215.3724,65.17406 217.2664,63.64906 C217.9334,63.11206 218.7544,62.45406 219.6464,62.39106 C220.5484,62.32706 221.4554,62.90606 222.1364,63.43606 C223.2404,64.29606 223.9674,65.53806 224.4804,66.82006"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-40"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <path
                    d="M218.4345,65.65896 C217.2785,65.35496 215.9005,65.18796 214.3395,65.04096 C208.8305,64.51796 206.1765,64.88196 205.6485,70.45396 C205.0575,76.67996 208.3285,82.10396 212.9335,82.53896 C215.1135,82.74596 217.2885,81.80396 219.0595,79.88496 C220.8985,77.89096 222.0715,75.10496 222.3625,72.03996 C222.7495,67.95496 221.2485,66.39896 218.4345,65.65896 M211.4355,84.29696 C206.4295,82.97896 203.0165,77.01396 203.6575,70.26496 C204.4325,62.09296 210.0315,62.62296 214.5295,63.04996 C219.0015,63.47096 225.1285,64.05696 224.3535,72.22796 C224.0215,75.72696 222.6635,78.92896 220.5295,81.24096 C218.3265,83.62896 215.5615,84.79696 212.7455,84.52896 C212.2975,84.48696 211.8615,84.40796 211.4355,84.29696"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-42"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <path
                    d="M231.6034,123.59736 C231.6034,123.59736 232.2934,129.33436 231.2644,130.32236 C230.7344,130.83036 227.6854,131.64936 226.8524,125.18236 C226.6944,123.95336 227.0584,122.97036 227.0584,122.97036 L231.6034,123.59736 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-44"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <path
                    d="M183.0448,69.82786 C183.0448,69.82786 180.0178,66.03286 178.9948,65.74386 C178.4688,65.59486 175.6878,65.33886 178.8368,70.91486 C179.4348,71.97386 181.0008,73.45686 181.0008,73.45686 L183.0448,69.82786 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-46"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <g id="illu_zusammen-am-flipchart_duotone_svg__Group-51" transform="translate(154.000000, 53.037360)">
                    <line
                      x1="26.8554"
                      y1="15.8618"
                      x2="0.8064"
                      y2="0.3618"
                      id="illu_zusammen-am-flipchart_duotone_svg__Fill-48"
                      fill="var(--color-illustration-classic-white, #FFFFFF)"
                    ></line>
                    <line
                      x1="26.8554"
                      y1="15.8618"
                      x2="0.8064"
                      y2="0.3618"
                      id="illu_zusammen-am-flipchart_duotone_svg__Stroke-50"
                      stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                      stroke-width="2"
                    ></line>
                  </g>
                  <path
                    d="M139.5536,47.87576 C139.5536,53.77076 134.2926,58.55076 127.8036,58.55076 C121.3136,58.55076 116.0536,53.77076 116.0536,47.87576 C116.0536,41.97976 121.3136,37.20076 127.8036,37.20076 C134.2926,37.20076 139.5536,41.97976 139.5536,47.87576"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-52"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                  ></path>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-54"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    points="122.3114 62.86596 116.0534 58.68596 127.7984 47.87596"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-56"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    points="157.0243 95.20876 150.4203 94.93476 151.4263 70.80176 158.0303 71.07676"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-58"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    points="146.497 94.79856 139.893 94.52356 140.686 75.48656 147.29 75.76256"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-60"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    points="135.9706 94.35916 129.3666 94.08416 129.8256 83.06216 136.4296 83.33816"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-62"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    points="158.0233 71.25656 151.4263 70.80156 150.4213 94.93456 157.0173 95.38856"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Stroke-64"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                    points="157.0243 95.20876 150.4203 94.93476 151.4263 70.80176 158.0303 71.07676"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-66"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    points="125.453 93.92066 118.849 93.64666 119.539 77.11066 126.142 77.38666"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Stroke-68"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                    points="125.453 93.92066 118.849 93.64666 119.539 77.11066 126.142 77.38666"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Stroke-70"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                    points="146.497 94.79856 139.893 94.52356 140.686 75.48656 147.29 75.76256"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-72"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    points="167.5507 95.67556 160.9467 95.40056 161.5887 79.99856 168.1927 80.27456"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Stroke-74"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                    points="167.5507 95.67556 160.9467 95.40056 161.5887 79.99856 168.1927 80.27456"
                  ></polygon>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Stroke-76"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                    points="135.9706 94.35916 129.3666 94.08416 129.8256 83.06216 136.4296 83.33816"
                  ></polygon>
                  <line
                    x1="172.9364"
                    y1="95.96556"
                    x2="111.3734"
                    y2="93.39956"
                    id="illu_zusammen-am-flipchart_duotone_svg__Stroke-78"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></line>
                  <g id="illu_zusammen-am-flipchart_duotone_svg__Group-83" transform="translate(116.000000, 12.037360)">
                    <line
                      x1="0.0536"
                      y1="0.7202"
                      x2="61.3586"
                      y2="2.4702"
                      id="illu_zusammen-am-flipchart_duotone_svg__Fill-80"
                      fill="var(--color-illustration-classic-white, #FFFFFF)"
                    ></line>
                    <line
                      x1="0.0536"
                      y1="0.7202"
                      x2="61.3586"
                      y2="2.4702"
                      id="illu_zusammen-am-flipchart_duotone_svg__Stroke-82"
                      stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                      stroke-width="2"
                    ></line>
                  </g>
                  <g id="illu_zusammen-am-flipchart_duotone_svg__Group-87" transform="translate(116.000000, 17.037360)">
                    <line
                      x1="0.0536"
                      y1="0.1079"
                      x2="44.7936"
                      y2="1.3839"
                      id="illu_zusammen-am-flipchart_duotone_svg__Fill-84"
                      fill="var(--color-illustration-classic-white, #FFFFFF)"
                    ></line>
                    <line
                      x1="0.0536"
                      y1="0.1079"
                      x2="44.7936"
                      y2="1.3839"
                      id="illu_zusammen-am-flipchart_duotone_svg__Stroke-86"
                      stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                      stroke-width="2"
                    ></line>
                  </g>
                  <polygon
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-88"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    points="13.0829 171.03786 29.0409 171.03786 28.1879 169.28586 13.4669 168.15886"
                  ></polygon>
                  <path
                    d="M17.2978,125.98506 C17.1278,126.22806 17.6838,147.70206 17.6838,147.70206 L16.2978,172.93406 L7.6088,172.77906 C7.6088,172.77906 8.1678,152.21006 8.6088,152.21006 C9.0478,152.21006 9.6068,150.71006 9.6068,150.71006 L8.4118,148.96006 L7.1348,130.71406 L7.1348,120.71006 L24.2978,121.21006 C24.1708,121.46406 24.6938,144.45806 24.6938,144.45806 L23.2978,168.15906 L17.0788,168.00406"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-90"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                  ></path>
                  <path
                    d="M15.0009,125.70876 L17.2979,125.20976 C17.1709,125.46376 17.6929,144.45676 17.6929,144.45676 L16.2979,168.15876 L14.3039,168.05976 L15.6549,144.68476 L15.0009,125.70876 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-92"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                  ></path>
                  <path
                    d="M17.2431,173.95096 L18.6801,147.75696 C18.4721,139.51696 18.1901,127.05196 18.2811,126.14696 L17.4041,126.05896 L16.4771,125.41396 C16.2311,125.76596 16.1201,125.92796 16.6821,147.72696 L15.3521,171.91796 L8.6361,171.79896 C8.8301,164.96896 9.1901,154.96096 9.4651,152.88096 C9.7931,152.59496 10.1711,152.05896 10.5441,151.05796 L10.7241,150.57396 L9.3901,148.61996 L8.1321,130.64396 L6.1381,130.78496 L7.4331,149.29996 L8.4721,150.82196 C8.3961,150.98196 8.3191,151.12896 8.2541,151.23196 C7.2971,151.42996 7.1361,153.38596 6.6081,172.75296 L6.5811,173.76196 L17.2431,173.95096 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-94"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <path
                    d="M21.0009,121.70876 L23.2979,121.20976 C23.1709,121.46376 23.6929,144.45676 23.6929,144.45676 L22.2979,168.15876 L20.3039,168.05976 L21.6549,144.68476 L21.0009,121.70876 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-96"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                  ></path>
                  <path
                    d="M4.8134,125.89916 C4.8134,125.89916 4.1824,130.15016 5.2114,131.13716 C5.7424,131.64616 8.7914,132.46416 9.6244,125.99816 C9.7824,124.76916 9.0934,122.49616 9.0934,122.49616 L4.8134,125.89916 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-98"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <path
                    d="M11.2021,95.30736 L9.6061,125.98536 L4.0571,125.98536 L1.0381,100.95836 C0.9571,100.29036 1.0081,99.61436 1.1861,98.96536 L2.9971,92.39136 C3.8121,89.42936 5.5351,86.79736 7.9241,84.86636 L11.3601,82.08636 L19.1721,81.21636 C21.7411,80.93036 24.0701,82.74436 24.4221,85.30636 L26.0011,96.81136 L26.0011,121.70936 L10.7377503,121.70936"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-100"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                  ></path>
                  <path
                    d="M19.4218,85.30546 C19.1778,83.52846 17.9728,82.13046 16.4048,81.52446 L19.1718,81.21646 C21.7408,80.92946 24.0698,82.74346 24.4218,85.30546 L26.0008,96.81146 L26.0008,121.70846 L21.0008,121.70846 L21.0008,96.81146 L19.4218,85.30546 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-102"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                  ></path>
                  <g id="illu_zusammen-am-flipchart_duotone_svg__Group-106" transform="translate(0.000000, 80.185131)">
                    <mask id="illu_zusammen-am-flipchart_duotone_svg__mask-2" fill="white">
                      <use xlink:href="#illu_zusammen-am-flipchart_duotone_svg__path-1"></use>
                    </mask>
                    <g id="illu_zusammen-am-flipchart_duotone_svg__Clip-105"></g>
                    <path
                      d="M3.1708,46.7999286 L10.5558,46.7999286 L12.2008,15.1749286 L10.2028,15.0709286 L8.6568,44.7999286 L4.9438,44.7999286 L2.0308,20.6539286 C1.9648,20.1129286 2.0058,19.5729286 2.1498,19.0469286 L3.9608,12.4729286 C4.7258,9.69592861 6.3138,7.26992861 8.5528,5.45892861 L11.7618,2.86392861 L19.2818,2.02592861 C21.3458,1.79292861 23.1498,3.21792861 23.4318,5.25692861 L25.0008,16.6949286 L25.0008,40.5249286 L10.8667931,40.5772286 L10.793651,42.5249286 L27.0008,42.5249286 L26.9908,16.4899286 L25.4118,4.98592861 C24.9848,1.86192861 22.2088,-0.313071393 19.0618,0.0369286066 L10.9588,0.940928607 L7.2948,3.90392861 C4.7288,5.97992861 2.9088,8.75892861 2.0328,11.9419286 L0.2228,18.5159286 C0.0078,19.2939286 -0.0522,20.0949286 0.0448,20.8949286 L3.1708,46.7999286 Z"
                      id="illu_zusammen-am-flipchart_duotone_svg__Fill-104"
                      fill="var(--color-illustration-classic-blue-1, #0066B3)"
                      mask="url(#illu_zusammen-am-flipchart_duotone_svg__mask-2)"
                    ></path>
                  </g>
                  <path
                    d="M21.7069,82.84546 C26.5619,81.01546 28.5489,74.36146 26.1439,67.98346 C23.7399,61.60546 19.9019,62.63046 15.0469,64.46046 C10.1919,66.29146 6.1589,68.23346 8.5629,74.61146 C10.9679,80.98946 16.8529,84.67546 21.7069,82.84546"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-107"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                  ></path>
                  <path
                    d="M8.83,64.78096 C10.986,63.05196 13.69,62.05096 16.443,61.81596 C16.951,61.77296 17.462,61.75596 17.963,61.66696 C18.652,61.54396 19.312,61.28896 19.936,60.97096 C21.208,60.32496 22.359,59.39096 23.094,58.16896 C23.52,59.28796 23.088,60.67196 22.101,61.34896 C22.93,61.05396 23.717,60.63996 24.429,60.12396 C24.767,60.92696 24.591,61.86396 24.226,62.65596 C23.475,64.28496 21.918,65.51696 20.161,65.87596 C20.857,65.85996 21.557,66.10496 22.092,66.55196 C19.081,67.46296 16.428,69.26696 13.535,70.50096 C12.644,70.88096 11.667,71.21196 10.723,70.99996 C9.436,70.71196 8.393,69.39596 7.716,68.33396 C6.872,67.01296 7.77,65.63096 8.83,64.78096"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-109"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <path
                    d="M6.0839,69.77806 C5.9409,71.01706 5.9679,72.29006 6.3039,73.49106 C6.6519,74.73306 7.3169,75.85906 7.9869,76.96206 C8.3839,77.61406 8.7839,78.26506 9.1879,78.91306 C10.2159,80.55706 11.4389,82.31406 13.3159,82.80006 C14.5529,80.59006 15.7239,78.34106 16.8269,76.06106 C17.2339,75.22106 17.6339,74.37306 17.8909,73.47606 C18.0359,72.96906 18.1359,72.44406 18.1169,71.91706 C18.0899,71.13806 17.8079,70.39006 17.4509,69.69806 C16.3379,67.53006 14.4379,65.79706 12.2099,64.82306 C11.4259,64.48106 10.4609,64.06106 9.5839,64.23506 C8.6959,64.41006 7.9739,65.20706 7.4559,65.89706 C6.6159,67.01606 6.2409,68.40706 6.0839,69.77806"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-111"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <path
                    d="M11.6112,67.07006 C12.6472,66.47206 13.9332,65.95006 15.4002,65.39606 C20.5782,63.44506 23.2342,63.09806 25.2082,68.33606 C27.4152,74.18706 25.6842,80.28106 21.3552,81.91006 C19.3052,82.68206 16.9592,82.34506 14.7472,80.96006 C12.4492,79.51906 10.5852,77.13906 9.4992,74.25806 C8.0512,70.41906 9.0902,68.52306 11.6112,67.07006 M23.2612,83.21306 C27.7462,80.62706 29.4712,73.97406 27.0802,67.63106 C24.1832,59.94906 18.9222,61.93106 14.6942,63.52506 C10.4892,65.10806 4.7322,67.28306 7.6272,74.96406 C8.8672,78.25206 11.0182,80.98306 13.6852,82.65406 C16.4392,84.38006 19.4132,84.78006 22.0602,83.78106 C22.4802,83.62306 22.8812,83.43206 23.2612,83.21306"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-113"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <path
                    d="M24.2392,169.18236 L25.6912,144.51636 C25.4932,135.61936 25.2192,122.19436 25.2872,121.33836 L24.4192,121.27036 L23.4032,120.76236 C23.2552,121.05936 23.1652,121.23836 23.6932,144.48036 L22.3562,167.13536 L17.1032,167.00436 L17.0542,169.00436 L24.2392,169.18236 Z"
                    id="illu_zusammen-am-flipchart_duotone_svg__Fill-115"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  ></path>
                  <g id="illu_zusammen-am-flipchart_duotone_svg__Group-119" transform="translate(7.000000, 173.037360)">
                    <mask id="illu_zusammen-am-flipchart_duotone_svg__mask-4" fill="white">
                      <use xlink:href="#illu_zusammen-am-flipchart_duotone_svg__path-3"></use>
                    </mask>
                    <g id="illu_zusammen-am-flipchart_duotone_svg__Clip-118"></g>
                    <polygon
                      id="illu_zusammen-am-flipchart_duotone_svg__Fill-117"
                      fill="var(--color-illustration-classic-blue-1, #0066B3)"
                      mask="url(#illu_zusammen-am-flipchart_duotone_svg__mask-4)"
                      points="0.0829 3.0005 16.0409 3.0005 15.1879 1.2485 0.4669 0.1215"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        }
      </figure>

      <h2>Kunden besser beraten</h2>
      <p>
        Beraten Sie Ihren Kunden entsprechend seiner Bedarfe. Stellen Sie komplexe Sachverhalte vereinfacht dar und
        bieten Sie Ihrem Kunden eine Komplettlösung aus einer Hand.
      </p>
    </kf-tile>
  </div>

  <div class="col-lg-4 equal-height-col mb-4 mb-lg-0">
    <kf-tile [interactive]="false">
      <figure class="d-flex flex-center mb-3 illu">
        @if (platform === 'vp') {
          <kf-img [src]="illustrationUhr" [selfHosted]="false" [alt]=""></kf-img>
        } @else {
          <svg
            width="84px"
            height="85px"
            viewBox="0 0 84 85"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title></title>
            <defs>
              <polygon
                id="illu_uhr_duotone_svg__path-1"
                points="0.000161049782 0.989 83.010439 0.989 83.010439 83.9993612 0.000161049782 83.9993612"
              ></polygon>
              <polygon
                id="illu_uhr_duotone_svg__path-3"
                points="0 84.0111 83.011 84.0111 83.011 1.0001 0 1.0001"
              ></polygon>
            </defs>
            <g id="illu_uhr_duotone_svg__Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="illu_uhr_duotone_svg__Group-15">
                <path
                  d="M41.9467,82.0125 C19.9207,82.2815 1.8477,64.6425 1.5797,42.6165 C1.3117,20.5905 18.9497,2.5175 40.9767,2.2495 C63.0017,1.9825 81.0747,19.6195 81.3427,41.6465 C81.6107,63.6715 63.9727,81.7445 41.9467,82.0125"
                  id="illu_uhr_duotone_svg__Fill-1"
                  fill="var(--color-illustration-classic-white, #FFFFFF)"
                ></path>
                <path
                  d="M21.6757,8.4383 C21.6757,8.4383 8.7897,23.9513 13.6937,48.0413 C17.2857,65.6853 31.1357,81.0303 31.1357,81.0303 C31.1357,81.0303 18.5467,75.2743 13.9847,71.5723 C8.7667,67.3353 2.4527,56.3193 1.5287,48.1363 C0.8137,41.8053 2.6897,28.6463 9.8327,17.2453 C12.4197,13.1183 21.6757,8.4383 21.6757,8.4383"
                  id="illu_uhr_duotone_svg__Fill-3"
                  fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                ></path>
                <g id="illu_uhr_duotone_svg__Group-7" transform="translate(0.000000, 0.011100)">
                  <mask id="illu_uhr_duotone_svg__mask-2" fill="white">
                    <use xlink:href="#illu_uhr_duotone_svg__path-1"></use>
                  </mask>
                  <g id="illu_uhr_duotone_svg__Clip-6"></g>
                  <path
                    d="M41.9833,81.7612 C20.2963,82.0252 2.5033,64.6582 2.2393,42.9722 C1.9753,21.2852 19.3413,3.4912 41.0283,3.2282 C62.7143,2.9642 80.5083,20.3302 80.7723,42.0172 C81.0363,63.7022 63.6703,81.4972 41.9833,81.7612 M41.0013,0.9912 C18.0793,1.2712 -0.2757,20.0782 0.0033,42.9992 C0.2823,65.9202 19.0893,84.2762 42.0103,83.9962 C64.9313,83.7182 83.2863,64.9102 83.0073,41.9902 C82.7293,19.0682 63.9213,0.7132 41.0013,0.9912"
                    id="illu_uhr_duotone_svg__Fill-5"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    mask="url(#illu_uhr_duotone_svg__mask-2)"
                  ></path>
                </g>
                <mask id="illu_uhr_duotone_svg__mask-4" fill="white">
                  <use xlink:href="#illu_uhr_duotone_svg__path-3"></use>
                </mask>
                <g id="illu_uhr_duotone_svg__Clip-9"></g>
                <polygon
                  id="illu_uhr_duotone_svg__Fill-8"
                  fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  mask="url(#illu_uhr_duotone_svg__mask-4)"
                  points="40.444 42.1641 42.444 42.1641 42.444 13.4091 40.444 13.4091"
                ></polygon>
                <polygon
                  id="illu_uhr_duotone_svg__Fill-10"
                  fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  mask="url(#illu_uhr_duotone_svg__mask-4)"
                  points="40.444 9.3961 42.444 9.3961 42.444 7.3901 40.444 7.3901"
                ></polygon>
                <polygon
                  id="illu_uhr_duotone_svg__Fill-11"
                  fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  mask="url(#illu_uhr_duotone_svg__mask-4)"
                  points="74.859 43.5061 76.865 43.5061 76.865 41.5061 74.859 41.5061"
                ></polygon>
                <polygon
                  id="illu_uhr_duotone_svg__Fill-12"
                  fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  mask="url(#illu_uhr_duotone_svg__mask-4)"
                  points="5.98 43.5061 7.986 43.5061 7.986 41.5061 5.98 41.5061"
                ></polygon>
                <polygon
                  id="illu_uhr_duotone_svg__Fill-13"
                  fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  mask="url(#illu_uhr_duotone_svg__mask-4)"
                  points="40.444 78.9441 42.444 78.9441 42.444 76.9371 40.444 76.9371"
                ></polygon>
                <polygon
                  id="illu_uhr_duotone_svg__Fill-14"
                  fill="var(--color-illustration-classic-blue-1, #0066B3)"
                  mask="url(#illu_uhr_duotone_svg__mask-4)"
                  points="55.619 51.0418 54.507 52.7138 39.911 43.0048 41.023 41.3328"
                ></polygon>
              </g>
            </g>
          </svg>
        }
      </figure>

      <h2>Schnellere Produktabschlüsse</h2>
      <p>
        Durch die intelligente Empfehlungslogik werden Produktvorschläge erstellt und können über den Lösungskorb direkt
        abgeschlossen werden. Die nachgelagerten Bearbeitungsschritte, wie z.B. die Vertragserstellung, sind fester
        Bestandteil der digitalisierten Beratungsanwendung.
      </p>
    </kf-tile>
  </div>

  <div class="col-lg-4 equal-height-col mb-4 mb-lg-0">
    <kf-tile [interactive]="false">
      <figure class="d-flex flex-center mb-3 illu">
        @if (platform === 'vp') {
          <kf-img [src]="illustrationPuzzle" [selfHosted]="false" [alt]=""></kf-img>
        } @else {
          <svg
            width="471px"
            height="191px"
            viewBox="0 0 471 191"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title></title>
            <g
              id="illu_puzzle-zusammen-schieben_duotone_svg__Symbols"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="illu_puzzle-zusammen-schieben_duotone_svg__Zusammen/Puzzle-zusammen-schieben-(Duotone)">
                <g id="illu_puzzle-zusammen-schieben_duotone_svg__Group" transform="translate(112.000000, 0.000000)">
                  <path
                    d="M207.887387,105.01457 L207.630516,105.01457 C207.665789,106.79078 208.323399,108.497149 209.486963,109.831689 C214.934609,116.271331 214.447828,125.87686 208.377542,131.723621 C202.307256,137.570381 192.764155,137.625403 186.627892,131.849022 C180.491629,126.07264 179.895835,116.473352 185.270019,109.971316 C186.560339,108.350085 187.246579,106.324633 187.209591,104.246623 C187.201968,100.278456 184.012046,97.0635335 180.074737,97.0558507 L152,97.0558507 L152,123.291698 C152,125.782622 150.760363,128.171321 149.011412,129.93136 C147.262461,131.691399 144.890879,132.678316 142.419328,132.674609 L139.64851,132.674609 C137.576594,132.707409 135.558147,132.011216 133.940627,130.705873 C127.501639,125.326878 118.022356,125.921828 112.295295,132.064403 C106.568235,138.206978 106.568235,147.77906 112.295295,153.921634 C118.022356,160.064209 127.501639,160.659159 133.940627,155.280164 C135.550391,153.981824 137.559319,153.290472 139.620802,153.325391 L142.39162,153.325391 C147.541013,153.325391 151.71542,157.532514 151.71542,162.722264 L151.71542,189 L243,189 L243,97 L215.582762,97 C211.190858,97 207.887387,100.588245 207.887387,105.01457 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M151.959637,153 L149.188339,153 C148.09963,153.001936 147.019965,153.207101 146,153.605871 C149.568973,154.960763 151.94959,158.506627 151.959637,162.482583 L151.959637,189 L161,189 L161,162.482583 C161.003561,159.968942 160.052675,157.556973 158.356893,155.778237 C156.661111,153.999502 154.359621,153 151.959637,153 L151.959637,153 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                  ></path>
                  <rect
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Rectangle"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                    x="161"
                    y="179"
                    width="82"
                    height="9"
                  ></rect>
                  <path
                    d="M117.615527,141.138572 C118.394268,134.847893 122.837352,129.59023 128.97161,127.700508 C122.877282,125.862054 116.255902,127.71216 112.045428,132.429918 C107.834953,137.147676 106.815257,143.859255 109.438787,149.586673 C112.062316,155.314091 117.843136,158.996506 124.21625,159 C125.83889,158.996091 127.451637,158.750695 129,158.272105 C121.509234,156.018789 116.719763,148.810677 117.615527,141.138572 L117.615527,141.138572 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M151.959641,123.531507 C151.95186,127.504929 149.569391,131.047915 146,132.394129 C147.019966,132.792899 148.099631,132.998064 149.188341,133 L151.959641,133 C156.952494,133 161,128.760811 161,123.531507 L161,97 L151.959641,97 L151.959641,123.531507 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M207.620982,106.001346 C207.656495,107.774625 208.318555,109.478178 209.489994,110.810517 C212.945764,114.892451 214.118019,120.436629 212.610057,125.566853 C211.102095,130.697076 207.115736,134.726734 202,136.292155 C206.465877,137.669447 211.311661,137.017048 215.253665,134.507783 C219.195668,131.998518 221.836082,127.885611 222.47544,123.258604 C223.103324,118.784192 221.788876,114.254811 218.862947,110.810517 C217.691508,109.478178 217.029448,107.774625 216.993936,106.001346 C216.993936,101.582325 220.578373,98 225,98 L215.627047,98 C213.503706,98 211.467333,98.8429965 209.965904,100.34354 C208.464476,101.844083 207.620982,103.879257 207.620982,106.001346 L207.620982,106.001346 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M207.630516,105.01457 C207.665789,106.79078 208.323399,108.497149 209.486963,109.831689 C214.934609,116.271331 214.447828,125.87686 208.377542,131.723621 C202.307256,137.570381 192.764155,137.625403 186.627892,131.849022 C180.491629,126.07264 179.895835,116.473352 185.270019,109.971316 C186.560339,108.350085 187.246579,106.324633 187.209591,104.246623 C187.201968,100.278456 184.012046,97.0635335 180.074737,97.0558507 L152,97 L152,123.5 C152,125.990924 150.760363,128.171321 149.011412,129.93136 C147.262461,131.691399 144.890879,132.678316 142.419328,132.674609 L139.64851,132.674609 C137.576594,132.707409 135.558147,132.011216 133.940627,130.705873 C127.501639,125.326878 118.022356,125.921828 112.295295,132.064403 C106.568235,138.206978 106.568235,147.77906 112.295295,153.921634 C118.022356,160.064209 127.501639,160.659159 133.940627,155.280164 C135.550391,153.981824 137.559319,153.290472 139.620802,153.325391 L142.39162,153.325391 C147.541013,153.325391 151.71542,157.532514 151.71542,162.722264 L151.71542,189 L244,189 L244,97 L215.582762,97 C211.190858,97 207.630516,100.588245 207.630516,105.01457 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></path>
                  <path
                    d="M164.841754,34.5338621 C171.296812,29.1290125 180.878325,29.6526515 186.696219,35.7282298 C192.514113,41.8038081 192.552431,51.3260795 186.783619,57.44757 C181.014807,63.5690605 171.437813,64.1686149 164.939463,58.8150931 C163.317575,57.5271134 161.293517,56.8412788 159.216507,56.8759189 C155.246358,56.8759189 152.027917,60.0696467 152.027917,64.0093097 L152.027917,97.8063454 L178.297679,97.8063454 C183.490214,97.8063454 186.696219,96.4489235 186.696219,101.615438 L187.691701,104.357984 C187.717041,106.430617 187.016456,108.447692 185.709604,110.064696 C180.332241,116.502363 180.92701,125.979701 187.067721,131.705586 C193.208432,137.431471 202.777609,137.431471 208.91832,131.705586 C215.059031,125.979701 215.6538,116.502363 210.276438,110.064696 C208.988665,108.450587 208.303169,106.444148 208.336216,104.385686 L208.336216,101.615438 C208.336216,96.4627747 212.523745,97.8340479 217.71628,97.8340479 L244,97.8340479 L244,1 L152,1 L152,28.4393147 C152,32.8303176 155.587156,36.3899288 160.012138,36.3899288 C161.793112,36.3603251 163.505257,35.7023318 164.841754,34.5338621 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M208,98.2235294 L208,101.119608 C208,102.231373 208.21135,101.970588 208.605871,103 C209.930333,99.4588235 213.382387,98.2921569 217.468493,98.2921569 L244,98.2921569 L244,89 L217.468493,89 C212.239189,89 208,93.1295148 208,98.2235294 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M177.517417,98.268969 C181.603523,98.268969 185.055577,99.4413819 186.394129,103 C186.78865,101.951725 187,102.227587 187,101.110346 L187,98.268969 C187,95.8082966 186.000498,93.448608 184.221763,91.709944 C182.443027,89.97128 180.031058,88.9963486 177.517417,89 L151,89 L151,98.268969 L177.517417,98.268969 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M159.980289,56.6491228 C161.760386,56.6788609 163.471687,57.3398398 164.807526,58.5136116 C168.257866,61.4032678 172.777541,62.6933045 177.238361,62.061706 C183.359436,61.1868628 188.433031,56.884533 190.283063,51 C191.671746,55.4553655 191.023206,60.2945144 188.510019,64.229758 C185.996833,68.1650016 181.873913,70.7971862 177.238361,71.4258923 C172.77804,72.0503242 168.261245,70.7611096 164.807526,67.8777979 C163.482842,66.7038246 161.78008,66.0423203 160.008192,66.0133091 C155.58539,66.0133091 152,69.5890724 152,74 L152,64.6358137 C152,60.2357309 155.568387,56.6644541 159.980289,56.6491228 L159.980289,56.6491228 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M160.012138,36.3899288 L160.012138,36.3899288 C161.793112,36.3603251 163.505257,35.7023318 164.841754,34.5338621 C171.296812,29.1290125 180.878325,29.6526515 186.696219,35.7282298 C192.514113,41.8038081 192.552431,51.3260795 186.783619,57.44757 C181.014807,63.5690605 171.437813,64.1686149 164.939463,58.8150931 C163.317575,57.5271134 161.293517,56.8412788 159.216507,56.8759189 L159.216507,56.8759189 C155.246358,56.8759189 152.027917,60.0696467 152.027917,64.0093097 L152.027917,97.8063454 L178.297679,97.8063454 C183.490214,97.8063454 187.691701,100.576594 187.691701,105.743108 L187.691701,104.357984 C187.717041,106.430617 187.016456,108.447692 185.709604,110.064696 C180.332241,116.502363 180.92701,125.979701 187.067721,131.705586 C193.208432,137.431471 202.777609,137.431471 208.91832,131.705586 C215.059031,125.979701 215.6538,116.502363 210.276438,110.064696 C208.988665,108.450587 208.303169,106.444148 208.336216,104.385686 L208.336216,105.770811 C208.336216,100.618148 212.523745,97.8340479 217.71628,97.8340479 L244,97.8340479 L244,1 L152,1 L152,28.4393147 C152,32.8303176 155.587156,36.3899288 160.012138,36.3899288 L160.012138,36.3899288 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></path>
                  <path
                    d="M35.3739725,84.9854303 L35.3739725,84.9854303 C35.3378541,83.2135751 34.6805769,81.5118963 33.5187427,80.1822735 C28.11633,73.7252561 28.6397328,64.1408344 34.7125718,58.3211747 C40.7854108,52.501515 50.3033889,52.4631852 56.4221194,58.2337483 C62.54085,64.0043115 63.140134,73.5842121 57.789026,80.0845348 C56.501627,81.7069153 55.8161016,83.7315878 55.8507261,85.8092275 L55.8507261,85.8092275 C55.8507261,89.7805815 59.043014,93 62.9809006,93 L91.2246986,93 L91.2246986,66.7222644 C91.2210231,64.2313434 92.1996234,61.8411746 93.9448303,60.0785165 C95.6900372,58.3158583 98.0586057,57.325388 100.528538,57.3253908 L103.380608,57.3253908 C105.452306,57.3000431 107.468471,58.0008408 109.084747,59.3080892 C115.521018,64.6808389 124.989998,64.0817628 130.709999,57.9399196 C136.43,51.7980764 136.43,42.229849 130.709999,36.0880057 C124.989998,29.9461625 115.521018,29.3470864 109.084747,34.7198361 C107.476039,36.0181764 105.468429,36.709528 103.408298,36.6746092 L100.556228,36.6746092 C95.4178594,36.6746092 91.2523886,32.4737369 91.2523886,27.2916983 L91.2523886,1 L0,1 L0,93 L27.4269431,93 C31.8159663,93 35.3739725,89.4117549 35.3739725,84.9854303 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M91.1076233,36 L93.7982063,36 C94.891411,35.9991768 95.9757217,35.7939957 97,35.3938711 C93.4306093,34.0476672 91.04814,30.504707 91.0403587,26.5313134 L91.0403587,0 L82,0 L82,26.5313134 C82,29.0547496 82.9616249,31.4738186 84.671597,33.2515316 C86.3815691,35.0292447 88.6983378,36.0185131 91.1076233,36 L91.1076233,36 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M126.353067,48.8614669 C125.580627,55.1512634 121.141144,60.4071471 115.014174,62.2855316 C121.104575,64.1451266 127.733085,62.3043613 131.949631,57.5824828 C136.166176,52.8606042 137.186841,46.1354801 134.557368,40.4001801 C131.927896,34.6648801 126.137145,30.9856819 119.762335,31 C118.147592,31.0003997 116.542122,31.2410674 115,31.713939 C122.483213,33.9688086 127.262246,41.1870004 126.353067,48.8614669 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M91.0403627,66.4825832 C91.0424817,62.5039923 93.4259785,58.9538408 97,57.6058708 C95.979731,57.2082591 94.9002664,57.0031315 93.8116613,57 L91.0403627,57 C88.6403794,57 86.3388893,57.9995017 84.643107,59.7782372 C82.9473247,61.5569727 81.9964386,63.9689423 82,66.4825832 L82,93 L91.0403627,93 L91.0403627,66.4825832 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M35.3895631,83.9975554 C35.3531542,82.2283809 34.6905926,80.5292765 33.5194175,79.2016652 C30.0684546,75.1186618 28.8980891,69.5785787 30.4032344,64.4507918 C31.9083796,59.3230049 35.888277,55.2914664 41,53.716528 C36.5327884,52.3285991 31.6803612,52.9768637 27.7352398,55.4886462 C23.7901183,58.0004287 21.1529154,62.1206667 20.5260922,66.7518689 C19.9030629,71.2242189 21.212106,75.7503365 24.1268204,79.2016652 C25.3043556,80.5253992 25.9678668,82.2269389 25.996966,83.9975554 C25.9969727,88.4117447 22.418859,91.9923098 18,92 L27.3786408,92 C31.802951,92 35.3895631,88.4171835 35.3895631,83.9975554 L35.3895631,83.9975554 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M35.3739725,84.9854303 C35.3378541,83.2135751 34.6805769,81.5118963 33.5187427,80.1822735 C28.11633,73.7252561 28.6397328,64.1408344 34.7125718,58.3211747 C40.7854108,52.501515 50.3033889,52.4631852 56.4221194,58.2337483 C62.54085,64.0043115 63.140134,73.5842121 57.789026,80.0845348 C56.501627,81.7069153 55.8161016,83.7315878 55.8507261,85.8092275 C55.8507261,89.7805815 59.043014,93 62.9809006,93 L92,93 L92,66.5 C91.9963245,64.009079 92.1996234,61.8411746 93.9448303,60.0785165 C95.6900372,58.3158583 98.0586057,57.325388 100.528538,57.3253908 L103.380608,57.3253908 C105.452306,57.3000431 107.468471,58.0008408 109.084747,59.3080892 C115.521018,64.6808389 124.989998,64.0817628 130.709999,57.9399196 C136.43,51.7980764 136.43,42.229849 130.709999,36.0880057 C124.989998,29.9461625 115.521018,29.3470864 109.084747,34.7198361 C107.476039,36.0181764 105.468429,36.709528 103.408298,36.6746092 L100.556228,36.6746092 C95.4178594,36.6746092 91.2523886,32.4737369 91.2523886,27.2916983 L91.2523886,1 L0,1 L0,93 L27.4269431,93 C31.8159663,93 35.3739725,89.4117549 35.3739725,84.9854303 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></path>
                  <path
                    d="M83.9854178,153.611485 L83.9854178,153.611485 C82.213563,153.647618 80.5118844,154.305166 79.1822618,155.467478 C72.7268345,160.878279 63.1383436,160.358794 57.3153529,154.282771 C51.4923621,148.206749 51.4539952,138.681053 57.2278556,132.559067 C63.001716,126.437082 72.5857162,125.841609 79.0845231,131.201067 C80.7116166,132.478901 82.7341955,133.159105 84.8092149,133.126313 L84.8092149,133.126313 C86.7174629,133.13 88.5486237,132.379662 89.8979614,131.041145 C91.247299,129.702629 92.0037025,127.886153 92,125.993208 L92,92.1975223 L65.708292,92.1975223 C60.528144,92.1975223 56.3253825,93.5825913 56.3253825,88.4301344 L56.3253825,85.5768921 C56.2949182,83.5058514 56.9908483,81.4886608 58.2941179,79.8704075 C61.1436144,76.4741992 62.4207189,72.0452064 61.812709,67.6679489 C60.8870204,61.0141172 55.8293378,55.6640478 49.1949337,54.3207344 C42.5605295,52.9774209 35.7937797,55.9333162 32.3134216,61.6950253 C28.8330634,67.4567344 29.3968057,74.7698758 33.7198311,79.9396609 C35.0181712,81.5490306 35.7095227,83.5574662 35.6746039,85.6184441 L35.6746039,88.4439851 C35.6746039,93.5825913 31.485805,92.2113729 26.2777317,92.2113729 L0,92.2113729 L0,189 L92,189 L92,161.561782 C92,159.453232 91.1555968,157.431043 89.6525736,155.940073 C88.1495504,154.449103 86.1110136,153.611485 83.9854178,153.611485 L83.9854178,153.611485 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M36,91.6620654 L36,88.9034468 C36,87.7724132 35.7883887,88.0482751 35.393871,87 C34.0694189,90.558618 30.6173892,91.7310309 26.5172234,91.7310309 L0,91.7310309 L0,101 L26.5172234,101 C29.0431008,101.003737 31.4658033,100.01935 33.2465705,98.2657365 C35.0273378,96.5121226 36.0185341,94.134666 36,91.6620654 L36,91.6620654 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M65.4684932,91.7452924 C61.3823875,91.7452924 57.9303327,90.5659068 56.6058708,87 C56.2113503,88.0406343 56,87.7631318 56,88.900892 L56,91.6759167 C56,96.8254657 60.2391888,101 65.4684932,101 L92,101 L92,91.7452924 L65.4684932,91.7452924 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M84.0375478,153.648878 C82.2679661,153.685265 80.5684706,154.347425 79.2405538,155.51789 C75.1503986,158.985313 69.5903488,160.161325 64.4469456,158.646901 C59.3035425,157.132478 55.2672961,153.130932 53.7077167,148 C51.6257791,154.756436 54.2456373,162.076975 60.1413302,165.977126 C66.0370231,169.877277 73.797461,169.423568 79.1987196,164.862947 C80.5266364,163.692482 82.2261318,163.030323 83.9957135,162.993936 C88.4163589,162.993936 92,166.578373 92,171 L92,161.613099 C91.9771254,157.224093 88.4255788,153.671758 84.0375478,153.648878 L84.0375478,153.648878 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                    fill-rule="nonzero"
                  ></path>
                  <rect
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Rectangle"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                    fill-rule="nonzero"
                    transform="translate(46.000000, 183.500000) rotate(180.000000) translate(-46.000000, -183.500000) "
                    x="1"
                    y="179"
                    width="90"
                    height="9"
                  ></rect>
                  <path
                    d="M83.9854178,153.611485 L83.9854178,153.611485 C82.213563,153.647618 80.5118844,154.305166 79.1822618,155.467478 C72.7268345,160.878279 63.1383436,160.358794 57.3153529,154.282771 C51.4923621,148.206749 51.4539952,138.681053 57.2278556,132.559067 C63.001716,126.437082 72.5857162,125.841609 79.0845231,131.201067 C80.7116166,132.478901 82.7341955,133.159105 84.8092149,133.126313 L84.8092149,133.126313 C86.7174629,133.13 88.5486237,132.379662 89.8979614,131.041145 C91.247299,129.702629 92.0037025,127.886153 92,125.993208 L92,92.1975223 L65.708292,92.1975223 C60.528144,92.1975223 56.3253825,89.4273841 56.3253825,84.2749271 L56.3253825,85.5768921 C56.2949182,83.5058514 56.9908483,81.4886608 58.2941179,79.8704075 C61.1436144,76.4741992 62.4207189,72.0452064 61.812709,67.6679489 C60.8870204,61.0141172 55.8293378,55.6640478 49.1949337,54.3207344 C42.5605295,52.9774209 35.7937797,55.9333162 32.3134216,61.6950253 C28.8330634,67.4567344 29.3968057,74.7698758 33.7198311,79.9396609 C35.0181712,81.5490306 35.7095227,83.5574662 35.6746039,85.6184441 L35.6746039,84.2887778 C35.6746039,89.4273841 31.485805,92.2113729 26.2777317,92.2113729 L0,92.2113729 L0,189 L92,189 L92,161.561782 C92,159.453232 91.1555968,157.431043 89.6525736,155.940073 C88.1495504,154.449103 86.1110136,153.611485 83.9854178,153.611485 L83.9854178,153.611485 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></path>
                </g>
                <g id="illu_puzzle-zusammen-schieben_duotone_svg__Man_17L" transform="translate(351.000000, 53.000000)">
                  <path
                    d="M37.4739764,43.1323584 C42.4982651,60.8652777 53.9486468,76.1086343 53.9486468,76.1086343 L86.7453158,72.8123975 L63.1505899,28.5009614 L45.4545455,25.4272553 L15.9611381,16.7346478 L12.8938237,19.2659351 L18.4455239,24.1337953 L32.7827897,30.2672992 L25.1492019,31.1991467 L5.95419847,14.0503706 L2.37335184,18.5705265 L19.5975017,42.0892455 L37.4739764,43.1323584 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    fill-rule="nonzero"
                  ></path>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    fill-rule="nonzero"
                    points="54.240111 76.0530016 85.870923 73.3269999 95.9333796 108.445134 115.711312 134.522957 103.913949 135.399172 81.0131853 111.226769 68.0638446 88.7511627 56.7244969 107.930532 66.7869535 134.703763 58.1124219 135.746876 41.2907703 103.924978"
                  ></polygon>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                    fill-rule="nonzero"
                    points="61.0687023 83.0070877 54.9895906 107.290756 68.0083276 88.8624281"
                  ></polygon>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                    points="82.1790423 72.6315913 86.0513532 72.2282543 62.5121443 27.9585426 58.5149202 27.4300321 53.9486468 31.3104121 59.0423317 32.4786986"
                  ></polygon>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                    points="40.7078418 31.4355857 23.5947259 35.7749353 5.02428869 15.3855551 6.24566273 13.8695643 24.6911867 31.379953"
                  ></polygon>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                    points="39.611381 23.7304583 42.9701596 30.8514424 12.7133935 19.5580067 15.9056211 16.6511988"
                  ></polygon>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    fill-rule="nonzero"
                    points="97.8764747 137.930459 120 137.930459 117.182512 134.008355 98.9590562 135.552162"
                  ></polygon>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    fill-rule="nonzero"
                    points="49.3823734 138 69.8820264 137.888735 67.8278973 133.66065 50.4510756 135.496529"
                  ></polygon>
                  <path
                    d="M42.2345593,4.31465014 C42.2345593,4.31465014 36.9604441,7.87514219 36.3913949,11.6442568 C35.2810548,18.8625981 40.666204,31.5746674 51.0201249,31.1156977 C60.7356003,30.6845444 62.2900763,18.5983428 62.2900763,18.5983428 L42.2345593,4.31465014 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M38.9868147,6.08098799 C37.5386391,7.60164408 36.6270746,9.55557773 36.3913949,11.6442568 C35.2810548,18.8625981 40.666204,31.5746674 51.0201249,31.1156977 C60.7356003,30.6845444 62.2900763,18.5983428 62.2900763,18.5983428"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></path>
                  <path
                    d="M33.4906315,2.07543444 C35.271546,3.12541485 37.3280341,3.61030271 39.389313,3.46625164 C43.6502429,3.29935358 44.6217904,0.503810992 49.2019431,0.0726576582 C50.8198786,-0.0928040289 52.4542029,0.024848982 54.0319223,0.42036196 C54.0319223,0.42036196 60.8188758,-2.51426235 64.2886884,9.97527617 C66.8979875,19.3632923 62.3178348,25.399439 62.3178348,25.399439 C62.3178348,25.399439 53.0603747,22.3674575 50.2984039,17.722128 C47.536433,13.0767985 48.2997918,10.768042 48.2997918,10.768042 C45.5973992,10.7799387 42.9070926,11.1303606 40.2914643,11.8111549 C40.6922128,10.856899 41.3388508,10.0265177 42.1651631,9.40504111 C40.3578286,9.82688568 38.4689138,9.73537381 36.7106176,9.14078584 C34.9545118,8.57118962 33.6263041,7.11873621 33.2130465,5.31603853 C34.3756007,5.44040341 35.5480634,5.44040341 36.7106176,5.31603853 C36.7106176,5.31603853 33.8098543,4.60672175 33.4906315,2.07543444 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M5.71825121,13.2019721 C5.71825121,13.2019721 5.09368494,9.54412283 4.62179042,6.94329466 C4.0666204,3.81395594 0,4.85706885 0,8.59836713 C0,15.5524532 1.47120056,18.8625981 1.47120056,18.8625981 L5.71825121,13.2019721 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M15.9611381,16.748556 C15.9611381,16.748556 14.6703678,13.9669215 13.4489938,11.6303486 C12.0610687,8.84871423 8.31367106,10.8375828 9.71547536,14.3146258 C10.3364343,15.9463381 11.2436155,17.4536071 12.3941707,18.7652409 L15.9611381,16.748556 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    fill-rule="nonzero"
                  ></path>
                  <polyline
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                    points="85.870923 73.3269999 95.9333796 108.445134 115.711312 134.522957 103.913949 135.399172 81.0131853 111.226769 67.480916 87.7636825 53.4351145 75.9417363"
                  ></polyline>
                  <polyline
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                    points="53.9486468 76.2894406 41.2907703 103.924978 58.1124219 135.746876 66.7869535 134.703763 56.7244969 107.930532 68.1193616 88.7233464"
                  ></polyline>
                  <path
                    d="M59.1533657,27.263134 L63.1505899,28.473145 L86.7453158,72.8123975 L53.9486468,76.1086343 C46.8311585,66.368183 41.398083,55.4981954 37.8764747,43.9529405"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></path>
                  <path
                    d="M49.0909091,43.42443 C48.5773768,43.42443 19.5975017,42.214419 19.5975017,42.214419 L2.08188758,18.3897203 L6.24566273,13.354962 L24.8022207,31.4355857 L44.9271339,30.0447685"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></path>
                  <polyline
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                    points="38.3344899 23.0906824 15.9611381 16.6511988 12.6578765 18.9182308"
                  ></polyline>
                  <line
                    x1="34.351145"
                    y1="30.7401771"
                    x2="18.0430257"
                    y2="24.6483977"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></line>
                </g>
                <g id="illu_puzzle-zusammen-schieben_duotone_svg__Man_17R" transform="translate(0.000000, 52.000000)">
                  <path
                    d="M82.5260236,43.1234393 C77.5017349,60.8580258 66.0513532,76.1584534 66.0513532,76.1584534 L33.3102012,72.8062688 L56.9049271,28.4628477 L74.5454545,25.3888527 L104.094379,16.6954279 L107.21721,19.2547722 L101.66551,24.1230901 L87.2727273,30.2571706 L94.9063151,31.1891057 L113.990285,14.0387173 L117.571131,18.5592982 L100.346981,42.0802283 L82.5260236,43.1234393 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    fill-rule="nonzero"
                  ></path>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    fill-rule="nonzero"
                    points="65.759889 76.0471776 34.129077 73.3209196 24.0666204 108.442356 4.28868841 134.52263 16.0860514 135.398927 38.9868147 111.224252 51.9500347 88.7465325 63.2755031 107.927705 53.2130465 134.703453 61.8875781 135.746664 78.7092297 103.921775"
                  ></polygon>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-2, #BED7F0)"
                    fill-rule="nonzero"
                    points="58.9312977 83.0019174 65.0104094 107.287869 51.9916724 88.8578084"
                  ></polygon>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                    points="37.8348369 72.6254456 33.9486468 72.2220707 57.4878557 27.948197 61.4850798 27.4196367 66.0513532 31.3003816 60.9576683 32.4687778"
                  ></polygon>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                    points="79.2921582 31.4255669 96.4052741 35.7653245 114.975711 15.3740273 113.754337 13.8578941 95.3088133 31.369929"
                  ></polygon>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-orange, #FFB27F)"
                    fill-rule="nonzero"
                    points="80.388619 23.7197151 77.0437196 30.8413687 107.286607 19.5468712 104.108258 16.63979"
                  ></polygon>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    fill-rule="nonzero"
                    points="22.1235253 137.930453 0 137.930453 2.81748786 134.007979 21.0409438 135.538022"
                  ></polygon>
                  <polygon
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    fill-rule="nonzero"
                    points="70.6176266 138 50.1179736 137.888724 52.1721027 133.660242 69.5489244 135.496294"
                  ></polygon>
                  <path
                    d="M77.7238029,4.30208154 C77.7238029,4.30208154 82.9979181,7.86290833 83.5669674,11.6323773 C84.6773074,18.8513973 79.2921582,31.5646617 68.9382373,31.1056488 C59.222762,30.674455 57.6682859,18.5871171 57.6682859,18.5871171 L77.7238029,4.30208154 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-white, #FFFFFF)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M81.0131853,6.06858546 C82.4613609,7.58938452 83.3729254,9.54350187 83.6086051,11.6323773 C84.7189452,18.8513973 79.333796,31.5646617 68.9798751,31.1056488 C59.2643997,30.674455 57.7099237,18.5871171 57.7099237,18.5871171"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></path>
                  <path
                    d="M86.5093685,2.0765648 C84.728454,3.12664392 82.6719659,3.61157737 80.610687,3.46751276 C76.3358779,3.300599 75.3782096,0.504793594 70.7841777,0.0735997245 C69.1708687,-0.0933892646 67.5408395,0.0243037768 65.9680777,0.421336716 C65.9680777,0.421336716 59.167245,-2.52747297 55.6974323,9.97714924 C53.0881332,19.366048 57.6682859,25.4027622 57.6682859,25.4027622 C57.6682859,25.4027622 66.925746,22.3704956 69.6877169,17.7247294 C72.4496877,13.0789632 71.6863289,10.7699896 71.6863289,10.7699896 C74.3887215,10.7818874 77.0790282,11.1323423 79.6946565,11.8132006 C79.2939079,10.858855 78.6472699,10.0283956 77.8209577,9.40686057 C79.6231537,9.84669664 81.5120039,9.7744461 83.2755031,9.19821838 C85.035428,8.63025547 86.3685956,7.17800416 86.7869535,5.37311147 C85.61974,5.49711414 84.4427166,5.49711414 83.2755031,5.37311147 C83.2755031,5.37311147 86.1901457,4.59418061 86.5093685,2.0765648 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M114.281749,13.1624201 L115.37821,6.90315424 C115.93338,3.77352131 120,4.81673229 120,8.55838232 C120,15.5131221 118.528799,18.8096688 118.528799,18.8096688 L114.281749,13.1624201 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M104.094379,16.7232469 C104.094379,16.7232469 105.385149,13.9413509 106.606523,11.6184678 C107.994448,8.83657191 111.741846,10.8256275 110.340042,14.2890879 C109.713649,15.9140922 108.806937,17.4160152 107.661346,18.7262119 L104.094379,16.7232469 Z"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    fill="var(--color-illustration-classic-blue-1, #0066B3)"
                    fill-rule="nonzero"
                  ></path>
                  <polyline
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                    points="34.129077 73.3209196 24.0666204 108.442356 4.28868841 134.52263 16.0860514 135.398927 38.9868147 111.224252 52.519084 87.7589595 66.5648855 75.9359018"
                  ></polyline>
                  <polyline
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                    points="66.0513532 76.2836388 78.7092297 103.921775 61.8875781 135.746664 53.2130465 134.703453 63.2755031 107.927705 51.8806384 88.7048041"
                  ></polyline>
                  <path
                    d="M60.8466343,27.252723 L56.9049271,28.4628477 L33.3102012,72.8062688 L66.0513532,76.1584534 C73.1939595,66.4040156 78.6461223,55.5135585 82.1790423,43.9440986"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></path>
                  <path
                    d="M70.9090909,43.4155383 C71.4226232,43.4155383 100.402498,42.2054136 100.402498,42.2054136 L117.973629,18.378475 L113.809854,13.3432433 L95.2532963,31.4255669 L75.1283831,30.0346189"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></path>
                  <polyline
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                    points="81.6655101 23.0798791 104.038862 16.63979 107.342124 18.9070352"
                  ></polyline>
                  <line
                    x1="85.648855"
                    y1="30.7300929"
                    x2="101.956974"
                    y2="24.6377408"
                    id="illu_puzzle-zusammen-schieben_duotone_svg__Path"
                    stroke="var(--color-illustration-classic-blue-1, #0066B3)"
                    stroke-width="2"
                  ></line>
                </g>
              </g>
            </g>
          </svg>
        }
      </figure>

      <h2>Kundenbeziehung stärken</h2>
      <p>
        Durch den ganzheitlichen Ansatz der Beratungsmodule machen Sie Ihre Kompetenz deutlich. Ihre Kunden fühlen sich
        wohl und wissen, dass sie bei Ihnen gut aufgestellt sind.
      </p>
    </kf-tile>
  </div>
</div>
