<button
  #reference
  type="button"
  tabindex="{{ tabIndex }}"
  [ngClass]="buttonClass"
  [style]="buttonStyle"
  (click)="handleClick($event)"
  (focus)="focused.emit()"
  (keyup)="handleKeyup($event, reference)"
  (keydown)="preventScrolling($event)"
  [disabled]="disabled"
>
  @if (hasIcon && pos === 'left') {
    <ng-template *ngTemplateOutlet="iconTemplate"></ng-template>
  }

  @if (useLabel) {
    <ng-template *ngTemplateOutlet="labelTemplate"></ng-template>
  } @else {
    <ng-template *ngTemplateOutlet="invisibleLabelTemplate"></ng-template>
  }

  @if (hasIcon && pos === 'right') {
    <ng-template *ngTemplateOutlet="iconTemplate"></ng-template>
  }
</button>

<!-- LABEL CONTENT -->
<ng-template #labelTemplate>
  <span
    class="position-relative line-clamp"
    [class.button-text-transparent]="useSpinnerOnLabel"
    [class.ml-auto]="hasIcon && pos === 'right' && useIconOnEdge"
    [class.mr-auto]="hasIcon && pos === 'left' && useIconOnEdge"
    [style.-webkitLineClamp]="options.maxLabelLines"
  >
    {{ label }}
    @if (useSpinnerOnLabel) {
      <span class="position-absolute button-spinner" [style]="iconStyle"></span>
    }
  </span>
</ng-template>

<ng-template #invisibleLabelTemplate>
  <span class="sr-only">
    {{ label }}
  </span>
</ng-template>

<!-- ICON CONTENT -->
<ng-template #iconTemplate>
  @if (icon !== buttonIcon.number) {
    <vr-icon
      #iconContainer
      tabindex="-1"
      class="button-icon flex-center"
      [icon]="icon"
      [iconColor]="disabled ? textColorDisabled : textColor"
      [class.ml-2]="useLabel && pos === 'right' && !useIconOnEdge"
      [class.mr-2]="useLabel && pos === 'left' && !useIconOnEdge"
      [class.ml-auto]="useLabel && pos === 'right' && useIconOnEdge"
      [class.mr-auto]="useLabel && pos === 'left' && useIconOnEdge"
      [width]="options.iconSize"
      [height]="options.iconSize"
      [style.width]="options.iconSize"
      [style.height]="options.iconSize"
    >
    </vr-icon>
    @if (useSpinnerOnIcon) {
      <span class="position-absolute button-spinner" [style]="iconStyle"></span>
    }
  } @else {
    <span
      style="margin-top: -1px"
      class="icon-number"
      [class]="iconClass"
      [class.ml-2]="label && pos === 'right'"
      [class.mr-2]="label && pos === 'left'"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11" [attr.stroke]="textColor" stroke-width="2" />
        <text x="50%" y="50%" stroke-width="2px" dy=".35em" font-size="14" [attr.fill]="textColor" text-anchor="middle">
          {{ number }}
        </text>
      </svg>
    </span>
  }
</ng-template>
