import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextEnum, LicenseStatusEnum } from '@enums';
import { environment } from '@environment/environment';
import { CacheService } from '@services/cache.service';
import { ConfigService } from '@services/config.service';
import {
  GetTestConsultationsResponse,
  GraphResponse,
  PostConsultationResponse,
  UNDTicketResponse,
  UNDUploadRequest,
  UNDUploadResponse,
} from '@utils/domains';
import moment from 'moment';
import { Observable } from 'rxjs';

/**
 * Service to invoke backend endpoints
 */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  private options = { headers: this.headers };

  private api = 'api/v1';
  private hub = 'hub/v1';
  private portalServiceUrl = '';

  constructor(
    private http: HttpClient,
    private cacheService: CacheService,
    private configService: ConfigService
  ) {
    if (environment.platform === 'vp' && (!environment.isLocal || window.location.pathname.includes('/bws'))) {
      this.hub = `serviceproxy/bgzv-backend-guided-service-hub/api/v1`;
      this.api = `serviceproxy/bgzv-backend-consultation-app/api/v1`;
      this.portalServiceUrl = 'bws/serviceproxy';
    }
  }

  // ----------------------------------------------------------------------- //
  // ----------------------- GET CONFIGURATION CALLS ----------------------- //
  // ----------------------------------------------------------------------- //
  getHubOpenApiConfiguration(): Observable<any> {
    const request = this.http.get(`${this.hub}/v3/api-docs/configurator`);
    return this.cacheService.fetch('hub-config', () => request);
  }

  getAppOpenApiConfiguration(): Observable<any> {
    const request = this.http.get(`${this.api}/v3/api-docs/configurator`);
    return this.cacheService.fetch('app-config', () => request);
  }

  // ----------------------------------------------------------------------- //
  // -------------------------- GENERIC API CALLS -------------------------- //
  // ----------------------------------------------------------------------- //
  setApi() {
    return this.configService.currentContext === ContextEnum.bankHub ? this.hub : this.api;
  }

  get(endpoint: string, renew: boolean = false): Observable<any> {
    const storage = `${this.configService.currentContext}-${endpoint}`;
    const request = this.http.get<any>(`${this.setApi()}/configurator/${endpoint}`);
    return this.cacheService.fetch(storage, () => request, renew);
  }

  post(endpoint, object) {
    return this.http.post(`${this.setApi()}/configurator/${endpoint}`, object, this.options);
  }

  postHub(endpoint, object) {
    return this.http.post(`${this.hub}/configurator/${endpoint}`, object, this.options);
  }

  patch(endpoint, object) {
    return this.http.patch(`${this.setApi()}/configurator/${endpoint}/${object.id}`, object, this.options);
  }

  delete(endpoint, id) {
    return this.http.delete(`${this.setApi()}/configurator/${endpoint}/${id}`);
  }

  // Fixed Endpoints for communication
  getHubData(endpoint: string, renew: boolean = false): Observable<any> {
    const storage = `hub-${endpoint}`;
    const request = this.http.get<any>(`${this.hub}/configurator/${endpoint}`);
    return this.cacheService.fetch(storage, () => request, renew);
    // return this.http.get<any>(`${this.hub}/configurator/${endpoint}`);
  }

  getCAData(endpoint: string, renew: boolean = false): Observable<any> {
    const storage = `api-${endpoint}`;
    const request = this.http.get<any>(`${this.api}/configurator/${endpoint}`);
    return this.cacheService.fetch(storage, () => request, renew);
    // return this.http.get<any>(`${this.api}/configurator/${endpoint}`);
  }

  getCustomInstanceCAData(endpoint, customInstanceId): Observable<any> {
    return this.http.get<any>(`${this.api}/configurator/${endpoint}`, {
      params: { 'instance-id': customInstanceId },
      responseType: 'text' as any,
    });
  }

  // Special Endpoints
  getContentEvalCSVData(instanceId): Observable<any> {
    return this.http.get<any>(`${this.api}/configurator/content-evaluations/csv`, {
      params: { 'instance-id': instanceId },
      responseType: 'text' as any,
    });
  }

  getRZBKCSVData(): Observable<any> {
    return this.http.get<any>(`${this.hub}/consultations/rzbk/download`, { responseType: 'text' as any });
  }

  getCustomizationData(): Observable<any> {
    return this.http.get<any>(`${this.api}/configurator/customisation/export`, {
      responseType: 'blob' as any,
    });
  }

  getMediaContent(mediaUrl: string, renew = false): Observable<any> {
    const options = { responseType: 'blob' as 'json' };
    const params = `?url=${mediaUrl}`;
    return this.cacheService.fetch(
      `mediaContent_${mediaUrl}`,
      () => this.http.get(`${this.api}/content/medias/download${params}`, options),
      renew
    );
  }

  postImportCustomization(object: FormData): Observable<any> {
    return this.http.post<any>(`${this.api}/configurator/customisation/import`, object, {
      responseType: 'text' as any,
    });
  }

  getInstanceExport(instanceId: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/zip');
    const responseType = 'blob' as any;
    const options = { headers, responseType };

    return this.http.get<any>(`${this.hub}/configurator/instances/${instanceId}/export`, options);
  }

  postInstanceImport(obj: FormData): Observable<any> {
    return this.http.post<any>(`${this.hub}/configurator/instances/import`, obj);
  }

  getChangelogData(instanceId, date = ''): Observable<any> {
    let options = {};
    options['params'] = { 'instance-id': instanceId };
    options['responseType'] = 'text' as any;

    if (date) {
      options['params'] = { ...options['params'], from: date, to: moment().format('YYYY-MM-DD') };
    }
    return this.http.get<any>(`${this.api}/configurator/changelog/csv`, options);
  }

  postUploadFile(endpoint, object: FormData): Observable<any> {
    return this.http.post(`${this.api}/${endpoint}`, object);
  }

  clearCache(): Observable<any> {
    return this.http.delete(`${this.api}/application/clear-cache`);
  }

  getLicenseStatus(): Observable<LicenseStatusEnum> {
    return this.http.get<LicenseStatusEnum>(`${this.hub}/application/license/status`);
  }

  updateLicense(): Observable<any> {
    return this.http.post(`${this.hub}/application/license/update-license`, {});
  }

  copyInstance(instanceId: string): Observable<any> {
    return this.http.post(`${this.hub}/configurator/instances/${instanceId}/copy`, {});
  }

  copySubTopic(subtopicId: number): Observable<any> {
    return this.http.post(`${this.api}/configurator/subtopics/${subtopicId}/copy`, {}, this.options);
  }

  copyQuestion(questionid: number): Observable<any> {
    return this.http.post(`${this.api}/configurator/questions/${questionid}/copy`, {}, this.options);
  }

  resetContentForTenant() {
    return this.http.post(`${this.hub}/application/contenet/reset`, {}, this.options);
  }

  // ----------------------------------------------------------------------- //
  // --------------------- TEST-CONSULTATION API CALLS --------------------- //
  // ----------------------------------------------------------------------- //

  getTestConsultations(instanceId: string): Observable<GetTestConsultationsResponse[]> {
    return this.http.get<GetTestConsultationsResponse[]>(`${this.hub}/instances/${instanceId}/test-consultations`);
  }

  getProdConsultations(instanceId: string): Observable<GetTestConsultationsResponse[]> {
    return this.http.get<GetTestConsultationsResponse[]>(`${this.hub}/instances/${instanceId}/consultations`);
  }

  createTestConsultation(instanceId: string): Observable<PostConsultationResponse> {
    return this.http.post<PostConsultationResponse>(`${this.hub}/instances/${instanceId}/test-consultations`, {});
  }

  // ----------------------------------------------------------------------- //
  // --------------------------- GRAPH API CALLS --------------------------- //
  // ----------------------------------------------------------------------- //

  getGraphCompositionData(): Observable<GraphResponse> {
    return this.http.get<GraphResponse>(`${this.api}/configurator/graphs/composition`, this.options);
  }

  getGraphSubtopicData(subtopicId: number): Observable<GraphResponse> {
    return this.http.get<GraphResponse>(`${this.api}/configurator/graphs/subtopic/${subtopicId}`, this.options);
  }

  getPdfFile(consultationId: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/pdf');
    const options = { headers: headers, responseType: 'blob' as 'json' };
    return this.http.get<any>(`${this.hub}/consultations/${consultationId}/pdf/result`, options);
  }

  // ----------------------------------------------------------------------- //
  // -------------------- DISCARD CONSULTATION API CALLS ------------------- //
  // ----------------------------------------------------------------------- //

  deleteConsultation(consultationId: number): Observable<any> {
    return this.http.delete<any>(`${this.hub}/consultations/${consultationId}`);
  }

  deleteProdConsultation(consultationId: number, instanceId: string): Observable<any> {
    return this.http.delete<any>(`${this.hub}/instances/${instanceId}/consultations/${consultationId}`);
  }

  archiveConsultation(consultationId: number): Observable<any> {
    return this.http.post(`${this.hub}/consultations/${consultationId}/archive`, {}, this.options);
  }

  archiveProdConsultation(consultationId: number, instanceId: string): Observable<any> {
    return this.http.put<any>(`${this.hub}/instances/${instanceId}/consultations/${consultationId}/archive`, {});
  }

  // ----------------------------------------------------------------------- //
  // --------------------------- VARIOUS API CALLS ------------------------- //
  // ----------------------------------------------------------------------- //

  getTenants(): Observable<any> {
    return this.http.get<any>(`${this.hub}/tenants`);
  }

  getInstances(renew: boolean = false): Observable<any> {
    const storage = `${this.configService.currentContext}-instances`;
    const request = this.http.get<any>(`${this.setApi()}/configurator/instances`);
    return this.cacheService.fetch(storage, () => request, renew);

    // return this.http.get<any>(`${this.hub}/configurator/instances`);
  }

  // ----------------------------------------------------------------------- //
  // ------------------------ UND SERVICE (VP ONLY) ------------------------ //
  // ----------------------------------------------------------------------- //

  getUNDTicket(): Observable<UNDTicketResponse> {
    // return this.http.get<UNDTicketResponse>(`${this.portalUrlBase}/datasharing-ticket/ticket`);
    return this.http.get<UNDTicketResponse>(`${this.hub}/und-service/ticket`);
  }

  postUNDUploadFile(data: FormData): Observable<UNDUploadResponse> {
    return this.http.post<UNDUploadResponse>(`/${this.portalServiceUrl}/datasharing/ticket/upload?owner=bgzv`, data);
  }

  postUNDDataToBackend(data: UNDUploadResponse): Observable<any> {
    const sendData = {
      undVersionId: data.undID,
      fileName: data.fileName,
    } as UNDUploadRequest;
    return this.http.post<any>(`${this.api}/content/medias/upload/und`, sendData, this.options);
  }

  postUNDImportCustomization(undVersionId: string): Observable<string> {
    return this.http.post<string>(
      `${this.api}/configurator/customisation/import/und/${undVersionId}`,
      {},
      { ...this.options, responseType: 'text' as any }
    );
  }

  postUNDImportInstance(undVersionId: string): Observable<string> {
    return this.http.post<string>(
      `${this.hub}/configurator/instances/import/und/${undVersionId}`,
      {},
      { ...this.options, responseType: 'text' as any }
    );
  }
}
