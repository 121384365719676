import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { UserRoleEnum } from '@enums';
import { environment } from '@environment/environment';
import { CacheService } from '@services/cache.service';
import { TenantService } from '@services/tenant.service';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { BehaviorSubject } from 'rxjs';

const poolData = {
  UserPoolId: environment.cognitoUserPoolId,
  ClientId: environment.cognitoClientId,
};

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  public currentAuthState = new BehaviorSubject<any>(null);
  public currentErrorState = new BehaviorSubject<any>(null);

  public headers = new HttpHeaders();

  private _authState: string;
  private _jwtToken: string;
  private _userObject: CognitoUser;

  public userPool = new CognitoUserPool(poolData);
  public keyPrefix = `CognitoIdentityServiceProvider.${this.userPool.getClientId()}`;

  constructor(private tenantService: TenantService, private cacheService: CacheService) {}

  ngOnDestroy(): void {}

  async login(email: string, password: string, newPassword?: string): Promise<any> {
    const self = this;

    const authenticationData = {
      Username: email.toLowerCase(),
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email.toLowerCase(),
      Pool: this.userPool,
    };

    this.userObject = new CognitoUser(userData);
    return new Promise((resolve, reject) => {
      self.userObject.authenticateUser(authenticationDetails, {
        onSuccess: function (session) {
          self.userObject.setSignInUserSession(session);

          self.jwtToken = session.getIdToken().getJwtToken();
          const payload = session.getIdToken().decodePayload();

          if (payload['cognito:groups']?.includes(UserRoleEnum.ContentAdmin)) {
            self.tenantService.tenantId = payload['custom:rzbk'];
            self.tenantService.userRole = payload['cognito:groups'];
            self.tenantService.userName = payload['cognito:username'];

            self.authState = 'signedIn';
            self.currentAuthState.next(self.authState);
            resolve(session);
          } else {
            self.currentErrorState.next({ code: 'NoContentAdmin' });
            reject(null);
          }
        },
        onFailure: function (err) {
          self.currentErrorState.next(err);
          reject(err);
        },
        newPasswordRequired(userAttributes, requiredAttributes) {
          if (newPassword) {
            self.userObject.completeNewPasswordChallenge(newPassword, {}, this);
          } else {
            self.authState = 'requireNewPassword';
            self.currentAuthState.next(self.authState);
            reject({ authState: 'requireNewPassword' });
          }
        },
      });
    });
  }

  logout() {
    // remove the entries from localStorage to be sure
    Object.entries(localStorage)
      .map(
        x => x[0] // get keys
      )
      .filter(x => x.includes(this.keyPrefix))
      .map(x => localStorage.removeItem(x));

    this.userObject?.signOut();
    this.jwtToken = '';
    this.authState = 'signedOut';
    this.currentAuthState.next(this.authState);
    this.cacheService.renewAll();
  }

  async getSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      const user = this.userPool?.getCurrentUser();
      if (!user) {
        this.authState = 'signedOut';
        this.currentAuthState.next(this.authState);
        resolve(null);
      }

      user.getSession((err, session) => {
        if (session) {
          this.jwtToken = session.getIdToken().getJwtToken();
          const payload = session.getIdToken().decodePayload();

          if (payload['cognito:groups'].includes(UserRoleEnum.ContentAdmin)) {
            this.tenantService.tenantId = payload['custom:rzbk'];
            this.tenantService.userRole = payload['cognito:groups'];
            this.tenantService.userName = payload['cognito:username'];

            this.authState = 'signedIn';
            this.currentAuthState.next(this.authState);

            resolve(session);
          } else {
            this.currentErrorState.next({ code: 'NoContentAdmin' });
            reject(null);
          }
        } else {
          this.authState = 'signedOut';
          this.currentAuthState.next(this.authState);
          resolve(null);
        }
      });
    });
  }

  public get authState(): string {
    return this._authState;
  }
  public set authState(value: string) {
    this._authState = value;
  }

  public get jwtToken(): string {
    return this._jwtToken;
  }
  public set jwtToken(value: string) {
    this._jwtToken = value;
  }

  public get userObject(): CognitoUser {
    return this._userObject;
  }
  public set userObject(value: CognitoUser) {
    this._userObject = value;
  }
}
